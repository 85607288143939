import { IChartBar } from 'componentes_share_admin/charts/bar/bar.component';
import { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import { getAxisIndex } from 'componentes_share_admin/machines/reports/reports.functions';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IOptionsReports } from 'pages/machines/kanban/reports/reports.component';

import { IDayReadingDataIJNew } from 'types/OEE-dayReading';

export function generateShiftReadingsOverview(
  monthlySummary: IDayReadingDataIJNew[] | null,
  filters: IFilters,
  report: IOptionsReports,
  getAxisLabels: string[],
  startDate: Date,
  endDate: Date,
  equipmentsIds: string[],
  eventsMap: { [key: string]: IOptionsEventList }
) {
  if (!monthlySummary) {
    return { aggregatedEvents: [], separatedReadings: {} };
  }

  const separatedReadings: { [key: string]: IChartDonut[] } = {};
  const aggregatedEvents: { [key: string]: IChartBar } = {};

  monthlySummary
    .filter((i) => !filters.equipments.includes(i.equipment_id))
    .forEach((reading) => {
      reading.data
        .filter((i) => !filters.events.includes(i.event_id))
        .forEach((day) => {
          const p = {
            labels: getAxisLabels,
            report: report.id,
            date: reading.date,
            start: startDate,
            end: endDate,
            equipIds: equipmentsIds,
            machines: reading.equipment_id,
          };

          const token = day.event_id;
          const event = eventsMap[token] || 'error';
          const index = getAxisIndex(p.labels, p.report, p.date, p.start, p.end, p.equipIds, p.machines);

          aggregatedEvents[token] = aggregatedEvents[token] || {
            id: event.id ?? 'error',
            name: event.nickName ?? 'error',
            color: event.color ?? 'error',
            value: 0,
            data: Array(getAxisLabels.length).fill(0),
            itemStyle: { color: event.color ?? 'error' },
          };

          aggregatedEvents[token].data[index] += Math.floor(day.sumSeconds);
          aggregatedEvents[token].value += Math.floor(day.sumSeconds);

          separatedReadings[reading.equipment_id] = separatedReadings[reading.equipment_id] || [];

          const existingReading = separatedReadings[reading.equipment_id].find((r) => r.id === token);

          if (existingReading) {
            existingReading.value += Math.floor(day.sumSeconds);
          } else {
            separatedReadings[reading.equipment_id].push({
              id: token,
              name: event.nickName ?? 'error',
              value: Math.floor(day.sumSeconds),
              itemStyle: { color: event.color },
            });
          }
        });
    });

  const aggregatedEventsArray = Object.values(aggregatedEvents).sort((a, b) => b.value - a.value);

  return { aggregatedEvents: aggregatedEventsArray, separatedReadings };
}
