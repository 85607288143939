import { useContext, useEffect, useState } from 'react';

import { collection, limit, onSnapshot, query, where } from 'firebase/firestore';

import { IOptionsData, IOptionsEventList } from 'types_new/ALL-options';

import { Auth } from 'contexts/auth.context';

import { ISettings } from 'routes/listRoutes';

import { firebaseFirestore } from '../../firebase/authentication';

export function useTempletes(configs: ISettings): {
  templete: IOptionsData | null;
  eventsMap: { [key: string]: IOptionsEventList };
  eventsList: IOptionsEventList[];
  eventsLoading: boolean;
} {
  const { user } = useContext(Auth);

  const [templete, setTemplete] = useState<IOptionsData | null>(null);
  const [eventsMap, setEventsMap] = useState<{ [key: string]: IOptionsEventList }>({});
  const [eventsList, setEventsList] = useState<IOptionsEventList[]>([]);
  const [eventsLoading, setEventsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !configs) return;

    setEventsLoading(true);

    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-options'),
      where('group', '==', configs.group),
      limit(1)
    );

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.docs) {
          const [object] = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as IOptionsData));

          const generateEventMap: { [key: string]: IOptionsEventList } =
            object?.event_list?.reduce((acc: { [key: string]: IOptionsEventList }, event: IOptionsEventList) => {
              acc[event.id] = event;
              acc[event.number] = event;
              return acc;
            }, {}) || {};

          setTemplete(object);
          setEventsList(object.event_list ?? []);
          setEventsMap(generateEventMap);
        }

        setEventsLoading(false);
      },
      (error) => {
        console.error('Erro ao buscar dados do templete:', error);

        setEventsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [configs, user]);

  return { templete, eventsMap, eventsList, eventsLoading };
}
