export function secondsToTime(seconds: number) {
  const newSeconds = Math.floor(seconds);

  const hours =
    Math.floor(newSeconds / 3600) < 10
      ? ('00' + Math.floor(newSeconds / 3600)).slice(-2)
      : Math.floor(newSeconds / 3600);
  const minutes = ('00' + Math.floor((newSeconds % 3600) / 60)).slice(-2);
  const secondsInternal = ('00' + ((newSeconds % 3600) % 60)).slice(-2);

  return hours + 'h : ' + minutes + 'm : ' + secondsInternal + 's';
}

export function formatTime(seconds: number) {
  if (seconds <= 0) {
    return '-';
  }

  const sec = seconds % 60;
  const min = Math.floor((seconds % 3600) / 60);
  const hrs = Math.floor((seconds % 86400) / 3600);
  const days = Math.floor(seconds / 86400);

  const formatSeconds = sec === 1 ? `${sec} segundo` : `${sec} segundos`;
  const formatMinutes = min === 1 ? `${min} minuto` : `${min} minutos`;
  const formatHours = hrs === 1 ? `${hrs} hora` : `${hrs} horas`;
  const formatDays = days === 1 ? `${days} dia` : `${days} dias`;

  if (seconds < 60) {
    return formatSeconds;
  } else if (seconds < 3600) {
    return `${formatMinutes} e ${formatSeconds}`;
  } else if (seconds < 86400) {
    return `${formatHours}, ${formatMinutes} e ${formatSeconds}`;
  } else {
    return `${formatDays}, ${formatHours}, ${formatMinutes} e ${formatSeconds}`;
  }
}
