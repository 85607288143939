import { useContext, useEffect, useState } from 'react';

import { Auth } from 'contexts/auth.context';

import { ICardData } from 'types/SCRUM-card';

import { getApi } from '../../../../../firebase/authentication';

export interface IProductionScheduling {
  celula: string;
  romaneio: string;
  seq: number;
  ordem: string;
  status_romaneio: string;
  necessidade: string;
  produto: string;
  processo: string;
}

export function useProductionScheduling(orderList: ICardData[]): {
  productionScheduling: IProductionScheduling[] | null;
  productionSchedulingLoading: boolean;
} {
  const { user, accessToken } = useContext(Auth);

  const [productionScheduling, setProductionScheduling] = useState<IProductionScheduling[] | null>(null);
  const [productionSchedulingLoading, setProductionSchedulingLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !orderList || !accessToken) return;

    const idsArray = orderList.map((i) => String(i.order));

    const fetch = async () => {
      setProductionSchedulingLoading(true);

      const api = getApi(accessToken);

      try {
        const response = await api.post('/productionScheduling/searchOrder', idsArray);

        setProductionScheduling(response.data ? response.data : []);
        setProductionSchedulingLoading(false);
      } catch (error) {
        setProductionScheduling([]);
        setProductionSchedulingLoading(false);

        console.error('Erro ao buscar dados de ProductionScheduling:', error);
      }
    };

    fetch();
  }, [accessToken, orderList, user]);

  return { productionScheduling, productionSchedulingLoading };
}
