import React from 'react';

import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';

import { catchErrors, catchInvalid } from 'components/formErrors/formErrors';

import { IValidationError } from 'types/ALL-yup';

import './inputTextarea.scss';

interface IProps extends InputTextareaProps {
  icon?: string;
  placeholder: string;
  formErr: IValidationError[];
  fieldCode: string;
  firstClick?: boolean;
}

const InputTextareaPrime: React.FC<IProps> = ({
  icon,
  placeholder,
  formErr,
  fieldCode,
  firstClick,
  ...InputTextareaProps
}) => {
  const invalid = catchInvalid(formErr, fieldCode);

  return (
    <div className='prime-input-textarea-default-class'>
      <span className='p-float-label p-input-icon-right width100'>
        <i
          className={
            firstClick ? (invalid ? 'pi pi-times input-text-icon-error' : 'pi pi-check input-text-icon-success') : icon
          }
        />
        <InputTextarea
          style={{ resize: 'none' }}
          className={
            firstClick
              ? invalid
                ? 'input-text-error width100'
                : 'input-text-success width100'
              : 'input-text-default width100'
          }
          {...InputTextareaProps}
        />
        <label
          className={firstClick ? (invalid ? 'input-text-icon-error' : 'input-text-icon-success') : ''}
          htmlFor={placeholder}
        >
          {placeholder}
        </label>
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputTextareaPrime;
