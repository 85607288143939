import React from 'react';

import { Data } from 'contexts/data.context';

import { IFactoryData } from 'types/ALL-factory';
import { IGroupData } from 'types/ALL-group';
import { IParkingSpaceData } from 'types/KC-ParkingSpace';

import s from './parking.module.scss';

interface IParkingFormatProps {
  factory: string;
  group: string;
  parking: string;
}

const ParkingFormat: React.FC<IParkingFormatProps> = ({ factory, group, parking }) => {
  const { searchDataById } = React.useContext(Data);

  const findFactory = searchDataById('factory', factory) as IFactoryData;
  const findGroup = searchDataById('group', group) as IGroupData;
  const findParking = searchDataById('parkingSpace', parking) as IParkingSpaceData;

  return (
    <div className={s.container}>
      <span>{findFactory?.name} </span>
      <span>{findGroup?.name} </span>
      <span>{findParking?.name} </span>
    </div>
  );
};

export default ParkingFormat;
