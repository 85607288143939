import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Timestamp } from 'firebase/firestore';

import { differenceInSeconds, format } from 'date-fns';

import { FaCheck } from 'react-icons/fa';

import ComponentConfig from 'pages/components_prime/componentConfig';
import ParkingFormat from 'pages/machines/scrum/components/parking/parking.component';

import { Auth } from 'contexts/auth.context';
import { Data } from 'contexts/data.context';
import { Toaster } from 'contexts/toast.context';

import { IParkingSpaceData } from 'types/KC-ParkingSpace';
import { ICardData } from 'types/SCRUM-card';

import { formatTime } from '../../components/scrum.functions';
import { genericFunctionToEdit } from '../../monitor/controllers/firebase';
import { useSearchSector } from '../../monitor/hooks/useSearchSector';
import { useSector } from '../../monitor/hooks/useSector';
import { IShowHideCard } from '../frame.page';
import { IProductionScheduling } from '../hooks/useProductionScheduling';

import './card.scss';

interface ICardProps {
  card: ICardData;
  setShowHideCard: React.Dispatch<React.SetStateAction<IShowHideCard>>;
  color: string;
  cardsLength: number;
  index: number;
  productionScheduling: IProductionScheduling | null;
}

const Card: React.FC<ICardProps> = ({ card, setShowHideCard, color, cardsLength, index, productionScheduling }) => {
  const { FrameID } = useParams();

  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);
  const { searchDataById, parkingSpaceList } = React.useContext(Data);

  const [sectorList] = useSector();

  const parking = searchDataById('parkingSpace', card.parkingSpace_id) as IParkingSpaceData;

  const [time, setTime] = useState<string>('-');

  const { indexCard, findCard, note, withMeNotes, sectorId } = React.useMemo(() => {
    const newCard = card.sequence.find((item) => item.frame_id === FrameID);
    const currentIndex = card.sequence.findIndex((item) => item.frame_id === FrameID);
    const note = card.sequence.find((item) => item.frame_id === FrameID)?.notes;
    const withMeNotes = card.sequence.find((item) => item.status === 'withMe')?.notes;
    const sectorId = card.sequence.find((item) => item.status === 'withMe' || item.status === 'current')?.sector_id;

    const nextIndex = currentIndex + 1;

    return {
      indexCard: nextIndex,
      findCard: newCard,
      note: note,
      withMeNotes: withMeNotes,
      sectorId: sectorId,
    };
  }, [FrameID, card.sequence]);

  const [sector] = useSearchSector(sectorId ? sectorId : null);

  async function handleYes() {
    const dataValidation: Partial<ICardData> = {
      filed: indexCard === 1 ? true : false,
      endDate: indexCard === 1 ? Timestamp.now() : null,
      sequence: card.sequence.map((item, index) => {
        if (index + 1 === indexCard) {
          return {
            ...item,
            status: 'concluded',
            frameEndDate: Timestamp.now(),
          };
        }
        if (index + 1 === indexCard - 1) {
          return { ...item, status: 'withMe', frameStartDate: Timestamp.now() };
        }

        return item;
      }),
    };

    const code3c = user ? user.code3c_id : '';
    const id = card ? card.id : '';
    const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-card', id);

    if (response) {
      toastSuccess('Card Editado com Sucesso!');
    } else {
      toastError('Ops! Algo deu errado ao editar o Card!');
    }
  }

  useEffect(() => {
    const updateEventTime = () => {
      if (card?.created_at <= Timestamp.now()) {
        const newTime = differenceInSeconds(new Date(), card.created_at.toDate());

        const format = formatTime(newTime);

        setTime(format);
      } else {
        setTime('-');
      }
    };

    updateEventTime();

    const intervalID = setInterval(updateEventTime, 5000);

    return () => clearInterval(intervalID);
  }, [card]);

  const keyframesCSS = `
    @keyframes piscar {
      0% {
        opacity: 1
      }
      50% {
        background-color: ${color};
      }
      50% {
        opacity: 0.7
      }
    }
  `;

  const container = `dc-sc-fm-cd ${parking?.status === 'unavailable' ? 'effect keyframesCSS' : 'error'}`;
  const borderStyle = findCard?.status === 'notWithMe' ? `4px solid #${color}` : 'none';
  const backgroundColor = findCard?.status === 'withMe' ? `#${color}` : 'white';

  const isVisibleCheck = findCard?.status === 'withMe' && cardsLength === 0;
  const isVisibleAwaiting = findCard?.status === 'notWithMe';

  const findRequester = card.sequence[0].sector_id;
  const requester = sectorList.find((i) => i.id === findRequester);

  const whiteMe = card.sequence.findIndex((item) => item.frame_id === FrameID);

  const findParking = parkingSpaceList.find((i) => i.id === card.parkingSpace_id);

  return (
    <div
      className={container}
      style={{
        borderRight: borderStyle,
        borderLeft: whiteMe === 0 ? borderStyle : `4px solid #${requester?.color}`,
        borderTop: borderStyle,
        borderBottom: borderStyle,
        backgroundColor: backgroundColor,
        color: index === 1 && findCard?.status === 'withMe' ? 'white' : 'black',
      }}
    >
      <div className='sc-fm-cd-container-text'>
        <div
          className='sc-fm-cd-click'
          onClick={() => setShowHideCard({ type: 'edit', visible: true, value: card })}
        />
        <div className='sc-fm-cd-first-line'>
          <div className='sc-fm-cd-left'>
            <ParkingFormat
              factory={findParking?.factory_id ?? 'error'}
              group={findParking?.group_id ?? 'error'}
              parking={card.parkingSpace_id}
            />
          </div>
          <div className='sc-fm-cd-right'>
            <ComponentConfig
              isConfirm={true}
              handleFunction={handleYes}
              visible={isVisibleCheck}
              moduleID='scrum-online'
              permissionID='btn-check-frame'
            >
              <div className='sc-fm-cd-container-icons'>
                <FaCheck className='sc-fm-cd-check' />
              </div>
            </ComponentConfig>
          </div>
        </div>
        <div className='sc-fm-cd-second-line'>
          <span>
            Ordem: <b>{card.order}</b>
          </span>
          <span>
            Data: <b>{card ? format(card.created_at.toDate(), 'dd/MM HH:mm') : '-'}</b>
          </span>
          <span>
            Tempo: <b>{time}</b>
          </span>
        </div>
        <div>
          Modelo: <b className='sc-fm-cd-break-word'>{card.model}</b>
        </div>
        <div>
          Processo: <b className='sc-fm-cd-break-word'>{productionScheduling?.processo ?? ''}</b>
        </div>
        <div>
          Romaneio: <b className='sc-fm-cd-break-word'>{productionScheduling?.romaneio ?? ''}</b>
        </div>
        <div>
          Status: <b className='sc-fm-cd-break-word'>{productionScheduling?.status_romaneio ?? ''}</b>
        </div>
        <div>
          Solicitante: <b>{whiteMe === 0 ? 'DISTRIBUIÇÃO' : requester?.name}</b>
        </div>
        <div>
          Observação: <b className='sc-fm-cd-break-word'>{note ? note : '-'}</b>
        </div>

        <ComponentConfig visible={isVisibleAwaiting}>
          <div style={{ border: 'solid 1px black', padding: '0.5rem' }}>
            Aguardando: <b className='sc-fm-cd-break-word'>{sector?.name}</b>
            <br />
            Observação: <b className='sc-fm-cd-break-word'>{withMeNotes ? withMeNotes : '-'}</b>
          </div>
        </ComponentConfig>
      </div>
      <style>{keyframesCSS}</style>
    </div>
  );
};

export default Card;
