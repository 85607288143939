import { useContext, useEffect, useState } from 'react';

import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { startOfDay } from 'date-fns';

import { IDayReadingIjData } from 'types_new/ALL-dayReading';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReadingList(): { dayReadingList: IDayReadingIjData[]; dayReadingLoading: boolean } {
  const { user } = useContext(Auth);

  const [dayReadingList, setDayReadingList] = useState<IDayReadingIjData[]>([]);
  const [dayReadingLoading, setDayReadingLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user) return;

    setDayReadingLoading(true);

    const lastDay = startOfDay(Timestamp.now().toDate());

    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-dayReadingIj'),
      where('date', '>=', lastDay),
      orderBy('date')
    );

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.docs) {
          const list: IDayReadingIjData[] = [];

          snapshot.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as IDayReadingIjData);
          });

          setDayReadingList(list);
        }

        setDayReadingLoading(false);
      },
      (error) => {
        console.error('Erro ao buscar dados de leituras:', error);

        setDayReadingLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  return { dayReadingList, dayReadingLoading };
}
