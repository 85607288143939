import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { ITypeOfEquipment } from 'types/OEE-equipment';

import './legends.scss';

interface IProps {
  white?: boolean;
  defaultLoading?: boolean;
  type: ITypeOfEquipment;
}

const Legends: React.FC<IProps> = ({ white, type, defaultLoading }) => {
  const { getEventsType } = React.useContext(Events);

  const events = getEventsType(type);

  return (
    <div className='default-class-legends'>
      {events.length !== 0 && (
        <div className='legends'>
          {defaultLoading ? (
            <Skeleton className='skeleton p-mt-2' />
          ) : (
            <div className={`${white ? 'background-white' : 'background'} p-p-3 p-mt-2 flex-center-x-y p-flex-wrap `}>
              {events.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className='p-py-1 p-mx-1 flex-center-x-y'
                  >
                    <div
                      className='p-m-1 flex-center-x-y color'
                      style={{ background: item.colour }}
                    />
                    <div className='flex-center-x-y name'>{item.name}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Legends;
