import { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';

import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { IAllWorkShiftData, IOeeDayReadingData, IReading } from 'types-node-react/react/type';

import { dayReadingSummary, lastValidEvent } from 'utils-node-react/react/utils';

/**
 * Agrega e resume as leituras de equipamentos por turno com base em filtros aplicados, manipulando dados de turnos e eventos.
 * Esta função organiza as leituras dos equipamentos em leituras separadas e agrupadas para o turno especificado, além de registrar o último evento válido.
 *
 * @param dayReading - Dados mensais de leituras por equipamento, ou nulo se não disponível.
 * @param workShift - Informações sobre os turnos de trabalho, ou nulo se não disponível.
 * @param equipments - Lista de equipamentos a serem considerados na análise.
 * @param filters - Critérios de exclusão de equipamentos e eventos na análise.
 * @param currentShift - Identificador do turno atual para análise focalizada.
 * @param eventsMap - Mapeamento de eventos com seus detalhes, usado para enriquecer as leituras.
 * @returns - Objeto contendo três campos: leituras separadas por equipamento, leituras agrupadas por eventos e o último evento válido por equipamento.;
 */
export function generateShiftReadingsOverview(
  dayReading: IOeeDayReadingData[] | null,
  workShift: IAllWorkShiftData | null,
  equipments: IEquipmentGNdata[],
  filters: IFilters,
  currentShift: string,
  eventsMap: { [key: string]: IOptionsEventList }
) {
  if (!dayReading || !workShift) {
    return { separatedReadings: {}, groupedReadings: [], separatedLastEvent: {} };
  }

  const separatedReadings: { [key: string]: IChartDonut[] } = {};
  const separatedLastEvent: { [key: string]: IReading } = {};
  const aggregatedEvents: { [key: string]: IChartDonut } = {};

  const workShiftFind = workShift?.shifts;

  const summaryMap = new Map(dayReading.map((summary) => [summary.machine_id, summary.reading ?? []]));

  const filteredEquipments = equipments.filter((equip) => !filters.equipments.includes(equip.id));

  filteredEquipments.forEach((equip) => {
    const reading = summaryMap.get(equip.id) || [];
    const daySummary = dayReadingSummary(reading, workShiftFind);
    const currentShiftEvents = daySummary.find((s) => s.shift === currentShift)?.evts ?? [];

    separatedLastEvent[equip.id] = lastValidEvent(reading, workShiftFind);
    separatedReadings[equip.id] = [];

    currentShiftEvents
      .filter((evt) => !filters.events.includes(evt.id))
      .forEach((evt) => {
        const { id, secs } = evt;
        const eventDetail = eventsMap[id] || { name: 'error', color: 'error' };
        const detail: IChartDonut = {
          name: eventDetail.name,
          value: secs,
          itemStyle: { color: eventDetail.color },
        };

        if (aggregatedEvents[detail.name]) {
          aggregatedEvents[detail.name].value += detail.value;
        } else {
          aggregatedEvents[detail.name] = { ...detail };
        }

        separatedReadings[equip.id].push(detail);
      });
  });

  const groupedReadings = Object.values(aggregatedEvents);

  return { separatedReadings, groupedReadings, separatedLastEvent };
}
