import React from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TabMenu } from 'primereact/tabmenu';

import s from './tabMenu.module.scss';

interface IBaseMenuProps {
  menuItems?: MenuItem[];
  index: number;

  children?: React.ReactNode;
}

const BaseTabMenu: React.FC<IBaseMenuProps> = ({ menuItems, index, children }) => {
  return (
    <React.Fragment>
      <TabMenu
        key={index}
        model={menuItems}
        activeIndex={index}
        className={s.tab}
      />
      {children}
    </React.Fragment>
  );
};

export default BaseTabMenu;
