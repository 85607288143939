import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import DropdownPrime from 'components/primereact/dropdown/dropdown';
import InputTextPrime from 'components/primereact/inputText/inputText';
import InputTextareaPrime from 'components/primereact/inputTextarea/inputTextarea';

import { addGeneric, editGeneric } from 'controllers/administration';

import { IFactoryData } from 'types/ALL-factory';
import { IGroupData, initialGroup } from 'types/ALL-group';
import { IValidationError } from 'types/ALL-yup';

import { dataSchema } from '../validations/yup';

interface IProps {
  factoryList: IFactoryData[];
  showHideManager: IShowHideManager;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideManager>>;
}

interface IShowHideManager {
  type: string;
  visible: boolean;
  value: IGroupData;
}

interface IDropDownType {
  key: string;
  name: string;
}

const GroupManager: React.FC<IProps> = ({ factoryList, showHideManager, setShowHideManager }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = useContext(Toaster);

  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [factory, setFactory] = React.useState<IDropDownType | undefined>(undefined);
  const [notes, setNotes] = React.useState('');

  const [pending, setPending] = useState<boolean>(false);
  const [firstClick, setFirstClick] = useState<boolean>(false);

  const [formErr, setFormErr] = useState<IValidationError[]>([]);

  const initialState = useCallback(() => {
    setShowHideManager({ type: '', visible: false, value: initialGroup });
    setName('');
    setNumber('');
    setFactory(undefined);
    setNotes('');
    setPending(false);
    setFirstClick(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IGroupData> = {
      code3c_id: user?.code3c_id,
      enabled: true,
      factory_id: factory !== undefined ? factory.key : '',
      name: name,
      number: number,
      notes: notes,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addGeneric(dataValidation, 'ALL-group');

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Novo grupo adicionado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar o grupo!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IGroupData> = {
      factory_id: factory !== undefined ? factory.key : '',
      name: name,
      number: number,
      notes: notes,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editGeneric(dataValidation, 'ALL-group', showHideManager.value.id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Grupo editado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o grupo!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  useEffect(() => {
    if (showHideManager.type === 'edit') {
      const getFactory = factoryList.find((factory) => factory.id === showHideManager.value.factory_id);
      setFactory({ key: getFactory?.id ? getFactory?.id : '', name: getFactory?.name ? getFactory?.name : '' });

      setName(showHideManager.value.name ? showHideManager.value.name : '');
      setNumber(showHideManager.value.number ? showHideManager.value.number : '');
      setNotes(showHideManager.value.notes ? showHideManager.value.notes : '');
    }
  }, [factoryList, showHideManager.type, showHideManager.value]);

  return (
    <Dialog
      className='default-class-utils-dialog dialog-default-width'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add' ? (
          'ADICIONAR NOVO GRUPO'
        ) : showHideManager.type === 'edit' ? (
          'EDITAR GRUPO'
        ) : (
          <React.Fragment />
        )
      }
      footer={
        showHideManager.type === 'add' ? (
          <Button
            type='button'
            label='Adicionar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
            severity='success'
            disabled={pending}
            onClick={() => handleAdd()}
          />
        ) : showHideManager.type === 'edit' ? (
          <Button
            type='button'
            label='Editar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'}
            severity='warning'
            disabled={pending}
            onClick={() => handleEdit()}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      <div className='p-p-2'>
        <div className='p-grid flex'>
          <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
            <InputTextPrime
              disabled={pending}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Nome'
              fieldCode='name'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
          <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
            <InputTextPrime
              disabled={pending}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder='Número'
              fieldCode='number'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
          <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
            <DropdownPrime
              disabled={pending}
              value={factory}
              onChange={(e) => setFactory(e.value)}
              optionLabel='name'
              options={factoryList.map((factory) => {
                return { name: factory.name, key: factory.id };
              })}
              placeholder='Fabrica'
              fieldCode='factory_id'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
        </div>
        <div className='p-grid flex p-mt-2'>
          <div className='p-col-12 p-md-12 p-lg-12 p-p-12'>
            <InputTextareaPrime
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder='Observações'
              fieldCode='notes'
              formErr={[]}
              rows={4}
              autoResize={false}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GroupManager;
