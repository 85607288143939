import React from 'react';

import { AiOutlineCheckCircle, AiOutlineDoubleLeft } from 'react-icons/ai';
import { BsKanban } from 'react-icons/bs';
import { FaCut, FaIndustry, FaParking, FaQuestionCircle, FaWindows } from 'react-icons/fa';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { IoOpenOutline } from 'react-icons/io5';
import { MdAdminPanelSettings, MdDashboardCustomize } from 'react-icons/md';

import './icons.scss';

interface BasicIconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  iconName: string;
}

interface IIcons {
  [string: string]: React.ReactElement;
}

const Icons: React.FC<BasicIconProps> = ({ iconName, ...props }) => {
  const icons: IIcons = {
    FaWindows: <FaWindows />,
    MdDashboardCustomize: <MdDashboardCustomize />,
    FaIndustry: <FaIndustry />,
    BsKanban: <BsKanban />,
    FaQuestionCircle: <FaQuestionCircle />,
    FaParking: <FaParking />,
    MdAdminPanelSettings: <MdAdminPanelSettings />,
    ImCheckboxUnchecked: <ImCheckboxUnchecked />,
    ImCheckboxChecked: <ImCheckboxChecked />,
    AiOutlineDoubleLeft: <AiOutlineDoubleLeft />,
    AiOutlineCheckCircle: <AiOutlineCheckCircle />,
    FaCut: <FaCut />,
    IoOpenOutline: <IoOpenOutline />,
  };

  return <i {...props}> {icons[iconName]}</i>;
};

export default Icons;
