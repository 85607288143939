import React from 'react';
import alertSound from 'assets/alert.mp3';

import { useParams } from 'react-router-dom';

import { differenceInSeconds } from 'date-fns';

import { ProgressSpinner } from 'primereact/progressspinner';

import BaseFieldset from 'componentes_share_admin/primereact/fieldset/fieldset.component';

import Card from 'pages/machines/scrum/components/card/card.component';

import { IShowHideCard } from '../../distribution.page';
import { useCard } from '../../hooks/useCard';

import s from './listOfCards.module.scss';

interface IListOfCards {
  setShowHideCard: React.Dispatch<React.SetStateAction<IShowHideCard>>;
}

const ListOfCards: React.FC<IListOfCards> = ({ setShowHideCard }) => {
  const { SectorID } = useParams();

  const [card, pendingCard] = useCard('sector_id', SectorID ? SectorID : '');

  const [audio] = React.useState(new Audio(alertSound));

  const filteredCards = React.useMemo(() => {
    const currentSectorCards = card.filter((item) =>
      item.sequence.find((item) => item.status === 'current' && item.sector_id === SectorID)
    );

    return currentSectorCards;
  }, [SectorID, card]);

  React.useEffect(() => {
    const findSeconds = filteredCards.find((item) => differenceInSeconds(new Date(), item.created_at.toDate()) >= 3600);

    let intervalId: NodeJS.Timeout;

    if (findSeconds) {
      intervalId = setInterval(() => {
        audio.play();
      }, 1000);
    } else {
      audio.pause();
    }

    return () => {
      audio.pause();

      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [audio, filteredCards]);

  return (
    <BaseFieldset
      legend='CARTÕES ESPERANDO CONFIGURAÇÃO DO SETOR'
      visible={true}
    >
      {pendingCard ? (
        <div className={s.content_pending}>
          <ProgressSpinner className={s.spinner} />
        </div>
      ) : (
        <div className={s.content_cards}>
          {filteredCards?.length === 0 ? (
            <div>Nenhum cartão foi encontrado! Os cartões aparecerão automaticamente!</div>
          ) : (
            <React.Fragment>
              {filteredCards.map((item, index) => (
                <Card
                  key={index}
                  card={item}
                  setShowHideCard={setShowHideCard}
                />
              ))}
            </React.Fragment>
          )}
        </div>
      )}
    </BaseFieldset>
  );
};

export default ListOfCards;
