import React from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import Icons from 'components/icons/icons';
import LoadPage from 'components/loading/loadPage/loadPage';
import ButtonPrime from 'components/primereact/button/button';

import SectorManager from './components/sectorManager';

import { editEnabledGeneric } from 'controllers/administration';

import { useSector } from './hooks/useSector';

import { InputSearch, ModuleDropdown } from 'utils/administration/administration';

import { initialSector, ISectorData } from 'types/ALL-sector';

const Sector: React.FC = () => {
  const { toastSuccess, toastError } = React.useContext(Toaster);
  const { user } = React.useContext(Auth);

  const [sectorList, pendingSector] = useSector();

  const [showHideManager, setShowHideManager] = React.useState({ type: '', visible: false, value: initialSector });

  const [search, setSearch] = React.useState('');

  async function handleEditEnabled(enabled: boolean, id: string) {
    const response = await editEnabledGeneric(enabled, id, 'ALL-sector');

    if (response) {
      toastSuccess(`Setor ${enabled ? 'Ativado' : 'Desativado'} com sucesso.`);
    } else {
      toastError('Não foi possível ativar o setor devido a um erro de banco de dados.');
    }
  }

  return (
    <div className='default-class-admin'>
      {pendingSector ? (
        <LoadPage />
      ) : (
        <React.Fragment>
          <SectorManager
            showHideManager={showHideManager}
            setShowHideManager={setShowHideManager}
          />
          <div className='admin-header'>
            <div className='admin-buttons-left'>
              <div className='admin-dropdown'>
                <ModuleDropdown
                  moduleName='Setores'
                  moduleKey='setores'
                  userType={user?.userAccessLevel}
                />
              </div>
              <div className='admin-input-text'>
                <InputSearch
                  placeholder='Buscar Setores...'
                  search={search}
                  setSearch={setSearch}
                />
              </div>
            </div>
            <div className='admin-buttons-right'>
              <div className='button-add'>
                <ButtonPrime
                  label='Adicionar Novo'
                  icon='pi pi-plus'
                  customClassName='system'
                  onClick={() => setShowHideManager({ type: 'add', visible: true, value: initialSector })}
                />
              </div>
            </div>
          </div>
          <div className='admin-table'>
            <DataTable
              className='admin-table-body'
              stripedRows
              rowHover
              scrollable
              scrollHeight='flex'
              value={sectorList.filter((item) => item.name?.toUpperCase().indexOf(search.toUpperCase()) !== -1)}
              virtualScrollerOptions={{ itemSize: 75, showLoader: true }}
              onRowDoubleClick={(e) =>
                setShowHideManager({ type: 'edit', visible: true, value: e.data as ISectorData })
              }
            >
              <Column
                field='name'
                header='Setor'
                sortable
              />
              <Column
                field='enabled'
                header='Ativo'
                sortable
                body={(rowData) => (
                  <div className='default-class-utils-admin'>
                    <Icons
                      iconName='AiOutlineCheckCircle'
                      className={rowData.enabled ? 'admin-check' : 'admin-unchecked'}
                      onClick={() => handleEditEnabled(rowData.enabled ? !rowData.enabled : true, rowData.id)}
                    />
                  </div>
                )}
              />
              <Column
                field='action'
                header='Ação'
                body={(rowData) => (
                  <div className='default-class-utils-admin'>
                    <ButtonPrime
                      className='admin-button-pencil'
                      type='button'
                      icon='pi pi-pencil'
                      severity='warning'
                      rounded
                      onClick={() => setShowHideManager({ type: 'edit', visible: true, value: rowData })}
                    />
                  </div>
                )}
              />
            </DataTable>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Sector;
