import * as React from 'react';
import systemLogo from 'assets/company/logoNotName.png';

const CompanyLogo: React.FC = () => {
  return (
    <img
      className='flex p-mx-auto'
      src={systemLogo}
      alt='system-logo'
      style={{ width: '7rem' }}
    />
  );
};

export default CompanyLogo;
