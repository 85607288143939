import { useContext, useEffect, useState } from 'react';

import { collectionGroup, getDocs, query, where } from 'firebase/firestore';

import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { Auth } from 'contexts/auth.context';

import { ISettings } from 'routes/listRoutes';

import { firebaseFirestore } from '../../../../../firebase/authentication';

const extractNumber = (name: string) => Number(name.replace(/[^\d]+/g, ''));

export function useEquipments(configs: ISettings): {
  equipments: IEquipmentGNdata[];
  equipmentsIds: string[];
  equipmentsLoading: boolean;
} {
  const { user } = useContext(Auth);

  const [equipments, setEquipments] = useState<IEquipmentGNdata[]>([]);
  const [equipmentsIds, setEquipmentsIds] = useState<string[]>([]);
  const [equipmentsLoading, setEquipmentsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !configs) return;

    setEquipmentsLoading(true);

    const fetchEquipments = async () => {
      const q = query(
        collectionGroup(firebaseFirestore, 'OEE-machine'),
        where('code3c_id', '==', configs.code3c_id),
        where('type', '==', configs.type),
        where('group', '==', configs.group),
        where('enabled', '==', true)
      );

      try {
        const querySnapshot = await getDocs(q);
        const data: IEquipmentGNdata[] = [];

        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id } as IEquipmentGNdata);
        });

        setEquipments(data.sort((a, b) => extractNumber(a.name) - extractNumber(b.name)));
        setEquipmentsIds(data.map((equipment) => equipment.id));
        setEquipmentsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados de equipamentos:', error);

        setEquipmentsLoading(false);
      }
    };

    fetchEquipments();
  }, [configs, user]);

  return { equipments, equipmentsIds, equipmentsLoading };
}
