import React, { useMemo } from 'react';

import EChartDonut, { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import EventAndTime from 'componentes_share_admin/machines/global/eventAndTime/eventAndTime.component';

import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { IReading } from 'types-node-react/react/type';

import s from './graphDonut.module.scss';

interface IGraphDonutProps {
  eventMap: { [key: string]: IOptionsEventList };

  equipment?: IEquipmentGNdata;
  lastEvent?: IReading;

  enhancedReadings: IChartDonut[];
}

export const GraphDonut: React.FC<IGraphDonutProps> = ({ enhancedReadings, eventMap, equipment, lastEvent }) => {
  const lastUpdated = lastEvent?.start ? new Date(lastEvent.start * 1000) : undefined;
  const lastEventId = lastEvent?.id ? lastEvent?.id : undefined;

  const series = useMemo(() => {
    if (!enhancedReadings || enhancedReadings.length === 0) return [];

    return enhancedReadings.sort((a, b) => b.value - a.value);
  }, [enhancedReadings]);

  return (
    <div className={s.container}>
      <div className={s.title}>
        {equipment?.status === 'offline' && <div className={s.offline} />}
        {equipment ? equipment.name : 'Status Geral'}
      </div>
      <div className={s.graph}>
        <EventAndTime
          lastEventUpdatedAt={lastUpdated}
          lastEventId={lastEventId}
          eventMap={eventMap}
        />
        <EChartDonut
          series={series}
          height='12rem'
          width='13rem'
        />
      </div>
    </div>
  );
};
