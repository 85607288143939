import { useContext, useEffect, useState } from 'react';

import { doc, onSnapshot } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ICode3cData } from 'types/ALL-code3c';

import { firebaseFirestore } from '../firebase/authentication';

export function useSearchCode3c(): [ICode3cData | undefined, boolean] {
  const { user } = useContext(Auth);

  const [code3c, setCode3c] = useState<ICode3cData>();
  const [pendingReadingList, setPendingReadingList] = useState<boolean>(true);

  useEffect(() => {
    if (!user) {
      setPendingReadingList(false);
      return;
    }

    setPendingReadingList(true);

    const unsubscribe = onSnapshot(doc(firebaseFirestore, 'ALL-code3c', user.code3c_id), (snapshot) => {
      if (snapshot.exists()) {
        const newUser = snapshot.data() as ICode3cData;
        setCode3c({ ...newUser, id: snapshot.id });
        setPendingReadingList(false);
      }
      setPendingReadingList(false);
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  return [code3c, pendingReadingList];
}
