import { Timestamp } from 'firebase/firestore';

import { IDefaultParams } from './ALL-DefaultParams';

export interface ISector extends IDefaultParams {
  code3c_id: string;
  name: string;
  enabled: boolean;
}

export interface ISectorData extends ISector {
  id: string;
}

export const initialSector: ISectorData = {
  id: '',
  code3c_id: '',
  name: '',
  enabled: false,
  updated_at: Timestamp.now(),
  created_at: Timestamp.now(),
};
