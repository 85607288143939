import React, { useContext, useMemo, useState } from 'react';
import { AxiosError } from 'axios';

import { useNavigate } from 'react-router-dom';

import { addHours } from 'date-fns';

import Loading from 'componentes_share_admin/loading/loading.component';
import Filter, { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import Legends from 'componentes_share_admin/machines/global/legends/legends.component';
import {
  dateAxisLabels,
  optionsReports,
  setAxisValue,
} from 'componentes_share_admin/machines/reports/reports.functions';
import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { IAllWorkShiftData, IOeeMonthlySummaryData } from 'types-node-react/react/type';

import { IOptionsReports } from 'pages/machines/kanban/reports/reports.component';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { GraphBar } from './components/bar.component';
import { GraphDonut } from './components/graphDonut.component';
import { GraphMixed } from './components/graphMixed.component';

import { ErrorResponse } from 'controllers/equipments';

import { useTempletes } from 'hooks/machines/useTemplete.hook';
import { IShiftDisplayList, useWorkShift } from 'hooks/machines/useWorkShift.hook';

import { useDayReading } from './hooks/useDayReading.hook';
import { useEquipments } from './hooks/useEquipments.hook';
import { useMonthlySummary } from './hooks/useMonthlySummary.hook';

import { ISettings } from 'routes/listRoutes';

import Navbar from '../../../../componentes_share_admin/machines/reports/navbar/navbar.component';
import { getApi } from '../../../../firebase/authentication';
import { navigation } from '../global';

import { generateShiftReadingsOverview } from './reports.functions';

import s from './reports.module.scss';

interface IReportsGenericController {
  settings: ISettings;
}

const ReportsGenericController: React.FC<IReportsGenericController> = ({ settings }) => {
  const history = useNavigate();

  const { user } = useContext(Auth);

  const configs = useMemo(() => {
    return { ...settings, code3c_id: user?.code3c_id };
  }, [settings, user?.code3c_id]);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [report, setReport] = useState<IOptionsReports>({ name: 'Por Mês', id: 'month' });

  const { equipments, equipmentsIds, equipmentsLoading } = useEquipments(configs);
  const { templete, eventsMap, eventsList, eventsLoading } = useTempletes(configs);
  const { workShift, shiftDisplayList, loadingWorkShift } = useWorkShift(user?.code3c_id, templete?.workShift_id);
  const { monthlySummary, monthlySummaryLoading } = useMonthlySummary(equipmentsIds, startDate, endDate, report.id);
  const { dayReading, dayReadingLoading } = useDayReading(equipmentsIds, startDate, workShift?.shifts, report.id);

  if (eventsLoading || equipmentsLoading || monthlySummaryLoading || loadingWorkShift || dayReadingLoading) {
    return (
      <BaseTabMenu
        index={2}
        menuItems={navigation(configs.title, history)}
      >
        <Loading height='calc(100vh - 8rem)' />
      </BaseTabMenu>
    );
  }

  return (
    <ReportsGenericComponent
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      report={report}
      setReport={setReport}
      equipments={equipments}
      eventsMap={eventsMap}
      eventsList={eventsList}
      shiftDisplayList={shiftDisplayList}
      monthlySummary={monthlySummary}
      dayReading={dayReading}
      equipmentsIds={equipmentsIds}
      workShift={workShift}
      configs={configs}
    />
  );
};

interface IReportsGenericComponent {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  report: IOptionsReports;
  setReport: React.Dispatch<React.SetStateAction<IOptionsReports>>;
  equipments: IEquipmentGNdata[];
  eventsMap: { [key: string]: IOptionsEventList };
  eventsList: IOptionsEventList[];
  shiftDisplayList: IShiftDisplayList[];
  monthlySummary: IOeeMonthlySummaryData[] | null;
  dayReading: IOeeMonthlySummaryData[] | null;
  equipmentsIds: string[];
  workShift: IAllWorkShiftData | null;
  configs: ISettings;
}

const ReportsGenericComponent: React.FC<IReportsGenericComponent> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  report,
  setReport,
  equipments,
  eventsMap,
  eventsList,
  shiftDisplayList,
  monthlySummary,
  dayReading,
  equipmentsIds,
  workShift,
  configs,
}) => {
  const history = useNavigate();

  const { user, accessToken } = useContext(Auth);
  const { toastError, toastSuccess } = useContext(Toaster);

  const [filters, setFilters] = useState<IFilters>({ equipments: [], events: [], shifts: ['OFF'] });

  const getAxisLabels = useMemo(() => {
    const data = dateAxisLabels(report.id, startDate, endDate, equipmentsIds);

    return data;
  }, [startDate, endDate, equipmentsIds, report.id]);

  const memoizedEquipmentReadings = useMemo(() => {
    return generateShiftReadingsOverview(
      dayReading,
      monthlySummary,
      filters,
      report,
      getAxisLabels,
      startDate,
      endDate,
      equipmentsIds,
      workShift,
      eventsMap
    );
  }, [
    dayReading,
    endDate,
    equipmentsIds,
    eventsMap,
    filters,
    getAxisLabels,
    monthlySummary,
    report,
    startDate,
    workShift,
  ]);

  const daySummaryCalculation = async () => {
    const api = getApi(accessToken);

    const data = {
      machine_ids: equipmentsIds,
      date: addHours(startDate, 12).getTime(),
      code3c_id: user?.code3c_id,
    };

    try {
      const response = await api.post('/equipment/dayReadingSummaryCalculation', data);
      if (response) {
        toastSuccess('Dia Recalculado com Sucesso!');
        return true;
      }
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        const errorResponse: ErrorResponse = error.response.data;

        toastError('Não foi possível recalcular o dia');
        console.log({ response: false, message: errorResponse.description });
      } else {
        console.log('Não foi possível recalcular o dia');
      }
    }
  };

  return (
    <BaseTabMenu
      index={2}
      menuItems={navigation(configs.title, history)}
    >
      <div className={s.container}>
        <Navbar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          typeReport={report}
          setTypeReport={setReport}
          optionsReports={optionsReports}
          daySummaryCalculation={daySummaryCalculation}
        >
          <Filter
            equipmentsList={equipments}
            eventsList={eventsList}
            shiftsList={shiftDisplayList}
            filters={filters}
            setFilters={setFilters}
            filterKey={`v1_${user?.code3c_id}_reports_${configs.group}`}
          />
        </Navbar>
        <GraphBar
          eventEnhanced={memoizedEquipmentReadings.aggregatedEvents}
          dateAxisLabels={setAxisValue(getAxisLabels, report.id, startDate, endDate, equipments)}
        />
        <GraphMixed
          eventList={eventsList}
          eventEnhanced={memoizedEquipmentReadings.aggregatedEvents}
        />
        <div className={s.row_graph}>
          <div className={s.graph}>
            <GraphDonut
              eventEnhanced={memoizedEquipmentReadings.aggregatedEvents}
              title='Status Geral'
            />
          </div>
          {equipments
            .filter((equip) => !filters.equipments.includes(equip.id))
            .map((equip, index) => (
              <div
                key={index}
                className={s.graph}
              >
                <GraphDonut
                  eventEnhanced={memoizedEquipmentReadings.separatedReadings[equip.id]}
                  title={equip.name}
                />
              </div>
            ))}
        </div>
        <Legends eventArray={eventsList.filter((i) => !filters.events.includes(i.id))} />
      </div>
    </BaseTabMenu>
  );
};

export default ReportsGenericController;
