import { collection, doc, getDocs, limit, query, where, writeBatch } from 'firebase/firestore';

import { ICardData } from 'types/SCRUM-card';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export async function inverterCards(cardOne: string, cardTwo: string, code3c_id: string) {
  if (!cardOne || !cardTwo) {
    return { response: false, message: 'Não foram encontrados os dois cartões necessários.' };
  }

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c_id, 'SCRUM-card'),
      where('parkingSpace_id', 'in', [cardOne, cardTwo]),
      where('filed', '==', false),
      limit(2)
    );

    const docsSnap = await getDocs(colRef);

    if (docsSnap.docs.length < 2) {
      return { response: false, message: 'Não foram encontrados os dois cartões necessários.' };
    }

    const items = docsSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id } as ICardData));
    const firstItem = items.find((item) => item.parkingSpace_id === cardOne);
    const secondItem = items.find((item) => item.parkingSpace_id === cardTwo);

    if (!firstItem || !secondItem) {
      return { response: false, message: 'Não foram encontrados os dois cartões necessários.' };
    }

    const batch = writeBatch(firebaseFirestore);

    batch.update(doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'SCRUM-card', firstItem.id), {
      parkingSpace_id: secondItem.parkingSpace_id,
      created_at: secondItem.created_at,
    });

    batch.update(doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'SCRUM-card', secondItem.id), {
      parkingSpace_id: firstItem.parkingSpace_id,
      created_at: firstItem.created_at,
    });

    await batch.commit();

    return { response: true, message: 'Cartões Foram Invertidos com Sucesso!' };
  } catch (error) {
    return { response: false, message: 'Erro ao estabelecer conexão com o banco de dados!' };
  }
}
