import React from 'react';

import { Auth } from 'contexts/auth.context';

import Logo from './components/logo.component';
import TableList from './components/tableList.component';

import packageJson from '../../../../package.json';
import { getApi } from '../../../firebase/authentication';

import s from './about.module.scss';

const AboutPage: React.FC = () => {
  const { accessToken } = React.useContext(Auth);

  const [version, setVersion] = React.useState('0.0.0');

  const getVersionApi = React.useCallback(async () => {
    try {
      const api = getApi(accessToken);
      const response = await api.get('/info/version');

      setVersion(response.data.version);

      return false;
    } catch (error) {
      return false;
    }
  }, [accessToken]);

  React.useEffect(() => {
    getVersionApi();
  }, [getVersionApi]);

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.image}>
          <Logo />
        </div>
        <div className={s.content_version}>
          <div className={s.version}>Versão API: {version}</div>
          <div className={s.version}>Versão WEB: {packageJson.version}</div>
        </div>
        <div className={s.table}>
          <TableList />
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
