import React from 'react';

import { useParams } from 'react-router-dom';

import { startOfDay } from 'date-fns';

import Card from './components/card.component';
import MachineEvents from './components/events.component';
import StoppedGraph from './components/graphBars.component';
import TimeLineGraph from './components/graphTimeline.component';
import Navbar from './components/navbar.component';

import { useDayReadingEquipment } from './hooks/useDayReading.hook';
import { useReadingTimeline } from './hooks/useReadingTimeline.hook';

import { secondsToTime } from 'functions/millisToTime';

import s from './details.module.scss';

const DetailsKB: React.FC = () => {
  const { ID } = useParams();

  const [date, setDate] = React.useState(new Date());
  const [newDate, setNewDate] = React.useState(date);

  const [readingList] = useReadingTimeline(ID ? ID : '', date);
  const [dayReadingList] = useDayReadingEquipment(ID ? ID : '', date);

  const { available, unavailable } = React.useMemo(() => {
    const time = { available: 0, unavailable: 0 };

    const dataNew = startOfDay(date).valueOf();

    time.available = dayReadingList.reduce((previous, item) => {
      if (item.status === 'available' && item.date.toDate().valueOf() >= dataNew) {
        return previous + item.sumMillis;
      }
      return previous;
    }, 0);

    time.unavailable = dayReadingList.reduce((previous, item) => {
      if (item.status === 'unavailable' && item.date.toDate().valueOf() >= dataNew) {
        return previous + item.sumMillis;
      }
      return previous;
    }, 0);

    return time;
  }, [dayReadingList, date]);

  return (
    <div className={s.container}>
      <Navbar
        date={date}
        setDate={setDate}
        newDate={newDate}
        setNewDate={setNewDate}
      />
      <div className={s.row}>
        <Card
          label='TEMPO CHEIO'
          value={secondsToTime(unavailable / 1000)}
          icon='FaArrowAltCircleUp'
          color='#1C9E48'
        />
        <Card
          label='TEMPO VAZIO'
          value={secondsToTime(available / 1000)}
          icon='FaArrowAltCircleDown'
          color='#942222'
        />
      </div>
      <div className={s.row}>
        <StoppedGraph
          dayReadingList={dayReadingList}
          date={date}
        />
        <TimeLineGraph
          reading={readingList}
          date={date}
        />
      </div>
      <MachineEvents readings={readingList} />
    </div>
  );
};

export default DetailsKB;
