import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IGroupData } from 'types/ALL-group';

import { firebaseFirestore } from '../../../../firebase/authentication';

export function useGroup(): [IGroupData[], boolean] {
  const { user } = React.useContext(Auth);

  const [groupList, setGroupList] = React.useState<IGroupData[]>([]);
  const [pendingGroup, setPendingGroup] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingGroup(true);

    const unsubscribe = onSnapshot(
      query(collection(firebaseFirestore, 'ALL-group'), where('code3c_id', '==', user.code3c_id), orderBy('name')),
      (snapshot) => {
        const list: IGroupData[] = [];

        if (snapshot.empty) {
          setGroupList([]);
          setPendingGroup(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IGroupData);
        });

        setGroupList(list);
        setPendingGroup(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  return [groupList, pendingGroup];
}
