import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { ProgressSpinner } from 'primereact/progressspinner';

import Button from 'pages/components_prime/button';
import InputText from 'pages/components_prime/inputText';
import { genericFunctionToAdd, genericFunctionToDelete } from 'pages/machines/scrum/monitor/controllers/firebase';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { IValidationError } from 'types/ALL-yup';
import { IParkingSpaceData } from 'types/KC-ParkingSpace';
import { ICardData } from 'types/SCRUM-card';

import { useHistoricCard } from '../hooks/useHistoricCard.hook';
import { referenceSchema } from '../validations/reference.validation';

import './historicCards.scss';

interface IHistoricCardsProps {
  card: IParkingSpaceData | undefined;
}

const HistoricCards: React.FC<IHistoricCardsProps> = ({ card }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [historicCardList, pendingHistoricCardList] = useHistoricCard(card ? card.id : '');

  const [order, setOrder] = React.useState('');

  const [firstClick, setFirstClick] = React.useState<boolean>(false);
  const [formErr, setFormErr] = React.useState<IValidationError[]>([]);
  const [pending, setPending] = React.useState(false);

  async function handleAddReference() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ICardData> = {
      filed: true,
      reference: true,
      order: Number(order),
      parkingSpace_id: card ? card.id : '',
      cell: card ? card.cell : '',
    };

    referenceSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const response = await genericFunctionToAdd(dataValidation, code3c, 'SCRUM-card');

        if (response) {
          setPending(false);
          setOrder('');
          setFirstClick(false);
          setFormErr([]);

          toastSuccess('Referencia Criada com Sucesso!');
        } else {
          setPending(false);
          setFormErr([]);

          toastError('Ops! Algo deu errado ao criar a Referencia!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleDeleteCard(id: string) {
    setPending(true);

    const code3c = user ? user.code3c_id : '';
    const response = await genericFunctionToDelete(id, code3c, 'SCRUM-card');

    if (response) {
      setPending(false);

      toastSuccess('Cartão Deletado com Sucesso!');
    } else {
      setPending(false);

      toastError('Ops! Algo deu errado ao deletar o Cartão!');
    }
  }

  const startDate = (data: ICardData) => {
    return <span>{format(data.created_at.toDate(), 'dd/MM - HH:mm:ss')}</span>;
  };

  const endDate = (data: ICardData) => (
    <span>
      {data.excluded
        ? format(data.updated_at.toDate(), 'dd/MM - HH:mm:ss')
        : data.endDate
        ? format(data.endDate.toDate(), 'dd/MM - HH:mm:ss')
        : '-'}
    </span>
  );

  const status = (data: ICardData) => (
    <span>
      <div
        className={
          data.reference
            ? 'kb-hc-reference'
            : data.excluded
            ? 'kb-hc-excluded'
            : data.filed
            ? 'kb-hc-done'
            : 'kb-hc-progress'
        }
      >
        {data.reference ? 'REFERENCIA' : data.excluded ? 'EXCLUÍDO' : data.filed ? 'CONCLUÍDO' : 'EM ANDAMENTO'}
      </div>
    </span>
  );

  const distance = (data: ICardData) => (
    <span>
      {data.excluded
        ? formatDistanceStrict(data.created_at.toDate(), data.updated_at.toDate(), {
            locale: ptBR,
          })
        : data.endDate
        ? formatDistanceStrict(data.created_at.toDate(), data.endDate.toDate(), {
            locale: ptBR,
          })
        : '-'}
    </span>
  );

  const model = (data: ICardData) => {
    return <span style={{ wordBreak: 'break-all' }}>{data.model ? data.model : '-'}</span>;
  };

  const reset = (data: ICardData) => {
    return <span>{data.quantityReset !== undefined ? data.quantityReset : '-'}</span>;
  };

  const actions = (data: ICardData) => {
    return (
      <Button
        moduleID='kanban-online'
        permissionID='btn-delete-history'
        icon='pi pi-trash'
        severity='danger'
        pendingProps={pending}
        onClick={() => handleDeleteCard(data.id)}
      />
    );
  };

  return (
    <div className='dc-kb-hc'>
      {pendingHistoricCardList ? (
        <div className='kb-hc-pending'>
          <ProgressSpinner />
        </div>
      ) : (
        <div className='kb-hc-container'>
          <Fieldset
            legend='ATUALIZAR REFERENCIA'
            className='kb-hc-content'
          >
            <div className='kb-hc-row'>
              <InputText
                placeholder='Ordem'
                id='order'
                fieldCode='order'
                formErr={formErr}
                firstClick={firstClick}
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              />
              <Button
                moduleID='kanban-online'
                permissionID='btn-add-reference'
                label='ADICIONAR'
                severity='success'
                className='kb-hc-button-add'
                pendingProps={pending || order.length === 0}
                onClick={() => handleAddReference()}
              />
            </div>
          </Fieldset>
          <Fieldset legend='HISTÓRICO DE CARTÕES'>
            <DataTable
              value={historicCardList}
              stripedRows
              rowHover
              emptyMessage='Nenhum Cartão foi Encontrado no Histórico!'
            >
              <Column
                field='order'
                header='Ordem'
                sortable
              />
              <Column
                field='model'
                header='Modelo'
                body={model}
                style={{ width: '20%' }}
              />
              <Column
                field='startDate'
                header='Criado'
                body={startDate}
              />
              <Column
                field='endDate'
                header='Finalizado / Excluído'
                body={endDate}
              />
              <Column
                field='endDate'
                header='Tempo'
                body={distance}
              />
              <Column
                field='quantityReset'
                header='Resets'
                body={reset}
              />
              <Column
                field='status'
                header='Status'
                body={status}
              />
              <Column
                field='action'
                header='Ação'
                body={actions}
              />
            </DataTable>
          </Fieldset>
        </div>
      )}
    </div>
  );
};

export default HistoricCards;
