import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Timestamp } from 'firebase/firestore';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import DropdownPrime from 'components/primereact/dropdown/dropdown';
import InputTextPrime from 'components/primereact/inputText/inputText';
import InputTextareaPrime from 'components/primereact/inputTextarea/inputTextarea';

import { addGeneric, deleteGeneric, editGeneric } from 'controllers/administration';

import { IFactoryData } from 'types/ALL-factory';
import { IGroupData } from 'types/ALL-group';
import { IValidationError } from 'types/ALL-yup';
import { initialParkingSpace, IParkingSpaceData } from 'types/KC-ParkingSpace';

import { dataSchema } from '../validations/yup';

import './parkingManager.scss';

interface IProps {
  factoryList: IFactoryData[];
  groupList: IGroupData[];
  showHideManager: IShowHideManager;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideManager>>;
}

interface IShowHideManager {
  type: string;
  visible: boolean;
  value: IParkingSpaceData;
}

interface IDropDownType {
  key: string;
  name: string;
}

const ParkingManager: React.FC<IProps> = ({ factoryList, groupList, showHideManager, setShowHideManager }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = useContext(Toaster);

  const [code, setCode] = React.useState('');
  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [cell, setCell] = React.useState('');
  const [factory, setFactory] = React.useState<IDropDownType | undefined>(undefined);
  const [group, setGroup] = React.useState<IDropDownType | undefined>(undefined);
  const [notes, setNotes] = React.useState('');

  const [pending, setPending] = useState<boolean>(false);
  const [firstClick, setFirstClick] = useState<boolean>(false);

  const [formErr, setFormErr] = useState<IValidationError[]>([]);

  const initialState = useCallback(() => {
    setShowHideManager({ type: '', visible: false, value: initialParkingSpace });

    setCode('');
    setName('');
    setNumber('');
    setCell('');
    setFactory(undefined);
    setGroup(undefined);
    setNotes('');

    setPending(false);
    setFirstClick(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IParkingSpaceData> = {
      code3c_id: user?.code3c_id,
      code: code,
      enabled: true,
      name: name,
      number: number,
      status: 'available',
      notes: notes,
      cell: cell,
      factory_id: factory !== undefined ? factory.key : '',
      group_id: group !== undefined ? group.key : '',
      lastStatus_updated_at: Timestamp.now(),
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addGeneric(dataValidation, 'KC-parkingSpace');

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Nova Vaga adicionada com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar a vaga!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IParkingSpaceData> = {
      code: code,
      name: name,
      number: number,
      cell: cell,
      notes: notes,
      factory_id: factory !== undefined ? factory.key : '',
      group_id: group !== undefined ? group.key : '',
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editGeneric(dataValidation, 'KC-parkingSpace', showHideManager.value.id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Vaga editada com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar a vaga!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleDelete() {
    setPending(true);

    const response = await deleteGeneric(showHideManager.value.id, 'KC-parkingSpace');

    if (response) {
      initialState();
      setFormErr([]);
      toastSuccess('Vaga deletada com sucesso!');
    } else {
      setPending(false);
      setFormErr([]);
      toastError('Ops! Algo deu errado ao deletar a vaga!');
    }
  }

  useEffect(() => {
    if (showHideManager.type === 'edit') {
      const getFactory = factoryList.find((factory) => factory.id === showHideManager.value.factory_id);
      setFactory({ key: getFactory?.id ? getFactory?.id : '', name: getFactory?.name ? getFactory?.name : '' });

      const getGroup = groupList.find((group) => group.id === showHideManager.value.group_id);
      setGroup({ key: getGroup?.id ? getGroup?.id : '', name: getGroup?.name ? getGroup?.name : '' });

      setCode(showHideManager.value.code ? showHideManager.value.code : '');
      setName(showHideManager.value.name ? showHideManager.value.name : '');
      setNumber(showHideManager.value.number ? showHideManager.value.number : '');
      setNotes(showHideManager.value.notes ? showHideManager.value.notes : '');
      if (getFactory && getGroup) {
        setCell(`${getFactory?.number}${getGroup?.number}08`);
      }
    }
  }, [factoryList, groupList, showHideManager]);

  return (
    <Dialog
      className='default-class-utils-dialog dialog-default-width'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add' ? (
          'ADICIONAR NOVA VAGA'
        ) : showHideManager.type === 'edit' ? (
          'EDITAR VAGA'
        ) : showHideManager.type === 'delete' ? (
          'DELETAR VAGA'
        ) : (
          <React.Fragment />
        )
      }
      footer={
        showHideManager.type === 'add' ? (
          <Button
            type='button'
            label='Adicionar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
            severity='success'
            disabled={pending}
            onClick={() => handleAdd()}
          />
        ) : showHideManager.type === 'edit' ? (
          <Button
            type='button'
            label='Editar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'}
            severity='warning'
            disabled={pending}
            onClick={() => handleEdit()}
          />
        ) : showHideManager.type === 'delete' ? (
          <Button
            type='button'
            label='Deletar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-trash'}
            severity='danger'
            disabled={pending}
            onClick={() => handleDelete()}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      {showHideManager.type === 'delete' ? (
        <div className='container-delete'>
          Você está prestes a excluir a {showHideManager.value ? showHideManager.value.name : '-'}.
          <br /> <br />
          Tem certeza de que deseja continuar? Essa ação é irreversível e todas as informações associadas a esta vaga
          serão permanentemente apagadas.
        </div>
      ) : showHideManager.type === 'add' || showHideManager.type === 'edit' ? (
        <div className='p-p-2'>
          <div className='p-grid flex'>
            <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
              <InputTextPrime
                disabled={pending}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder='Code'
                fieldCode='code'
                formErr={formErr}
                firstClick={firstClick}
              />
            </div>
            <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
              <InputTextPrime
                disabled={pending}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Nome'
                fieldCode='name'
                formErr={formErr}
                firstClick={firstClick}
              />
            </div>
            <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
              <InputTextPrime
                disabled={pending}
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder='Número'
                fieldCode='number'
                formErr={formErr}
                firstClick={firstClick}
              />
            </div>
          </div>
          <div className='p-grid flex p-mt-2'>
            <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
              <DropdownPrime
                disabled={pending}
                value={factory}
                onChange={(e) => setFactory(e.value)}
                optionLabel='name'
                options={factoryList.map((factory) => {
                  return { name: factory.name, key: factory.id };
                })}
                placeholder='Fabrica'
                fieldCode='factory_id'
                formErr={formErr}
                firstClick={firstClick}
              />
            </div>
            <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
              <DropdownPrime
                disabled={pending || factory === undefined}
                value={group}
                onChange={(e) => setGroup(e.value)}
                optionLabel='name'
                options={groupList
                  .filter((group) => group.factory_id === (factory !== undefined ? factory.key : ''))
                  .map((group) => {
                    return { name: group.name, key: group.id };
                  })}
                placeholder='Grupo'
                fieldCode='group_id'
                formErr={formErr}
                firstClick={firstClick}
              />
            </div>
            <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
              <InputTextPrime
                disabled={pending}
                value={cell}
                onChange={(e) => setCell(e.target.value)}
                placeholder='Célula'
                fieldCode='cell'
                formErr={formErr}
                firstClick={firstClick}
              />
            </div>
          </div>
          <div className='p-grid flex p-mt-2'>
            <div className='p-col-12 p-md-12 p-lg-12 p-p-12'>
              <InputTextareaPrime
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder='Observações'
                fieldCode='notes'
                formErr={[]}
                rows={4}
                autoResize={false}
              />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment />
      )}
    </Dialog>
  );
};

export default ParkingManager;
