import React from 'react';

import { format } from 'date-fns';

import EChartLine from 'componentes_share_admin/charts/line/line.component';
import LayoutGraph from 'componentes_share_admin/layout/graph/graph.component';

import { IReading } from '../hooks/useReading.hook';

interface IProps {
  timelineList: IReading[];
}

interface IReadingLine {
  [string: string]: number;
}

const PeerEstimation: React.FC<IProps> = ({ timelineList }) => {
  const series = React.useMemo(() => {
    if (!timelineList || timelineList.length === 0) return { hours: [], numbers: [] };

    const hour = timelineList.reduce((previous, item) => {
      const token = format(item.startDate.toDate(), 'HH:00');

      previous[token] = previous[token] || 0;

      if (
        item.event_id === 'Fe8XfKsmAEoht1fFWRAe' &&
        item.differenceInMilliseconds >= 300000 &&
        item.differenceInMilliseconds <= 780000
      ) {
        previous[token] += 1;
      }
      return previous;
    }, {} as IReadingLine);

    const hours = Object.keys(hour).sort();
    const numbers = Object.values(hour);

    return { hours, numbers };
  }, [timelineList]);

  return (
    <LayoutGraph
      title='Histórico de Pares'
      tooltip=''
      height='25rem'
    >
      <EChartLine
        width='100%'
        height='25rem'
        series={series}
      />
    </LayoutGraph>
  );
};

export default PeerEstimation;
