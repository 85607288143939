import React from 'react';

import { InputText as InputTextPrime, InputTextProps } from 'primereact/inputtext';

import { catchErrors, catchInvalid } from 'components/formErrors/formErrors';

import { IValidationError } from 'types/ALL-yup';

import './inputText.scss';

interface IProps extends InputTextProps {
  placeholder: string;
  formErr: IValidationError[];
  fieldCode: string;
  icon?: string;
  firstClick?: boolean;
}

const InputText: React.FC<IProps> = ({ icon, placeholder, formErr, fieldCode, firstClick, ...InputTextProps }) => {
  const invalid = catchInvalid(formErr, fieldCode);

  return (
    <div className='default-class-input-text'>
      <span className='p-float-label p-input-icon-right'>
        <i className={firstClick ? (invalid ? 'pi pi-times icon-error' : 'pi pi-check icon-success') : icon} />
        <InputTextPrime
          className={firstClick ? (invalid ? 'error' : 'success') : 'default'}
          {...InputTextProps}
        />
        <label
          className={firstClick ? (invalid ? 'icon-error' : 'icon-success') : ''}
          htmlFor={placeholder}
        >
          {placeholder}
        </label>
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputText;
