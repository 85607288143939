import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Menu } from 'primereact/menu';

import { BsThreeDotsVertical } from 'react-icons/bs';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { ISectorData } from 'types/SCRUM-sector';

import { IShowHideSector } from '../../monitor';

import './sector.scss';

interface ISectorProps {
  sector: ISectorData;
  getFrameList: (sector_id: string) => number;
  getDistributionList: (sector_id: string) => number;
  setShowHideSector: React.Dispatch<React.SetStateAction<IShowHideSector>>;
}

const Sector: React.FC<ISectorProps> = ({ sector, getFrameList, getDistributionList, setShowHideSector }) => {
  const { user } = React.useContext(Auth);

  const history = useNavigate();

  const { toastError } = React.useContext(Toaster);

  const menu = React.useRef<Menu>(null);

  const checkEdit = (moduleID: string, permissionID: string) => {
    if (
      moduleID &&
      permissionID &&
      user &&
      user.permissions &&
      user.permissions[moduleID] &&
      user.permissions[moduleID][permissionID]
    ) {
      const permission = user.permissions[moduleID][permissionID];

      const checkEdit = permission.includes('R');
      return checkEdit ? false : true;
    } else {
      return true;
    }
  };

  const items = [
    {
      label: 'Abrir',
      icon: 'pi pi-plus',
      command: () => {
        history(`/cartao_digital/${sector.id}`);
      },
    },
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      disabled: checkEdit('scrum-online', 'btn-edit-sector'),
      command: () => {
        setShowHideSector({ type: 'edit', visible: true, value: sector });
      },
    },
    {
      label: 'Excluir',
      icon: 'pi pi-trash',
      disabled: checkEdit('scrum-online', 'btn-delete-sector'),
      command: () => {
        getDistributionList(sector.id) === 0 && getFrameList(sector.id) === 0
          ? setShowHideSector({ type: 'delete', visible: true, value: sector })
          : toastError(
              'Não foi possível apagar este setor, uma vez que há cartões armazenados dentro dele. Por favor, finalize as ações relacionadas aos cartões antes de prosseguir com a exclusão.'
            );
      },
    },
  ];

  return (
    <div
      className='dc-monitor-sector'
      style={{ borderBottom: `4px solid #${sector?.color}` }}
    >
      <div
        className='temporary-bg'
        onClick={() => history(`/cartao_digital/${sector.id}`)}
      />
      <div className='container-title'>
        <div className='title'>{sector.name}</div>
        <div className='icon'>
          <div onClick={(event) => menu.current?.toggle(event)}>
            <BsThreeDotsVertical />
          </div>
        </div>
        <Menu
          model={items}
          popup
          ref={menu}
        />
      </div>
      <div>
        <div>Distribuição: {getDistributionList(sector.id)}</div>
        <div>Quadros: {getFrameList(sector.id)}</div>
      </div>
    </div>
  );
};

export default Sector;
