import React from 'react';

import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';

import s from './inputTextarea.module.scss';

interface IInputTextareaProps extends InputTextareaProps {
  placeholder: string;
}

const BaseInputTextarea: React.FC<IInputTextareaProps> = ({ placeholder, ...InputTextareaProps }) => {
  return (
    <span className={`p-float-label ${s.container}`}>
      <InputTextarea
        rows={3}
        cols={30}
        className={s.default}
        {...InputTextareaProps}
      />
      <label htmlFor={placeholder}>{placeholder}</label>
    </span>
  );
};

export default BaseInputTextarea;
