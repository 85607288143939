import * as React from 'react';

import { Button, ButtonProps } from 'primereact/button';

import './button.scss';

interface IProps extends ButtonProps {
  customClassName?: string;
}

const ButtonPrime: React.FC<IProps> = ({ customClassName, ...buttonProps }) => {
  return (
    <Button
      className={`prime-button ${customClassName ? customClassName : customClassName}`}
      {...buttonProps}
    />
  );
};

export default ButtonPrime;
