import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Menu } from 'primereact/menu';

import { BsThreeDotsVertical } from 'react-icons/bs';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { IFrameData } from 'types/SCRUM-frame';

import { IShowHideFrame } from '../../distribution.page';
import { useCard } from '../../hooks/useCard';

import './frame.scss';

interface IFrameProps {
  i: IFrameData;
  setShowHideFrame: React.Dispatch<React.SetStateAction<IShowHideFrame>>;
}

const Frame: React.FC<IFrameProps> = ({ i, setShowHideFrame }) => {
  const { user } = React.useContext(Auth);

  const [frame] = useCard('frame_id', i.id);

  const history = useNavigate();

  const { toastError } = React.useContext(Toaster);

  const menu = React.useRef<Menu>(null);

  const checkEdit = (moduleID: string, permissionID: string) => {
    if (
      moduleID &&
      permissionID &&
      user &&
      user.permissions &&
      user.permissions[moduleID] &&
      user.permissions[moduleID][permissionID]
    ) {
      const permission = user.permissions[moduleID][permissionID];

      const checkEdit = permission.includes('R');
      return checkEdit ? false : true;
    } else {
      return true;
    }
  };

  const newCard = frame.filter((item) =>
    item.sequence.find(
      (item) => (item.status === 'notWithMe' || item.status === 'withMe') && item.sector_id === i.sector_id
    )
  );

  const items = [
    {
      label: 'Abrir',
      icon: 'pi pi-plus',
      command: () => {
        history(`${i.id}`);
      },
    },
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      disabled: checkEdit('scrum-online', 'btn-edit-frame'),
      command: () => {
        setShowHideFrame({ type: 'edit', visible: true, value: i });
      },
    },
    {
      label: 'Excluir',
      icon: 'pi pi-trash',
      disabled: checkEdit('scrum-online', 'btn-delete-frame'),
      command: () => {
        newCard.length === 0
          ? setShowHideFrame({ type: 'delete', visible: true, value: i })
          : toastError(
              'Não foi possível apagar este quadro, uma vez que há cartões armazenados dentro dele. Por favor, finalize as ações relacionadas aos cartões antes de prosseguir com a exclusão.'
            );
      },
    },
  ];

  return (
    <div className='dc-distribution-frame'>
      <div
        className='temporary-bg'
        onClick={() => history(`${i.id}`)}
      />
      <div className='container-title'>
        <div className='title'>{i.name}</div>
        <div className='icon'>
          <div onClick={(event) => menu.current?.toggle(event)}>
            <BsThreeDotsVertical />
          </div>
        </div>
        <Menu
          model={items}
          popup
          ref={menu}
        />
      </div>
      <div>
        <div>Quantidade de Cartões: {newCard.length}</div>
      </div>
    </div>
  );
};

export default Frame;
