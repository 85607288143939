import { Timestamp } from 'firebase/firestore';

import { IDefaultParams } from './ALL-DefaultParams';

export type IStatus = 'available' | 'unavailable';

export interface IParkingSpace extends IDefaultParams {
  code3c_id: string;
  code: string;
  name: string;
  factory_id: string;
  factory_name?: string;
  group_id: string;
  group_name?: string;
  sector_id: string;
  sector_name?: string;
  number: string;
  cell?: string;
  priority: number;
  status?: IStatus;
  notes?: string;
  lastStatus_updated_at: Timestamp;
  enabled?: boolean;
}

export interface IParkingSpaceData extends IParkingSpace {
  id: string;
}

export const initialParkingSpace: IParkingSpaceData = {
  id: '',
  code3c_id: '',
  code: '',
  name: '',
  factory_id: '',
  factory_name: '',
  group_id: '',
  group_name: '',
  sector_id: '',
  sector_name: '',
  number: '',
  priority: 0,
  lastStatus_updated_at: Timestamp.now(),
  created_at: Timestamp.now(),
  updated_at: Timestamp.now(),
};
