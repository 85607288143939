import { IChartBar } from 'componentes_share_admin/charts/bar/bar.component';
import { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import { filterReadingsByDate, getAxisIndex } from 'componentes_share_admin/machines/reports/reports.functions';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IAllWorkShiftData, IOeeMonthlySummaryData } from 'types-node-react/react/type';

import { IOptionsReports } from 'pages/machines/kanban/reports/reports.component';

export function generateShiftReadingsOverview(
  dayReading: IOeeMonthlySummaryData[] | null,
  monthlySummary: IOeeMonthlySummaryData[] | null,
  filters: IFilters,
  report: IOptionsReports,
  getAxisLabels: string[],
  startDate: Date,
  endDate: Date,
  equipmentsIds: string[],
  workShift: IAllWorkShiftData | null,
  eventsMap: { [key: string]: IOptionsEventList }
) {
  const combinedArray = [...(dayReading || []), ...(monthlySummary || [])];

  if (!combinedArray || !workShift) {
    return { aggregatedEvents: [], separatedReadings: {} };
  }

  const separatedReadings: { [key: string]: IChartDonut[] } = {};
  const aggregatedEvents: { [key: string]: IChartBar } = {};

  combinedArray
    .filter((i) => !filters.equipments.includes(i.machine_id))
    .forEach((reading) => {
      reading.data
        .filter((i) => !filters.shifts.includes(i.shift))
        .filter((i) => filterReadingsByDate(report.id, getAxisLabels, i.date.toDate(), startDate, endDate))
        .forEach((day) => {
          day.evts
            .filter((i) => !filters.events.includes(i.id))
            .forEach((evt) => {
              const p = {
                labels: getAxisLabels,
                report: report.id,
                date: day.date,
                start: startDate,
                end: endDate,
                equipIds: equipmentsIds,
                machines: reading.machine_id,
              };

              const token = evt.id;
              const event = eventsMap[token] || 'error';
              const index = getAxisIndex(p.labels, p.report, p.date, p.start, p.end, p.equipIds, p.machines);

              aggregatedEvents[token] = aggregatedEvents[token] || {
                id: event.id ?? 'error',
                name: event.nickName ?? 'error',
                color: event.color ?? 'error',
                value: 0,
                data: Array(getAxisLabels.length).fill(0),
                itemStyle: { color: event.color ?? 'error' },
              };

              aggregatedEvents[token].data[index] += Math.floor(evt.secs);
              aggregatedEvents[token].value += Math.floor(evt.secs);

              separatedReadings[reading.machine_id] = separatedReadings[reading.machine_id] || [];

              const existingReading = separatedReadings[reading.machine_id].find((r) => r.id === token);

              if (existingReading) {
                existingReading.value += Math.floor(evt.secs);
              } else {
                separatedReadings[reading.machine_id].push({
                  id: token,
                  name: event.nickName ?? 'error',
                  value: Math.floor(evt.secs),
                  itemStyle: { color: event.color },
                });
              }
            });
        });
    });

  const aggregatedEventsArray = Object.values(aggregatedEvents).sort((a, b) => b.value - a.value);

  return { aggregatedEvents: aggregatedEventsArray, separatedReadings };
}
