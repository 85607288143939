import React from 'react';

import { useParams } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseFieldset from 'componentes_share_admin/primereact/fieldset/fieldset.component';

import { useFrame } from '../../../monitor/hooks/useFrame';
import { IShowHideFrame } from '../../distribution.page';

import Frame from './frame';

import s from './listOfFrames.module.scss';

interface IListOfFrames {
  setShowHideFrame: React.Dispatch<React.SetStateAction<IShowHideFrame>>;
}

const ListOfFrames: React.FC<IListOfFrames> = ({ setShowHideFrame }) => {
  const { SectorID } = useParams();

  const [frame, pendingFrame] = useFrame(SectorID ? SectorID : '');

  return (
    <div className={s.container}>
      <BaseFieldset
        legend='LISTA DE QUADROS'
        visible={true}
      >
        <div className={s.content_actions}>
          <div className={s.button}>
            <BaseButton
              label='ADICIONAR NOVO QUADRO'
              severity='success'
              size='small'
              onClick={() => setShowHideFrame({ type: 'add', visible: true })}
              permission={{ moduleId: 'scrum-online', permissionId: 'btn-add-frame' }}
            />
          </div>
        </div>
        {pendingFrame ? (
          <div className={s.content_pending}>
            <ProgressSpinner className={s.spinner} />
          </div>
        ) : (
          <div className={s.content_frames}>
            {frame?.length === 0 ? (
              <div>Nenhum Quadro foi Encontrado, crie um agora!</div>
            ) : (
              <React.Fragment>
                {frame.map((i, index) => (
                  <Frame
                    key={index}
                    i={i}
                    setShowHideFrame={setShowHideFrame}
                  />
                ))}
              </React.Fragment>
            )}
          </div>
        )}
      </BaseFieldset>
    </div>
  );
};

export default ListOfFrames;
