import { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IAllWorkShiftData, IOeeDayReadingData } from 'types-node-react/react/type';

import { dayReadingSeparator, dayReadingSummary, findShiftDayWeek } from 'utils-node-react/react/utils';

import { IShiftDisplayList } from 'hooks/machines/useWorkShift.hook';

export function generateShiftReadingsOverview(
  workShift: IAllWorkShiftData | null,
  dayReading: IOeeDayReadingData[],
  date: Date,
  shiftDisplayList: IShiftDisplayList[],
  filters: IFilters,
  eventsMap: { [key: string]: IOptionsEventList }
) {
  const shifts = workShift?.shifts ?? [];
  const readings = dayReading?.[0]?.reading ?? [];

  const startShift = findShiftDayWeek(date, workShift?.shifts);
  const readingSeparated = dayReadingSeparator(readings, shifts, startShift);
  const readingSummary = dayReadingSummary(readings, shifts, startShift);

  const workShifts = shiftDisplayList.filter((shift) => !filters.shifts.includes(shift.id));

  const separatedSummary: { [key: string]: IChartDonut[] } = {};
  const aggregatedEvents: { [key: string]: IChartDonut } = {};

  workShifts.forEach((shift) => {
    const shiftSummary = readingSummary.find((summary) => summary.shift === shift.id)?.evts ?? [];

    separatedSummary[shift.id] = shiftSummary
      .filter((e) => !filters.events.includes(e.id))
      .map((evt) => ({
        name: eventsMap[evt.id]?.name ?? 'error',
        value: evt.secs,
        itemStyle: { color: eventsMap[evt.id]?.color ?? 'error' },
      }));

    separatedSummary[shift.id].forEach((evt) => {
      if (aggregatedEvents[evt.name]) {
        aggregatedEvents[evt.name].value += evt.value;
      } else {
        aggregatedEvents[evt.name] = { ...evt };
      }
    });
  });

  return {
    workShifts,
    readingSeparated,
    separatedSummary,
    aggregatedEvents: Object.values(aggregatedEvents),
  };
}
