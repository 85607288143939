import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import ButtonPrime from 'components/primereact/button/button';
import CalendarPrime from 'components/primereact/calendar/calendar';
import DropdownPrime from 'components/primereact/dropdown/dropdown';

import { useEquipments } from 'hooks/useEquipments';

import { IEquipmentsData, ITypeOfEquipment } from 'types/OEE-equipment';

import Filter from '../filter/filter';

import './navbar.scss';

interface IProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  newDate: Date;
  setNewDate: React.Dispatch<React.SetStateAction<Date>>;
  ID: string | undefined;
  filterEvent: string[];
  setFilterEvent: React.Dispatch<React.SetStateAction<string[]>>;
  type: ITypeOfEquipment;
  defaultLoading: boolean;
  storageEventID: string;
  disabledFilter?: boolean;
}

const Navbar: React.FC<IProps> = ({
  date,
  setDate,
  newDate,
  setNewDate,
  ID,
  filterEvent,
  setFilterEvent,
  type,
  defaultLoading,
  storageEventID,
  disabledFilter,
}) => {
  const history = useNavigate();

  const { getEventsName, getEventsType } = React.useContext(Events);

  const [equipments, pendingEquipments] = useEquipments<IEquipmentsData>(type);

  const colores = getEventsName(type);
  const events = getEventsType(type);

  const getEquipmentName = equipments.find((item) => item.id === ID);

  const [showFilter, setShowFilter] = React.useState(false);
  const [cleanFilter, setCleanFilter] = React.useState(false);

  const items = [
    {
      label:
        type === 'CR'
          ? 'Carrossel'
          : type === 'CT'
          ? 'Costuras'
          : type === 'BD'
          ? 'Bordadeiras'
          : type === 'IJ'
          ? 'Injetoras'
          : '',
      command: () => {
        history(
          type === 'CR'
            ? '/carrossel'
            : type === 'CT'
            ? '/costuras'
            : type === 'BD'
            ? '/bordadeiras'
            : type === 'IJ'
            ? '/injetoras'
            : ''
        );
      },
    },
    { label: 'Detalhes' },
  ];

  const home = {
    icon: 'pi pi-home',
    command: () => {
      history('/dashboard');
    },
  };

  return (
    <div className='default-class-details-navbar'>
      {defaultLoading || pendingEquipments ? (
        <Skeleton className='skeleton' />
      ) : (
        <React.Fragment>
          <div className='background'>
            <div className='flex p-jc-between'>
              <BreadCrumb
                model={items}
                home={home}
              />
            </div>
            <div className='flex-center-y p-flex-wrap p-mt-3'>
              <div className='width100 flex-center-y p-flex-wrap p-jc-between'>
                <div className='flex-center-y p-flex-wrap g-rp-width100'>
                  <div className='flex-center-y dropdown-machine'>
                    <DropdownPrime
                      customClassName='width100 input-text-default'
                      placeholder={
                        type === 'CR'
                          ? 'Carrossel'
                          : type === 'CT'
                          ? 'Costuras'
                          : type === 'BD'
                          ? 'Bordadeiras'
                          : type === 'IJ'
                          ? 'Injetoras'
                          : ''
                      }
                      fieldCode='select-carrossel'
                      formErr={[]}
                      value={{ name: getEquipmentName?.name, id: getEquipmentName?.id }}
                      onChange={(e) => {
                        history(
                          `${
                            type === 'CR'
                              ? '/carrossel/detalhes/'
                              : type === 'CT'
                              ? '/costuras/detalhes/'
                              : type === 'BD'
                              ? '/bordadeiras/detalhes/'
                              : type === 'IJ'
                              ? '/injetoras/detalhes/'
                              : ''
                          }${e.value.id}`
                        );
                      }}
                      optionLabel='name'
                      options={equipments
                        .sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')))
                        .map((item) => {
                          return {
                            name: item.name,
                            id: item.id,
                          };
                        })}
                    />
                  </div>
                  <div className='flex-center-y prime-calendar'>
                    <CalendarPrime
                      showIcon
                      value={newDate}
                      dateFormat='dd/mm/yy'
                      onChange={(e) => setNewDate(e.value as Date)}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className='flex-center-y button-search'>
                    <ButtonPrime
                      customClassName='system'
                      label='Buscar'
                      icon='pi pi-search'
                      disabled={newDate === date}
                      onClick={() => setDate(newDate)}
                    />
                  </div>
                </div>
                {!disabledFilter && (
                  <div className='flex-center-y'>
                    {filterEvent.length !== 0 && (
                      <div className='button-filter'>
                        <ButtonPrime
                          severity='warning'
                          label='Limpar Filtros'
                          icon='pi pi-times'
                          onClick={() => setCleanFilter(true)}
                        />
                      </div>
                    )}
                    <div className='button-filter'>
                      <ButtonPrime
                        customClassName='system'
                        label='Filtros'
                        icon='pi pi-filter'
                        onClick={() => setShowFilter((show) => !show)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Filter
            events={{ events: events, colores: colores }}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            filterEvent={filterEvent}
            setFilterEvent={setFilterEvent}
            cleanFilter={cleanFilter}
            setCleanFilter={setCleanFilter}
            storageEventId={storageEventID}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Navbar;
