import * as React from 'react';

import Loading from 'componentes_share_admin/loading/loading.component';

import { useSearchCode3c } from 'hooks/useSearchCode3c';

import ListRoutes, { IRoutes } from 'routes/listRoutes';

interface IProps {
  children: React.ReactNode;
}

interface IContextRoutes {
  routes: IRoutes[];
}

export const Routes = React.createContext<IContextRoutes>({} as IContextRoutes);

export const RoutesProvider: React.FC<IProps> = ({ children }) => {
  const [code3c, pendingReadingList] = useSearchCode3c();

  const routes = React.useMemo(() => {
    return ListRoutes.filter((item) => code3c?.routes.includes(item.id));
  }, [code3c?.routes]);

  if (pendingReadingList) {
    return <Loading height='100vh' />;
  }

  return <Routes.Provider value={{ routes }}>{children}</Routes.Provider>;
};
