import React from 'react';

import { format } from 'date-fns';

import * as echarts from 'echarts/core';

import { EChartOption } from 'echarts';
import { CustomChart } from 'echarts/charts';
import { DataZoomComponent, GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import ReactEChartsCore from 'echarts-for-react/lib/core';

import { useWindowResizeListener } from 'hooks/useWindowResizeListener.hook';

import { timeLineRender } from './timeLine.function';

echarts.use([TooltipComponent, GridComponent, CustomChart, CanvasRenderer, DataZoomComponent]);

export interface IChartTimeLIne {
  name: string;
  value: number[];
  itemStyle: { normal: { color: string } };
}

interface IProps {
  series: IChartTimeLIne[];
  startTime: number;
  endTime: number;
  categories: string[];
  height: string;
}

const EChartTimeLine: React.FC<IProps> = ({ series, startTime, endTime, categories, height }) => {
  const { windowWidth } = useWindowResizeListener();

  const options = {
    yAxis: { data: categories },
    dataZoom: [{ type: 'inside', filterMode: 'weakFilter' }],
    tooltip: {
      formatter(params: EChartOption.Tooltip.Format) {
        return `
        <b style="color: ${params.data.itemStyle.color}">${params.name}</b> <br>
        <span>Turno</span>: <b>${params.data.shift}</b><br />
        <span>Incio</span>: <b>${format(params.data.value[1], 'dd/MM - HH:mm:ss')}</b><br />
        <span>Fim</span>: <b>${format(params.data.value[2], 'dd/MM - HH:mm:ss')}</b>`;
      },
    },
    grid: {
      top: '20rem',
      left: '20rem',
      right: '75rem',
      containLabel: true,
    },
    xAxis: {
      min: startTime,
      max: endTime,
      scale: true,
      splitNumber: Math.max(1, Math.floor(windowWidth / 150)),
      axisLabel: { formatter: (val: number) => format(val, 'dd/MM - HH:mm') },
    },
    series: [
      {
        type: 'custom',
        renderItem: timeLineRender,
        encode: { x: [1, 2], y: 0 },
        data: series,
      },
    ],
  } as EChartOption;

  return (
    <ReactEChartsCore
      style={{ height: height, width: '100%' }}
      echarts={echarts}
      option={options}
    />
  );
};

export default EChartTimeLine;
