import React from 'react';

import { Data } from 'contexts/data.context';

import Card from './components/card.component';
import Graph from './components/graph.component';
import Navbar from './components/navbar.component';
import Table from './components/table.component';

import { useReadings } from './hooks/useReadings.hook';

import { secondsToTime } from 'functions/millisToTime';

import s from './reports.module.scss';

const optionsReports = [
  { name: 'Mensal', id: 'mensal' },
  { name: 'Anual', id: 'anual' },
];

export interface IOptionsReports {
  name: string;
  id: string;
}

const ReportsKanban: React.FC = () => {
  const { parkingSpaceList } = React.useContext(Data);

  const [date, setDate] = React.useState<Date>(new Date());
  const [newDate, setNewDate] = React.useState<Date>(new Date());

  const [typeReport, setTypeReport] = React.useState<IOptionsReports>({ name: 'Mensal', id: 'mensal' });

  const [readings, pendingReading] = useReadings(date, typeReport);

  const formatReading = React.useMemo(() => {
    const data = readings.map((item) => {
      const findParking = parkingSpaceList.find((i) => i.id === item.parkingSpace_id);

      return { ...item, group_id: findParking?.group_id, factory_id: findParking?.factory_id };
    });

    return data;
  }, [parkingSpaceList, readings]);

  const { available, unavailable } = React.useMemo(() => {
    const initialState = { available: 0, unavailable: 0 };

    const result = readings.reduce((acc, equipment) => {
      equipment.data.forEach((day) => {
        day.events.forEach((item) => {
          if (item.status === 'available') {
            acc.available += item.sumSeconds;
          } else if (item.status === 'unavailable') {
            acc.unavailable += item.sumSeconds;
          }
        });
      });
      return acc;
    }, initialState);

    return result;
  }, [readings]);

  const percentagemAvailable = (100 * available) / (available + unavailable);
  const percentagemUnavailable = (100 * unavailable) / (available + unavailable);

  return (
    <div className={s.container}>
      <Navbar
        date={date}
        setDate={setDate}
        newDate={newDate}
        setNewDate={setNewDate}
        typeReport={typeReport}
        setTypeReport={setTypeReport}
        optionsReports={optionsReports}
        pendingReading={pendingReading}
      />
      <div className={s.row}>
        <Card
          label={`TEMPO CHEIO - ${isNaN(percentagemUnavailable) ? 0 : percentagemUnavailable.toFixed()}%`}
          value={secondsToTime(unavailable)}
          icon='FaArrowAltCircleUp'
          color='#1C9E48'
        />
        <Card
          label={`TEMPO VAZIO - ${isNaN(percentagemAvailable) ? 0 : percentagemAvailable.toFixed()}%`}
          value={secondsToTime(available)}
          icon='FaArrowAltCircleDown'
          color='#942222'
        />
      </div>
      <div className={s.row}>
        <Graph
          date={date}
          typeReport={typeReport}
          data={formatReading}
        />
      </div>
      <div>
        <Table formatReading={formatReading} />
      </div>
    </div>
  );
};

export default ReportsKanban;
