import * as React from 'react';

import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IGroupData } from 'types/ALL-group';

import { firebaseFirestore } from '../../firebase/authentication';

export function useGroup(): [IGroupData[], boolean] {
  const { user } = React.useContext(Auth);

  const [data, setData] = React.useState<IGroupData[]>([]);
  const [pending, setPending] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) {
      setPending(false);
      return;
    }

    setPending(true);

    const queryConfig = query(collection(firebaseFirestore, 'ALL-group'), where('code3c_id', '==', user.code3c_id));

    const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
      const data: IGroupData[] = [];

      if (snapshot.empty) {
        setData([]);
        setPending(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        data.push({ ...item.data(), id: item.id } as IGroupData);
      });

      setData(data.sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, ''))));
      setPending(false);
    });

    return () => unsubscribe();
  }, [user]);

  return [data, pending];
}
