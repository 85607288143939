import React, { useContext, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import Loading from 'componentes_share_admin/loading/loading.component';
import CurrentShift from 'componentes_share_admin/machines/global/currentShift/currentShift.component';
import Filter, { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import Legends from 'componentes_share_admin/machines/global/legends/legends.component';
import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

import { IDayReadingIjData } from 'types_new/ALL-dayReading';
import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { Auth } from 'contexts/auth.context';

import { GraphDonut } from './components/graphDonut.component';

import { useEquipments } from 'hooks/machines/useEquipments.hook';
import { useTempletes } from 'hooks/machines/useTemplete.hook';

import { useDayReadingList } from './hooks/useDayReading.hook';

import { ISettings } from 'routes/listRoutes';

import { navigation } from '../global';

import { generateShiftReadingsOverview } from './monitor.functions';

import s from './monitor.module.scss';

interface IMonitorInjectorController {
  settings: ISettings;
}

const MonitorInjectorController: React.FC<IMonitorInjectorController> = ({ settings }) => {
  const { user } = useContext(Auth);

  const history = useNavigate();

  const configs = useMemo(() => {
    return { ...settings, code3c_id: user?.code3c_id };
  }, [settings, user?.code3c_id]);

  const { eventsMap, eventsList, eventsLoading } = useTempletes(configs);
  const { equipments, equipmentsLoading } = useEquipments(configs);
  const { dayReadingList, dayReadingLoading } = useDayReadingList();

  if (equipmentsLoading || eventsLoading || dayReadingLoading) {
    return (
      <BaseTabMenu
        index={0}
        menuItems={navigation(configs.title, history)}
      >
        <Loading height='calc(100vh - 8rem)' />
      </BaseTabMenu>
    );
  }

  return (
    <MonitorInjectorComponent
      dayReading={dayReadingList}
      equipments={equipments}
      eventsMap={eventsMap}
      configs={configs}
      eventsList={eventsList}
    />
  );
};

interface IMonitorInjectorComponent {
  dayReading: IDayReadingIjData[];
  equipments: IEquipmentGNdata[];
  eventsMap: { [key: string]: IOptionsEventList };
  configs: ISettings;
  eventsList: IOptionsEventList[];
}

const MonitorInjectorComponent: React.FC<IMonitorInjectorComponent> = ({
  dayReading,
  equipments,
  eventsMap,
  configs,
  eventsList,
}) => {
  const history = useNavigate();

  const { user } = useContext(Auth);

  const [filters, setFilters] = React.useState<IFilters>({ equipments: [], events: [], shifts: [] });

  const memoizedEquipmentReadings = useMemo(() => {
    return generateShiftReadingsOverview(dayReading, equipments, filters, eventsMap);
  }, [dayReading, equipments, filters, eventsMap]);

  return (
    <BaseTabMenu
      index={0}
      menuItems={navigation(configs.title, history)}
    >
      <div className={s.container}>
        <CurrentShift workShift={[]}>
          <Filter
            equipmentsList={equipments}
            eventsList={eventsList}
            filters={filters}
            setFilters={setFilters}
            filterKey={`${user?.code3c_id}_monitor_${configs.group}`}
          />
        </CurrentShift>
        <div className={s.content}>
          <GraphDonut
            eventMap={eventsMap}
            eventArray={eventsList}
            enhancedReadings={memoizedEquipmentReadings.groupedReadings}
          />
          {equipments
            .filter((i) => !filters.equipments.includes(i.id))
            .map((i) => (
              <span
                key={i.id}
                onClick={() => history(`/injetoras/detalhes/${i.id}`)}
              >
                <GraphDonut
                  eventMap={eventsMap}
                  eventArray={eventsList}
                  equipment={i as any}
                  enhancedReadings={memoizedEquipmentReadings.separatedReadings[i.id]}
                />
              </span>
            ))}
        </div>
        <Legends eventArray={eventsList.filter((i) => !filters.events.includes(i.id))} />
      </div>
    </BaseTabMenu>
  );
};

export default MonitorInjectorController;
