import React from 'react';

import { IChartBar } from 'componentes_share_admin/charts/bar/bar.component';
import EChartMixed from 'componentes_share_admin/charts/mixed/mixed.component';
import LayoutGraph from 'componentes_share_admin/layout/graph/graph.component';

import { IOptionsEventList } from 'types_new/ALL-options';

interface IProps {
  eventEnhanced: IChartBar[];
  eventList: IOptionsEventList[] | undefined;
}

const GraphMixed: React.FC<IProps> = ({ eventEnhanced }) => {
  const series = React.useMemo(() => {
    if (!eventEnhanced || eventEnhanced.length === 0) return { barSeries: [], lineSeries: [] };

    const sumSeconds = eventEnhanced.reduce((total, item) => total + item.value, 0);
    const cPercentage = eventEnhanced.map((i) => ({ ...i, percentage: Number((100 * i.value) / sumSeconds) }));

    let value = 0;

    const cAccumulated = cPercentage.map((i, index) => {
      if (index === 0) {
        value = i.percentage;

        return { ...i, accumulated: i.percentage };
      } else {
        value = value + i.percentage;

        return { ...i, accumulated: value };
      }
    });

    const barSeries = cAccumulated.map((i) => ({
      value: i.value,
      percentage: i.percentage,
      itemStyle: { color: i.color, barBorderRadius: 10 },
    }));

    const lineSeries = cAccumulated.map((d) => ({ x: d.name, y: d.accumulated.toFixed(2) }));

    return { barSeries: barSeries, lineSeries: lineSeries };
  }, [eventEnhanced]);

  const tooltip =
    'O Diagrama de Pareto é uma ferramenta que identifica as principais causas de problemas, seguindo o princípio de que 20% das causas geram 80% dos efeitos. Ajuda a priorizar ações focando nos aspectos mais impactantes.';

  return (
    <LayoutGraph
      title='Diagrama de Pareto por Evento'
      tooltip={tooltip}
      height='30rem'
    >
      <EChartMixed
        series={series}
        height='26rem'
      />
    </LayoutGraph>
  );
};

export default GraphMixed;
