import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IReading } from '../hooks/useReading.hook';

import s from './tableList.module.scss';

interface IProps {
  eventMap: { [key: string]: IOptionsEventList };
  readings: IReading[];
}

const TableList: React.FC<IProps> = ({ readings, eventMap }) => {
  return (
    <div className={s.container}>
      <div className={s.title}>Histórico Detalhado de Eventos</div>
      <DataTable
        scrollable
        scrollHeight='30rem'
        virtualScrollerOptions={{ itemSize: 46 }}
        size='small'
        emptyMessage='Nenhum Evento Foi Encontrado.'
        value={readings}
        className={s.table}
        sortField='startDate'
        sortOrder={-1}
      >
        <Column
          field='index'
          header='ÍNDICE'
          body={(_, index: ColumnBodyOptions) => <span>{index.rowIndex + 1}</span>}
        />
        <Column
          field='event_name'
          header='EVENTO'
          body={(row) => {
            const event = eventMap[row.event_id] ?? 'error';

            return <div style={{ color: event.color, fontWeight: 'bold' }}>{event.name}</div>;
          }}
        />
        <Column
          field='stationNumber'
          header='ESTAÇÃO'
          body={(row) => <span>Estação {row.stationNumber + 1}</span>}
        />
        <Column
          field='compartmentNumber'
          header='COMPARTIMENTO'
          body={(row) => <span>Compartimento {row.compartmentNumber + 1}</span>}
        />
        <Column
          field='startDate'
          header='INICIO'
          body={(row) => <span>{format(row.startDate.toDate(), 'dd/MM - HH:mm:ss')}</span>}
        />
        <Column
          field='endDate'
          header='FIM'
          body={(row) => <span>{format(row.endDate.toDate(), 'dd/MM - HH:mm:ss')}</span>}
        />
        <Column
          field='differenceInMilliseconds'
          header='DURAÇÃO'
          body={(row) => {
            const date = formatDistanceStrict(row.startDate.toDate(), row.endDate.toDate(), { locale: ptBR });
            return <span>{date === '0 segundos' ? '---' : date}</span>;
          }}
        />
      </DataTable>
    </div>
  );
};

export default TableList;
