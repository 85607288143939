import * as React from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { Events } from 'contexts/events.context';

import Time from 'components/monitor/time/time';

import { IDayReadingCR } from 'types/OEE-dayReading';
import { IEquipmentCR } from 'types/OEE-equipment';

import { millisToTime } from 'functions/millisToTime';

import './chartEquipment.scss';

interface IProps {
  index: number;
  equipment: IEquipmentCR;
  dayReadingFiltered: IDayReadingCR[];
}

interface IGraphData {
  color: string;
  sumMillis: number;
  name: string;
}

const ChartEquipment: React.FC<IProps> = ({ equipment, dayReadingFiltered, index }) => {
  const { getEventsName, getEventsType } = React.useContext(Events);

  const colores = getEventsName('CR');
  const events = getEventsType('CR');

  const series = React.useMemo(() => {
    if (!dayReadingFiltered) return { name: ['Sem Expediente'], color: ['#2E294E'], sumMillis: [1] };

    const date = dayReadingFiltered.reduce((previous, item) => {
      const color = colores.find((colores) => colores.number === item.event_number)?.color;
      previous.push({
        name: item.event_name,
        color: color ? color : '',
        sumMillis: item.sumMillis,
      });
      return previous;
    }, [] as Array<IGraphData>);
    const name = date.map((item) => item.name);
    const color = date.map((item) => item.color);
    const sumMillis = date.map((item) => item.sumMillis);

    return { name, color, sumMillis };
  }, [dayReadingFiltered, colores]);

  const options: ApexOptions = {
    series: series.sumMillis.length === 1 ? [1] : series.sumMillis,
    colors: series.color.length === 1 ? ['#2E294E'] : series.color,
    labels: series.name.length === 1 ? ['Sem Expediente'] : series.name,
    chart: {
      type: 'donut',
      animations: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (e) => millisToTime(e),
      },
    },
    dataLabels: {
      formatter: (val: number) => {
        return `${val.toFixed(0)}%`;
      },
    },
  };

  const graph = React.useCallback(() => {
    return (
      <Chart
        options={options}
        series={options.series}
        type='donut'
        height={210}
        width='100%'
      />
    );
  }, [series, equipment]);

  const color = { color: colores.find((colores) => colores.number === equipment.lastEvent_number)?.color };

  return (
    <div className='carrossel-monitor-chart-equipment'>
      <div className='background'>
        <div
          className='container flex p-as-center p-jc-between'
          style={color}
        >
          <div className='flex p-as-center'>
            {equipment.status === 'offline' && <div className='flex p-as-center icon-equipment-offline' />}
            <div className='flex p-as-center title'>{equipment.name}</div>
          </div>
          <Time
            equipment={equipment}
            index={index}
            events={{ events: events, colores: colores }}
          />
        </div>
        <div>{graph()}</div>
      </div>
    </div>
  );
};

export default ChartEquipment;
