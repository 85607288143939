import * as React from 'react';

import ButtonPrime from 'components/primereact/button/button';

import './dialog.scss';

export const DialogHeader = (props: { type: string; title: string }) => {
  return (
    <div className='default-class-utils-dialog'>
      <div className='dialog-header'>
        <div className={`dialog-icon ${props.type === 'edit' ? 'edit' : props.type === 'delete' ? 'delete' : ''}`}>
          <i
            className={
              props.type === 'add'
                ? 'pi pi-plus'
                : props.type === 'edit'
                ? 'pi pi-pencil'
                : props.type === 'delete'
                ? 'pi pi-trash'
                : 'pi pi-trash'
            }
          />
        </div>
        <div>{props.title}</div>
      </div>
    </div>
  );
};

export const DialogFooter = (props: {
  type: string;
  pending: boolean;
  firstChange: boolean;
  initialState: () => void;
  handleFunction: () => void;
}) => {
  return (
    <div>
      <ButtonPrime
        type='button'
        label={
          props.type === 'add'
            ? 'Adicionar'
            : props.type === 'edit'
            ? 'Editar'
            : props.type === 'delete'
            ? 'Deletar'
            : 'Deletar'
        }
        icon={
          props.pending
            ? 'pi pi-spin pi-spinner'
            : props.type === 'add'
            ? 'pi pi-plus'
            : props.type === 'edit'
            ? 'pi pi-pencil'
            : props.type === 'delete'
            ? 'pi pi-trash'
            : ''
        }
        severity={
          props.type === 'add'
            ? 'success'
            : props.type === 'edit'
            ? 'warning'
            : props.type === 'delete'
            ? 'danger'
            : 'danger'
        }
        disabled={props.pending || !props.firstChange}
        onClick={() => props.handleFunction()}
        customClassName={props.type === 'delete' ? 'system' : ''}
      />
    </div>
  );
};
