import React from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { IDayReadingBD } from 'types/OEE-dayReading';

import './timeline.scss';

interface IProps {
  timelineList: IDayReadingBD[];
  date: Date;
  defaultLoading: boolean;
}

interface IReadingGraph {
  name: string;
  color: string;
  data: IData[];
}

interface IReadingGraphData {
  [string: string]: IReadingGraph;
}
interface IData {
  x: string;
  y: number[];
  headersActive: number;
}

const Timeline: React.FC<IProps> = ({ timelineList, date, defaultLoading }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('BD');

  const series = React.useMemo(() => {
    const data = timelineList.reduce((previous, item) => {
      const token = item.event_name;
      const headersActive = item.headersEvent_number.filter((i) => i === '1').length;
      const color = colores.find((colores) => colores.number === item.event_number)?.color;

      previous[token] = previous[token] || {
        color: color ? color : '',
        name: item.event_name,
        data: [],
      };

      previous[token].data.push({
        x: `Cabeças Ativa: ${headersActive}`,
        y: [item.startDate.toDate().getTime(), item.endDate.toDate().getTime()],
        headersActive,
      });

      return previous;
    }, {} as IReadingGraphData);

    return Object.keys(data).map((item) => data[item]);
  }, [timelineList, date, colores]);

  const options: ApexOptions = {
    chart: {
      height: 300,
      type: 'rangeBar',
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '30%',
        rangeBarGroupRows: true,
      },
    },

    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip: {
      x: {
        show: true,
        format: 'HH:mm:ss',
      },
    },
    legend: {
      show: false,
      position: 'right',
    },
    grid: {
      padding: {
        top: 20,
      },
    },
  };

  const graph = React.useCallback(() => {
    return (
      <Chart
        options={options}
        series={series}
        type='rangeBar'
        height={350}
        width='100%'
      />
    );
  }, [series]);

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='28rem'
        />
      ) : (
        <div className='embroidery-details-timeline'>
          <div className='title'>Linha do Tempo</div>
          <div className='graph'>{graph()}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Timeline;
