import React from 'react';

import { endOfWeek, format, isDate, startOfWeek, subDays } from 'date-fns';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseCalendar from 'componentes_share_admin/primereact/calendar/calendar.component';
import BaseDropDown from 'componentes_share_admin/primereact/dropdown/dropdown.component';

import PrimeTabMenu from '../../tabMenu';
import { IOptionsReports } from '../reports.component';

import s from './navbar.module.scss';

interface INavbarProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  newDate: Date;
  setNewDate: React.Dispatch<React.SetStateAction<Date>>;
  typeReport: IOptionsReports;
  setTypeReport: React.Dispatch<React.SetStateAction<IOptionsReports>>;
  optionsReports: IOptionsReports[];
  pendingReading: boolean;
}

const yearList = [
  { name: '2021', code: '2021-01-01T00:00:00' },
  { name: '2022', code: '2022-01-01T00:00:00' },
  { name: '2023', code: '2023-01-01T00:00:00' },
  { name: '2024', code: '2024-01-01T00:00:00' },
];

const Navbar: React.FC<INavbarProps> = ({
  date,
  setDate,
  newDate,
  setNewDate,
  typeReport,
  setTypeReport,
  optionsReports,
  pendingReading,
}) => {
  const [week, setWeek] = React.useState<Date[]>([subDays(startOfWeek(newDate), -1), subDays(endOfWeek(newDate), -1)]);
  const [year, setYear] = React.useState({ name: format(date, 'yyyy'), code: '2024-01-01T00:00:00' });

  const getWeek = (day: Date | Date[]): Date[] => {
    const getStartAndEndOfWeek = (date: Date) => {
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });

      return [start, end];
    };

    if (Array.isArray(day) && day.length > 0) {
      return getStartAndEndOfWeek(day[0]);
    } else if (isDate(day)) {
      return getStartAndEndOfWeek(day as Date);
    }

    return [];
  };

  return (
    <div>
      <PrimeTabMenu active={2} />
      <div className={s.content_calendar}>
        <div className={s.right}>
          {typeReport.id === 'semanal' ? (
            <div>
              <BaseCalendar
                showIcon
                id='range'
                dateFormat='dd/mm/yy'
                selectionMode='range'
                readOnlyInput
                value={week}
                onChange={(e) => {
                  if (Array.isArray(e.value)) {
                    const value = getWeek(e.value as Date[]) || [];
                    e.value[0] = value[0];
                    e.value[1] = value[1];
                    setWeek(value);
                    setNewDate(subDays(e.value[1], 2) as Date);
                  }
                }}
              />
            </div>
          ) : typeReport.id === 'mensal' ? (
            <div>
              <BaseCalendar
                showIcon
                view='month'
                dateFormat='mm/yy'
                readOnlyInput
                value={newDate}
                onChange={(e) => setNewDate(e.value as Date)}
              />
            </div>
          ) : (
            <div className={s.dropdown_year}>
              <BaseDropDown
                placeholder='Selecione o Ano:'
                optionLabel='name'
                options={yearList}
                value={year}
                onChange={(e) => {
                  setYear(e.value);
                  setNewDate(new Date(e.value.code));
                }}
              />
            </div>
          )}
          <div className={s.dropdown_type}>
            <BaseDropDown
              placeholder='Período'
              optionLabel='name'
              disabled={pendingReading}
              options={optionsReports}
              value={typeReport}
              onChange={(e) => setTypeReport(e.value)}
            />
          </div>
          <BaseButton
            label='Pesquisar'
            disabled={pendingReading}
            icon={pendingReading ? 'pi pi-spinner pi-spinner' : 'pi pi-search'}
            onClick={() => setDate(newDate)}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
