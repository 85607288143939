import * as React from 'react';
import { collections } from 'collections';

import { collection, onSnapshot, query, where } from 'firebase/firestore';

import Loading from 'componentes_share_admin/loading/loading.component';

import { ITypeOfEquipment } from 'types/OEE-equipment';
import { IEventData } from 'types/OEE-event';

import { firebaseFirestore } from '../firebase/authentication';

import { Auth } from './auth.context';

export interface IEventsName {
  id: string;
  name: string;
  number: string;
  color: string;
}

interface IContext {
  pending: boolean;
  events: IEventData[];
  getEventsName(type?: ITypeOfEquipment): IEventsName[];
  getEventsType(type?: ITypeOfEquipment): IEventData[];
}

interface IProps {
  children: React.ReactNode;
}

export const Events = React.createContext<IContext>({} as IContext);

export const EventsProvider: React.FC<IProps> = ({ children }) => {
  const { user } = React.useContext(Auth);

  const [pending, setPending] = React.useState<boolean>(true);
  const [events, setEvents] = React.useState<IEventData[]>([]);

  React.useEffect(() => {
    if (!user) {
      setPending(false);
      return;
    }

    setPending(true);
    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, collections.oeeEvent),
        where('code3c_enabled', 'array-contains', user.code3c_id)
      ),
      (snapshot) => {
        const list: IEventData[] = [];

        if (snapshot.empty) {
          setPending(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IEventData);
        });

        setEvents(list);
        setPending(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  function getEventsName(type?: ITypeOfEquipment) {
    if (type) {
      const data: IEventsName[] = events
        .filter((i) => i.equipments_enabled.includes(type))
        .map((i) => ({ name: i.name, id: i.id, number: i.number, color: i.colour }));

      return data;
    }
    return events.map((i) => ({ name: i.name, id: i.id, number: i.number, color: i.colour }));
  }

  function getEventsType(type?: ITypeOfEquipment) {
    if (type) {
      const data: IEventData[] = events.filter((i) => i.equipments_enabled.includes(type));
      return data;
    }
    return events;
  }

  if (pending) {
    return <Loading height='100vh' />;
  }

  return (
    <Events.Provider value={{ pending, events, getEventsName: getEventsName, getEventsType: getEventsType }}>
      {children}
    </Events.Provider>
  );
};
