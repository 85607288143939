import { Timestamp } from 'firebase/firestore';

import { IDefaultParams } from './ALL-DefaultParams';

export interface IGroup extends IDefaultParams {
  code3c_id: string;
  name: string;
  number: string;
  enabled: boolean;
  factory_id: string;
  factory_name?: string;
  notes?: string;
}

export interface IGroupData extends IGroup {
  id: string;
}

export const initialGroup: IGroupData = {
  id: '',
  code3c_id: '',
  name: '',
  number: '',
  enabled: false,
  factory_id: '',
  factory_name: '',
  updated_at: Timestamp.now(),
  created_at: Timestamp.now(),
};
