import React from 'react';

import './loadPage.scss';

const LoadPage: React.FC = () => {
  return (
    <div className='load-page-main'>
      <div className='flex-center-x-y load-container'>
        <div className='flex-center-x-y'>
          <div className='load-spinner' />
        </div>
      </div>
    </div>
  );
};

export default LoadPage;
