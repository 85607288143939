import React from 'react';

import { Calendar, CalendarProps } from 'primereact/calendar';

import './calendar.scss';

interface IProps extends CalendarProps {
  customClassName?: string;
}

const CalendarPrime: React.FC<IProps> = ({ customClassName, ...CalendarProps }) => {
  return (
    <Calendar
      className={`prime-calendar ${customClassName ? customClassName : customClassName}`}
      {...CalendarProps}
    />
  );
};

export default CalendarPrime;
