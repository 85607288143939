import React from 'react';

import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaHandshake,
  FaHistory,
  FaQuestionCircle,
  FaWhatsapp,
} from 'react-icons/fa';
import {
  FaBars,
  FaBell,
  FaChartLine,
  FaChartPie,
  FaExpand,
  FaFilter,
  FaIndustry,
  FaLaptop,
  FaRegCopy,
  FaStar,
  FaUser,
  FaUsers,
} from 'react-icons/fa6';

import './icons.scss';

interface BasicIconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  iconName: string;
}

interface IIcons {
  [string: string]: React.ReactElement;
}

const Icons: React.FC<BasicIconProps> = ({ iconName, ...props }) => {
  const icons: IIcons = {
    FaChartPie: <FaChartPie />,
    FaHistory: <FaHistory />,
    FaQuestionCircle: <FaQuestionCircle />,
    FaUser: <FaUser />,
    FaIndustry: <FaIndustry />,
    FaWhatsapp: <FaWhatsapp />,
    FaHandshake: <FaHandshake />,
    FaUsers: <FaUsers />,
    FaLaptop: <FaLaptop />,
    FaChartLine: <FaChartLine />,
    FaExpand: <FaExpand />,
    FaBell: <FaBell />,
    FaBars: <FaBars />,
    FaStar: <FaStar />,
    FaRegCopy: <FaRegCopy />,
    FaArrowAltCircleUp: <FaArrowAltCircleUp />,
    FaArrowAltCircleDown: <FaArrowAltCircleDown />,
    FaFilter: <FaFilter />,
    BsThreeDotsVertical: <BsThreeDotsVertical />,
  };

  return <i {...props}>{icons[iconName]}</i>;
};

export default Icons;
