import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';

import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { IDayReadingBD } from 'types/OEE-dayReading';

import './tableEvents.scss';

interface IProps {
  equipmentName: string | undefined;
  readings: IDayReadingBD[];
  defaultLoading: boolean;
}

const TableEvents: React.FC<IProps> = ({ equipmentName, readings, defaultLoading }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('BD');

  const statusBodyTemplate = (data: IDayReadingBD, dataTable: ColumnBodyOptions) => {
    return <span>{dataTable.rowIndex + 1}</span>;
  };

  const startBodyTemplate = (data: IDayReadingBD) => {
    return <span>{format(data.startDate.toDate(), 'dd/MM - HH:mm:ss')}</span>;
  };

  const endBodyTemplate = (data: IDayReadingBD) => {
    return (
      <span>
        {data.endDate === data.startDate ? 'Em andamento' : format(data.endDate.toDate(), 'dd/MM - HH:mm:ss')}
      </span>
    );
  };

  const totalBodyTemplate = (data: IDayReadingBD) => {
    return (
      <span>
        {data.endDate === data.startDate
          ? '---'
          : formatDistanceStrict(data.startDate.toDate(), data.endDate.toDate(), {})}
      </span>
    );
  };

  const actionBodyTemplate = () => {
    return <span>-</span>;
  };

  const headsBodyTemplate = (data: IDayReadingBD) => {
    return (
      <div className='p-d-flex p-as-center p-jc-center p-flex-wrap'>
        {data.headersEvent_number?.map((item, index) => {
          return (
            <div
              key={index.toString()}
              style={{
                background: `${colores ? colores.find((colores) => colores.number === item)?.color : 'Error'}`,
                color: 'white',
              }}
              className='p-m-1 p-p-2'
            >
              {index + 1}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton className='skeleton' />
      ) : (
        <div className='embroidery-details-table-event'>
          <div className='title'>Eventos da Máquina: {equipmentName}</div>
          <div className='p-p-4'>
            <DataTable
              value={readings}
              stripedRows
              showGridlines
              rowHover
              removableSort
              emptyMessage='Nenhum Evento Foi Encontrado.'
              sortField='index'
              sortOrder={-1}
              paginator
              rows={10}
            >
              <Column
                field='#'
                header='#'
                body={statusBodyTemplate}
              />
              <Column
                field='event_name'
                header='Status'
                sortable
              />
              <Column
                field='event_number'
                header='Evento'
                sortable
              />
              <Column
                field='#'
                header='Cabeças Ativas'
                body={headsBodyTemplate}
                style={{ width: '24%' }}
              />
              <Column
                field='start'
                header='Inicio'
                body={startBodyTemplate}
              />
              <Column
                field='end'
                header='Fim'
                body={endBodyTemplate}
              />
              <Column
                field='differenceInMilliseconds'
                header='Total'
                body={totalBodyTemplate}
                sortable
                sortField='differenceInMilliseconds'
              />
              <Column
                field='action'
                header='Ação'
                body={actionBodyTemplate}
              />
            </DataTable>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TableEvents;
