import * as React from 'react';

import { Toast, ToastMessage } from 'primereact/toast';

interface IProps {
  children: React.ReactNode;
}

interface IToaster {
  toastSuccess: (detail: string) => void;
  toastWarn: (detail: string) => void;
  toastError: (detail: string) => void;
  toastCustom: (message: ToastMessage) => void;
  toastClean: () => void;
}

export const Toaster = React.createContext<IToaster>({} as IToaster);

export const ToasterProvider: React.FC<IProps> = ({ children }) => {
  const toastRef = React.useRef<Toast>(null);

  const toastConfig: ToastMessage = {
    life: 5000,
    closable: true,
  };

  const toastSuccess = (description: string) => {
    toastRef.current?.show({
      detail: description,
      severity: 'success',
      summary: 'Sucesso',
      ...toastConfig,
    });
  };

  const toastWarn = (description: string) => {
    toastRef.current?.show({
      detail: description,
      severity: 'warn',
      summary: 'Alerta',
      ...toastConfig,
    });
  };

  const toastError = (description: string) => {
    toastRef.current?.show({
      detail: description,
      severity: 'error',
      summary: 'Erro',
      ...toastConfig,
    });
  };

  const toastCustom = (message: ToastMessage) => {
    toastRef.current?.show({ ...toastConfig, ...message });
  };

  const toastClean = () => {
    toastRef.current?.clear();
  };

  return (
    <Toaster.Provider value={{ toastSuccess, toastCustom, toastWarn, toastError, toastClean }}>
      {children}
      <Toast ref={toastRef} />
    </Toaster.Provider>
  );
};
