import * as React from 'react';
import logoNotNameDark from 'assets/logoNotNameDark.png';
import logoNotNameWhite from 'assets/logoNotNameWhite.png';
import logoWithNameDark from 'assets/logoWithNameDark.png';
import logoWithNameWhite from 'assets/logoWithNameWhite.png';

import { Themes } from 'contexts/theme.context';

import { useWindowResizeListener } from 'hooks/useWindowResizeListener.hook';

import s from './logo.module.scss';

const Logo: React.FC = () => {
  const { windowWidth } = useWindowResizeListener();

  const { actualTheme } = React.useContext(Themes);

  return (
    <div className={s.container}>
      {windowWidth && windowWidth < 1280 ? (
        <img
          src={actualTheme?.type === 'dark' ? logoNotNameDark : logoNotNameWhite}
          alt='Logo do Sistema sem Nome'
          loading='lazy'
          className={s.image_small}
        />
      ) : (
        <div className={s.image}>
          <img
            src={actualTheme?.type === 'dark' ? logoWithNameDark : logoWithNameWhite}
            alt='Logo do Sistema com Nome'
            loading='lazy'
            className={s.image}
          />
          <div className={s.title}>
            {process.env.REACT_APP_DESKTOP === 'STAGING' ? 'OEE - STAGING ' : 'OEE - PRODUÇÃO'}
          </div>
        </div>
      )}
    </div>
  );
};

export default Logo;
