import React from 'react';

import { useNavigate } from 'react-router-dom';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Skeleton } from 'primereact/skeleton';

import Filter from 'components/monitor/filter/filter';
import ButtonPrime from 'components/primereact/button/button';

import { ITypeOfEquipment } from 'types/OEE-equipment';

import './navbar.scss';

interface IProps {
  filterEvent: string[];
  filterEquipment: string[];
  defaultLoading: boolean;
  setFilterEvent: React.Dispatch<React.SetStateAction<string[]>>;
  setFilterEquipment: React.Dispatch<React.SetStateAction<string[]>>;
  type: ITypeOfEquipment;
  storageEquipmentID: string;
  storageEventID: string;
}

const Navbar: React.FC<IProps> = ({
  filterEvent,
  filterEquipment,
  setFilterEquipment,
  defaultLoading,
  setFilterEvent,
  type,
  storageEquipmentID,
  storageEventID,
}) => {
  const history = useNavigate();

  const activeCleanFilter = filterEvent.length !== 0 || filterEquipment.length !== 0;

  const [showFilter, setShowFilter] = React.useState(false);
  const [cleanFilter, setCleanFilter] = React.useState(false);

  const items = [
    {
      label:
        type === 'CR'
          ? 'Carrossel'
          : type === 'CT'
          ? 'Costuras'
          : type === 'BD'
          ? 'Bordadeiras'
          : type === 'IJ'
          ? 'Injetoras'
          : '',
      command: () => {
        history(
          type === 'CR'
            ? '/carrossel'
            : type === 'CT'
            ? '/costuras'
            : type === 'BD'
            ? '/bordadeiras'
            : type === 'IJ'
            ? '/injetoras'
            : ''
        );
      },
    },
  ];

  const home = {
    icon: 'pi pi-home',
    command: () => {
      history('/dashboard');
    },
  };

  return (
    <div className='monitor-navbar'>
      {defaultLoading ? (
        <Skeleton height='4rem' />
      ) : (
        <React.Fragment>
          <div className='background'>
            <div className='flex p-jc-between width100'>
              <BreadCrumb
                model={items}
                home={home}
              />
              <div className='flex'>
                {activeCleanFilter && (
                  <div className='button clean'>
                    <ButtonPrime
                      onClick={() => setCleanFilter(true)}
                      label='Limpar Filtros'
                      icon='pi pi-times'
                      severity='warning'
                    />
                  </div>
                )}
                <div className='button filter'>
                  <ButtonPrime
                    customClassName='system'
                    label='Filtros'
                    icon='pi pi-filter'
                    onClick={() => setShowFilter(true)}
                  />
                </div>
                <div className='button reports'>
                  <ButtonPrime
                    onClick={() =>
                      history(
                        type === 'CR'
                          ? '/carrossel/relatorios/semanal'
                          : type === 'CT'
                          ? '/costuras/relatorios/semanal'
                          : type === 'BD'
                          ? '/bordadeiras/relatorios/semanal'
                          : type === 'IJ'
                          ? '/injetoras/relatorios/semanal'
                          : ''
                      )
                    }
                    label='Relatórios'
                    icon='pi pi-chart-bar'
                    severity='info'
                  />
                </div>
              </div>
            </div>
          </div>
          <Filter
            type={type}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            filterEvent={filterEvent}
            setFilterEvent={setFilterEvent}
            filterEquipment={filterEquipment}
            setFilterEquipment={setFilterEquipment}
            cleanFilter={cleanFilter}
            setCleanFilter={setCleanFilter}
            storageEquipmentID={storageEquipmentID}
            storageEventID={storageEventID}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default Navbar;
