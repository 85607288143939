import React from 'react';

import { useParams } from 'react-router-dom';

import { format } from 'date-fns';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';

import BaseFieldset from 'componentes_share_admin/primereact/fieldset/fieldset.component';
import BaseInputNumber from 'componentes_share_admin/primereact/inputNumber/inputNumber.component';
import BaseInputText from 'componentes_share_admin/primereact/inputText/inputText.component';
import BaseInputTextarea from 'componentes_share_admin/primereact/inputTextarea/inputTextarea.component';

import ParkingFormat from 'pages/machines/scrum/components/parking/parking.component';
import Sequence from 'pages/machines/scrum/components/sequence/sequence';
import { genericFunctionToEdit } from 'pages/machines/scrum/monitor/controllers/firebase';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { useSector } from '../../../monitor/hooks/useSector';
import { IShowHideCard } from '../../distribution.page';

import Question from './question';

import s from './managerCard.module.scss';

interface IManagerCard {
  showHideManager: IShowHideCard;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideCard>>;
  enableQuest: boolean;
}

const ManagerCard: React.FC<IManagerCard> = ({ showHideManager, setShowHideManager, enableQuest }) => {
  const { FrameID } = useParams();

  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [sector] = useSector();

  const [notes, setNotes] = React.useState<string>('');
  const [pending, setPending] = React.useState(false);
  const [firstChange, setFirstChange] = React.useState(false);

  const initialState = React.useCallback(() => {
    setShowHideManager({ type: '', visible: false });
    setNotes('');
    setPending(false);
    setFirstChange(false);
  }, [setShowHideManager]);

  React.useEffect(() => {
    const notesFromItemWithMe = showHideManager.value?.sequence.find((i) => i.frame_id === FrameID)?.notes ?? '';
    setNotes(notesFromItemWithMe);
  }, [FrameID, showHideManager.value?.sequence]);

  async function handleEdit() {
    setPending(true);

    const dataValidation = {
      sequence: showHideManager.value?.sequence?.map((i) => (i.frame_id === FrameID ? { ...i, notes } : i)),
    };

    const code3c = user?.code3c_id ?? '';
    const id = showHideManager.value?.id ?? '';

    try {
      const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-card', id);

      if (response) {
        toastSuccess('Cartão Editado com Sucesso!');
        setPending(false);
        initialState();
      } else {
        toastError('Ops! Algo deu errado ao editar o Cartão!');
        setPending(false);
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  }

  return (
    <Dialog
      header='INFORMAÇÕES DO CARTÃO'
      className={s.dialog}
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
    >
      <Fieldset legend='INFORMAÇÕES BÁSICAS'>
        <div className={s.info}>
          <div className={s.title}>
            <ParkingFormat
              factory={showHideManager.value ? showHideManager.value.factory_id : ''}
              group={showHideManager.value ? showHideManager.value.group_id : ''}
              parking={showHideManager.value ? showHideManager.value.parkingSpace_id : ''}
            />
          </div>
          <div className={s.row}>
            <BaseInputNumber
              disabled={true}
              placeholder='Ordem'
              value={showHideManager?.value?.order || 0}
            />
            <BaseInputText
              placeholder='Data de Criação'
              value={
                showHideManager?.value?.created_at
                  ? format(showHideManager?.value?.created_at.toDate(), 'dd/MM HH:mm')
                  : '-'
              }
              disabled={true}
            />
          </div>
          <BaseInputText
            placeholder='Modelo'
            value={showHideManager?.value?.model || ''}
            disabled={true}
          />
          {!enableQuest && (
            <React.Fragment>
              <BaseInputTextarea
                rows={3}
                cols={30}
                placeholder='Observação'
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  setFirstChange(true);
                }}
              />
              <div className={s.button}>
                <Button
                  type='button'
                  label='Salvar'
                  severity='warning'
                  onClick={() => handleEdit()}
                  disabled={pending || !firstChange}
                  icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </Fieldset>
      <BaseFieldset
        legend='CONFIGURAÇÃO DO CARTÃO'
        visible={enableQuest}
      >
        <Question
          sequence={showHideManager.value?.sequence}
          value={showHideManager.value}
          initialState={initialState}
        />
      </BaseFieldset>
      <BaseFieldset
        legend='STATUS DO FLUXO'
        visible={true}
      >
        <Sequence
          sequence={showHideManager.value?.sequence}
          sectorList={sector}
        />
      </BaseFieldset>
    </Dialog>
  );
};

export default ManagerCard;
