import * as React from 'react';

import Loading from 'componentes_share_admin/loading/loading.component';

import { useFactory } from './hooks/useFactory';
import { useGroup } from './hooks/useGroup';
import { useParkingSpace } from './hooks/useParkingSpace';
import { useSector } from './hooks/useSector';

import { IFactoryData } from 'types/ALL-factory';
import { IGroupData } from 'types/ALL-group';
import { ISectorData } from 'types/ALL-sector';
import { IParkingSpaceData } from 'types/KC-ParkingSpace';

type ITypeData = 'factory' | 'group' | 'parkingSpace' | 'sector';

interface IDataContext {
  factoryList: IFactoryData[];
  groupList: IGroupData[];
  parkingSpaceList: IParkingSpaceData[];
  sectorList: ISectorData[];
  searchDataById(type: ITypeData, id: string): IFactoryData | IGroupData | IParkingSpaceData | ISectorData | undefined;
}

interface IProps {
  children: React.ReactNode;
}

export const Data = React.createContext<IDataContext>({} as IDataContext);

export const DataProvider: React.FC<IProps> = ({ children }) => {
  const [factoryList, pendingFactory] = useFactory();
  const [groupList, pendingGroup] = useGroup();
  const [parkingSpaceList, pendingSpaceList] = useParkingSpace();
  const [sectorList, pendingSectorList] = useSector();

  function searchDataById(type: ITypeData, id: string) {
    const data =
      type === 'factory'
        ? factoryList.find((i) => i.id === id)
        : type === 'group'
        ? groupList.find((i) => i.id === id)
        : type === 'parkingSpace'
        ? parkingSpaceList.find((i) => i.id === id)
        : type === 'sector'
        ? sectorList.find((i) => i.id === id)
        : undefined;

    return data;
  }

  if (pendingFactory || pendingGroup || pendingSpaceList || pendingSectorList) {
    return <Loading height='100vh' />;
  }

  return (
    <Data.Provider value={{ factoryList, groupList, parkingSpaceList, sectorList, searchDataById }}>
      {children}
    </Data.Provider>
  );
};
