import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ISectorData } from 'types/ALL-sector';

import { firebaseFirestore } from '../../../../firebase/authentication';

export function useSector(): [ISectorData[], boolean] {
  const { user } = React.useContext(Auth);

  const [sectorList, setSectorList] = React.useState<ISectorData[]>([]);
  const [pendingSector, setPendingSector] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingSector(true);

    const unsubscribe = onSnapshot(
      query(collection(firebaseFirestore, 'ALL-sector'), where('code3c_id', '==', user.code3c_id), orderBy('name')),
      (snapshot) => {
        const list: ISectorData[] = [];

        if (snapshot.empty) {
          setSectorList([]);
          setPendingSector(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as ISectorData);
        });

        setSectorList(list);
        setPendingSector(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  return [sectorList, pendingSector];
}
