import React from 'react';

import Icons from 'componentes_share_admin/icons/icons';
import BaseTooltip from 'componentes_share_admin/primereact/tooltip/tooltip.component';

import './graph.styles.scss';
import s from './graph.module.scss';

interface IProps {
  title: string;
  tooltip?: string;

  height: string;

  children: React.ReactNode;
}

const LayoutGraph: React.FC<IProps> = ({ title, tooltip, height, children }) => {
  return (
    <div
      className={s.container}
      style={{ height: height }}
    >
      <div className={s.header}>
        <div className={s.left}>
          <div className={s.title}>
            {title}{' '}
            {tooltip && (
              <BaseTooltip
                value={tooltip}
                index={0}
              >
                <Icons iconName='FaQuestionCircle' />
              </BaseTooltip>
            )}
          </div>
        </div>
      </div>
      <div className={s.graph}>{children}</div>
    </div>
  );
};

export default LayoutGraph;
