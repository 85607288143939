import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const sectorSchema = yup.object().shape({
  name: yup.string().label('Nome').required(),
  multipleFrames: yup.boolean().label('Múltiplos Frames').required(),
  color: yup.string().label('Cor').required(),
});
