import React from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseFieldset from 'componentes_share_admin/primereact/fieldset/fieldset.component';

import { Auth } from 'contexts/auth.context';

import { ISectorData } from 'types/SCRUM-sector';

import { useCard } from '../../hooks/useCard';
import { IShowHideSector } from '../../monitor';

import Sector from './sector';

import s from './listOfSectors.module.scss';

interface IListOfSectors {
  setShowHideSector: React.Dispatch<React.SetStateAction<IShowHideSector>>;
  sector: ISectorData[];
  pendingSector: boolean;
}

const ListOfSectors: React.FC<IListOfSectors> = ({ setShowHideSector, sector, pendingSector }) => {
  const { user } = React.useContext(Auth);

  const [card, pendingCard] = useCard(false);

  function getDistributionList(sector_id: string) {
    const newCard = card.filter((item) =>
      item.sequence.find((item) => item.status === 'current' && item.sector_id === sector_id)
    );

    return newCard.length;
  }

  function getFrameList(sector_id: string) {
    const newCard = card.filter((item) =>
      item.sequence.find(
        (item) => (item.status === 'notWithMe' || item.status === 'withMe') && item.sector_id === sector_id
      )
    );

    return newCard.length;
  }

  const filterSector = React.useMemo(() => {
    const filter = sector.filter(
      (item) => user && user.permissions['scrum-online'] && user.permissions['scrum-online'][item.id]
    );

    if (filter.length !== 0) {
      return filter;
    } else {
      return sector;
    }
  }, [sector, user]);

  return (
    <div className={s.container}>
      <BaseFieldset
        legend='LISTA DE SETORES'
        visible={true}
      >
        <div className={s.content_actions}>
          <div className={s.button}>
            <BaseButton
              label='ADICIONAR NOVO SETOR'
              severity='success'
              size='small'
              onClick={() => setShowHideSector({ type: 'add', visible: true })}
              permission={{ moduleId: 'scrum-online', permissionId: 'btn-add-sector' }}
            />
          </div>
        </div>
        {pendingSector || pendingCard ? (
          <div className={s.content_pending}>
            <ProgressSpinner className={s.spinner} />
          </div>
        ) : (
          <div className={s.content_sector}>
            {sector.length === 0 ? (
              <div>Nenhum Setor foi Encontrado, Crie um para Continuar!</div>
            ) : (
              <React.Fragment>
                {filterSector?.map((sector, index) => (
                  <Sector
                    key={index}
                    sector={sector}
                    getFrameList={getFrameList}
                    getDistributionList={getDistributionList}
                    setShowHideSector={setShowHideSector}
                  />
                ))}
              </React.Fragment>
            )}
          </div>
        )}
      </BaseFieldset>
    </div>
  );
};

export default ListOfSectors;
