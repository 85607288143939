import * as React from 'react';

import { collectionGroup, getDocs, query, where } from 'firebase/firestore';

import { startOfDay } from 'date-fns';

import { IOeeDayReadingData, IOeeMonthlySummaryData, Shift } from 'types-node-react/react/type';

import { dayReadingSummary } from 'utils-node-react/react/utils';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReading(
  equipments: string[],
  date: Date,
  workShift: Shift[] | undefined,
  typeReport: string
): {
  dayReading: IOeeMonthlySummaryData[] | null;
  dayReadingLoading: boolean;
} {
  const { user } = React.useContext(Auth);

  const [dayReading, setDayReading] = React.useState<IOeeMonthlySummaryData[] | null>(null);
  const [dayReadingLoading, setDayReadingLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user || !workShift || !equipments || equipments.length === 0) return;

    const fetchEquipments = async () => {
      setDayReadingLoading(true);

      const lastDay = startOfDay(new Date());

      const q = query(
        collectionGroup(firebaseFirestore, 'OEE-dayReading'),
        where('date', '>=', lastDay),
        where('date', '<=', lastDay),
        where('machine_id', 'in', equipments)
      );

      try {
        const documents = await getDocs(q);
        const data: IOeeDayReadingData[] = [];

        documents.docs.forEach((item) => data.push({ ...item.data(), id: item.id } as IOeeDayReadingData));

        const summary = data.map((i) => {
          const daySummary = dayReadingSummary(i.reading, workShift, date);

          return { ...i, data: daySummary };
        }) as IOeeMonthlySummaryData[];

        setDayReading(summary.length > 0 ? summary : null);
        setDayReadingLoading(false);
      } catch (error) {
        setDayReading(null);
        setDayReadingLoading(false);

        console.error('Erro ao buscar dados de leituras:', error);
      }
    };

    fetchEquipments();
  }, [date, equipments, typeReport, user, workShift]);

  return { dayReading, dayReadingLoading };
}
