import * as React from 'react';

interface IProps {
  children: React.ReactNode;
}

interface IThemeItem {
  name: string;
  type: string;
}

interface ITheme {
  changeTheme: (theme: IThemeItem) => void;
  themes: IThemeItem[];
  actualTheme: IThemeItem | undefined;
}

export const Themes = React.createContext<ITheme>({} as ITheme);

export const ThemesProvider: React.FC<IProps> = ({ children }) => {
  const themes = [
    { name: 'Alvorada', type: 'white' },
    { name: 'Noturno', type: 'dark' },
    { name: 'Marítimo', type: 'dark' },
  ] as IThemeItem[];

  const [actualTheme, setActualTheme] = React.useState<IThemeItem>();

  const changeTheme = (theme: IThemeItem) => {
    setActualTheme(theme);

    if (theme.name === 'Alvorada') {
      document.documentElement.style.setProperty('--primary-color:', '#942222');
      document.documentElement.style.setProperty('--primary-text-color', '#343a40');
      document.documentElement.style.setProperty('--secondary-text-color', 'white');
      document.documentElement.style.setProperty('--primary-background-color', 'white');
      document.documentElement.style.setProperty('--secondary-background-color', '#ecedee');
      document.documentElement.style.setProperty('--p-box-shadow', 'rgba(0, 0, 0, 0.18) 0px 2px 4px');
      document.documentElement.style.setProperty('--p-border', '#dfe7ef');
    }
    if (theme.name === 'Noturno') {
      document.documentElement.style.setProperty('--primary-color:', '#942222');
      document.documentElement.style.setProperty('--primary-text-color', '#E1E3E8');
      document.documentElement.style.setProperty('--secondary-text-color', '#E1E3E8');
      document.documentElement.style.setProperty('--primary-background-color', '#242526');
      document.documentElement.style.setProperty('--secondary-background-color', '#18191A');
      document.documentElement.style.setProperty('--p-box-shadow', 'rgba(0, 0, 0, 0.18) 0px 2px 4px');
      document.documentElement.style.setProperty('--p-border', '#888888');
    }
    if (theme.name === 'Marítimo') {
      document.documentElement.style.setProperty('--primary-color:', '#942222');
      document.documentElement.style.setProperty('--primary-text-color', '#E1E3E8');
      document.documentElement.style.setProperty('--secondary-text-color', '#E1E3E8');
      document.documentElement.style.setProperty('--primary-background-color', '#171C36');
      document.documentElement.style.setProperty('--secondary-background-color', '#070C27');
      document.documentElement.style.setProperty('--p-box-shadow', 'rgba(0, 0, 0, 0.18) 0px 2px 4px');
      document.documentElement.style.setProperty('--p-border', 'rgb(106, 113, 153)');
    }
  };

  React.useEffect(() => {
    changeTheme({ name: 'Alvorada', type: 'white' });
  }, []);

  return <Themes.Provider value={{ changeTheme, themes, actualTheme }}>{children}</Themes.Provider>;
};
