// eslint-disable-next-line
export function formatGraphItem(series: any, e: any) {
  // eslint-disable-next-line
  const total = series.reduce((previous: any, item: any) => {
    return previous + item.data[e.dataPointIndex];
  }, 0);
  if (e.w.globals.series[e.seriesIndex]) {
    return `${(e.w.globals.series[e.seriesIndex][e.dataPointIndex] * (100 / total)).toFixed(0)}%`;
  }
  return `${0}%`;
}

export const listDays = [
  { label: 'Segunda-feira', value: 'Monday' },
  { label: 'Terça-feira', value: 'Tuesday' },
  { label: 'Quarta-feira', value: 'Wednesday' },
  { label: 'Quinta-feira', value: 'Thursday' },
  { label: 'Sexta-feira', value: 'Friday' },
  { label: 'Sábado', value: 'Saturday' },
  { label: 'Domingo', value: 'Sunday' },
];

export const othersFilters = [
  { label: 'Filtrar 8 horas do(s) Sabado(s)', value: 'filterhoursaturday' },
  { label: 'Feriados', value: 'holiday' },
];
