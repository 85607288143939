import React from 'react';

import { Dropdown, DropdownProps } from 'primereact/dropdown';

import { catchErrors, catchInvalid } from 'components/formErrors/formErrors';

import { IValidationError } from 'types/ALL-yup';

import './dropdown.scss';

interface IProps extends DropdownProps {
  customClassName?: string;
  placeholder: string;
  formErr: IValidationError[];
  fieldCode: string;
  firstClick?: boolean;
}

const DropdownPrime: React.FC<IProps> = ({
  customClassName,
  placeholder,
  formErr,
  fieldCode,
  firstClick,
  ...DropdownProps
}) => {
  const invalid = catchInvalid(formErr, fieldCode);

  return (
    <div className='prime-drop-down-default-class'>
      <div className={customClassName}>
        <span className='width100 p-float-label p-input-icon-right'>
          <i className={firstClick ? (invalid ? 'pi pi-times icon-error' : 'pi pi-check icon-success') : ''} />
          <Dropdown
            className={firstClick ? (invalid ? 'error width100' : 'success width100') : 'default width100'}
            placeholder={placeholder}
            {...DropdownProps}
          />
          <label
            className={firstClick ? (invalid ? 'icon-error' : 'icon-success') : ''}
            htmlFor={placeholder}
          >
            {placeholder}
          </label>
        </span>
      </div>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default DropdownPrime;
