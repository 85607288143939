import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  name: yup.string().label('Nome').required(),
  number: yup.string().label('Número').required(),
  sector_id: yup.string().label('Setor').required(),
});
