import { useContext, useEffect, useState } from 'react';

import { collectionGroup, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { endOfDay, startOfDay } from 'date-fns';

import { IOeeDayReadingData } from 'types-node-react/react/type';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReading(
  id: string | undefined,
  date: Date
): { dayReading: IOeeDayReadingData[]; dayReadingLoading: boolean } {
  const { user } = useContext(Auth);

  const [dayReading, setDayReading] = useState<IOeeDayReadingData[]>([]);
  const [dayReadingLoading, setDayReadingLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !id || !date) return;

    setDayReadingLoading(true);

    const queryConfig = query(
      collectionGroup(firebaseFirestore, 'OEE-dayReading'),
      where('machine_id', '==', id),
      where('date', '>=', startOfDay(date)),
      where('date', '<=', endOfDay(date)),
      orderBy('date', 'asc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.docs) {
          const data: IOeeDayReadingData[] = [];

          snapshot.docs.forEach((item) => {
            data.push({ ...item.data(), id: item.id } as IOeeDayReadingData);
          });

          setDayReading(data);
        }

        setDayReadingLoading(false);
      },
      (error) => {
        console.error('Erro ao buscar dados do turno de trabalho:', error);

        setDayReadingLoading(false);
      }
    );

    return () => unsubscribe();
  }, [id, date, user]);

  return { dayReading, dayReadingLoading };
}
