import * as React from 'react';

import './animation.scss';

interface IProps {
  show: boolean;
  customClassName: string;
  children: JSX.Element;
}

const Animation: React.FC<IProps> = ({ show, children, customClassName }) => {
  const [shouldRender, setRender] = React.useState(show);

  React.useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    <React.Fragment>
      {shouldRender && (
        <div
          className={customClassName}
          onAnimationEnd={onAnimationEnd}
        >
          {children}
        </div>
      )}
    </React.Fragment>
  );
};

export default Animation;
