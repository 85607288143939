import React from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import Icons from 'components/icons/icons';
import LoadPage from 'components/loading/loadPage/loadPage';
import ButtonPrime from 'components/primereact/button/button';

import GroupManager from './components/groupManager';

import { editEnabledGeneric } from 'controllers/administration';

import { useGroup } from './hooks/useGroup';

import { InputSearch, ModuleDropdown } from 'utils/administration/administration';

import { IGroupData, initialGroup } from 'types/ALL-group';

import { useFactory } from '../factory/hooks/useFactory';

const Group: React.FC = () => {
  const { toastSuccess, toastError } = React.useContext(Toaster);
  const { user } = React.useContext(Auth);

  const [factoryList, pendingFactory] = useFactory();
  const [groupList, pendingGroup] = useGroup();

  const [showHideManager, setShowHideManager] = React.useState({ type: '', visible: false, value: initialGroup });

  const [search, setSearch] = React.useState('');

  const getParkingList = React.useCallback(() => {
    const group = groupList.map((parking) => {
      const getFactory = factoryList.find((factory) => factory.id === parking.factory_id);
      return {
        ...parking,
        factory_name: getFactory?.name ? getFactory?.name : '',
      };
    });

    return group.sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')));
  }, [factoryList, groupList]);

  async function handleEditEnabled(enabled: boolean, id: string) {
    const response = await editEnabledGeneric(enabled, id, 'ALL-group');

    if (response) {
      toastSuccess(`Grupo ${enabled ? 'Ativado' : 'Desativado'} com sucesso.`);
    } else {
      toastError('Não foi possível ativar a fábrica devido a um erro de banco de dados.');
    }
  }

  const factoryName = (data: IGroupData) => {
    const findFactory = factoryList.find((factory) => factory.id === data.factory_id)?.name;

    return findFactory ? findFactory : '';
  };

  return (
    <div className='default-class-admin'>
      {pendingFactory || pendingGroup ? (
        <LoadPage />
      ) : (
        <React.Fragment>
          <GroupManager
            factoryList={factoryList}
            showHideManager={showHideManager}
            setShowHideManager={setShowHideManager}
          />
          <div className='admin-header'>
            <div className='admin-buttons-left'>
              <div className='admin-dropdown'>
                <ModuleDropdown
                  moduleName='Grupos'
                  moduleKey='grupos'
                  userType={user?.userAccessLevel}
                />
              </div>
              <div className='admin-input-text'>
                <InputSearch
                  placeholder='Buscar Grupos...'
                  search={search}
                  setSearch={setSearch}
                />
              </div>
            </div>
            <div className='admin-buttons-right'>
              <div className='button-add'>
                <ButtonPrime
                  label='Adicionar Novo'
                  icon='pi pi-plus'
                  customClassName='system'
                  onClick={() => setShowHideManager({ type: 'add', visible: true, value: initialGroup })}
                />
              </div>
            </div>
          </div>
          <div className='admin-table'>
            <DataTable
              className='admin-table-body'
              stripedRows
              rowHover
              sortField='name'
              sortOrder={1}
              scrollable
              scrollHeight='flex'
              onRowDoubleClick={(e) => setShowHideManager({ type: 'edit', visible: true, value: e.data as IGroupData })}
              virtualScrollerOptions={{ itemSize: 75, showLoader: true }}
              value={getParkingList().filter(
                (item) =>
                  item.name?.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
                  item.factory_name?.toUpperCase().indexOf(search.toUpperCase()) !== -1
              )}
            >
              <Column
                field='name'
                header='Nome'
                sortable
              />
              <Column
                field='number'
                header='Número'
                sortable
              />
              <Column
                field='factory_id'
                header='Fabrica'
                body={factoryName}
              />
              <Column
                field='enabled'
                header='Ativo'
                sortable
                body={(rowData) => (
                  <div className='default-class-utils-admin'>
                    <Icons
                      iconName='AiOutlineCheckCircle'
                      className={rowData.enabled ? 'admin-check' : 'admin-unchecked'}
                      onClick={() => handleEditEnabled(rowData.enabled ? !rowData.enabled : true, rowData.id)}
                    />
                  </div>
                )}
              />
              <Column
                field='action'
                header='Ação'
                body={(rowData) => (
                  <div className='default-class-utils-admin'>
                    <ButtonPrime
                      className='admin-button-pencil'
                      type='button'
                      icon='pi pi-pencil'
                      severity='warning'
                      rounded
                      onClick={() => setShowHideManager({ type: 'edit', visible: true, value: rowData })}
                    />
                  </div>
                )}
              />
            </DataTable>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Group;
