import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ICardData } from 'types/SCRUM-card';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useCard(inputValue: string, sectorId: string): [ICardData[], boolean] {
  const { user } = React.useContext(Auth);

  const [card, setCard] = React.useState<ICardData[]>([]);
  const [pendingCard, setPendingCard] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    setPendingCard(true);

    const queryConfig =
      inputValue === 'sector_id'
        ? query(
            collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-card'),
            where('sectorIds', 'array-contains-any', [sectorId]),
            where('filed', '==', false),
            orderBy('created_at', 'asc')
          )
        : query(
            collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-card'),
            where('frameIds', 'array-contains-any', [sectorId]),
            where('filed', '==', false),
            orderBy('created_at', 'asc')
          );

    const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
      const data: ICardData[] = [];

      if (snapshot.empty) {
        setCard([]);
        setPendingCard(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        data.push({ ...item.data(), id: item.id } as ICardData);
      });

      setCard(data);
      setPendingCard(false);
    });

    return () => unsubscribe();
  }, [user, sectorId, inputValue]);

  return [card, pendingCard];
}
