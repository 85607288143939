import React from 'react';

import EChartTimeLine from 'componentes_share_admin/charts/timeLine/timeLine.component';
import LayoutGraph from 'componentes_share_admin/layout/graph/graph.component';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IReading } from '../hooks/useReading.hook';

interface IProps {
  timelineList: IReading[];
  eventMap: { [key: string]: IOptionsEventList };
  count: number;
}

const Timeline: React.FC<IProps> = ({ timelineList, eventMap, count }) => {
  const generateCategories = (numRows: number) => {
    const categories = [];

    for (let i = 1; i <= numRows; i++) {
      categories.push(`Est ${i} - Comp 1`, `Est ${i} - Comp 2`);
    }

    return categories;
  };

  const series = React.useMemo(() => {
    const categoriesName = generateCategories(count);

    const data = timelineList?.map((reading) => {
      const start = reading.startDate.toDate().getTime();
      const end = reading.endDate.toDate().getTime();
      const event = eventMap[reading.event_id] || { name: 'error', color: 'error' };

      const shiftIndex = categoriesName.indexOf(
        `Est ${reading.stationNumber + 1} - Comp ${reading.compartmentNumber + 1}`
      );

      return {
        name: event.name,
        shift: 'OFF',
        value: [shiftIndex, start, end],
        itemStyle: { normal: { color: event.color } },
      };
    });

    const startTime = data?.reduce((min, { value }) => Math.min(min, value[1]), Infinity);
    const endTime = data?.reduce((max, { value }) => Math.max(max, value[2]), -Infinity);

    return { series: data, startTime: startTime, endTime: endTime, categoriesName };
  }, [count, eventMap, timelineList]);

  return (
    <LayoutGraph
      title='TimeLine de Eventos'
      tooltip=''
      height='28rem'
    >
      <EChartTimeLine
        series={series.series}
        startTime={series.startTime}
        endTime={series.endTime}
        categories={series.categoriesName}
        height='28rem'
      />
    </LayoutGraph>
  );
};

export default Timeline;
