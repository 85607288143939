import { useContext, useEffect, useState } from 'react';

import { collectionGroup, onSnapshot, query, Timestamp, where } from 'firebase/firestore';

import { startOfDay } from 'date-fns';

import { IOeeDayReadingData } from 'types-node-react/react/type';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReading(equipmentsList: string[]): {
  dayReading: IOeeDayReadingData[] | null;
  loadingDayReading: boolean;
} {
  const { user } = useContext(Auth);

  const [dayReading, setDayReading] = useState<IOeeDayReadingData[] | null>(null);
  const [loadingDayReading, setLoadingDayReading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !equipmentsList || equipmentsList.length === 0) {
      setLoadingDayReading(false);

      return;
    }

    const lastDay = startOfDay(Timestamp.now().toDate());

    const queryConfig = query(
      collectionGroup(firebaseFirestore, 'OEE-dayReading'),
      where('date', '>=', lastDay),
      where('machine_id', 'in', equipmentsList)
    );

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.docs) {
          const data: IOeeDayReadingData[] = [];

          snapshot.docs.forEach((item) => {
            data.push({ ...item.data(), id: item.id } as IOeeDayReadingData);
          });

          setDayReading(data);
          setLoadingDayReading(false);
        }

        setLoadingDayReading(false);
      },
      (error) => {
        console.error('Erro ao buscar dados de MonthlySummary:', error);

        setLoadingDayReading(false);
      }
    );

    return () => unsubscribe();
  }, [equipmentsList, user]);

  return { dayReading, loadingDayReading };
}
