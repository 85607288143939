import React from 'react';
import chrome from 'assets/browser/chrome.png';
import edge from 'assets/browser/edge.png';
import firefox from 'assets/browser/firefox.png';
import opera from 'assets/browser/opera.png';
import safari from 'assets/browser/safari.png';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import s from './tableList.module.scss';

const TableList: React.FC = () => {
  const browserSupport = [
    {
      edge: 'IE11, Edge',
      firefox: 'Últimas 2 versões',
      chrome: 'Últimas 2 versões',
      safari: 'Últimas 2 versões',
      opera: 'Últimas 2 versões',
    },
  ];

  const headerBodyTemplate = () => {
    return <div>Navegadores com Suporte</div>;
  };

  const edgeHeaderTemplate = () => {
    return (
      <div className={s.content_image}>
        <img
          src={edge}
          className={s.image}
        />
      </div>
    );
  };

  const firefoxHeaderTemplate = () => {
    return (
      <div className={s.content_image}>
        <img
          src={firefox}
          className={s.image}
        />
      </div>
    );
  };

  const chromeHeaderTemplate = () => {
    return (
      <div className={s.content_image}>
        <img
          src={chrome}
          className={s.image}
        />
      </div>
    );
  };

  const safariHeaderTemplate = () => {
    return (
      <div className={s.content_image}>
        <img
          src={safari}
          className={s.image}
        />
      </div>
    );
  };

  const operaHeaderTemplate = () => {
    return (
      <div className={s.content_image}>
        <img
          src={opera}
          className={s.image}
        />
      </div>
    );
  };

  return (
    <DataTable
      value={browserSupport}
      stripedRows
      showGridlines
      rowHover
      removableSort
      header={headerBodyTemplate}
    >
      <Column
        field='edge'
        header={edgeHeaderTemplate}
      />
      <Column
        field='firefox'
        header={firefoxHeaderTemplate}
      />
      <Column
        field='chrome'
        header={chromeHeaderTemplate}
      />
      <Column
        field='safari'
        header={safariHeaderTemplate}
      />
      <Column
        field='opera'
        header={operaHeaderTemplate}
      />
    </DataTable>
  );
};

export default TableList;
