import React from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';

import { IDayReadingCR } from 'types/OEE-dayReading';
import { IEquipmentBDdata, IEquipmentCRdata, IEquipmentCTdata, IEquipmentIJdata } from 'types/OEE-equipment';

import './tableData.scss';

interface IProps {
  equipments: IEquipmentCRdata[] | IEquipmentCTdata[] | IEquipmentBDdata[] | IEquipmentIJdata[];
  dayReadingList: IDayReadingCR[];
  defaultLoading: boolean;
}

const TableData: React.FC<IProps> = ({ equipments, dayReadingList, defaultLoading }) => {
  const statusValueTemplate = (data: number) => {
    return <ProgressBar value={data} />;
  };

  const totalSum = (data: IEquipmentCRdata | IEquipmentCTdata | IEquipmentIJdata | IEquipmentBDdata) => {
    const responseReady = dayReadingList.filter((item) => {
      return item.equipment_id === data.id && item.event_number === '1';
    });

    const responseStop = dayReadingList.filter((item) => {
      return item.equipment_id === data.id && item.event_number !== '1';
    });

    const sumReady = responseReady.reduce((prevValue, currentValue) => {
      if (currentValue.sumMillis) {
        return prevValue + currentValue.sumMillis;
      }
      return prevValue;
    }, 0);

    const sumStop = responseStop.reduce((prevValue, currentValue) => {
      if (currentValue.sumMillis) {
        return prevValue + currentValue.sumMillis;
      }
      return prevValue;
    }, 0);

    const sum = responseReady && responseStop ? sumReady + sumStop : 0;

    const response = responseReady && responseStop ? (100 * sumReady) / sum : 0;

    return isNaN(response) ? 0 : response.toFixed(0);
  };

  const newMachineList = equipments.map((item) => {
    return { ...item, totalSum: totalSum(item) };
  });

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='20rem'
        />
      ) : (
        <div className='reports-table-data'>
          <div className='title'>Histórico por Maquina Simplificado</div>
          <div className='p-p-4'>
            <DataTable
              value={newMachineList}
              stripedRows
              showGridlines
              rowHover
              removableSort
              emptyMessage='Nenhuma Maquina foi Encontrado.'
              sortField='totalSum'
              sortOrder={-1}
            >
              <Column
                field='id'
                header='ID'
                sortable
              />
              <Column
                field='name'
                header='Maquina'
                sortable
              />
              <Column
                field='totalSum'
                header='Status'
                sortable
                body={(rowData) => {
                  return statusValueTemplate(rowData.totalSum);
                }}
              />
            </DataTable>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TableData;
