import React from 'react';

import { ColorPicker } from 'primereact/colorpicker';

import './pickColor.scss';

interface IPickColor {
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}

const PickColor: React.FC<IPickColor> = ({ color, setColor }) => {
  return (
    <div className='default-class-pick-color'>
      <ColorPicker
        className='picker'
        format='hex'
        value={color}
        onChange={(e) => setColor(e.value as string)}
      />
    </div>
  );
};

export default PickColor;
