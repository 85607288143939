import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';
import { Data } from 'contexts/data.context';

import { ICardData } from 'types/SCRUM-card';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useCard(sectorId: string | undefined): [ICardData[], boolean] {
  const { user } = React.useContext(Auth);
  const { parkingSpaceList } = React.useContext(Data);

  const [card, setCard] = React.useState<ICardData[]>([]);
  const [pendingCard, setPendingCard] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    if (sectorId) {
      setPendingCard(true);

      const queryConfig = query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-card'),
        where('frameIds', 'array-contains-any', [sectorId]),
        where('filed', '==', false),
        orderBy('created_at', 'asc')
      );

      const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
        const data: ICardData[] = [];

        if (snapshot.empty) {
          setCard([]);
          setPendingCard(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          data.push({ ...item.data(), id: item.id } as ICardData);
        });

        const formatData = data
          .sort((a, b) => Number(a.created_at) - Number(b.created_at))
          .map((item) => {
            const group = parkingSpaceList.find((parking) => parking.id === item.parkingSpace_id);

            return { ...item, group_id: group ? group.group_id : 'unknown' };
          });

        setCard(formatData);
        setPendingCard(false);
      });

      return () => unsubscribe();
    }
  }, [parkingSpaceList, sectorId, user]);

  return [card, pendingCard];
}
