import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const CurrentTime: React.FC = () => {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(format(new Date(), 'HH:mm:ss', { locale: ptBR }));
    };

    updateCurrentTime();

    const interval = setInterval(updateCurrentTime, 1000);

    return () => clearInterval(interval);
  }, []);

  return <span>{currentTime}</span>;
};

export default CurrentTime;
