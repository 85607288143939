import React from 'react';

import { format } from 'date-fns';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import Icons from 'components/icons/icons';
import LoadPage from 'components/loading/loadPage/loadPage';
import ButtonPrime from 'components/primereact/button/button';

import ParkingManager from './components/parkingManager';

import { editEnabledGeneric } from 'controllers/administration';

import { useParkingSpace } from './hooks/useParkingSpace';

import { InputSearch, ModuleDropdown } from 'utils/administration/administration';

import { initialParkingSpace, IParkingSpaceData } from 'types/KC-ParkingSpace';

import { useFactory } from '../factory/hooks/useFactory';
import { useGroup } from '../group/hooks/useGroup';

const Parking: React.FC = () => {
  const { toastSuccess, toastError } = React.useContext(Toaster);
  const { user } = React.useContext(Auth);

  const [factoryList, pendingFactory] = useFactory();
  const [groupList, pendingGroup] = useGroup();
  const [parkingSpaceList, pendingParkingSpace] = useParkingSpace();

  const [showHideManager, setShowHideManager] = React.useState({
    type: '',
    visible: false,
    value: initialParkingSpace,
  });

  const [search, setSearch] = React.useState('');

  const statusBody = (rowData: IParkingSpaceData) => {
    return (
      <Tag
        value={rowData.status === 'available' ? 'VAZIO' : 'CHEIO'}
        severity={rowData.status === 'available' ? 'danger' : 'success'}
      />
    );
  };

  async function handleEditEnabled(enabled: boolean, id: string) {
    const response = await editEnabledGeneric(enabled, id, 'KC-parkingSpace');

    if (response) {
      toastSuccess(`Vaga ${enabled ? 'Ativada' : 'Desativada'} com sucesso.`);
    } else {
      toastError('Não foi possível ativar a fábrica devido a um erro de banco de dados.');
    }
  }

  const lastUpdateBodyTemplate = (data: IParkingSpaceData) => {
    return (
      <React.Fragment>{data.updated_at ? format(data.updated_at.toDate(), 'dd/MM - hh:mm') : '---'}</React.Fragment>
    );
  };

  const groupName = (data: IParkingSpaceData) => {
    const findGroup = groupList.find((group) => group.id === data.group_id)?.name;

    return findGroup ? findGroup : '';
  };

  const factoryName = (data: IParkingSpaceData) => {
    const findFactory = factoryList.find((factory) => factory.id === data.factory_id)?.name;

    return findFactory ? findFactory : '';
  };

  return (
    <div className='default-class-admin'>
      {pendingFactory || pendingGroup || pendingParkingSpace ? (
        <LoadPage />
      ) : (
        <React.Fragment>
          <ParkingManager
            factoryList={factoryList}
            groupList={groupList}
            showHideManager={showHideManager}
            setShowHideManager={setShowHideManager}
          />
          <div className='admin-header'>
            <div className='admin-buttons-left'>
              <div className='admin-dropdown'>
                <ModuleDropdown
                  moduleName='Vagas'
                  moduleKey='vagas'
                  userType={user?.userAccessLevel}
                />
              </div>
              <div className='admin-input-text'>
                <InputSearch
                  placeholder='Buscar Vagas...'
                  search={search}
                  setSearch={setSearch}
                />
              </div>
            </div>
            <div className='admin-buttons-right'>
              <div className='button-add'>
                <ButtonPrime
                  label='Adicionar Novo'
                  icon='pi pi-plus'
                  customClassName='system'
                  onClick={() => setShowHideManager({ type: 'add', visible: true, value: initialParkingSpace })}
                />
              </div>
            </div>
          </div>
          <div className='admin-table'>
            <DataTable
              className='admin-table-body'
              stripedRows
              rowHover
              sortField='code'
              sortOrder={1}
              scrollable
              scrollHeight='flex'
              virtualScrollerOptions={{ itemSize: 75, showLoader: true }}
              onRowDoubleClick={(e) =>
                setShowHideManager({ type: 'edit', visible: true, value: e.data as IParkingSpaceData })
              }
              value={parkingSpaceList.filter(
                (item) =>
                  item.code?.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
                  item.name?.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
                  item.factory_name?.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
                  item.group_name?.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
                  item.status?.toUpperCase().indexOf(search.toUpperCase()) !== -1
              )}
            >
              <Column
                field='code'
                header='Code'
                sortable
              />
              <Column
                field='name'
                header='Nome'
              />
              <Column
                field='number'
                header='Número'
              />
              <Column
                field='cell'
                header='Célula'
              />
              <Column
                field='factory_id'
                header='Fabrica'
                body={factoryName}
              />
              <Column
                field='group_id'
                header='Grupo'
                body={groupName}
              />
              <Column
                field='updated_at'
                header='Ultima Atualização'
                body={lastUpdateBodyTemplate}
                sortable
              />
              <Column
                field='enabled'
                header='Ativo'
                sortable
                body={(rowData) => (
                  <div className='default-class-utils-admin'>
                    <Icons
                      iconName='AiOutlineCheckCircle'
                      className={rowData.enabled ? 'admin-check' : 'admin-unchecked'}
                      onClick={() => handleEditEnabled(rowData.enabled ? !rowData.enabled : true, rowData.id)}
                    />
                  </div>
                )}
              />
              <Column
                field='status'
                header='Status'
                body={statusBody}
                sortable
              />
              <Column
                field='action'
                header='Ação'
                body={(rowData) => (
                  <div className='default-class-utils-admin'>
                    <ButtonPrime
                      className='admin-button-pencil'
                      type='button'
                      icon='pi pi-pencil'
                      severity='warning'
                      rounded
                      onClick={() => setShowHideManager({ type: 'edit', visible: true, value: rowData })}
                    />
                    <ButtonPrime
                      className='admin-button-trash'
                      type='button'
                      icon='pi pi-trash'
                      severity='danger'
                      rounded
                      onClick={() => setShowHideManager({ type: 'delete', visible: true, value: rowData })}
                    />
                  </div>
                )}
              />
            </DataTable>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Parking;
