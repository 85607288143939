import * as React from 'react';

import { Checkbox } from 'primereact/checkbox';

import { FaFilter } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import { Events } from 'contexts/events.context';

import ButtonPrime from 'components/primereact/button/button';

import { useEquipments } from 'hooks/useEquipments';

import Animation from 'utils/animation/animation';

import { IEquipmentsData, ITypeOfEquipment } from 'types/OEE-equipment';

import './filter.scss';

interface IProps {
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  filterEvent: string[];
  setFilterEvent: React.Dispatch<React.SetStateAction<string[]>>;
  filterEquipment: string[];
  setFilterEquipment: React.Dispatch<React.SetStateAction<string[]>>;
  cleanFilter: boolean;
  setCleanFilter: React.Dispatch<React.SetStateAction<boolean>>;
  storageEventID: string;
  storageEquipmentID: string;
  type: ITypeOfEquipment;
}

const Filter: React.FC<IProps> = ({
  setShowFilter,
  showFilter,
  filterEvent,
  setFilterEvent,
  filterEquipment,
  setFilterEquipment,
  cleanFilter,
  setCleanFilter,
  storageEventID,
  storageEquipmentID,
  type,
}) => {
  const { getEventsType } = React.useContext(Events);

  const [equipments] = useEquipments<IEquipmentsData>(type);

  const events = getEventsType(type);

  const [internalFilterEquipment, setInternalFilterEquipment] = React.useState([] as string[]);
  const [internalFilterEvent, setInternalFilterEvent] = React.useState([] as string[]);

  function cleanFilters() {
    setInternalFilterEquipment([]);
    setInternalFilterEvent([]);
  }

  function applyFilter() {
    setFilterEquipment(internalFilterEquipment);
    setFilterEvent(internalFilterEvent);
    setShowFilter(false);
    localStorage.setItem(storageEquipmentID, JSON.stringify(internalFilterEquipment));
    localStorage.setItem(storageEventID, JSON.stringify(internalFilterEvent));
  }

  function onChangeFilterEquipment(event: string) {
    if (internalFilterEquipment.includes(event)) {
      setInternalFilterEquipment(internalFilterEquipment.filter((filter) => filter !== event));
    } else {
      setInternalFilterEquipment([...internalFilterEquipment, event]);
    }
  }

  function onChangeFilterEvent(event: string) {
    if (internalFilterEvent.includes(event)) {
      setInternalFilterEvent(internalFilterEvent.filter((filter) => filter !== event));
    } else {
      setInternalFilterEvent([...internalFilterEvent, event]);
    }
  }

  React.useEffect(() => {
    if (cleanFilter === true) {
      setFilterEvent([]);
      setFilterEquipment([]);
      setInternalFilterEquipment([]);
      setInternalFilterEvent([]);
      setCleanFilter(false);
      localStorage.setItem(storageEquipmentID, JSON.stringify([]));
      localStorage.setItem(storageEventID, JSON.stringify([]));
    }
  }, [cleanFilter]);

  React.useEffect(() => {
    setInternalFilterEquipment(filterEquipment);
  }, [filterEquipment, showFilter]);

  React.useEffect(() => {
    setInternalFilterEvent(filterEvent);
  }, [filterEvent, showFilter]);

  return (
    <Animation
      show={showFilter}
      customClassName={`monitor-event-equipment-filter ${showFilter ? 'transition-fadeIn' : 'transition-fadeOut'}`}
    >
      <div className='p-p-3 container'>
        <div className='p-d-flex p-jc-between'>
          <div className='flex'>
            <div className='icon-bg flex-center-x'>
              <FaFilter className='icon flex-center-x-y' />
            </div>
            <div className='title flex-center-y'>Filtros</div>
          </div>
          <IoMdClose
            className='icon-closed flex-center-x-y'
            onClick={() => setShowFilter(false)}
          />
        </div>
        <div className='p-my-2 p-mx-5 flex-center-x-y p-flex-column'>
          <div
            className='flex-center-x-y p-flex-wrap p-mx-3'
            style={{ width: '55rem' }}
          >
            <div className='filter-header'>Lista de Eventos</div>
            {events.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className='p-my-1'
                >
                  <Checkbox
                    value={item.name}
                    checked={internalFilterEvent.filter((filter) => filter === item.name).length !== 0 ? true : false}
                    onChange={(e) => onChangeFilterEvent(e.value)}
                  />
                  <label className='p-m-3 p-checkbox-label'>{item.name}</label>
                </div>
              );
            })}
          </div>
          <div
            className='flex-center-x-y p-flex-wrap p-mx-3'
            style={{ width: '55rem' }}
          >
            <div className='filter-header'>Lista de Equipamentos</div>
            {equipments
              .sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')))
              .map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className='p-my-1'
                  >
                    <Checkbox
                      value={item.name}
                      checked={
                        internalFilterEquipment.filter((filter) => filter === item.name).length !== 0 ? true : false
                      }
                      onChange={(e) => onChangeFilterEquipment(e.value)}
                    />
                    <label className='p-m-3 p-checkbox-label'>{item.name}</label>
                  </div>
                );
              })}
          </div>
        </div>
        <div className='p-d-flex p-jc-end'>
          <div className='p-mr-3'>
            <ButtonPrime
              onClick={() => cleanFilters()}
              severity='warning'
              label='Limpar'
              icon='pi pi-trash'
            />
          </div>
          <div>
            <ButtonPrime
              onClick={() => applyFilter()}
              severity='success'
              label='Aplicar'
              icon='pi pi-check'
            />
          </div>
        </div>
      </div>
    </Animation>
  );
};

export default Filter;
