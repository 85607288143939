import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Timestamp } from 'firebase/firestore';

import { formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';

import { BsWifiOff } from 'react-icons/bs';

import { useEquipments } from 'hooks/useEquipments';

import { IParkingSpaceData } from 'types/KC-ParkingSpace';
import { IEquipmentMVdata } from 'types/OEE-equipment';
import { ISectorData } from 'types/SCRUM-sector';

import { useCard } from '../hooks/useCard.hook';
import { IShowHideParking } from '../monitor.component';

import './parking.scss';

interface IParkingSpaceProps {
  parking: IParkingSpaceData;
  index: number;
  sectorList: ISectorData[];
  setShowHideParking: React.Dispatch<React.SetStateAction<IShowHideParking>>;
}

const Parking: React.FC<IParkingSpaceProps> = ({ parking, index, sectorList, setShowHideParking }) => {
  const history = useNavigate();

  const [equipmentsList, pendingEquipmentsList] = useEquipments<IEquipmentMVdata>('MV');
  const [cardList, pendingCardList] = useCard(parking.id);

  const [time, setTime] = React.useState<string | undefined>(undefined);

  const menu = React.useRef<Menu>(null);

  const [findCard] = cardList;

  const updateEventTime = React.useCallback(() => {
    if (parking) {
      const eventUpdate = parking.lastStatus_updated_at.toDate();
      const formatDate = formatDistanceStrict(parking ? eventUpdate : new Date(), new Date(), {
        locale: ptBR,
        addSuffix: true,
      });

      if (parking.lastStatus_updated_at > Timestamp.now()) {
        setTime('-');
      } else {
        setTime(formatDate);
      }
    }
  }, [parking]);

  const findSector = React.useMemo(() => {
    if (!findCard) {
      return '';
    }

    const sectorCurrent = findCard.sequence?.find((item) => item.status === 'current')?.sector_id;
    const sectorWhiteMe = findCard.sequence?.find((item) => item.status === 'withMe')?.sector_id;

    const findSectorName = (sectorId: string) => {
      return sectorList.find((item) => item.id === sectorId)?.name || '-';
    };

    if (sectorCurrent) {
      const currentSectorName = findSectorName(sectorCurrent);

      if (currentSectorName) {
        return (
          <div>
            <div>{currentSectorName}</div>
            <div>CONFIGURANDO</div>
          </div>
        );
      }
    } else if (sectorWhiteMe) {
      return <div>{findSectorName(sectorWhiteMe)}</div>;
    } else {
      return <div>CONFIGURANDO SEQUENCIA</div>;
    }
  }, [findCard, sectorList]);

  React.useEffect(() => {
    updateEventTime();

    const intervalID = setInterval(updateEventTime, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [updateEventTime]);

  const offline = React.useMemo(() => {
    const data = equipmentsList.find(
      (item: IEquipmentMVdata) =>
        Object.keys(item.parkingSpace_ids).filter((i) => item.parkingSpace_ids[i] === parking.id).length > 0
    );
    return data;
  }, [equipmentsList, parking.id]);

  const items = [
    {
      label: 'Gerenciar Cartão',
      command: () => setShowHideParking({ type: 'edit', visible: true, value: parking }),
    },
    {
      label: 'Dados da Vaga',
      command: () => history(`/kanban/detalhes/${parking.id}`),
    },
  ];

  return (
    <div
      className={`default-class-kanban-parking
      ${parking.status === 'available' ? 'red' : 'green'}
      ${index === 0 ? 'first ' : 'second'}
      ${parking.status === 'available' ? (!findCard ? 'blink-red' : '') : findCard ? 'blink-green' : ''}`}
      onClick={(event) => menu.current?.toggle(event)}
    >
      <Menu
        model={items}
        popup
        ref={menu}
      />
      {pendingCardList || pendingEquipmentsList ? (
        <div className='loading-parking'>
          <ProgressSpinner className='spinner' />
        </div>
      ) : (
        <React.Fragment>
          <div className='container-time'>
            <div className='status'>
              {offline && offline.status === 'offline' && <BsWifiOff className='icon' />}
              {time}
            </div>
            <div>{findCard?.order}</div>
          </div>
          {findSector}
        </React.Fragment>
      )}
    </div>
  );
};

export default Parking;
