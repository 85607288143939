import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';

import CheckBox from 'pages/components_prime/checkBox';
import InputText from 'pages/components_prime/inputText';
import PickColor from 'pages/components_prime/pickColor';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { DialogFooter } from 'utils/dialog/dialog';

import { IValidationError } from 'types/ALL-yup';
import { ISectorData } from 'types/SCRUM-sector';

import { genericFunctionToAdd, genericFunctionToDelete, genericFunctionToEdit } from '../../controllers/firebase';
import { IShowHideSector } from '../../monitor';
import { sectorSchema } from '../../validations/sector';

import './managerSector.scss';

interface IManagerSector {
  showHideManager: IShowHideSector;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideSector>>;
}

const ManagerSector: React.FC<IManagerSector> = ({ showHideManager, setShowHideManager }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [name, setName] = React.useState<string>('');
  const [multipleFrames, setMultipleFrames] = React.useState<boolean>(true);
  const [color, setColor] = React.useState<string>('000000');

  const [pending, setPending] = React.useState<boolean>(false);

  const [firstClick, setFirstClick] = React.useState<boolean>(false);

  const [formErr, setFormErr] = React.useState<IValidationError[]>([]);

  const initialState = React.useCallback(() => {
    setShowHideManager({ type: '', visible: false });

    setName('');
    setMultipleFrames(true);
    setColor('000000');

    setPending(false);
    setFirstClick(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ISectorData> = {
      name: name,
      multipleFrames: multipleFrames,
      color: `#${color}`.replace(/#/g, ''),
    };

    sectorSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const response = await genericFunctionToAdd(dataValidation, code3c, 'SCRUM-sector');

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Novo Setor adicionado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar o Setor!');
        }
      })
      .catch((error: IValidationError) => {
        console.log(error);
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ISectorData> = {
      name: name,
      multipleFrames: multipleFrames,
      color: color.replace(/#/g, ''),
    };

    sectorSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const id = showHideManager.value ? showHideManager.value.id : '';
        const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-sector', id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Setor Editado com Sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o Setor!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleDelete() {
    setPending(true);

    const code3c = user ? user.code3c_id : '';
    const id = showHideManager.value ? showHideManager.value.id : '';
    const response = await genericFunctionToDelete(id, code3c, 'SCRUM-sector');
    if (response) {
      initialState();
      setFormErr([]);
      toastSuccess('Quadro deletado com sucesso!');
    } else {
      setPending(false);
      setFormErr([]);
      toastError('Ops! Algo deu errado ao deletar o Quadro!');
    }
  }

  React.useEffect(() => {
    if (showHideManager.value && showHideManager.type === 'edit') {
      setName(showHideManager.value.name);
      setMultipleFrames(showHideManager.value.multipleFrames);
      setColor(showHideManager.value.color);
    }
  }, [showHideManager]);

  return (
    <Dialog
      className='default-class-monitor-manager-sector'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add'
          ? 'ADICIONAR NOVO SETOR'
          : showHideManager.type === 'edit'
          ? 'EDITAR SETOR'
          : showHideManager.type === 'delete'
          ? 'DELETAR SETOR'
          : ''
      }
      footer={
        showHideManager.type === 'add' ? (
          <DialogFooter
            type='add'
            pending={pending}
            initialState={initialState}
            firstChange={true}
            handleFunction={handleAdd}
          />
        ) : showHideManager.type === 'edit' ? (
          <DialogFooter
            type='edit'
            pending={pending}
            initialState={initialState}
            firstChange={true}
            handleFunction={handleEdit}
          />
        ) : showHideManager.type === 'delete' ? (
          <DialogFooter
            type='delete'
            pending={pending}
            initialState={initialState}
            firstChange={true}
            handleFunction={handleDelete}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      {showHideManager.type === 'delete' ? (
        <div className='container-delete'>
          Você está prestes a excluir o Setor {showHideManager.value ? showHideManager.value.name : '-'}.
          <br /> <br />
          Tem certeza de que deseja continuar? Essa ação é irreversível e todas as informações associadas a este setor
          serão permanentemente apagadas.
        </div>
      ) : showHideManager.type === 'add' || showHideManager.type === 'edit' ? (
        <div className='container-info-basic'>
          <Fieldset legend='INFORMAÇÕES BÁSICAS'>
            <InputText
              placeholder='Digite o Nome do Setor'
              id='name'
              fieldCode='name'
              formErr={formErr}
              firstClick={firstClick}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Fieldset>
          <Fieldset legend='CONFIGURAÇÕES'>
            <div className='settings'>
              <div className='container-color'>
                <div className='input'>
                  <InputText
                    placeholder='Cor Hexadecimal'
                    id='color'
                    fieldCode='color'
                    formErr={formErr}
                    firstClick={firstClick}
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
                <div className='pick-color'>
                  <PickColor
                    color={color}
                    setColor={setColor}
                  />
                </div>
              </div>
              <CheckBox
                multipleFrames={multipleFrames}
                setMultipleFrames={setMultipleFrames}
                description='Setor com Múltiplos Quadros'
              />
            </div>
          </Fieldset>
        </div>
      ) : (
        <React.Fragment />
      )}
    </Dialog>
  );
};

export default ManagerSector;
