import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { OverlayPanel } from 'primereact/overlaypanel';

import Icons from 'componentes_share_admin/icons/icons';
import BaseCheckbox from 'componentes_share_admin/primereact/checkbox/checkbox.module';
import BaseDropDown from 'componentes_share_admin/primereact/dropdown/dropdown.component';

import { useFrame } from 'pages/machines/scrum/monitor/hooks/useFrame';
import { useSearchSector } from 'pages/machines/scrum/monitor/hooks/useSearchSector';

import { Data } from 'contexts/data.context';

import s from './frame.module.scss';

interface IProps {
  filterFactory?: string[];
  setFilterFactory?: React.Dispatch<React.SetStateAction<string[]>>;

  children: React.ReactNode;
}

const DEFAULT_FRAME = { name: 'CONFIGURAR CARTÕES', code: '' };

const FrameLayout: React.FC<IProps> = ({ filterFactory, setFilterFactory, children }) => {
  const history = useNavigate();

  const { FrameID, SectorID } = useParams();

  const { factoryList } = React.useContext(Data);

  const [frame] = useFrame(SectorID ? SectorID : '');
  const [sector] = useSearchSector(SectorID ? SectorID : '');

  const [selectedFrame, setSelectedFrame] = React.useState({ name: 'CONFIGURAR CARTÕES', code: '' });

  const op = React.useRef<OverlayPanel | null>(null);

  const formattedFrameData = React.useMemo(() => {
    const data = [{ ...DEFAULT_FRAME }, ...frame.map(({ name, id }) => ({ name, code: id }))];

    return data;
  }, [frame]);

  React.useEffect(() => {
    setSelectedFrame(formattedFrameData.find(({ code }) => code === FrameID) || DEFAULT_FRAME);
  }, [FrameID, formattedFrameData]);

  return (
    <React.Fragment>
      <div className={s.container}>
        <div className={s.content_left}>
          <b>{sector ? sector.name : '-'}</b>
          <BaseDropDown
            placeholder=''
            value={selectedFrame}
            options={formattedFrameData}
            optionLabel='name'
            onChange={(e) => history(`/cartao_digital/${SectorID}/${e.value.code}`)}
          />
        </div>
        {filterFactory && (
          <div className={s.content_right}>
            <div className={filterFactory?.length !== 0 ? s.active : s.not_active}>
              <Icons
                iconName='FaFilter'
                onClick={(e) => op?.current?.toggle(e)}
              />
            </div>
            <OverlayPanel
              ref={op}
              showCloseIcon
            >
              <div className={s.filter}>
                <div>Filtros</div>
                <div className={s.events}>
                  {factoryList?.map((e, index) => {
                    const checked = !!filterFactory?.find((i) => i === e.id);

                    return (
                      <div key={index}>
                        <BaseCheckbox
                          checked={checked}
                          onClick={() => {
                            if (setFilterFactory) {
                              checked
                                ? setFilterFactory((value) => value.filter((i) => i !== e.id))
                                : setFilterFactory((value) => [...value, e.id]);
                            }
                          }}
                        />
                        <span>{e.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </OverlayPanel>
          </div>
        )}
      </div>
      {children}
    </React.Fragment>
  );
};

export default FrameLayout;
