import React from 'react';

import { format } from 'date-fns';

import { TiLockClosedOutline, TiLockOpenOutline } from 'react-icons/ti';

import { IOptionsEventList } from 'types_new/ALL-options';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { editEventInjector } from 'controllers/equipments';

import { useSearchCode3c } from 'hooks/useSearchCode3c';

import { IEquipmentIJdata } from 'types/OEE-equipment';

import { IEditEvent } from '../details.functions';

import { IDataEvent, IMessage } from './changeStations.component';

import s from './stations.module.scss';

interface IProps {
  equipment: IEquipmentIJdata | undefined;
  setShowHideEditEvent: React.Dispatch<React.SetStateAction<IEditEvent>>;
  date: Date;
  eventsMap: { [key: string]: IOptionsEventList };
}

const CardStations: React.FC<IProps> = ({ equipment: equipment, setShowHideEditEvent, date, eventsMap }) => {
  const { toastError, toastSuccess } = React.useContext(Toaster);
  const { accessToken } = React.useContext(Auth);

  const [code3c] = useSearchCode3c();

  async function editEventLocked({ compartment, station, locked }: IDataEvent) {
    const data: IDataEvent = { station, compartment, locked };

    const message: IMessage = { equipment_id: equipment ? equipment.id : '', data: [data] };

    const hostApi = code3c ? code3c?.hostApi : '';
    const response = await editEventInjector(message, accessToken, hostApi);

    if (response.response) {
      toastSuccess(response.message);
    } else {
      toastError(response.message);
    }
  }

  if (format(date, 'dd/MM') !== format(new Date(), 'dd/MM')) {
    return null;
  }

  if (equipment?.lastEventsStation) {
    return (
      <div className={s.container}>
        <div className={s.title}>Lista de Estações</div>
        <div className={s.card}>
          {Object.keys(equipment?.lastEventsStation).map((key, index: number) => (
            <div
              key={key}
              className={s.card_content}
            >
              <div
                className={s.header}
                onClick={() =>
                  setShowHideEditEvent({
                    visible: true,
                    item: equipment.lastEventsStation[Number(key)],
                    equipment_id: equipment.id,
                    key,
                  })
                }
              >
                <div className={s.text}>
                  Estação {index + 1} <br />
                  <small>
                    {equipment.lastEventsStation[Number(key)][0].openDoor ? (
                      <div>Porta Fechada</div>
                    ) : (
                      <div>Porta Aberta</div>
                    )}
                  </small>
                </div>
              </div>
              <div className={s.content}>
                {equipment?.lastEventsStation[Number(key)].map((dataItem, index: number) => (
                  <div
                    key={index}
                    className={s.compartment}
                    style={{ background: eventsMap[dataItem.event_number].color ?? 'error' }}
                  >
                    {dataItem.locked === true ? (
                      <TiLockClosedOutline
                        className={s.icon}
                        onClick={() => editEventLocked({ station: Number(key), compartment: index, locked: false })}
                      />
                    ) : (
                      <TiLockOpenOutline
                        className={s.icon}
                        onClick={() => editEventLocked({ station: Number(key), compartment: index, locked: true })}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CardStations;
