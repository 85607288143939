import * as React from 'react';

import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IMonthlyOperationSummaryData } from 'types/KC-monthlyOperationSummary';

import { firebaseFirestore } from '../../../../../firebase/authentication';
import { IOptionsReports } from '../reports.component';

export function useReadings(date: Date, typeReport: IOptionsReports): [IMonthlyOperationSummaryData[], boolean] {
  const { user } = React.useContext(Auth);

  const [data, setData] = React.useState<IMonthlyOperationSummaryData[]>([]);
  const [pending, setPending] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    setPending(true);

    const startDate = typeReport.id === 'anual' ? startOfYear(date) : startOfMonth(date);
    const endDate = typeReport.id === 'anual' ? endOfYear(date) : endOfMonth(date);

    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'KC-monthlyOperationSummary'),
      where('date', '>=', startDate),
      where('date', '<=', endDate)
    );

    const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
      const data: IMonthlyOperationSummaryData[] = [];

      if (snapshot.empty) {
        setData([]);
        setPending(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        data.push({ ...item.data(), id: item.id } as IMonthlyOperationSummaryData);
      });

      setData(data);
      setPending(false);
    });

    return () => unsubscribe();
  }, [date, typeReport.id, user]);

  return [data, pending];
}
