import { useContext, useEffect, useState } from 'react';
import { collections } from 'collections';

import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { differenceInMilliseconds, startOfDay, subDays } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IReadingDataIJ } from 'types/OEE-reading';

import { firebaseFirestore } from '../../../../../firebase/authentication';

interface IStationList {
  [string: string]: IReading[];
}

export interface IReading extends IReadingDataIJ {
  startDate: Timestamp;
  endDate: Timestamp;
  token: string;
  index: number;
  differenceInMilliseconds: number;
}

export function useReading(
  equipment_id: string | undefined,
  date: Date
): { readingList: IReading[]; readingLoading: boolean } {
  const { user } = useContext(Auth);

  const [readingList, setReadingList] = useState<IReading[]>([]);
  const [readingLoading, setReadingLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !equipment_id || !date) return;

    setReadingLoading(true);

    const dayNext = subDays(date, -1);

    const queryConfig = query(
      collection(firebaseFirestore, collections.allCode3C, user.code3c_id, collections.oeeReadingIj),
      where('equipment_id', '==', equipment_id),
      where('startDate', '>=', startOfDay(date)),
      where('startDate', '<=', startOfDay(dayNext)),
      orderBy('startDate', 'asc')
    );

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.docs) {
          const stationList: IStationList = {};

          snapshot.forEach((item) => {
            const currentValue = item.data() as IReading;

            const { stationNumber, compartmentNumber } = currentValue;

            const token = `Est: ${stationNumber + 1} - Comp: ${compartmentNumber + 1}`;

            stationList[token] = stationList[token] || [];

            stationList[token].push({ ...currentValue, id: item.id, token });
          });

          const list: IReading[] = Object.keys(stationList).reduce((acc, stationNumber) => {
            const data: IReading[] = stationList[stationNumber].map((item, index: number) => {
              const currentValue = item;
              if (stationList[stationNumber][index + 1]) {
                const nextValue = stationList[stationNumber][index + 1] as IReading;

                const difference = differenceInMilliseconds(
                  nextValue.startDate.toDate(),
                  currentValue.startDate.toDate()
                );

                return {
                  ...currentValue,
                  id: item.id,
                  endDate: nextValue.startDate,
                  differenceInMilliseconds: difference,
                  index,
                } as IReading;
              }

              return {
                ...currentValue,
                id: item.id,
                endDate: currentValue.startDate,
                differenceInMilliseconds: 0,
                index,
              } as IReading;
            });

            return [...acc, ...data];
          }, [] as IReading[]);

          setReadingList(list);
        }

        setReadingLoading(false);
      },
      (error) => {
        console.error('Erro ao buscar dados de leituras:', error);

        setReadingLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [date, equipment_id, user]);

  return { readingList, readingLoading };
}
