import React from 'react';
import alertSound from 'assets/alert.mp3';

import { differenceInSeconds } from 'date-fns';

import { ProgressSpinner } from 'primereact/progressspinner';

import BaseFieldset from 'componentes_share_admin/primereact/fieldset/fieldset.component';

import Card from 'pages/machines/scrum/components/card/card.component';

import { useCard } from '../../hooks/useCard';
import { IShowHideCard } from '../../monitor';

import s from './listOfCards.module.scss';

interface IListOfCards {
  setShowHideCard: React.Dispatch<React.SetStateAction<IShowHideCard>>;
}

const ListOfCards: React.FC<IListOfCards> = ({ setShowHideCard }) => {
  const [card, pendingCard] = useCard(true);

  const [audio] = React.useState(new Audio(alertSound));

  React.useEffect(() => {
    const findSeconds = card.find((item) => differenceInSeconds(new Date(), item.created_at.toDate()) >= 3600);

    let intervalId: NodeJS.Timeout;

    if (findSeconds) {
      intervalId = setInterval(() => {
        audio.play();
      }, 1000);
    } else {
      audio.pause();
    }

    return () => {
      audio.pause();

      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [audio, card]);

  return (
    <div className={s.container}>
      <BaseFieldset
        legend='CARTÕES ESPERANDO CONFIGURAÇÃO DE SEQUENCIA'
        visible={true}
      >
        {pendingCard ? (
          <div className={s.content_pending}>
            <ProgressSpinner className={s.spinner} />
          </div>
        ) : (
          <div className={s.content_cards}>
            {card.length === 0 ? (
              <div>Nenhum cartão foi encontrado! Os cartões aparecerão automaticamente!</div>
            ) : (
              <React.Fragment>
                {card.map((i, index) => (
                  <Card
                    key={index}
                    setShowHideCard={setShowHideCard}
                    card={i}
                  />
                ))}
              </React.Fragment>
            )}
          </div>
        )}
      </BaseFieldset>
    </div>
  );
};

export default ListOfCards;
