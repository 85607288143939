import React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { Fieldset } from 'primereact/fieldset';
import { Menu } from 'primereact/menu';

import { BsFillPlusCircleFill, BsTrash3 } from 'react-icons/bs';

import Button from 'pages/components_prime/button';
import InputText from 'pages/components_prime/inputText';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { ISectorData } from 'types/SCRUM-sector';
import { ISequenceData } from 'types/SCRUM-sequence';

import { genericFunctionToEdit } from '../../monitor/controllers/firebase';
import { IShowHideSequence } from '../sequence';

import './container.scss';

interface IContainerProps {
  sequenceProp: ISequenceData;
  sector: ISectorData[];
  setShowHideSequence: React.Dispatch<React.SetStateAction<IShowHideSequence>>;
}

interface IFormatSequence {
  id: string;
  sector_name: string;
  color: string;
}

const Container: React.FC<IContainerProps> = ({ sequenceProp, sector, setShowHideSequence }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const menu = React.useRef<Menu>(null);

  const [sequence, setSequence] = React.useState<IFormatSequence[]>([]);
  const [changeSequence, setChangeSequence] = React.useState(false);

  const formatSequence = React.useMemo(() => {
    const data = sequence.map((item) => item.id);

    return data;
  }, [sequence]);

  const TempleteMenu = () => {
    const [search, setSearch] = React.useState('');

    return (
      <div className='content'>
        <InputText
          placeholder='Buscar por Setores'
          id='sector'
          fieldCode='sector'
          formErr={[]}
          firstClick={false}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='container-options'>
          {sector
            .filter((item) => !formatSequence.includes(item.id))
            .filter((item) => item.name.toUpperCase().indexOf(search.toUpperCase()) !== -1)
            ?.map((item, index) => {
              const action = sequence
                ? [...sequence, { id: item.id, sector_name: item.name, color: item.color }]
                : [{ id: item.id, sector_name: item.name, color: item.color }];

              return (
                <div
                  className='item'
                  key={index}
                  onClick={() => {
                    setSequence(action);
                    setChangeSequence(true);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const AddSector = () => {
    return (
      <React.Fragment>
        <div onClick={(e) => menu.current?.toggle(e)}>
          <BsFillPlusCircleFill className='icon' />
        </div>
        <Menu
          model={[{ template: () => <TempleteMenu /> }]}
          popup
          ref={menu}
          className='sector-menu'
        />
      </React.Fragment>
    );
  };

  async function handleEdit() {
    const dataValidation: Partial<ISequenceData> = { sector_ids: sequence.map((item) => item.id) };

    const code3c = user ? user.code3c_id : '';
    const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-sequence', sequenceProp.id);

    if (response) {
      setChangeSequence(false);
      toastSuccess('Sequencia Editada com Sucesso!');
    } else {
      toastError('Ops! Algo deu errado ao Editar a Sequencia!');
    }
  }

  const getSequence = React.useCallback(async () => {
    if (changeSequence === false) {
      const formatSequence = sequenceProp?.sector_ids?.map((item) => {
        const findSector = sector.find((sector) => sector.id === item);

        return {
          id: item,
          color: findSector?.color ? findSector.color : 'error',
          sector_name: findSector?.name ? findSector.name : 'error',
        };
      });

      setSequence(formatSequence ? formatSequence : []);
    }
  }, [changeSequence, sector, sequenceProp?.sector_ids]);

  React.useEffect(() => {
    getSequence();
  }, [getSequence]);

  return (
    <div className='default-class-sequence-container'>
      <Fieldset legend={`Modelo: ${sequenceProp.model}`}>
        <div className='container-actions'>
          {changeSequence && (
            <Button
              moduleID='scrum-online'
              permissionID='btn-edit-sequence'
              pendingProps={false}
              label='SALVAR ALTERAÇÕES'
              severity='warning'
              onClick={() => handleEdit()}
            />
          )}
          <Button
            moduleID='scrum-online'
            permissionID='btn-delete-sequence'
            pendingProps={false}
            label='EXCLUIR'
            severity='danger'
            onClick={() => setShowHideSequence({ type: 'delete', visible: true, value: sequenceProp })}
          />
        </div>
        <div className='container-sector'>
          <ReactSortable
            filter='.addImageButtonContainer'
            dragClass='sortableDrag'
            list={sequence as IFormatSequence[]}
            setList={setSequence as React.Dispatch<React.SetStateAction<IFormatSequence[]>>}
            animation={200}
            easing='ease-out'
            className='card'
            onUpdate={() => setChangeSequence(true)}
          >
            {sequence?.map((item, index) => {
              return (
                <div
                  className='card-item'
                  key={index}
                  style={{ borderBottom: `4px solid #${item.color}` }}
                >
                  <div className='title'>{item.sector_name}</div>
                  <div
                    className='container-icon'
                    onClick={() => {
                      setSequence(sequence.filter((s, i) => index !== i));
                      setChangeSequence(true);
                    }}
                  >
                    <BsTrash3 className='icon-trash' />
                  </div>
                </div>
              );
            })}
          </ReactSortable>
          <AddSector />
        </div>
      </Fieldset>
    </div>
  );
};

export default Container;
