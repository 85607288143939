import * as React from 'react';

import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IDayReadingDataBD } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../../../../../../firebase/authentication';

export function useFirstDate(date: Date): [IDayReadingDataBD | undefined, boolean] {
  const { user } = React.useContext(Auth);

  const [firstDate, setFirstDate] = React.useState<IDayReadingDataBD | undefined>();

  const [pendingFirstDate, setPendingFirstDate] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingFirstDate(true);

    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-dayReadingBd'),
        orderBy('date', 'asc'),
        limit(1)
      ),
      (snapshot) => {
        const list: IDayReadingDataBD[] = [];

        if (snapshot.empty) {
          setFirstDate(undefined);
          setPendingFirstDate(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IDayReadingDataBD);
        });

        const [newList] = list;

        setFirstDate(newList);
        setPendingFirstDate(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [date]);

  return [firstDate, pendingFirstDate];
}
