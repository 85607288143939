export function millisToTime(mills: number) {
  const sc = mills / 1000;

  const minutes = Math.floor(sc / 60) % 60;
  const hours = Math.floor(sc / 3600);

  const pad = (n: number, z = 2) => `00${n}`.slice(-z);

  return `${hours.toString().length > 2 ? hours : pad(hours)}:${pad(minutes)}`;
}

export function secondsToTime(seconds: number) {
  const newSeconds = Math.floor(seconds);

  const hours =
    Math.floor(newSeconds / 3600) < 10
      ? ('00' + Math.floor(newSeconds / 3600)).slice(-2)
      : Math.floor(newSeconds / 3600);
  const minutes = ('00' + Math.floor((newSeconds % 3600) / 60)).slice(-2);
  const secondsInternal = ('00' + ((newSeconds % 3600) % 60)).slice(-2);

  return hours + 'h : ' + minutes + 'm : ' + secondsInternal + 's';
}

export function secondsFormatInjectorTotal(mills: number) {
  const newMills = mills / 12;

  const hours =
    Math.floor(newMills / 3600) < 10 ? ('00' + Math.floor(newMills / 3600)).slice(-2) : Math.floor(newMills / 3600);
  const minutes = ('00' + Math.floor((newMills % 3600) / 60)).slice(-2);
  const seconds = ('00' + ((newMills % 3600) % 60)).slice(-2);

  return hours + 'h : ' + minutes + 'm : ' + seconds + 's';
}
