import React from 'react';

import * as echarts from 'echarts/core';

import { EChartOption } from 'echarts';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import ReactEChartsCore from 'echarts-for-react/lib/core';

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

export interface ILineBar {
  hours: string[];
  numbers: number[];
}

interface IProps {
  series: ILineBar;
  height: string;
  width: string;
}

const EChartLine: React.FC<IProps> = ({ series, height, width }) => {
  const options = {
    tooltip: {
      trigger: 'axis',
    },
    yAxis: { type: 'value' },
    xAxis: {
      type: 'category',
      data: series.hours,
    },
    grid: {
      left: '1%',
      right: '1%',
      top: '10%',
      bottom: '15%',
      containLabel: true,
    },
    series: [
      {
        data: series.numbers,
        type: 'line',
        lineStyle: { color: '#942222' },
        itemStyle: { color: '#942222' },
        label: {
          show: true,
          position: 'top',
          backgroundColor: '#942222',
          color: 'white',
          borderRadius: 4,
          padding: [5, 5],
        },
      },
    ],
  } as EChartOption;

  return (
    <ReactEChartsCore
      style={{ width: width, height: height }}
      echarts={echarts}
      option={options}
    />
  );
};

export default EChartLine;
