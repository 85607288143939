import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { subDays } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IDayReadingData } from 'types/KC-dayReadingMV';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReadingEquipment(parkingSpace_id: string, date: Date): [IDayReadingData[], boolean] {
  const { user } = React.useContext(Auth);

  const [dayReadingList, setDayReadingList] = React.useState<IDayReadingData[]>([]);
  const [pendingDayReadingList, setPendingDayReadingList] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    const lastDay = subDays(date, 7);

    setPendingDayReadingList(true);

    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'KC-dayReadingMv'),
        where('parkingSpace_id', '==', parkingSpace_id),
        where('date', '>', lastDay),
        where('date', '<=', date),
        orderBy('date')
      ),
      (snapshot) => {
        const list: IDayReadingData[] = [];

        if (snapshot.empty) {
          setDayReadingList([]);
          setPendingDayReadingList(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IDayReadingData);
        });

        setDayReadingList(list);
        setPendingDayReadingList(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [date, parkingSpace_id, user]);

  return [dayReadingList, pendingDayReadingList];
}
