import React, { useCallback, useMemo } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { endOfMonth, endOfWeek, format, getDaysInMonth, subDays } from 'date-fns';

import { IMonthlyOperationSummaryData } from 'types/KC-monthlyOperationSummary';

import { secondsToTime } from 'functions/millisToTime';
import { formatGraphItem } from 'functions/reports';

import { IOptionsReports } from '../reports.component';

import s from './graph.module.scss';

interface IProps {
  date: Date;
  typeReport: IOptionsReports;
  data: IMonthlyOperationSummaryData[];
}

interface TransformedEvent {
  name: string;
  color: string;
  data: number[];
}

const Graph: React.FC<IProps> = ({ date, typeReport, data }) => {
  const optionsName = useMemo(() => {
    if (typeReport.id === 'semanal') {
      const optionsName = [
        format(subDays(endOfWeek(date), 5), 'dd/MM'),
        format(subDays(endOfWeek(date), 4), 'dd/MM'),
        format(subDays(endOfWeek(date), 3), 'dd/MM'),
        format(subDays(endOfWeek(date), 2), 'dd/MM'),
        format(subDays(endOfWeek(date), 1), 'dd/MM'),
        format(subDays(endOfWeek(date), 0), 'dd/MM'),
        format(subDays(endOfWeek(date), -1), 'dd/MM'),
      ];

      return optionsName;
    }

    if (typeReport.id === 'mensal') {
      const count = getDaysInMonth(date);

      const options: string[] = [];
      for (let i = 0; i < count; i++) {
        options.push(format(subDays(endOfMonth(date), count - 1 - i), 'dd/MM'));
      }
      return options;
    }

    if (typeReport.id === 'anual') {
      const optionsNameYearly = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];

      return optionsNameYearly;
    }

    return [];
  }, [date, typeReport]);

  function parseUTCDate(dateString: string) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(Date.UTC(year, month - 1, day));
  }

  const series = useMemo(() => {
    const transformed = data.reduce((acc, equipment) => {
      equipment.data.forEach((day) => {
        const dateIndex =
          typeReport.id === 'anual'
            ? Number(format(equipment.date.toDate(), 'MM')) - 1
            : optionsName.indexOf(format(parseUTCDate(day.date), 'dd/MM')) + 1;

        if (dateIndex < 0) return;

        day.events
          .filter((i) => i.status === 'available' || i.status === 'unavailable')
          .forEach((event) => {
            acc[event.status] = acc[event.status] || {
              name: event.status === 'available' ? 'VAZIO' : 'CHEIO',
              color: event.status === 'available' ? '#a83232' : '#3ca832',
              data: Array(optionsName.length).fill(0),
            };

            acc[event.status].data[dateIndex] += event.sumSeconds;
          });
      });

      return acc;
    }, {} as Record<string, TransformedEvent>);

    return Object.values(transformed);
  }, [data, optionsName, typeReport.id]);

  const graph = useCallback(() => {
    const options = {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        animations: { enabled: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: false,
          dataLabels: { position: 'center' },
        },
      },
      xaxis: {
        categories: optionsName,
        labels: { style: { fontSize: '10.5px' } },
      },
      legend: { show: false },
      grid: { padding: { top: 20 } },
      fill: { opacity: 1 },
      tooltip: { y: { formatter: (val) => secondsToTime(val) } },
      yaxis: {
        axisBorder: { show: true },
        axisTicks: { show: false },
        labels: {
          show: true,
          formatter: (val) => {
            if (val === 0) {
              return `${String(0)}%`;
            }
            return `${val}%`;
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 3,
        formatter: (_val: number, e) => formatGraphItem(series, e),
      },
    } as ApexOptions;

    return (
      <Chart
        options={options}
        series={series}
        type='bar'
        height={300}
        width='100%'
      />
    );
  }, [optionsName, series]);

  return (
    <div className={s.container}>
      <div className={s.title}>Histórico Geral</div>
      <div className={s.graph}>{graph()}</div>
    </div>
  );
};

export default Graph;
