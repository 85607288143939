import React from 'react';

import BaseDropDown from 'componentes_share_admin/primereact/dropdown/dropdown.component';

import { useSector } from 'pages/machines/scrum/monitor/hooks/useSector';

import { Auth } from 'contexts/auth.context';
import { Data } from 'contexts/data.context';

import Factory from './components/factory.component';
import ManagerParking from './components/managerParking';

import { IParkingSpaceData } from 'types/KC-ParkingSpace';
import { ISectorData } from 'types/KC-sector';

import PrimeTabMenu from '../tabMenu';

import s from './monitor.module.scss';

export interface IShowHideParking {
  type: string;
  visible: boolean;
  value?: IParkingSpaceData;
}

const MonitorParkingSpace: React.FC = () => {
  const { user } = React.useContext(Auth);
  const { factoryList, sectorList, parkingSpaceList } = React.useContext(Data);

  const [sectorScrumList] = useSector();

  const [sector, setSector] = React.useState<ISectorData | undefined>();
  const [showHideParking, setShowHideParking] = React.useState<IShowHideParking>({ type: '', visible: false });

  const percentage = React.useMemo(() => {
    const parking = parkingSpaceList.filter((item) => item.enabled && item.sector_id === sector?.id);
    const totalParking = parking.length;
    const unavailableParking = parking.filter((item) => item.status === 'unavailable').length;

    return (100 * unavailableParking) / totalParking;
  }, [parkingSpaceList, sector?.id]);

  React.useEffect(() => {
    const localStorageItem = localStorage.getItem(`KANBAN_SECTOR_V1_${user ? user.code3c_id : 'error'}`);
    if (localStorageItem !== null) {
      setSector(JSON.parse(localStorageItem));
    }
    if (localStorageItem === null && sectorList.length > 0) {
      setSector(sectorList.length > 0 ? sectorList[0] : undefined);
      localStorage.setItem(`KANBAN_SECTOR_V1_${user ? user.code3c_id : 'error'}`, JSON.stringify(sectorList[0]));
    }
  }, [sectorList, user]);

  React.useEffect(() => {
    if (showHideParking.visible) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideParking.visible]);

  return (
    <div className={s.container}>
      <PrimeTabMenu active={0} />
      <ManagerParking
        showHideManager={showHideParking}
        setShowHideManager={setShowHideParking}
        sectorList={sectorScrumList}
      />
      <div className={s.header}>
        <div className={s.left}>
          <div className={s.title}>Kanban em Uso: {isNaN(percentage) ? 0 : percentage.toFixed(0)}%</div>
          <div className={s.dropdown}>
            <BaseDropDown
              optionLabel='name'
              placeholder='Setor'
              options={sectorList}
              value={sector}
              onChange={(e) => {
                setSector(e.value);
                localStorage.setItem(`KANBAN_SECTOR_V1_${user ? user.code3c_id : 'error'}`, JSON.stringify(e.value));
              }}
            />
          </div>
        </div>
        <div className={s.right}>
          <div className={s.legend}>
            <div className={s.full} />
            <div className={s.text}>VAZIO</div>
          </div>
          <div className={s.legend}>
            <div className={s.empty} />
            <div className={s.text}>CHEIO</div>
          </div>
        </div>
      </div>
      <div className={s.content_factory}>
        {factoryList
          .filter((item) => item.sector_id === sector?.id)
          .filter((item) => item.enabled === true)
          .map((factory, index) => {
            return (
              <Factory
                key={index}
                factory={factory}
                setShowHideParking={setShowHideParking}
                sectorList={sectorScrumList}
              />
            );
          })}
      </div>
    </div>
  );
};

export default MonitorParkingSpace;
