import React from 'react';

import { InputText, InputTextProps } from 'primereact/inputtext';

import { catchErrors, catchInvalid, IValidationError } from 'functions/validations';

import s from './inputText.module.scss';

interface IInputText extends InputTextProps {
  placeholder: string;
  icon?: string;
  firstClick?: boolean;

  formErr?: IValidationError[];
  fieldCode?: string;
}

const BaseInputText: React.FC<IInputText> = ({
  placeholder,
  firstClick,
  formErr,
  fieldCode,
  icon,
  ...InputTextProps
}) => {
  const invalid = catchInvalid(formErr ?? [], fieldCode ?? '');

  return (
    <div className={s.container}>
      <span className='p-float-label p-input-icon-right'>
        <i className={firstClick ? (invalid ? 'pi pi-times' : 'pi pi-check') : icon} />
        <InputText
          id={placeholder}
          className={firstClick ? (invalid ? s.error : s.success) : s.default}
          {...InputTextProps}
        />
        <label htmlFor={placeholder}>{placeholder}</label>
      </span>
      {catchErrors(formErr ?? [], fieldCode ?? '')}
    </div>
  );
};

export default BaseInputText;
