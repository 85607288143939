import { AxiosError } from 'axios';
import { collections } from 'collections';

import { collection, doc, getDoc, getDocs, query, Timestamp, where, writeBatch } from 'firebase/firestore';

import { differenceInMilliseconds, format, startOfDay } from 'date-fns';

import { IMessage } from 'pages/machines/injector/details/components/changeStations.component';

import { IDayReadingCR } from 'types/OEE-dayReading';
import { IEquipmentsData } from 'types/OEE-equipment';
import { IReadingCR } from 'types/OEE-reading';

import { firebaseFirestore, getApi } from '../firebase/authentication';

export interface ErrorResponse {
  description: string;
  message: string;
}

export interface IReadingGeneric {
  equipment_id: string;
  event_number: string;
  startDate: number;
}

export async function getEquipments(code3c: string, type: string) {
  try {
    const colRef = query(
      collection(firebaseFirestore, collections.allEquipment),
      where('code3c_id', '==', code3c),
      where('typeOf', '==', type)
    );

    const docsSnap = await getDocs(colRef);

    // eslint-disable-next-line
    const list: any[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IEquipmentsData));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export const editEventGeneric = async (event: IReadingGeneric, token: string) => {
  try {
    const api = getApi(token);
    await api.post('/reading/generic', event);

    return { response: true, message: 'Evento Editado com Sucesso.' };
  } catch (err) {
    const error = err as AxiosError;

    if (error.response) {
      const errorResponse: ErrorResponse = error.response.data;

      return { response: false, message: errorResponse.description };
    }
    return { response: false, message: 'Não foi possível editar os dados.' };
  }
};

// eslint-disable-next-line
export const editEventInjector = async (event: IMessage, token: string, hostApi: string) => {
  console.log(event);

  try {
    const api = getApi(token, hostApi);
    await api.post('/reading/injector', event);

    return { response: true, message: 'Evento Editado com Sucesso.' };
  } catch (err) {
    const error = err as AxiosError;

    if (error.response) {
      const errorResponse: ErrorResponse = error.response.data;

      return { response: false, message: errorResponse.description };
    }
    return { response: false, message: 'Não foi possível editar os dados.' };
  }
};

export const calculeCarrossel = async (equipmentList: string[], startDate: Date, token: string) => {
  const api = getApi(token);

  const data = {
    equipment_ids: equipmentList,
    date: startDate.valueOf(),
  };

  await api
    .post('/equipment/recalculateDayCr', data)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });

  return false;
};

export function generatorDayReadingId(startDate: Timestamp, equipment_id: string, event_number = '0'): string {
  return `${format(startDate.toDate(), 'dd-MM-yyyy')}-${event_number}-${equipment_id}`;
}

export function generatorDayReadingIdStation(
  startDate: Timestamp,
  equipment_id: string,
  event_number = '0',
  stationNumber: string
): string {
  return `${format(startDate.toDate(), 'dd-MM-yyyy')}-${event_number}-${equipment_id}-${stationNumber}`;
}

export async function editEventCR(data: Partial<IReadingCR>, id: string) {
  const {
    equipment_id,
    startDate,
    endDate,
    code3c_id,
    equipment_name,
    event_name: event_nameNew,
    event_id: event_idNew,
    event_number: event_numberNew,
  } = data;

  if (
    !equipment_id ||
    !event_numberNew ||
    !startDate ||
    !endDate ||
    !code3c_id ||
    !event_nameNew ||
    !event_idNew ||
    !equipment_name
  ) {
    return false;
  }

  const docReadingOld = doc(firebaseFirestore, 'OEE-reading', id);

  try {
    const batch = writeBatch(firebaseFirestore);

    const readingOldResponse = await getDoc(docReadingOld);

    if (!readingOldResponse.exists()) {
      console.log('Erro', 'Evento não encontrado.');
      return false;
    }

    const { event_number: event_numberOld } = readingOldResponse.data() as IReadingCR;

    batch.update(docReadingOld, {
      event_number: event_numberNew,
      event_name: event_nameNew,
      event_id: event_idNew,
    } as Partial<IReadingCR>);

    const dayReadingOld = generatorDayReadingId(startDate, equipment_id, event_numberOld);
    const responseDayReadingOld = doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'OEE-dayReading', dayReadingOld);
    const getReadingOld = await getDoc(responseDayReadingOld);

    const dayReadingNew = generatorDayReadingId(startDate, equipment_id, event_numberNew);
    const responseDayReadingNew = doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'OEE-dayReading', dayReadingNew);
    const getDayReadingNew = await getDoc(responseDayReadingNew);

    const sum = differenceInMilliseconds(endDate.toDate(), startDate.toDate());

    if (getReadingOld.exists() && getDayReadingNew.exists()) {
      const { sumMillis: durationOld } = getReadingOld.data() as IDayReadingCR;
      const { sumMillis: durationNew } = getReadingOld.data() as IDayReadingCR;

      batch.update(doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'OEE-dayReading', dayReadingOld), {
        sumMillis: durationOld - sum,
      } as Partial<IDayReadingCR>);

      batch.update(doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'OEE-dayReading', dayReadingNew), {
        sumMillis: durationNew + sum,
      } as Partial<IDayReadingCR>);
    } else if (getReadingOld.exists() && !getDayReadingNew.exists()) {
      const { sumMillis: durationOld } = getReadingOld.data() as IDayReadingCR;

      batch.update(doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'OEE-dayReading', dayReadingOld), {
        sumMillis: durationOld - sum,
      } as Partial<IDayReadingCR>);

      const dayReadingNewAdd: Partial<IDayReadingCR> = {
        sumMillis: sum,
        equipment_id,
        equipment_name,
        event_id: event_idNew,
        event_name: event_nameNew,
        event_number: event_numberNew,
        date: Timestamp.fromDate(startOfDay(startDate.toDate())),
        updated_at: Timestamp.now(),
        created_at: Timestamp.now(),
      };

      batch.set(doc(firebaseFirestore, 'ALL-code3c', code3c_id, 'OEE-dayReading', dayReadingNew), dayReadingNewAdd);
    }

    await batch.commit();
    return true;
  } catch (err) {
    console.log('Erro', 'Não foi possível editar os dados.');
    return false;
  }
}
