import React, { useCallback, useMemo } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { IDayReadingDataCR } from 'types/OEE-dayReading';
import { IEquipmentCR } from 'types/OEE-equipment';

import { millisToTime } from 'functions/millisToTime';
import { formatGraphItem } from 'functions/reports';

import './machinesHistory.scss';

interface IProps {
  dayReadingList: IDayReadingDataCR[];
  equipments: IEquipmentCR[];
  defaultLoading: boolean;
}

interface IReadingGraph {
  [string: string]: IItem;
}

interface IItem {
  name: string;
  data: number[];
  color: string;
}

const MachineHistory: React.FC<IProps> = ({ dayReadingList, equipments, defaultLoading }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('CR');

  const optionsName = useMemo(() => {
    return equipments
      .map((item) => item.name)
      .sort((a, b) => Number(a.replace(/[^\d]+/g, '')) - Number(b.replace(/[^\d]+/g, '')));
  }, [equipments]);

  const series = useMemo(() => {
    const days = dayReadingList.reduce((previous, item) => {
      const token = optionsName.indexOf(item.equipment_name);

      if (token < 0) return previous;

      const color = colores.find((colores) => colores.number === item.event_number)?.color;

      previous[item.event_id] = previous[item.event_id] || {
        name: item.event_name,
        color: color ? color : '',
        data: optionsName.map(() => 0),
      };

      previous[item.event_id].data[token] += item.sumMillis;

      return previous;
    }, {} as IReadingGraph);

    return Object.keys(days).map((i) => days[i]);
  }, [dayReadingList, colores, optionsName]);

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        dataLabels: {
          position: 'center',
        },
      },
    },
    xaxis: {
      categories: optionsName,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return `${millisToTime(val)}`;
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: (val) => {
          if (val === 0) {
            return `${String(0)}%`;
          }
          return `${val}%`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 3,
      formatter: (val: number, e) => formatGraphItem(series, e),
    },
  } as ApexOptions;

  const graph = useCallback(() => {
    return (
      <Chart
        options={options}
        series={series}
        type='bar'
        height={300}
        width='100%'
      />
    );
  }, [series]);

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='28rem'
        />
      ) : (
        <div className='carrossel-reports-diary-history'>
          <div className='title'>Histórico por Carrossel Completo</div>
          <div className='graph'>{graph()}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MachineHistory;
