import { addDoc, collection, deleteDoc, doc, Timestamp, updateDoc } from 'firebase/firestore';

import { firebaseFirestore } from '../firebase/authentication';

// eslint-disable-next-line
export const addGeneric = async (data: any, collectionName: string) => {
  try {
    await addDoc(collection(firebaseFirestore, collectionName), {
      ...data,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

// eslint-disable-next-line
export const editGeneric = async (data: any, collectionName: string, id: string) => {
  try {
    await updateDoc(doc(firebaseFirestore, collectionName, id), {
      ...data,
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const editEnabledGeneric = async (enabled: boolean, id: string, collectionName: string) => {
  try {
    await updateDoc(doc(firebaseFirestore, collectionName, id), {
      enabled: enabled,
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const deleteGeneric = async (id: string, collectionName: string) => {
  try {
    await deleteDoc(doc(firebaseFirestore, collectionName, id));

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};
