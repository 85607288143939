import * as echarts from 'echarts/core';

import { EChartOption } from 'echarts';

type RenderItemParams = EChartOption.SeriesCustom.RenderItemParams;
type RenderItemApi = EChartOption.SeriesCustom.RenderItemApi;

export function timeLineRender(params: RenderItemParams, api: RenderItemApi) {
  if (!api.value || !api.coord || !api.size || !api.style || !params.coordSys) return;

  const categoryIndex = api.value(0);
  const start = api.coord([api.value(1), categoryIndex]);
  const end = api.coord([api.value(2), categoryIndex]);
  const height = api.size([0, 1])[1] * 0.6;
  const rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0],
      y: start[1] - height / 2,
      width: end[0] - start[0],
      height: height,
    },
    {
      x: params.coordSys.x ?? 0,
      y: params.coordSys.y ?? 0,
      width: params.coordSys.width ?? 0,
      height: params.coordSys.height ?? 0,
    }
  );

  return { type: 'rect', transition: ['shape'], shape: rectShape, style: api.style() };
}
