import React from 'react';

import { IOptionsEventList } from 'types_new/ALL-options';

import { ILastEventsStation } from 'types/OEE-equipment';

import s from './listOfHeads.module.scss';

interface IProps {
  lastEventsStation: ILastEventsStation | null;
  eventArray: IOptionsEventList[];
}

export const ListOfHeads: React.FC<IProps> = ({ lastEventsStation, eventArray }) => {
  if (lastEventsStation) {
    return (
      <div className={s.station_container}>
        {Object.keys(lastEventsStation).map((key, index: number) => {
          return (
            <div
              key={key}
              className={s.station_content}
            >
              <div className={s.station_title}>
                E {index + 1} <br />
              </div>
              <div className={s.compartment_container}>
                {lastEventsStation[Number(key)].map((data, index: number) => {
                  const color = { background: eventArray?.find((c) => c.number === data.event_number)?.color };

                  return (
                    <div
                      className={s.compartment_content}
                      style={color}
                      key={index}
                    >
                      <div className={s.compartment_text}>{index + 1}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};
