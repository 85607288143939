import { useContext, useEffect, useState } from 'react';

import { collectionGroup, onSnapshot, query, where } from 'firebase/firestore';

import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { Auth } from 'contexts/auth.context';

import { ISettings } from 'routes/listRoutes';

import { firebaseFirestore } from '../../firebase/authentication';

const extractNumber = (name: string) => Number(name.replace(/[^\d]+/g, ''));

export function useEquipments(configs: ISettings): {
  equipments: IEquipmentGNdata[];
  equipmentsId: string[];
  equipmentsLoading: boolean;
} {
  const { user } = useContext(Auth);

  const [equipments, setEquipments] = useState<IEquipmentGNdata[]>([]);
  const [equipmentsId, setEquipmentsId] = useState<string[]>([]);
  const [equipmentsLoading, setEquipmentLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !configs) return;

    setEquipmentLoading(true);

    const queryConfig = query(
      collectionGroup(firebaseFirestore, 'OEE-machine'),
      where('code3c_id', '==', configs.code3c_id),
      where('type', '==', configs.type),
      where('group', '==', configs.group),
      where('enabled', '==', true)
    );

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.docs) {
          const data: IEquipmentGNdata[] = [];

          snapshot.docs.forEach((item) => {
            data.push({ ...item.data(), id: item.id } as IEquipmentGNdata);
          });

          setEquipments(data.sort((a, b) => extractNumber(a.name) - extractNumber(b.name)));
          setEquipmentsId(data.map((i) => i.id));
        }

        setEquipmentLoading(false);
      },
      (error) => {
        console.error('Erro ao buscar dados de equipamentos:', error);

        setEquipmentLoading(false);
      }
    );

    return () => unsubscribe();
  }, [configs, user]);

  return { equipments, equipmentsId, equipmentsLoading };
}
