import React from 'react';

import Button from 'pages/components_prime/button';
import InputText from 'pages/components_prime/inputText';

import Container from './components/container';
import ManagerSequence from './components/managerSequence';

import { useSector } from './hooks/useSector';
import { useSequence } from './hooks/useSequence';

import { ISequenceData } from 'types/SCRUM-sequence';

import PrimeTabMenu from '../tabMenu';

import './sequence.scss';

export interface IShowHideSequence {
  type: string;
  visible: boolean;
  value?: ISequenceData;
}

const Sequence: React.FC = () => {
  const [sequence] = useSequence();
  const [sector] = useSector();

  const [search, setSearch] = React.useState('');

  const [showHiderSequence, setShowHideSequence] = React.useState<IShowHideSequence>({ type: '', visible: false });

  return (
    <div className='default-class-scrum-model'>
      <PrimeTabMenu active={2} />
      <ManagerSequence
        showHideManager={showHiderSequence}
        setShowHideManager={setShowHideSequence}
      />
      <div className='container-header'>
        <div className='container-input'>
          <InputText
            placeholder='Buscar por Modelos'
            id='sequence'
            fieldCode='sequence'
            formErr={[]}
            firstClick={false}
            icon='pi pi-search'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Button
          moduleID='scrum-online'
          permissionID='btn-add-sequence'
          pendingProps={false}
          label='NOVA SEQUENCIA'
          onClick={() => setShowHideSequence({ type: 'add', visible: true })}
        />
      </div>
      {sequence
        .filter((item) => item.id.toUpperCase().indexOf(search.toUpperCase()) !== -1)
        .map((item, index) => {
          return (
            <Container
              key={index}
              sequenceProp={item}
              sector={sector}
              setShowHideSequence={setShowHideSequence}
            />
          );
        })}
    </div>
  );
};

export default Sequence;
