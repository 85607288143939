import * as React from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Loading from 'componentes_share_admin/loading/loading.component';

import { searchHexaUser } from 'controllers/user';

import { IUserData } from 'types/ALL-user';

import { firebaseConnect } from '../firebase/authentication';

interface IContext {
  user: IUserData | null;
  accessToken: string;
}

interface IProps {
  children: React.ReactNode;
}

export const Auth = React.createContext<IContext>({} as IContext);

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [pending, setPending] = React.useState<boolean>(true);

  const [user, setUser] = React.useState<IUserData | null>(null);
  const [accessToken, setAccessToken] = React.useState<string>('');

  const auth = getAuth(firebaseConnect);

  React.useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userToken = await user.getIdTokenResult(true);
        setAccessToken(userToken.token);

        const uid = user.uid;
        if (uid) {
          const newUser = await searchHexaUser(uid);
          setUser(newUser);
        }
      }
      setPending(false);
    });
  }, [auth]);

  const fetchUserToken = React.useCallback(async () => {
    onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const userToken = await user.getIdTokenResult(true);
          setAccessToken(userToken.token);
          console.log('Token Renovado com Sucesso!');
        }
      } catch (error) {
        console.log('Erro ao Renovar Token, tentando novamente...!');
        setTimeout(fetchUserToken, 5000);
      }
    });
  }, [auth]);

  React.useEffect(() => {
    const interval = setInterval(fetchUserToken, 3000000);

    return () => clearInterval(interval);
  }, [fetchUserToken]);

  if (pending) {
    return <Loading height='100vh' />;
  }

  return <Auth.Provider value={{ user, accessToken }}>{children}</Auth.Provider>;
};
