import React from 'react';

import { useParams } from 'react-router-dom';

import { Data } from 'contexts/data.context';

import { ICardData } from 'types/SCRUM-card';
import { IFrameData } from 'types/SCRUM-frame';

import { IShowHideCard } from '../frame.page';
import { IProductionScheduling } from '../hooks/useProductionScheduling';

import Card from './card';

import './lines.scss';

interface ILinesProps {
  frame: IFrameData | null;
  setShowHideCard: React.Dispatch<React.SetStateAction<IShowHideCard>>;
  listOfCards: ICardData[];
  lines: string[];
  productionScheduling: IProductionScheduling[] | null;
}

const Lines: React.FC<ILinesProps> = ({ lines, listOfCards, frame, setShowHideCard, productionScheduling }) => {
  const { FrameID } = useParams();

  const { factoryList, groupList } = React.useContext(Data);

  const getGroupAndFactory = (group: string) => {
    const findGroup = groupList.find((g) => g.id === group);
    const findFactory = factoryList.find((f) => f.id === findGroup?.factory_id);
    return { findGroup, findFactory };
  };

  const filterAndSortCards = (indexProp: number, group: string) => {
    const newList = listOfCards
      .filter((c) => c.sequence.some((s) => s.status !== 'concluded' && s.frame_id === FrameID && c.group_id === group))
      .sort((a, b) => Number(b.created_at) - Number(a.created_at))
      .filter((_, index) => index === indexProp);

    return newList;
  };

  return (
    <div className='line-container'>
      {lines.map((group, index) => {
        const { findGroup, findFactory } = getGroupAndFactory(group);
        const groupStyle = index % 4 < 2 ? { background: 'white' } : { background: '#F5F5F5' };

        return (
          <div
            key={index}
            className='group-container'
            style={groupStyle}
          >
            <div className='factory-group-info'>
              <div>{findFactory?.name}</div>
              <div>{findGroup?.name}</div>
            </div>
            {frame?.columns?.map((column, columnIndex) => (
              <div
                key={columnIndex}
                className='column-container'
              >
                {filterAndSortCards(columnIndex, group)?.map((item, cardIndex) => (
                  <div
                    key={cardIndex}
                    className='card-container'
                  >
                    <Card
                      index={columnIndex}
                      card={item}
                      setShowHideCard={setShowHideCard}
                      color={column.color}
                      cardsLength={filterAndSortCards(columnIndex + 1, group).length}
                      productionScheduling={
                        productionScheduling?.find((i) => String(i.ordem) === String(item.order)) ?? null
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Lines;
