import React, { useContext, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Loading from 'componentes_share_admin/loading/loading.component';
import Navbar from 'componentes_share_admin/machines/details/navbar/navbar.component';
import Filter, { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import Legends from 'componentes_share_admin/machines/global/legends/legends.component';
import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

import { IDayReadingIjData } from 'types_new/ALL-dayReading';
import { IOptionsEventList } from 'types_new/ALL-options';

import { Auth } from 'contexts/auth.context';

import ChangeStations from './components/changeStations.component';
import GraphDonut from './components/graphDonut.component';
import PeerEstimation from './components/peerEstimation.component';
import CardStations from './components/stations.component';
import TableList from './components/tableList.component';
import Timeline from './components/timeline.component';

import { useEquipments } from 'hooks/machines/useEquipments.hook';
import { useTempletes } from 'hooks/machines/useTemplete.hook';

import { useDayReading } from './hooks/useDayReading.hook';
import { IReading, useReading } from './hooks/useReading.hook';

import { ISettings } from 'routes/listRoutes';

import { navigation } from '../global';

import { generateShiftReadingsOverview, IEditEvent, initialEditEvent } from './details.functions';

import s from './details.module.scss';

interface IDetailsGenericController {
  settings: ISettings;
}

const DetailsGenericController: React.FC<IDetailsGenericController> = ({ settings }) => {
  const { ID } = useParams();

  const history = useNavigate();

  const { user } = useContext(Auth);

  const configs = useMemo(() => {
    return { ...settings, code3c_id: user?.code3c_id };
  }, [settings, user?.code3c_id]);

  const [date, setDate] = useState(new Date());

  const { eventsMap, eventsList, eventsLoading } = useTempletes(configs);
  const { dayReadingList, dayReadingLoading } = useDayReading(ID, date);
  const { readingList, readingLoading } = useReading(ID, date);
  const { equipments, equipmentsLoading } = useEquipments(configs);

  if (readingLoading || equipmentsLoading || eventsLoading || dayReadingLoading) {
    return (
      <BaseTabMenu
        index={1}
        menuItems={navigation(configs.title, history)}
      >
        <Loading height='calc(100vh - 8rem)' />
      </BaseTabMenu>
    );
  }

  return (
    <DetailsGenericComponent
      date={date}
      setDate={setDate}
      eventsMap={eventsMap}
      eventsList={eventsList}
      equipments={equipments}
      dayReading={dayReadingList}
      readingList={readingList}
      configs={configs}
    />
  );
};

interface IDetailsGenericComponent {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  eventsMap: { [key: string]: IOptionsEventList };
  eventsList: IOptionsEventList[];
  equipments: any[];
  dayReading: IDayReadingIjData[];
  readingList: IReading[];
  configs: ISettings;
}

const DetailsGenericComponent: React.FC<IDetailsGenericComponent> = ({
  date,
  setDate,
  eventsMap,
  eventsList,
  equipments,
  dayReading,
  readingList,
  configs,
}) => {
  const { ID } = useParams();

  const history = useNavigate();

  const { user } = useContext(Auth);

  const [showHideEditEvent, setShowHideEditEvent] = useState<IEditEvent>(initialEditEvent);
  const [filters, setFilters] = React.useState<IFilters>({ equipments: [], events: [], shifts: [] });

  const categories = React.useMemo(() => {
    const number = equipments.find((i) => i.id === ID)?.lastEventsStation || {};
    const count = Object.keys(number).length;

    const categories = [];

    for (let i = 1; i <= count; i++) {
      categories.push(`Estação ${i}`);
    }

    return { categories, count };
  }, [ID, equipments]);

  const memoizedEquipmentReadings = useMemo(() => {
    return generateShiftReadingsOverview(categories, dayReading, eventsMap);
  }, [categories, dayReading, eventsMap]);

  return (
    <BaseTabMenu
      index={1}
      menuItems={navigation(configs.title, history)}
    >
      <div className={s.container}>
        <Navbar
          id={ID}
          equipments={equipments}
          date={date}
          setDate={setDate}
          title={configs.title}
        >
          <Filter
            eventsList={eventsList.map((i) => ({ id: i.id, name: i.name }))}
            filters={filters}
            setFilters={setFilters}
            filterKey={`${user?.code3c_id}_details_${configs.group}`}
          />
        </Navbar>
        <ChangeStations
          setShowHideEditEvent={setShowHideEditEvent}
          showHideEditEvent={showHideEditEvent}
          eventsList={eventsList}
        />
        <CardStations
          date={date}
          equipment={equipments.find((i) => i.id === ID)}
          setShowHideEditEvent={setShowHideEditEvent}
          eventsMap={eventsMap}
        />
        <Timeline
          timelineList={readingList}
          eventMap={eventsMap}
          count={categories.count}
        />
        <PeerEstimation timelineList={readingList} />
        <div className={s.row_graph}>
          <div className={s.graph}>
            <GraphDonut
              title='Status Geral'
              enhancedReadings={memoizedEquipmentReadings.aggregatedEvents}
            />
          </div>
          {categories.categories.map((category, index) => (
            <div
              key={index}
              className={s.graph}
            >
              <GraphDonut
                title={category}
                enhancedReadings={memoizedEquipmentReadings.separatedReadings[category]}
              />
            </div>
          ))}
        </div>
        <TableList
          readings={readingList}
          eventMap={eventsMap}
        />
        <Legends eventArray={eventsList.filter((i) => !filters.events.includes(i.id))} />
      </div>
    </BaseTabMenu>
  );
};

export default DetailsGenericController;
