import * as React from 'react';

import { Toaster } from './toast.context';

interface IContext {
  isOnline: boolean;
}

interface IProps {
  children: React.ReactNode;
}

export const Connection = React.createContext<IContext>({} as IContext);

export const ConnectionProvider: React.FC<IProps> = ({ children }) => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  const { toastCustom, toastClean } = React.useContext(Toaster);

  React.useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);

      if (!navigator.onLine) {
        toastCustom({
          detail: 'Você está offline',
          severity: 'error',
          summary: 'Erro de Conexão',
          closable: false,
          sticky: true,
          icon: 'pi pi-wifi',
        });
      } else {
        toastClean();

        setTimeout(() => {
          toastCustom({
            detail: 'Sua conexão esta ativa novamente!',
            severity: 'success',
            summary: 'Conexão Reestabelecida',
            closable: true,
            life: 3000,
            icon: 'pi pi-wifi',
          });
        }, 1000);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [toastClean, toastCustom]);

  return <Connection.Provider value={{ isOnline }}>{children}</Connection.Provider>;
};
