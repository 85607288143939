import { useCallback, useContext, useEffect, useState } from 'react';

import { doc, onSnapshot } from 'firebase/firestore';

import { IAllWorkShiftData, Shift } from 'types-node-react/react/type';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../../firebase/authentication';

export interface IShiftDisplayList {
  id: string;
  name: string;
}

export function useWorkShift(
  code3cId: string | undefined,
  workShiftId: string | undefined
): {
  workShift: IAllWorkShiftData | null;
  shiftDisplayList: IShiftDisplayList[];
  loadingWorkShift: boolean;
} {
  const { user } = useContext(Auth);

  const [workShift, setWorkShift] = useState<IAllWorkShiftData | null>(null);
  const [shiftDisplayList, setShiftDisplayList] = useState<IShiftDisplayList[]>([]);
  const [loadingWorkShift, setLoadingWorkShift] = useState<boolean>(true);

  const formatShiftList = useCallback((shifts: Shift[]) => {
    return shifts
      .reduce((acc: IShiftDisplayList[], item) => {
        if (!acc.some(({ id }) => id === item.name)) {
          acc.push({ id: item.name, name: item.name !== 'OFF' ? `Turno ${item.name}` : 'Sem Turno' });
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  useEffect(() => {
    if (!user || !code3cId || !workShiftId) {
      setLoadingWorkShift(false);
      return;
    }

    const queryConfig = doc(firebaseFirestore, 'ALL-code3c', code3cId, 'ALL-workShift', workShiftId);

    const unsubscribe = onSnapshot(
      queryConfig,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data() as IAllWorkShiftData;

          setWorkShift({ ...data, id: snapshot.id });
          setShiftDisplayList(formatShiftList(data.shifts));
        }

        setLoadingWorkShift(false);
      },
      (error) => {
        console.error('Erro ao buscar dados do turno de trabalho:', error);

        setLoadingWorkShift(false);
      }
    );

    return unsubscribe;
  }, [code3cId, workShiftId, user, formatShiftList]);

  return { workShift, shiftDisplayList, loadingWorkShift };
}
