import React from 'react';

import FrameLayout from 'componentes_share_admin/layout/frame/frame.component';

import ListOfCards from './components/card/listOfCards.component';
import ManagerCard from './components/card/managerCard.component';
import ListOfFrames from './components/frame/listOfFrames.component';
import ManagerFrame from './components/frame/managerFrame';

import { ICardData } from 'types/SCRUM-card';
import { IFrameData } from 'types/SCRUM-frame';

import PrimeTabMenu from '../tabMenu';

import s from './distribution.module.scss';

export interface IShowHideFrame {
  type: string;
  visible: boolean;
  value?: IFrameData;
}

export interface IShowHideCard {
  type: string;
  visible: boolean;
  value?: ICardData;
  status?: string;
}

const Distribution: React.FC = () => {
  const [showHideFrame, setShowHideFrame] = React.useState<IShowHideFrame>({ type: '', visible: false });
  const [showHiderCard, setShowHideCard] = React.useState<IShowHideCard>({ type: '', visible: false });

  return (
    <div className={s.container}>
      <PrimeTabMenu active={1} />
      <FrameLayout>
        <div className={s.distribution}>
          <ListOfCards setShowHideCard={setShowHideCard} />
          <ListOfFrames setShowHideFrame={setShowHideFrame} />
        </div>
      </FrameLayout>
      <ManagerFrame
        showHideManager={showHideFrame}
        setShowHideManager={setShowHideFrame}
      />
      <ManagerCard
        showHideManager={showHiderCard}
        setShowHideManager={setShowHideCard}
        enableQuest={true}
      />
    </div>
  );
};

export default Distribution;
