import React from 'react';

import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';

import { Data } from 'contexts/data.context';

import { IFactoryData } from 'types/ALL-factory';
import { IMonthlyOperationSummaryData } from 'types/KC-monthlyOperationSummary';

import s from './table.module.scss';

interface ITableProps {
  formatReading: IMonthlyOperationSummaryData[];
}

const Table: React.FC<ITableProps> = ({ formatReading }) => {
  const { factoryList, groupList } = React.useContext(Data);

  const [expandedRows, setExpandedRows] = React.useState<DataTableExpandedRows | DataTableValueArray>();

  function filterReading(readings: IMonthlyOperationSummaryData[]) {
    const initialState = { available: 0, unavailable: 0 };

    const result = readings.reduce((acc, equipment) => {
      equipment.data.forEach((day) => {
        day.events.forEach((item) => {
          if (item.status === 'available') {
            acc.available += item.sumSeconds;
          } else if (item.status === 'unavailable') {
            acc.unavailable += item.sumSeconds;
          }
        });
      });
      return acc;
    }, initialState);

    return result;
  }

  const statusValue = (id: string, filterKey: 'factory_id' | 'group_id') => {
    const reading = formatReading.filter((item) => item[filterKey] === id);

    const available = filterReading(reading).available;
    const unavailable = filterReading(reading).unavailable;

    const sum = available + unavailable;

    const value = (100 * unavailable) / sum;

    return <ProgressBar value={isNaN(value) ? 0 : value.toFixed(0)} />;
  };

  const rowExpansionTemplate = (data: IFactoryData) => {
    const filterGroups = groupList.filter((i) => i.factory_id === data.id && i.enabled === true);

    return (
      <div className={s.rowExpansion}>
        <DataTable
          value={filterGroups}
          showHeaders={false}
        >
          <Column
            field='name'
            className={s.name}
          />
          <Column body={(data) => statusValue(data.id, 'group_id')} />
        </DataTable>
      </div>
    );
  };

  return (
    <div className={s.container}>
      <div className={s.title}>Histórico por Fábrica</div>
      <div className={s.table}>
        <DataTable
          scrollable
          size='small'
          showHeaders={false}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          value={factoryList.filter((i) => i.enabled === true)}
        >
          <Column
            expander={true}
            className={s.expander}
          />
          <Column
            field='name'
            header='Nome'
            className={s.name}
          />
          <Column
            header='Status'
            className={s.status}
            body={(data) => statusValue(data.id, 'factory_id')}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Table;
