import React from 'react';

import { Dropdown, DropdownProps } from 'primereact/dropdown';

import s from './dropdown.module.scss';

interface IInputTextProps extends DropdownProps {
  placeholder: string;
}

const BaseDropDown: React.FC<IInputTextProps> = ({ placeholder, ...DropdownProps }) => {
  return (
    <div className={s.container}>
      <span className='p-float-label p-input-icon-right'>
        <Dropdown
          className={s.default}
          {...DropdownProps}
        />
        <label htmlFor={placeholder}>{placeholder}</label>
      </span>
    </div>
  );
};

export default BaseDropDown;
