import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import DropdownPrime from 'components/primereact/dropdown/dropdown';
import InputTextPrime from 'components/primereact/inputText/inputText';

import './administration.scss';

export const ModuleDropdown = (props: { moduleName: string; moduleKey: string; userType: string | undefined }) => {
  const history = useNavigate();

  const module = { key: props.moduleKey, name: props.moduleName };

  const routes = [
    { key: 'fabricas', name: 'Fábricas' },
    { key: 'grupos', name: 'Grupos' },
    { key: 'setores', name: 'Setores' },
    { key: 'vagas', name: 'Vagas' },
  ];

  return (
    <div className='default-class-utils-admin'>
      <DropdownPrime
        placeholder='Módulo'
        fieldCode=''
        formErr={[]}
        value={module}
        onChange={(e) => history(`/admin/${e.value.key}`)}
        optionLabel='name'
        options={routes}
      />
    </div>
  );
};

export const InputSearch = (props: {
  placeholder: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <InputTextPrime
      placeholder={props.placeholder}
      icon='pi pi-search'
      fieldCode=''
      formErr={[]}
      value={props.search}
      onChange={(e) => props.setSearch(e.target.value)}
    />
  );
};
