import React from 'react';

import { Checkbox, CheckboxProps } from 'primereact/checkbox';

import s from './checkbox.module.scss';

interface IBaseCheckboxProps extends CheckboxProps {
  placeholder?: string;
}

const BaseCheckbox: React.FC<IBaseCheckboxProps> = ({ placeholder, ...CheckboxProps }) => {
  return (
    <div className={s.container}>
      <Checkbox {...CheckboxProps} />
      <label htmlFor={placeholder}>{placeholder}</label>
    </div>
  );
};

export default BaseCheckbox;
