import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { BsFillShiftFill } from 'react-icons/bs';
import { FaPlay, FaStop } from 'react-icons/fa';

import { millisToTime } from 'functions/millisToTime';

import './header.scss';

interface IHeaderProps {
  uptime: number;
  availableTime: number;
  defaultLoading: boolean;
}

const Header: React.FC<IHeaderProps> = ({ uptime, availableTime, defaultLoading }) => {
  return (
    <div className='reports-header-new'>
      <div className='p-grid flex'>
        <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
          {defaultLoading ? (
            <Skeleton className='skeleton' />
          ) : (
            <div className='container'>
              <div className='flex'>
                <div className='icon-bg flex-center-x'>
                  <BsFillShiftFill className='icon flex-center-x-y' />
                </div>
                <div className='title flex-center-y'>Turno Atual</div>
              </div>
              <div className='flex-center-x-y sub-title'>
                <div className='flex-center-x-y'>Desconhecido</div>
              </div>
            </div>
          )}
        </div>
        <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
          {defaultLoading ? (
            <Skeleton className='skeleton' />
          ) : (
            <div className='container green'>
              <div className='flex'>
                <div className='icon-bg green flex-center-x'>
                  <FaPlay className='icon flex-center-x-y' />
                </div>
                <div className='title flex-center-y green'>Tempo Funcionando</div>
              </div>
              <div className='flex-center-x-y sub-title green'>
                <div className='flex-center-x-y'>{millisToTime(uptime)}</div>
              </div>
            </div>
          )}
        </div>
        <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
          {defaultLoading ? (
            <Skeleton className='skeleton' />
          ) : (
            <div className='container red'>
              <div className='flex'>
                <div className='icon-bg red flex-center-x'>
                  <FaStop className='icon flex-center-x-y' />
                </div>
                <div className='title flex-center-y red'>Tempo Parado</div>
              </div>
              <div className='flex-center-x-y sub-title red'>
                <div className='flex-center-x-y'>{millisToTime(availableTime)}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
