import React from 'react';

import { Dialog } from 'primereact/dialog';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseDropDown from 'componentes_share_admin/primereact/dropdown/dropdown.component';

import { IOptionsEventList } from 'types_new/ALL-options';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { editEventInjector } from 'controllers/equipments';

import { useSearchCode3c } from 'hooks/useSearchCode3c';

import { IEditEvent } from '../details.functions';

import s from './changeStations.module.scss';

interface IProps {
  showHideEditEvent: IEditEvent;
  setShowHideEditEvent: React.Dispatch<React.SetStateAction<IEditEvent>>;
  eventsList: IOptionsEventList[];
}

export interface IDataEvent {
  compartment: number;
  station: string | number;
  event_number?: string;
  locked?: boolean;
}

export interface IMessage {
  equipment_id: string;
  data: IDataEvent[];
}

interface IInputEvent {
  name: string;
  event_number: string;
}

const initialInputs = [
  {
    name: '',
    event_number: '',
  },
  {
    name: '',
    event_number: '',
  },
];

const ChangeStations: React.FC<IProps> = ({ showHideEditEvent, setShowHideEditEvent, eventsList }) => {
  const { toastError, toastSuccess } = React.useContext(Toaster);
  const { accessToken } = React.useContext(Auth);

  const [code3c] = useSearchCode3c();

  const [changeEvent, setChangeEvent] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState<boolean>(false);
  const [inputEvents, setInputEvents] = React.useState<IInputEvent[]>(initialInputs);

  const initializeFields = React.useCallback(() => {
    setShowHideEditEvent((state) => ({ ...state, visible: false }));
    setChangeEvent(false);
    setInputEvents(initialInputs);
    setPending(false);
    setChangeEvent(false);
  }, [setShowHideEditEvent]);

  React.useEffect(() => {
    initializeFields();
  }, [initializeFields]);

  async function handleEditCompartment() {
    setPending(true);

    const data: IDataEvent[] = inputEvents.map((item, compartment) => ({
      compartment,
      station: showHideEditEvent.key,
      event_number: item.event_number,
    }));

    const message = {
      equipment_id: showHideEditEvent.equipment_id,
      data: data.filter((item) => item.event_number !== ''),
    } as IMessage;

    const hostApi = code3c ? code3c?.hostApi : '';
    const response = await editEventInjector(message, accessToken, hostApi);

    if (response.response) {
      initializeFields();
      toastSuccess(response.message);
    } else {
      setPending(false);
      toastError(response.message);
    }
  }

  return (
    <Dialog
      className={s.dialog}
      draggable={false}
      visible={showHideEditEvent.visible}
      onHide={() => initializeFields()}
      header='Editar Compartimento'
      footer={
        <BaseButton
          size='small'
          severity='success'
          label='Salvar'
          icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
          onClick={() => handleEditCompartment()}
          disabled={pending || !changeEvent}
        />
      }
    >
      <div className={s.container}>
        {showHideEditEvent.item.map((_item, index: number) => (
          <div key={index}>
            <BaseDropDown
              placeholder={`Compartimento ${index + 1}`}
              optionLabel='name'
              value={inputEvents[index]}
              options={eventsList
                .filter((i) => !i.isMachine)
                .map((events) => {
                  return {
                    name: events.name,
                    event_number: events.number,
                  };
                })}
              onChange={(e) => {
                setInputEvents(
                  inputEvents.map((data, index2) => {
                    if (index === index2) {
                      return e.value;
                    }
                    return data;
                  })
                );
                setChangeEvent(true);
              }}
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default ChangeStations;
