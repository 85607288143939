export function formatTime(seconds: number) {
  if (seconds <= 0) {
    return '-';
  }

  if (seconds <= 60) {
    return `${seconds} segundos`;
  }

  if (seconds <= 3600) {
    const minutes = Math.floor((seconds % 3600) / 60);

    const formatHours = minutes === 0 ? '' : minutes === 1 ? `${minutes} minuto` : `${minutes} minutos`;

    return `${formatHours}`;
  }

  if (seconds <= 86400) {
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);

    const minutes = Math.floor((seconds % 3600) / 60);

    const formatHours = hours === 0 ? '' : hours === 1 ? `${hours} hora` : `${hours} horas`;
    const formatMinutes = minutes === 0 ? '' : minutes === 1 ? `e ${minutes} minuto` : `e ${minutes} Minutos`;

    return `${formatHours} ${formatMinutes}`;
  }

  if (seconds >= 86400) {
    const days = Math.floor(seconds / (3600 * 24));

    const hours = Math.floor((seconds % (3600 * 24)) / 3600);

    const formatDays = days === 0 ? '' : days === 1 ? `${days} dia` : `${days} dias`;
    const formatHoras = hours === 0 ? '' : hours === 1 ? `e ${hours} hora` : `e ${hours} horas`;

    return `${formatDays} ${formatHoras}`;
  }

  return String(seconds);
}
