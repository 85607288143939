import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { differenceInMilliseconds, startOfDay, subDays } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IReadingData } from 'types/KC-readingMV';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useReadingTimeline(equipment_id: string, date: Date): [IReadingData[], boolean] {
  const { user } = React.useContext(Auth);

  const [readingList, setReadingList] = React.useState<IReadingData[]>([]);
  const [pendingReadingList, setPendingReadingList] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    if (equipment_id !== '') {
      setPendingReadingList(true);

      const dayNext = subDays(date, -1);

      const unsubscribe = onSnapshot(
        query(
          collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'KC-readingMv'),
          where('parkingSpace_id', '==', equipment_id),
          where('startDate', '>=', subDays(date, 7)),
          where('startDate', '<=', startOfDay(dayNext)),
          orderBy('startDate', 'asc')
        ),
        (snapshot) => {
          if (snapshot.empty) {
            setReadingList([]);
            setPendingReadingList(false);
            return;
          }

          const list: IReadingData[] = snapshot.docs.map((item, index) => {
            const currentValue = item.data() as IReadingData;
            if (snapshot.docs[index + 1]) {
              const nextValue = snapshot.docs[index + 1].data() as IReadingData;

              const difference = differenceInMilliseconds(
                nextValue.startDate.toDate(),
                currentValue.startDate.toDate()
              );

              return {
                ...currentValue,
                id: item.id,
                endDate: nextValue.startDate,
                differenceInMilliseconds: difference,
                index,
              } as IReadingData;
            }

            return {
              ...currentValue,
              id: item.id,
              endDate: currentValue.startDate,
              differenceInMilliseconds: 0,
              index,
            } as IReadingData;
          });

          setReadingList(
            list.map((item) => {
              return { ...item, parkingSpace_name: 'Vaga' };
            })
          );
          setPendingReadingList(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [date, equipment_id, user]);

  return [readingList, pendingReadingList];
}
