import React from 'react';

import { useParams } from 'react-router-dom';

import FrameLayout from 'componentes_share_admin/layout/frame/frame.component';

import { Auth } from 'contexts/auth.context';

import Columns from './components/columns';
import Lines from './components/lines';

import { useCard } from './hooks/useCard';
import { useProductionScheduling } from './hooks/useProductionScheduling';
import { useSearchFrame } from './hooks/useSearchFrame';

import { ICardData } from 'types/SCRUM-card';

import ManagerCard from '../distribution/components/card/managerCard.component';
import PrimeTabMenu from '../tabMenu';

import s from './frame.module.scss';

export interface IShowHideCard {
  type: string;
  visible: boolean;
  value?: ICardData;
}

const Frame: React.FC = () => {
  const { FrameID } = useParams();

  const { user } = React.useContext(Auth);

  const [card] = useCard(FrameID);
  const [frame] = useSearchFrame(FrameID);
  const { productionScheduling } = useProductionScheduling(card);

  const [showHiderCard, setShowHideCard] = React.useState<IShowHideCard>({ type: '', visible: false });
  const [filterFactory, setFilterFactory] = React.useState(() => {
    const localData = localStorage.getItem(`KANBAN_FACTORY_FILTER_${user && user.code3c_id}`);
    return localData ? JSON.parse(localData) : [];
  });

  const listOfLines = React.useMemo(() => {
    const newCards = card
      .filter((i) => !filterFactory?.includes(i.factory_id))
      .filter((l) => l.sequence.some((s) => s.status !== 'concluded' && s.frame_id === FrameID));
    const uniqueIdsSet = new Set(newCards.map((item) => item.group_id as string));

    return Array.from(uniqueIdsSet);
  }, [FrameID, card, filterFactory]);

  React.useEffect(() => {
    localStorage.setItem(`KANBAN_FACTORY_FILTER_${user && user.code3c_id}`, JSON.stringify(filterFactory));
  }, [filterFactory, user]);

  React.useEffect(() => {
    if (showHiderCard.visible) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHiderCard.visible]);

  return (
    <div className={s.container}>
      <PrimeTabMenu active={1} />
      <FrameLayout
        filterFactory={filterFactory}
        setFilterFactory={setFilterFactory}
      >
        <div className={s.columns}>
          <Columns frame={frame} />
          <Columns frame={frame} />
        </div>
        <Lines
          setShowHideCard={setShowHideCard}
          frame={frame}
          listOfCards={card}
          lines={listOfLines}
          productionScheduling={productionScheduling}
        />
      </FrameLayout>
      <ManagerCard
        showHideManager={showHiderCard}
        setShowHideManager={setShowHideCard}
        enableQuest={false}
      />
    </div>
  );
};

export default Frame;
