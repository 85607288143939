import React, { useMemo } from 'react';

import EChartBar, { IChartBar } from 'componentes_share_admin/charts/bar/bar.component';
import LayoutGraph from 'componentes_share_admin/layout/graph/graph.component';

interface IProps {
  eventEnhanced: IChartBar[];
  dateAxisLabels: string[];
}

const GraphBar: React.FC<IProps> = ({ eventEnhanced, dateAxisLabels }) => {
  const series = useMemo(() => {
    if (!eventEnhanced || eventEnhanced.length === 0) return [];

    const col: number[] = [];

    eventEnhanced.forEach(({ data }) => data.forEach((value, index) => (col[index] = (col[index] || 0) + value)));

    const data = eventEnhanced.map(({ name, color, data }) => ({
      name,
      color,
      data: data.map((value, columnIndex) => (col[columnIndex] ? ((100 * value) / col[columnIndex]).toFixed(2) : 0)),
    }));

    return data;
  }, [eventEnhanced]);

  return (
    <LayoutGraph
      title='Visão Geral dos Eventos por Dia'
      tooltip='Este gráfico exibe a frequência diária de eventos, facilitando a identificação de dias com maior ou menor atividade. É útil para análises temporais e planejamento estratégico.'
      height='30rem'
    >
      <EChartBar
        series={series as IChartBar[]}
        dateAxisLabels={dateAxisLabels}
        height='26rem'
      />
    </LayoutGraph>
  );
};

export default GraphBar;
