import * as React from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

import Error404 from 'pages/default/error404/error404';

import { Auth } from 'contexts/auth.context';
import { Routes } from 'contexts/routes.context';
import { SideMenu } from 'contexts/sideMenu.context';

import s from './private.module.scss';

interface PrivateRoute {
  element: JSX.Element;
  title: string;

  path?: string;
  style?: string;
  type?: string;
}

export const PrivateRoutes: React.FC<PrivateRoute> = ({ path, element, style, title, type }) => {
  const navigate = useNavigate();

  const { user } = React.useContext(Auth);
  const { setTitlePath } = React.useContext(SideMenu);
  const { routes } = React.useContext(Routes);

  React.useEffect(() => {
    if (setTitlePath) {
      setTitlePath(title);
    }
  }, [setTitlePath, title]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (path) {
      const route = routes.find((item) => window.location.href.includes(item.path));

      if (user && route === undefined && type !== 'electron') {
        navigate('/sobre');
      }
    }
  }, [navigate, path, routes, user, type]);

  return user ? (
    <div className={s.container}>
      {['master', 'senior'].includes(user.userAccessLevel) ||
      (user.userAccessLevel === 'junior' &&
        !['Administração', 'Eventos', 'Fábricas', 'Grupos', 'Setores', 'Vagas'].includes(title)) ? (
        <div className={style ? s.bg_white : s.bg_none}>{element}</div>
      ) : (
        <Error404 />
      )}
    </div>
  ) : (
    <Navigate to={'/'} />
  );
};
