import * as React from 'react';
import * as Sentry from '@sentry/react';

import ReactDOM from 'react-dom/client';

import reportWebVitals from './services/reportWebVitals';

import RoutesMain from './routes/mainRoutes';

import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './index.scss';
import './primereact.scss';
import './styles/administration.scss';
import './styles/fonts.scss';
import './styles/lastSevenDays.scss';
import './styles/monitor.scss';
import './styles/tableEvents.scss';
import './styles/timeline.scss';
import './styles/treeMap.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://b40bd3e4623e4a6faaa0fded9214fa83@o999233.ingest.sentry.io/6187797',

    tracesSampleRate: 1.0,

    tracePropagationTargets: ['localhost', 'app.intuitiva.io'],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

root.render(
  <React.StrictMode>
    <RoutesMain />
  </React.StrictMode>
);

reportWebVitals();
