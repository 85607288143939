import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  factory_id: yup.string().label('Fábrica').required(),
  name: yup.string().label('Nome').required(),
  number: yup.string().label('Número').required(),
});
