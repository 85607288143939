import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { BsFillShieldFill } from 'react-icons/bs';
import { FaPlay, FaStop } from 'react-icons/fa';

import { Events } from 'contexts/events.context';

import { IEquipmentBD, IEquipmentCR, IEquipmentCT, IEquipmentIJ } from 'types/OEE-equipment';
import { IEquipmentDataGn } from 'types/OEE-equipmentGn';

import { millisToTime, secondsToTime } from 'functions/millisToTime';

import './header.scss';

interface IHeaderProps {
  uptime: number;
  availableTime: number;
  equipment: IEquipmentCR | IEquipmentBD | IEquipmentCT | IEquipmentIJ | IEquipmentDataGn | undefined;
  defaultLoading: boolean;
  type?: string;
}

const Header: React.FC<IHeaderProps> = ({ uptime, availableTime, equipment, defaultLoading, type }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('CR');

  const color =
    equipment && equipment.lastEvent_number
      ? { color: colores.find((colores) => colores.number === equipment.lastEvent_number)?.color }
      : { color: '' };

  return (
    <div className='default-class-details-header'>
      <div className='p-grid flex'>
        <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
          {defaultLoading ? (
            <Skeleton className='skeleton' />
          ) : (
            <div className='container'>
              <div className='flex'>
                <div className='icon-bg flex-center-x'>
                  <BsFillShieldFill className='icon flex-center-x-y' />
                </div>
                <div className='title flex-center-y'>Operação</div>
              </div>
              <div className='flex-center-x-y sub-title'>
                <div
                  className='flex-center-x-y'
                  style={color}
                >
                  {equipment?.lastEvent_name ? equipment.lastEvent_name : ''}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
          {defaultLoading ? (
            <Skeleton className='skeleton' />
          ) : (
            <div className='container green'>
              <div className='flex'>
                <div className='icon-bg green flex-center-x'>
                  <FaPlay className='icon flex-center-x-y' />
                </div>
                <div className='title flex-center-y green'>Tempo Funcionando</div>
              </div>
              <div className='flex-center-x-y sub-title green'>
                <div className='flex-center-x-y'>
                  ~ {type === 'seconds' ? secondsToTime(uptime) : millisToTime(uptime)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
          {defaultLoading ? (
            <Skeleton className='skeleton' />
          ) : (
            <div className='container red'>
              <div className='flex'>
                <div className='icon-bg red flex-center-x'>
                  <FaStop className='icon flex-center-x-y' />
                </div>
                <div className='title flex-center-y red'>Tempo Parado</div>
              </div>
              <div className='flex-center-x-y sub-title red'>
                <div className='flex-center-x-y'>
                  ~ {type === 'seconds' ? secondsToTime(availableTime) : millisToTime(availableTime)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
