import * as React from 'react';

import { differenceInMilliseconds, endOfDay } from 'date-fns';

interface IContext {
  fullscreen: boolean;
  windowWidth: number;
  titlePath: string;
  setTitlePath: React.Dispatch<React.SetStateAction<string>>;
  extender: boolean;
  setExtender: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMenuSize: () => void;
}

interface IProps {
  children: React.ReactNode;
}

export const SideMenu = React.createContext<IContext>({} as IContext);

export const SideMenuProvider: React.FC<IProps> = ({ children }) => {
  const [fullscreen, setFullScreen] = React.useState<boolean>(false);

  const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth);

  const [titlePath, setTitlePath] = React.useState<string>('');

  const [extender, setExtender] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.matchMedia('(display-mode: fullscreen)').addEventListener('change', ({ matches }) => {
      if (matches) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    });

    if (window.matchMedia('(display-mode: fullscreen)').matches) {
      setFullScreen(true);
    }
  }, []);

  window.addEventListener('resize', () => {
    const newWidth = window.innerWidth;
    setWindowWidth(newWidth);
  });

  const toggleMenuSize = () => {
    const extendMenu = !extender;
    localStorage.setItem('EXTENDER', JSON.stringify(extendMenu));
    setExtender(extendMenu);
  };

  const peekSizeMenu = React.useCallback(() => {
    if (localStorage.getItem('EXTENDER') === null) {
      localStorage.setItem('EXTENDER', JSON.stringify(!extender));
    } else if (localStorage.getItem('EXTENDER') !== JSON.stringify(extender)) {
      setExtender(!extender);
    }
  }, [extender]);

  React.useEffect(() => {
    peekSizeMenu();
  }, [peekSizeMenu]);

  React.useEffect(() => {
    const end = endOfDay(new Date());
    const mili = differenceInMilliseconds(end, new Date());
    const interval = setTimeout(() => {
      window.location.reload();
    }, mili + 50000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SideMenu.Provider
      value={{
        fullscreen,
        windowWidth,
        titlePath,
        setTitlePath,
        extender,
        setExtender,
        toggleMenuSize,
      }}
    >
      {children}
    </SideMenu.Provider>
  );
};
