import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { subDays } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IDayReadingDataBD } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReadingList(equipment_id: string, date: Date): [Array<IDayReadingDataBD>, boolean] {
  const { user } = React.useContext(Auth);

  const [dayReadingList, setDayReadingList] = React.useState<Array<IDayReadingDataBD>>([]);
  const [pendingDayReadingList, setPendingDayReadingList] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    const lastDay = subDays(date, 7);

    setPendingDayReadingList(true);
    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-dayReadingBd'),
        where('equipment_id', '==', equipment_id),
        where('date', '>', lastDay),
        where('date', '<=', date),
        orderBy('date')
      ),
      (snapshot) => {
        const list: Array<IDayReadingDataBD> = [];

        if (snapshot.empty) {
          setDayReadingList([]);
          setPendingDayReadingList(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IDayReadingDataBD);
        });

        setDayReadingList(list);
        setPendingDayReadingList(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [equipment_id, date]);

  return [dayReadingList, pendingDayReadingList];
}
