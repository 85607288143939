import * as React from 'react';

import { Timestamp } from 'firebase/firestore';

import { differenceInMilliseconds, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { GoAlert } from 'react-icons/go';

import { IEventsName } from 'contexts/events.context';

import { IEquipmentBD, IEquipmentCR, IEquipmentCT, IEquipmentIJ } from 'types/OEE-equipment';
import { IEquipmentDataGn } from 'types/OEE-equipmentGn';
import { IEventData } from 'types/OEE-event';

import './time.scss';

interface IProps {
  index: number;
  equipment: IEquipmentDataGn | IEquipmentBD | IEquipmentCR | IEquipmentCT | IEquipmentIJ;
  events: { events: IEventData[]; colores: IEventsName[] };
  type?: string;
}

const Time: React.FC<IProps> = ({ equipment, index, events, type }) => {
  const [alert, setAlert] = React.useState(false);
  const [time, setTime] = React.useState<string | undefined>(undefined);

  const color = { color: events.colores.find((colores) => colores.number === equipment.lastEvent_number)?.color };

  const updateEventTime = React.useCallback(() => {
    if (events.events) {
      const event = events.events.find((item) => item.number === equipment.lastEvent_number);
      const timeDiference = differenceInMilliseconds(new Date(), equipment.lastEvent_updated_at.toDate());

      if (event !== undefined && timeDiference > Number(event.limitTime)) {
        setAlert(true);
      } else {
        setAlert(false);
      }

      const eventUpdate = equipment.lastEvent_updated_at.toDate();
      const formatDate = formatDistanceStrict(eventUpdate, new Date(), { locale: ptBR, addSuffix: true });

      if (equipment.lastEvent_updated_at > Timestamp.now()) {
        setTime('-');
      } else {
        setTime(formatDate);
      }
    }
  }, [equipment, events.events]);

  React.useEffect(() => {
    updateEventTime();

    const intervalID = setInterval(updateEventTime, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [updateEventTime]);

  return (
    <div className='monitor-alert p-d-flex p-as-center p-jc-center'>
      {alert && (
        <GoAlert
          className={type ? 'icon-time-injector flex' : 'icon-time flex'}
          data-tip
          data-for={`registerTip${index}`}
        />
      )}
      <div
        className={type ? 'absolute-injector p-text-center' : 'absolute p-text-center'}
        style={color}
      >
        <div>{equipment.lastEvent_name}</div>
        <div>{time}</div>
      </div>
    </div>
  );
};

export default Time;
