import axios from 'axios';

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { CACHE_SIZE_UNLIMITED, initializeFirestore } from 'firebase/firestore';

export function getApi(token: string, baseURL = 'https://staging.api.intuitiva.io:443') {
  const api = axios.create({
    baseURL: baseURL,
    timeout: 15000,
  });

  api.interceptors.request.use(
    async (config) => {
      const apiConfig = config;
      if (token) {
        apiConfig.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
}

export const app = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firestoreSettings = {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  cacheEnabled: false,
};

export const firebaseConnect = initializeApp(app);

export const firebaseFirestore = initializeFirestore(firebaseConnect, firestoreSettings);

if (process.env.NODE_ENV === 'production') {
  getAnalytics(firebaseConnect);
}
