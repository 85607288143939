import React from 'react';

import { InputText, InputTextProps } from 'primereact/inputtext';

import { catchErrors, catchInvalid } from 'components/formErrors/formErrors';

import { IValidationError } from 'types/ALL-yup';

import './inputText.scss';

interface IProps extends InputTextProps {
  customClassName?: string;
  icon?: string;
  placeholder: string;
  formErr: IValidationError[];
  fieldCode: string;
  firstClick?: boolean;
}

const InputTextPrime: React.FC<IProps> = ({ icon, placeholder, formErr, fieldCode, firstClick, ...InputTextProps }) => {
  const invalid = catchInvalid(formErr, fieldCode);

  return (
    <div className='prime-input-text-default-class'>
      <span className='p-float-label p-input-icon-right width100'>
        <i
          className={
            firstClick ? (invalid ? 'pi pi-times input-text-icon-error' : 'pi pi-check input-text-icon-success') : icon
          }
        />
        <InputText
          className={
            firstClick
              ? invalid
                ? 'input-text-error width100'
                : 'input-text-success width100'
              : 'input-text-default width100'
          }
          {...InputTextProps}
        />
        <label
          className={firstClick ? (invalid ? 'input-text-icon-error' : 'input-text-icon-success') : ''}
          htmlFor={placeholder}
        >
          {placeholder}
        </label>
      </span>
      {catchErrors(formErr, fieldCode)}
    </div>
  );
};

export default InputTextPrime;
