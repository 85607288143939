import React from 'react';

import { Auth } from 'contexts/auth.context';

import './error404.scss';

const Error404: React.FC = () => {
  const { user } = React.useContext(Auth);

  return (
    <div className='p-py-3 p-px-3'>
      <div className='p-d-flex p-as-center p-jc-center loading-bg width100 '>
        <div className={`p-text-center ${user ? 'erro404-display-login' : 'erro404-display-logout'}`}>
          <p className='p-my-0 erro404-title'>404</p>
          <p className='p-my-0 erro404-divisor' />
          <p className='p-my-5 erro404-subTitle'>Página Não Encontrada</p>
        </div>
      </div>
    </div>
  );
};

export default Error404;
