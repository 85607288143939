import React from 'react';

import { useParams } from 'react-router-dom';

import { Timestamp } from 'firebase/firestore';

import Button from 'pages/components_prime/button';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import DropdownPrime from 'components/primereact/dropdown/dropdown';
import InputTextareaPrime from 'components/primereact/inputTextarea/inputTextarea';

import { ICardData, ISequence } from 'types/SCRUM-card';

import { genericFunctionToEdit } from '../../../monitor/controllers/firebase';
import { useFrame } from '../../../monitor/hooks/useFrame';

import './question.scss';

interface ISequenceProps {
  sequence: ISequence[] | undefined;
  value: ICardData | undefined;
  initialState: () => void;
}

interface IFrameValue {
  code: string;
  name: string;
}

const Question: React.FC<ISequenceProps> = ({ sequence, value, initialState }) => {
  const { SectorID } = useParams();

  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [frame] = useFrame(SectorID ? SectorID : '');
  const [frameValue, setFrameValue] = React.useState<IFrameValue | null>(null);
  const [notes, setNotes] = React.useState<string>('');

  const [pending, setPending] = React.useState(false);

  function checkIndexPending(data: ISequence[] | undefined) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === 'pending') {
          return i;
        }
      }
      return -1;
    }
  }

  async function handleYes() {
    setPending(true);

    const frameIds = [frameValue?.code || ''];

    if (value) {
      frameIds.push(...value.frameIds);
    }

    const dataValidation: Partial<ICardData> = {
      frameIds,
      sequence: sequence?.map((item) => {
        if (item.sector_id === SectorID) {
          return {
            ...item,
            notes: notes,
            frame_id: frameValue?.code,
            status: 'withMe',
            distributionEndDate: Timestamp.now(),
            frameStartDate: Timestamp.now(),
          };
        }

        if (item.status === 'pending') {
          return { ...item, status: 'disabled' };
        }

        return item;
      }),
    };

    const code3c = user ? user.code3c_id : '';
    const id = value ? value.id : '';

    try {
      const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-card', id);

      if (response) {
        toastSuccess('Card Editado com Sucesso!');
        setPending(false);
        initialState();
      } else {
        toastError('Ops! Algo deu errado ao editar o Card!');
        setPending(false);
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  }

  async function handleNo() {
    setPending(true);

    const checkIndex = checkIndexPending(sequence);

    const frameIds = [frameValue?.code || ''];

    if (value) {
      frameIds.push(...value.frameIds);
    }

    const dataValidationCard: Partial<ICardData> = {
      frameIds,
      sequence: sequence?.map((item, index) => {
        if (item.sector_id === SectorID) {
          return {
            ...item,
            status: 'notWithMe',
            frame_id: frameValue?.code,
            distributionEndDate: Timestamp.now(),
            notes: notes,
          };
        }

        if (checkIndex === index) {
          return {
            ...item,
            status: 'current',
            distributionStartDate: Timestamp.now(),
          };
        }

        return item;
      }),
    };

    const code3c = user ? user.code3c_id : '';
    const id = value ? value.id : '';

    try {
      const response = await genericFunctionToEdit(dataValidationCard, code3c, 'SCRUM-card', id);

      if (response) {
        initialState();
        setPending(false);
        toastSuccess('Card Editado com Sucesso!');
      } else {
        setPending(false);
        toastError('Ops! Algo deu errado ao editar o Card!');
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  }

  return (
    <div className='dc-distribution-quest'>
      <div className='container-quest'>
        <div className='input-quest'>
          <DropdownPrime
            value={frameValue}
            fieldCode='select-frame'
            optionLabel='name'
            placeholder='Selecione a Maquina'
            formErr={[]}
            onChange={(e) => setFrameValue(e.value)}
            options={frame.map((item) => ({ code: item.id, name: item.name }))}
          />
        </div>
        {frameValue && (
          <React.Fragment>
            <InputTextareaPrime
              className='textarea-quest'
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder='Observação'
              fieldCode='notes'
              formErr={[]}
              rows={3}
              autoResize={false}
            />
            <div className='buttons'>
              <Button
                moduleID='scrum-online'
                permissionID='btn-question-yes'
                label='A ORDEM ESTA NO MEU SETOR'
                severity='success'
                onClick={() => handleYes()}
                pendingProps={pending}
              />
              <Button
                moduleID='scrum-online'
                permissionID='btn-question-no'
                label='A ORDEM NÃO ESTA NO MEU SETOR'
                severity='danger'
                onClick={() => handleNo()}
                visible={checkIndexPending(sequence) !== -1}
                pendingProps={pending}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Question;
