import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Skeleton } from 'primereact/skeleton';

import { Auth } from 'contexts/auth.context';

import Empty from 'components/empty/empty.component';
import Legends from 'components/legends/legends';
import Navbar from 'components/monitor/navbar/navbar';

import ChartComplete from './components/chartComplete';
import ChartEquipment from './components/chartEquipment';

import { useEquipments } from 'hooks/useEquipments';

import { useDayReadingList } from './hooks/useDayReadingList';

import { IDayReadingDataBD } from 'types/OEE-dayReading';
import { IEquipmentBD, IEquipmentBDdata } from 'types/OEE-equipment';

import './monitor.scss';

interface IEquipmentItem {
  [string: string]: IDayReadingDataBD[];
}

const MonitorBD: React.FC = () => {
  const history = useNavigate();

  const { user } = React.useContext(Auth);

  const [equipments, pendingEquipments] = useEquipments<IEquipmentBDdata>('BD');
  const [dayReadingList, pendingDayReading] = useDayReadingList();

  const [filterEquipment, setFilterEquipment] = React.useState([] as string[]);
  const [filterEvent, setFilterEvent] = React.useState([] as string[]);

  const [defaultLoading, setDefaultLoading] = React.useState(true);

  const equipmentsFilter =
    filterEquipment.length !== 0 && equipments.length !== 0
      ? equipments.filter((item) => !filterEquipment.includes(item.name))
      : equipments;

  const dayReadingFiltered = React.useMemo(() => {
    const filterDayReadingList =
      filterEquipment.length !== 0 || filterEvent.length !== 0
        ? dayReadingList.filter(
            (item) => !filterEquipment.includes(item.equipment_name) && !filterEvent.includes(item.event_name)
          )
        : dayReadingList;

    const dayReadingEquipment = filterDayReadingList.reduce((previous, item) => {
      const token = item.equipment_id;
      previous[token] = previous[token] || [];
      previous[token].push(item);
      return previous;
    }, {} as IEquipmentItem);

    return { filterDayReadingList, dayReadingEquipment };
  }, [filterEquipment, filterEvent, dayReadingList]);

  React.useEffect(() => {
    const localStorageItem = localStorage.getItem(
      `EQUIPMENT_GRAPH_FILTER_EQUIPMENT_BD_V1_${user ? user.code3c_id : 'error'}`
    );
    if (localStorageItem !== JSON.stringify(filterEquipment) && localStorageItem !== null) {
      setFilterEquipment(JSON.parse(localStorageItem));
    }
  }, [filterEquipment]);

  React.useEffect(() => {
    const localStorageItem = localStorage.getItem(
      `EQUIPMENT_GRAPH_FILTER_EVENT_BD_V1_${user ? user.code3c_id : 'error'}`
    );
    if (localStorageItem !== JSON.stringify(filterEvent) && localStorageItem !== null) {
      setFilterEvent(JSON.parse(localStorageItem));
    }
  }, [filterEvent]);

  React.useEffect(() => {
    setDefaultLoading(true);
    if (!pendingDayReading && !pendingEquipments) {
      setTimeout(() => {
        setDefaultLoading(false);
      }, 200);
    }
  }, [pendingDayReading, pendingEquipments]);

  return (
    <React.Fragment>
      {!defaultLoading && equipments.length === 0 ? (
        <Empty text='Nenhuma Bordadeira foi Encontrada,' />
      ) : (
        <div className='embroidery-monitor'>
          <Navbar
            type='BD'
            filterEvent={filterEvent}
            filterEquipment={filterEquipment}
            setFilterEquipment={setFilterEquipment}
            setFilterEvent={setFilterEvent}
            defaultLoading={defaultLoading}
            storageEquipmentID={`EQUIPMENT_GRAPH_FILTER_EQUIPMENT_BD_V1_${user ? user.code3c_id : 'error'}`}
            storageEventID={`EQUIPMENT_GRAPH_FILTER_EVENT_BD_V1_${user ? user.code3c_id : 'error'}`}
          />
          <div className='p-grid flex p-mt-2'>
            <div className='p-col-12 p-md-12 p-lg-12'>
              <div className='content flex p-jc-center p-as-center'>
                {defaultLoading ? (
                  <Skeleton
                    className='skeleton'
                    height='calc(-19.6rem + 100vh)'
                  />
                ) : (
                  <div className='flex p-as-center p-jc-center p-flex-wrap'>
                    <ChartComplete dayReadingFiltered={dayReadingFiltered.filterDayReadingList} />
                    {equipmentsFilter
                      .sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')))
                      .map((item, index) => {
                        return (
                          <div
                            key={index.toString()}
                            onClick={() => history(`/bordadeiras/detalhes/${item.id}`)}
                          >
                            <ChartEquipment
                              key={index.toString()}
                              index={index}
                              equipment={item as IEquipmentBD}
                              dayReadingFiltered={dayReadingFiltered.dayReadingEquipment[item.id]}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='p-grid flex'>
            <div className='p-col-12 p-md-12 p-lg-12'>
              <Legends
                type='BD'
                defaultLoading={defaultLoading}
                white={true}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MonitorBD;
