import React, { useMemo } from 'react';
import * as XLSX from 'xlsx';

import { format } from 'date-fns';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IReadingShift } from 'utils-node-react/react/utils';

import { IEquipment } from 'types/OEE-equipment';

import { formatTime } from 'functions/machines/date.functions';

import s from './tableList.module.scss';

interface IProps {
  enhancedDayReadings: IReadingShift[];
  eventMap: { [key: string]: IOptionsEventList };
  date: Date;
  equipment: IEquipment | undefined;
}

export const TableEvents: React.FC<IProps> = ({ enhancedDayReadings, eventMap, date, equipment }) => {
  const [pending, setPending] = React.useState(false);

  const handleExportToExcel = () => {
    setPending(true);

    const wb = XLSX.utils.book_new();
    const wsData = enhancedDayReadings.map((item, index) => {
      const event = eventMap[item.id] ?? 'error';

      return {
        Índice: { v: index + 1, s: { alignment: { horizontal: 'left' } } },
        Turno: item.shift,
        Evento: event.name,
        Início: format(item.start * 1000, 'dd/MM - HH:mm:ss'),
        Fim: format(item.end * 1000, 'dd/MM - HH:mm:ss'),
        Segundos: {
          v: item.end === item.start ? '---' : item.difference,
          s: { alignment: { horizontal: 'left' } },
        },
        Duração: formatTime(item.difference),
      };
    });
    const ws = XLSX.utils.json_to_sheet(wsData);
    const wsCols = [{ wch: 10 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 20 }, { wch: 40 }];
    ws['!cols'] = wsCols;
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${equipment?.name ?? ''} - ${format(date, 'dd-MM - HH-mm')}.xlsx`);

    setTimeout(() => setPending(false), 5000);
  };

  const formatData = useMemo(() => {
    return enhancedDayReadings.map((i, index) => {
      const event = eventMap[i.id] ?? 'error';

      return { ...i, index: index + 1, event_name: event.name };
    });
  }, [enhancedDayReadings, eventMap]);

  return (
    <div className={s.container}>
      <div className={s.title}>
        Histórico Detalhado de Eventos
        <BaseButton
          label='Exportar Eventos'
          onClick={() => handleExportToExcel()}
          disabled={pending}
          icon='pi pi-file-export'
          severity='info'
          size='small'
        />
      </div>
      <DataTable
        scrollable
        scrollHeight='30rem'
        virtualScrollerOptions={{ itemSize: 46 }}
        size='small'
        emptyMessage='Nenhum Evento Foi Encontrado.'
        value={formatData}
        className={s.table}
      >
        <Column
          field='index'
          header='ÍNDICE'
          sortable
        />
        <Column
          field='shift'
          header='TURNO'
          sortable
        />
        <Column
          field='event_name'
          header='STATUS'
          sortable
          body={(row) => {
            const event = eventMap[row.id] ?? 'error';

            return <div style={{ color: event.color, fontWeight: 'bold' }}>{event.name}</div>;
          }}
        />
        <Column
          field='start'
          header='INICIO'
          sortable
          body={(row) => <span>{format(row.start * 1000, 'dd/MM - HH:mm:ss')}</span>}
        />
        <Column
          field='end'
          header='FIM'
          sortable
          body={(row) => <span>{format(row.end * 1000, 'dd/MM - HH:mm:ss')}</span>}
        />
        <Column
          field='difference'
          header='DURAÇÃO'
          sortable
          body={(row) => <span>{formatTime(row.difference)}</span>}
        />
      </DataTable>
    </div>
  );
};
