import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ICardData } from 'types/SCRUM-card';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useHistoricCard(id: string): [ICardData[], boolean] {
  const { user } = React.useContext(Auth);

  const [historicCard, setHistoricCard] = React.useState<ICardData[]>([]);
  const [pendingHistoricCard, setPendingHistoricCard] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    setPendingHistoricCard(true);

    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-card'),
      where('parkingSpace_id', '==', id),
      orderBy('created_at', 'desc')
    );

    const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
      const data: ICardData[] = [];

      if (snapshot.empty) {
        setHistoricCard([]);
        setPendingHistoricCard(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        data.push({ ...item.data(), id: item.id } as ICardData);
      });

      setHistoricCard(data);
      setPendingHistoricCard(false);
    });

    return () => unsubscribe();
  }, [id, user]);

  return [historicCard, pendingHistoricCard];
}
