import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { ISequence } from 'types/SCRUM-card';
import { ISectorData } from 'types/SCRUM-sector';

import './sequence.scss';

interface ISequenceProps {
  sequence: ISequence[] | undefined;
  sectorList: ISectorData[];
}

const Sequence: React.FC<ISequenceProps> = ({ sequence, sectorList }) => {
  return (
    <div className='default-class-share-kanban-sequence'>
      {sequence?.map((i, index) => {
        const findSector = sectorList.find((sector) => sector.id === i.sector_id);
        const findNextSector = sequence[index + 1]
          ? sectorList.find((sector) => sector.id === sequence[index + 1].sector_id)?.name
          : '';

        return (
          <Card
            key={index}
            i={i}
            findSector={findSector}
            findNextSector={findNextSector}
          />
        );
      })}
    </div>
  );
};

interface ICardProps {
  i: ISequence;
  findSector: ISectorData | undefined;
  findNextSector: string | undefined;
}

const Card: React.FC<ICardProps> = ({ i, findSector, findNextSector }) => {
  const Status = (props: { sequence: ISequence; findNextSector: string | undefined }) => {
    const { sequence, findNextSector } = props;

    switch (sequence.status) {
      case 'concluded':
        return <div className='concluded'>CONCLUÍDO</div>;
      case 'current':
        return <div className='current'>CONFIGURANDO</div>;
      case 'notWithMe':
        return (
          <div className='notWithMe'>
            <div>AGUARDANDO:</div>
            <div>{findNextSector}</div>
          </div>
        );
      case 'withMe':
        return <div className='withMe'>SETOR ATUAL</div>;
      case 'disabled':
        return <div className='disabled'>INATIVO</div>;
      case 'pending':
        return <div className='pendente'>PENDENTE</div>;
      default:
        return <div>-</div>;
    }
  };

  const [distributionTime, setDistributionTime] = React.useState<string | undefined>(undefined);
  const [frameTime, setFrameTime] = React.useState<string | undefined>(undefined);

  const updateEventTime = React.useCallback(() => {
    if (i.distributionStartDate && i.distributionEndDate) {
      const startDate = i.distributionStartDate.toDate();
      const endDate = i.distributionEndDate.toDate();

      const newTime = formatDistanceStrict(startDate, endDate, { locale: ptBR });

      setDistributionTime(newTime);
    } else {
      setDistributionTime('-');
    }
    if (i.frameStartDate && i.frameEndDate) {
      const startDate = i.frameStartDate.toDate();
      const endDate = i.frameEndDate.toDate();

      const newTime = formatDistanceStrict(startDate, endDate, { locale: ptBR });

      setFrameTime(newTime);
    } else {
      setFrameTime('-');
    }
  }, [i.frameEndDate, i.frameStartDate, i.distributionEndDate, i.distributionStartDate]);

  React.useEffect(() => {
    updateEventTime();

    const intervalID = setInterval(updateEventTime, 2000);

    return () => {
      clearInterval(intervalID);
    };
  }, [updateEventTime]);

  return (
    <div
      className={`container-sequence ${i.status === 'disabled' ? 'opacity' : ''}`}
      style={{ borderBottom: `4px solid #${findSector?.color}` }}
    >
      <div className='title-sequence'>{findSector?.name}</div>
      <div className='container-description'>
        <b>
          <div>Configuração:</div>
          <span>Entrada: </span>
          {i.distributionStartDate ? format(i.distributionStartDate?.toDate(), 'dd/MM/yyyy HH:mm') : '-'}
          <br />
          <span>Saida: </span>
          {i.distributionEndDate ? format(i.distributionEndDate?.toDate(), 'dd/MM/yyyy HH:mm') : '-'}
          <br />
          <span>Tempo: {distributionTime}</span>
        </b>
        <br />
        <b>
          <div>Quadro:</div>
          <span>Entrada: </span>
          {i.frameStartDate ? format(i.frameStartDate?.toDate(), 'dd/MM/yyyy HH:mm') : '-'}
          <br />
          <span>Saida: </span>
          {i.frameEndDate ? format(i.frameEndDate?.toDate(), 'dd/MM/yyyy HH:mm') : '-'}
          <br />
          <span>Tempo: {frameTime}</span>
          <br />
          <br />
          <span>Observação: {i.notes}</span>
        </b>
      </div>
      <div className='container-status'>
        <Status
          sequence={i}
          findNextSector={findNextSector}
        />
      </div>
    </div>
  );
};

export default Sequence;
