import React from 'react';
import { ReactComponent as Carrossel } from 'assets/company/carrossel.svg';
import { ReactComponent as Embroidery } from 'assets/company/embroidery.svg';
import { ReactComponent as Injector } from 'assets/company/injector.svg';

import Parking from 'pages/administration/parking/parking';
import AboutPage from 'pages/default/about/about.page';
import MonitorCR from 'pages/machines/carrossel/monitor/monitor';
import MonitorBD from 'pages/machines/embroidery/monitor/monitor';
import MonitorGENERIC from 'pages/machines/generic/monitor/monitor.page';
import MonitorINJECTOR from 'pages/machines/injector/monitor/monitor.page';
import MonitorParkingSpace from 'pages/machines/kanban/monitor/monitor.component';
import IndexSCM from 'pages/machines/scrum/monitor/monitor';

export interface ISettings {
  group: string;
  type: string;
  title: string;
  code3c_id?: string;
}

export interface IRoutes {
  id: string;
  path: string;
  title: string;
  style?: string;
  component: JSX.Element;
  icon?: string;
  svg?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const ListRoutes: IRoutes[] = [
  {
    id: 'CR',
    path: '/carrossel',
    title: 'Carrossel',
    component: <MonitorCR />,
    svg: Carrossel,
  },
  {
    id: 'BD',
    path: '/bordadeiras',
    title: 'Bordadeiras',
    component: <MonitorBD />,
    svg: Embroidery,
  },
  {
    id: 'IJ',
    path: '/injetoras',
    title: 'Injetoras',
    icon: 'FaWindows',
    component: <MonitorINJECTOR settings={{ group: 'IJ', type: 'IJ', title: 'injetoras' }} />,
    svg: Injector,
  },
  {
    id: 'EF',
    path: '/enfestadeiras',
    title: 'Enfestadeiras',
    icon: 'FaIndustry',
    component: <MonitorGENERIC settings={{ group: 'EF', type: 'GN', title: 'enfestadeiras' }} />,
  },
  {
    id: 'RF',
    path: '/refiladeiras',
    title: 'Refiladeiras',
    icon: 'FaIndustry',
    component: <MonitorGENERIC settings={{ group: 'RF', type: 'GN', title: 'refiladeiras' }} />,
  },
  {
    id: 'MT',
    path: '/motores',
    title: 'Motores',
    icon: 'FaIndustry',
    component: <MonitorGENERIC settings={{ group: 'MT', type: 'GN', title: 'motores' }} />,
  },
  {
    id: 'CL',
    path: '/cl1400',
    title: 'CL 1400',
    icon: 'FaIndustry',
    component: <MonitorGENERIC settings={{ group: 'CL', type: 'GN', title: 'cl1400' }} />,
  },
  {
    id: 'GN',
    path: '/maquinas',
    title: 'Maquinas',
    icon: 'FaIndustry',
    component: <MonitorGENERIC settings={{ group: 'GN', type: 'GN', title: 'maquinas' }} />,
  },
  {
    id: 'KANBAN',
    path: '/kanban_online',
    title: 'Kanban Online',
    icon: 'FaParking',
    component: <MonitorParkingSpace />,
  },
  {
    id: 'SCRUM',
    path: '/cartao_digital',
    title: 'Cartão Digital',
    icon: 'BsKanban',
    component: <IndexSCM />,
  },
  {
    id: 'ADMIN',
    path: '/admin/vagas',
    title: 'Administração',
    icon: 'MdAdminPanelSettings',
    style: 'white',
    component: <Parking />,
  },
  {
    id: 'ABOUT',
    path: '/sobre',
    title: 'Sobre',
    icon: 'FaQuestionCircle',
    style: 'white',
    component: <AboutPage />,
  },
];

export default ListRoutes;
