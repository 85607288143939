import React from 'react';

import Icons from 'componentes_share_admin/icons/icons';

import s from './card.module.scss';

interface ICardProps {
  label: string;
  value: string;
  icon: string;
  color: string;
}

const Card: React.FC<ICardProps> = ({ label, value, color, icon }) => {
  return (
    <div className={s.container}>
      <div className={s.content_info}>
        <div className={s.label}>{label}</div>
        <div className={s.value}>{value}</div>
      </div>
      <div
        className={s.content_icon}
        style={{ backgroundColor: color }}
      >
        <Icons
          iconName={icon}
          className={s.icon}
        />
      </div>
    </div>
  );
};

export default Card;
