import * as React from 'react';

import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IParkingSpaceData } from 'types/KC-ParkingSpace';

import { firebaseFirestore } from '../../../../firebase/authentication';

export function useParkingSpace(): [IParkingSpaceData[], boolean] {
  const { user } = React.useContext(Auth);

  const [parkingSpaceList, setParkingSpaceList] = React.useState<IParkingSpaceData[]>([]);
  const [pendingParkingSpace, setPendingParkingSpace] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingParkingSpace(true);

    const unsubscribe = onSnapshot(
      query(collection(firebaseFirestore, 'KC-parkingSpace'), where('code3c_id', '==', user.code3c_id)),
      (snapshot) => {
        const list: IParkingSpaceData[] = [];

        if (snapshot.empty) {
          setParkingSpaceList([]);
          setPendingParkingSpace(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IParkingSpaceData);
        });

        setParkingSpaceList(list);
        setPendingParkingSpace(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  return [parkingSpaceList, pendingParkingSpace];
}
