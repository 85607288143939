import React, { ReactNode } from 'react';

import { ConfirmPopup } from 'primereact/confirmpopup';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import './componentConfig.scss';

interface IComponenteConfigProps {
  visible: boolean;
  children: ReactNode;

  isConfirm?: boolean;
  moduleID?: string;
  permissionID?: string;
  handleFunction?: () => Promise<void> | void;
}

const ComponentConfig: React.FC<IComponenteConfigProps> = ({
  children,
  visible,
  isConfirm,
  moduleID,
  permissionID,
  handleFunction,
}) => {
  const { user } = React.useContext(Auth);
  const { toastError } = React.useContext(Toaster);

  const [visibleConfirm, setVisibleConfirm] = React.useState(false);

  const buttonEl = React.useRef(null);

  const isPermission = React.useMemo(() => {
    if (
      moduleID &&
      permissionID &&
      user &&
      user.permissions &&
      user.permissions[moduleID] &&
      user.permissions[moduleID][permissionID]
    ) {
      const permission = user.permissions[moduleID][permissionID];

      const checkEdit = permission.includes('R');
      return checkEdit ? false : true;
    } else {
      return true;
    }
  }, [moduleID, permissionID, user]);

  if (visible) {
    return (
      <React.Fragment>
        <ConfirmPopup
          target={buttonEl.current ? buttonEl.current : undefined}
          visible={visibleConfirm}
          onHide={() => setVisibleConfirm(false)}
          message='Voce tem Certeza que Deseja Prosseguir?'
          icon='pi pi-exclamation-triangle'
          reject={() => setVisibleConfirm(false)}
          acceptLabel='Sim'
          rejectLabel='Não'
          accept={() => {
            if (handleFunction) {
              handleFunction();
              setVisibleConfirm(false);
            }
          }}
        />
        <span
          style={{ opacity: isPermission && moduleID ? '0.7' : '1' }}
          ref={buttonEl}
          onClick={(event) => {
            if (isPermission) {
              event.stopPropagation();
              toastError(
                'Você não tem permissão para editar. Se acredita que isso é um erro, por favor, entre em contato com um administrador.'
              );
            } else {
              if (isConfirm) {
                setVisibleConfirm(!visibleConfirm);
              } else if (handleFunction) {
                handleFunction();
              }

              if (isConfirm || handleFunction || isPermission) {
                event.stopPropagation();
              }
            }
          }}
        >
          {children}
        </span>
      </React.Fragment>
    );
  }
  return <React.Fragment />;
};

export default ComponentConfig;
