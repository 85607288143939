import React, { useContext, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { isWithinInterval } from 'date-fns';

import Loading from 'componentes_share_admin/loading/loading.component';
import Navbar from 'componentes_share_admin/machines/details/navbar/navbar.component';
import Filter, { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import Legends from 'componentes_share_admin/machines/global/legends/legends.component';
import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { IAllWorkShiftData, IOeeDayReadingData } from 'types-node-react/react/type';

import { Auth } from 'contexts/auth.context';

import { GraphDonut } from './components/graphDonut.component';
import { TableEvents } from './components/tableList.component';
import { Timeline } from './components/timeLine.component';

import { useEquipments } from 'hooks/machines/useEquipments.hook';
import { useTempletes } from 'hooks/machines/useTemplete.hook';
import { IShiftDisplayList, useWorkShift } from 'hooks/machines/useWorkShift.hook';

import { useDayReading } from './hooks/useDayReading.hook';

import { ISettings } from 'routes/listRoutes';

import { navigation } from '../global';

import { generateShiftReadingsOverview } from './details.functions';

import s from './details.module.scss';

interface IDetailsGenericController {
  settings: ISettings;
}

const DetailsGenericController: React.FC<IDetailsGenericController> = ({ settings }) => {
  const history = useNavigate();

  const { ID } = useParams();

  const { user } = useContext(Auth);

  const configs = useMemo(() => {
    return { ...settings, code3c_id: user?.code3c_id };
  }, [settings, user?.code3c_id]);

  const [date, setDate] = useState(new Date());

  const { templete, eventsMap, eventsList, eventsLoading } = useTempletes(configs);
  const { equipments, equipmentsLoading } = useEquipments(configs);
  const { dayReading, dayReadingLoading } = useDayReading(ID, date);

  const workShift_id = useMemo(() => {
    const foundWorkShift = templete?.workShift_ids?.find((item) =>
      isWithinInterval(date, {
        start: item.start.toDate(),
        end: item.end ? item.end.toDate() : new Date(),
      })
    );

    return foundWorkShift?.id ?? templete?.workShift_id;
  }, [date, templete?.workShift_id, templete?.workShift_ids]);

  const { workShift, shiftDisplayList, loadingWorkShift } = useWorkShift(user?.code3c_id, workShift_id);

  if (eventsLoading || equipmentsLoading || dayReadingLoading || loadingWorkShift) {
    return (
      <BaseTabMenu
        index={1}
        menuItems={navigation(configs.title, history)}
      >
        <Loading height='calc(100vh - 8rem)' />
      </BaseTabMenu>
    );
  }

  return (
    <DetailsGenericComponent
      date={date}
      setDate={setDate}
      eventsMap={eventsMap}
      eventsList={eventsList}
      equipments={equipments}
      dayReading={dayReading}
      workShift={workShift}
      shiftDisplayList={shiftDisplayList}
      configs={configs}
    />
  );
};

interface IDetailsGenericComponent {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  eventsMap: { [key: string]: IOptionsEventList };
  eventsList: IOptionsEventList[];
  equipments: IEquipmentGNdata[];
  dayReading: IOeeDayReadingData[];
  workShift: IAllWorkShiftData | null;
  shiftDisplayList: IShiftDisplayList[];
  configs: ISettings;
}

const DetailsGenericComponent: React.FC<IDetailsGenericComponent> = ({
  date,
  setDate,
  eventsMap,
  eventsList,
  equipments,
  dayReading,
  workShift,
  shiftDisplayList,
  configs,
}) => {
  const history = useNavigate();

  const { ID } = useParams();

  const { user } = useContext(Auth);

  const [filters, setFilters] = useState<IFilters>({ equipments: [], events: [], shifts: [] });

  const memoizedEquipmentReadings = useMemo(() => {
    return generateShiftReadingsOverview(workShift, dayReading, date, shiftDisplayList, filters, eventsMap);
  }, [date, dayReading, eventsMap, filters, shiftDisplayList, workShift]);

  return (
    <BaseTabMenu
      index={1}
      menuItems={navigation(configs.title, history)}
    >
      <div className={s.container}>
        <Navbar
          id={ID}
          equipments={equipments}
          date={date}
          setDate={setDate}
          title={configs.title}
        >
          <Filter
            eventsList={eventsList}
            shiftsList={shiftDisplayList}
            filters={filters}
            setFilters={setFilters}
            filterKey={`${user?.code3c_id}_details_${configs.group}`}
          />
        </Navbar>
        <div className={s.row_graph}>
          <div className={s.graph}>
            <GraphDonut
              title='Status Geral'
              enhancedReadings={memoizedEquipmentReadings.aggregatedEvents}
            />
          </div>
          {memoizedEquipmentReadings.workShifts.map((shift, index) => (
            <div
              key={index}
              className={s.graph}
            >
              <GraphDonut
                title={shift.name}
                enhancedReadings={memoizedEquipmentReadings.separatedSummary[shift.id]}
              />
            </div>
          ))}
        </div>
        <Timeline
          enhancedDayReadings={memoizedEquipmentReadings.readingSeparated}
          shiftDisplayList={memoizedEquipmentReadings.workShifts}
          eventMap={eventsMap}
        />
        <TableEvents
          enhancedDayReadings={memoizedEquipmentReadings.readingSeparated}
          equipment={equipments.find((i) => i.id === ID)}
          eventMap={eventsMap}
          date={date}
        />
        <Legends eventArray={eventsList} />
      </div>
    </BaseTabMenu>
  );
};

export default DetailsGenericController;
