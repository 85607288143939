import * as React from 'react';

import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { differenceInMilliseconds, startOfDay, subDays } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IDayReadingBD } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export interface IReading extends IDayReadingBD {
  startDate: Timestamp;
  endDate: Timestamp;
  differenceInMilliseconds: number;
  index: number;
}

export function useTimeline(equipment_id: string, date: Date): [Array<IDayReadingBD>, boolean] {
  const { user } = React.useContext(Auth);

  const [timelineList, setTimelineList] = React.useState<IReading[]>([]);
  const [pendingTimelineList, setPendingTimelineList] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    const dayNext = subDays(date, -1);

    setPendingTimelineList(true);
    if (equipment_id !== '') {
      const unsubscribe = onSnapshot(
        query(
          collection(firebaseFirestore, 'OEE-readingBd'),
          where('code3c_id', '==', user.code3c_id),
          where('equipment_id', '==', equipment_id),
          where('startDate', '>=', startOfDay(date)),
          where('startDate', '<=', startOfDay(dayNext)),
          orderBy('startDate', 'asc')
        ),
        (snapshot) => {
          if (snapshot.empty) {
            setTimelineList([]);
            setPendingTimelineList(false);
          }

          const list: IReading[] = snapshot.docs.map((item, index) => {
            const currentValue = item.data() as IReading;
            if (snapshot.docs[index + 1]) {
              const nextValue = snapshot.docs[index + 1].data() as IReading;

              const difference = differenceInMilliseconds(
                nextValue.startDate.toDate(),
                currentValue.startDate.toDate()
              );

              return {
                ...currentValue,
                id: item.id,
                endDate: nextValue.startDate,
                differenceInMilliseconds: difference,
                index,
              } as IReading;
            }

            return {
              ...currentValue,
              id: item.id,
              endDate: currentValue.startDate,
              differenceInMilliseconds: 0,
              index,
            } as IReading;
          });

          setTimelineList(list);
          setPendingTimelineList(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [equipment_id, date]);

  return [timelineList, pendingTimelineList];
}
