import * as React from 'react';

import { useParams } from 'react-router-dom';

import { startOfDay } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import Header from 'components/details/header/header';
import Navbar from 'components/details/navbar/navbar';
import Legends from 'components/legends/legends';

import EditEvent from './components/editEvent';
import LastSevenDays from './components/lastSevenDays';
import TableEvents from './components/tableEvents';
import Timeline from './components/timeline';

import { useSearchEquipment } from 'hooks/useSearchEquipment';

import { useDayReadingList } from './hooks/useDayReadingList';
import { useTimeline } from './hooks/useReadingTimeline';

import { IEquipmentCRdata } from 'types/OEE-equipment';
import { initialReadingCR, IReadingDataCR } from 'types/OEE-reading';

import './details.scss';

export interface IShowEditEvent {
  item: IReadingDataCR;
  visible: boolean;
}

const DetailsCR: React.FC = () => {
  const { ID } = useParams();

  const { user } = React.useContext(Auth);

  const [date, setDate] = React.useState(new Date());
  const [newDate, setNewDate] = React.useState(date);

  const [timelineList, pendingTimelineList] = useTimeline(ID ? ID : '', date);
  const [dayReadingList, pendingDayReadingList] = useDayReadingList(ID ? ID : '', date);
  const [equipment, pendingEquipment] = useSearchEquipment<IEquipmentCRdata>(ID ? ID : '');

  const [filterEvent, setFilterEvent] = React.useState([] as string[]);

  const machineEvents = { item: initialReadingCR, visible: false };
  const [showHideEdit, setShowHideEdit] = React.useState<IShowEditEvent>(machineEvents);

  const [defaultLoading, setDefaultLoading] = React.useState(true);

  const dayReadingFilter = React.useMemo(() => {
    const filterDayReadingList =
      filterEvent.length !== 0
        ? dayReadingList.filter((item) => !filterEvent.includes(item.event_name))
        : dayReadingList;

    return filterDayReadingList;
  }, [filterEvent, dayReadingList]);

  const { availableTime, uptime } = React.useMemo(() => {
    const time = { availableTime: 0, uptime: 0 };
    const dataNew = startOfDay(date).valueOf();

    time.availableTime = dayReadingFilter.reduce((previous, item) => {
      if (item.event_number !== '1' && item.date.toDate().valueOf() >= dataNew) {
        return previous + item.sumMillis;
      }
      return previous;
    }, 0);

    time.uptime = dayReadingFilter.reduce((previous, item) => {
      if (item.event_number === '1' && item.date.toDate().valueOf() >= dataNew) {
        return previous + item.sumMillis;
      }
      return previous;
    }, 0);

    return time;
  }, [dayReadingFilter, date]);

  React.useEffect(() => {
    const localStorageItem = localStorage.getItem(`DETAILS_FILTER_EVENT_CR_V1_${user && user.code3c_id}`);
    if (localStorageItem !== JSON.stringify(filterEvent) && localStorageItem !== null) {
      setFilterEvent(JSON.parse(localStorageItem));
    }
  }, [filterEvent, user]);

  React.useEffect(() => {
    setDefaultLoading(true);
    if (!pendingDayReadingList && !pendingTimelineList && !pendingEquipment) {
      setTimeout(() => {
        setDefaultLoading(false);
      }, 200);
    }
  }, [pendingDayReadingList, pendingTimelineList, pendingEquipment]);

  React.useEffect(() => {
    if (showHideEdit.visible) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideEdit.visible]);

  return (
    <div className='carrossel-details'>
      <EditEvent
        visible={showHideEdit}
        setVisible={setShowHideEdit}
      />
      <Navbar
        date={date}
        setDate={setDate}
        newDate={newDate}
        setNewDate={setNewDate}
        ID={ID}
        filterEvent={filterEvent}
        setFilterEvent={setFilterEvent}
        type='CR'
        defaultLoading={defaultLoading}
        storageEventID={`DETAILS_FILTER_EVENT_CR_V1_${user && user.code3c_id}`}
      />
      <div className='p-mt-3'>
        <Header
          availableTime={availableTime}
          uptime={uptime}
          equipment={equipment}
          defaultLoading={defaultLoading}
        />
      </div>
      <div className='p-grid flex p-mt-1'>
        <div className='p-col-12 p-md-12 p-lg-6'>
          <LastSevenDays
            dayReadingFiltered={dayReadingFilter}
            date={date}
            defaultLoading={defaultLoading}
          />
        </div>
        <div className='p-col-12 p-md-12 p-lg-6'>
          <Timeline
            timelineList={timelineList}
            date={date}
            defaultLoading={defaultLoading}
          />
        </div>
      </div>
      <div className='p-grid flex'>
        <div className='p-col-12 p-md-12 p-lg-12'>
          <Legends
            type='CR'
            defaultLoading={defaultLoading}
            white={true}
          />
        </div>
      </div>
      <div className='p-grid flex p-mt-0'>
        <div className='p-col-12 p-md-12 p-lg-12'>
          <TableEvents
            equipmentName={equipment && equipment.name ? equipment.name : ''}
            readings={timelineList}
            defaultLoading={defaultLoading}
            setShowHideEdit={setShowHideEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsCR;
