import * as React from 'react';

import { Navigate } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

import { Auth } from 'contexts/auth.context';

import { AlertMessages } from 'components/alertMessages/alertMessages';
import ButtonPrime from 'components/primereact/button/button';

import CompanyLogo from './components/companyLogo';

import { signInUser } from 'controllers/user';

import './login.scss';

const Login: React.FC = () => {
  const { user } = React.useContext(Auth);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const [passError, setPassError] = React.useState(false);

  const [pending, setPending] = React.useState(false);

  async function handleLogin() {
    setPassError(false);
    setPending(true);

    const response = await signInUser(email, password);
    if (response !== null) {
      setPending(false);
      return;
    } else if (response === null) {
      setPending(false);
      setPassError(true);
    }
  }

  if (user !== null) {
    return <Navigate to={'/sobre'} />;
  }

  return (
    <div className='flex width100'>
      <div className='login-background'>
        <div className='login-kanban-background' />
      </div>
      <div className='flex-center-x-y login-form'>
        <div className='flex-center-x-y-column '>
          <div className='p-mb-4'>
            <CompanyLogo />
          </div>
          <AlertMessages
            passError={passError}
            icon='pi pi-times'
            message='E-mail e/ou Senha Incorreto(s)'
            style='p-my-2 p-p-2 flex-center-y alert-message-error'
          />
          <div className='login-width-input'>
            <InputText
              type='email'
              disabled={false}
              className='password'
              placeholder='E-mail'
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              id='E-mail'
            />
          </div>
          <div className='login-width-input'>
            <Password
              type='password'
              disabled={false}
              placeholder='Senha'
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
              id='Senha'
              className='password'
              toggleMask
              feedback={false}
            />
          </div>
          <div className='flex-center-x p-my-3'>
            <ButtonPrime
              customClassName='system'
              onClick={() => handleLogin()}
              label='Entrar'
              disabled={pending}
              icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
