import { useContext, useEffect, useState } from 'react';

import { doc, onSnapshot } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ISectorData } from 'types/SCRUM-sector';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useSearchSector(id: string | null): [ISectorData | undefined, boolean] {
  const { user } = useContext(Auth);

  const [sector, setSector] = useState<ISectorData>();
  const [pendingSector, setPendingSector] = useState<boolean>(true);

  useEffect(() => {
    if (!user) {
      setPendingSector(false);
      return;
    }

    if (id) {
      setPendingSector(true);

      const unsubscribe = onSnapshot(
        doc(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-sector', id),
        (snapshot) => {
          if (snapshot.exists()) {
            const newUser = snapshot.data() as ISectorData;
            setSector({ ...newUser, id: snapshot.id });
            setPendingSector(false);
          }
          setPendingSector(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [user, id]);

  return [sector, pendingSector];
}
