export const collections = {
  allCode3C: 'ALL-code3c',
  allGroup: 'ALL-group',
  allEquipment: 'OEE-equipment',
  allProvince: 'ALL-province',
  allUser: 'ALL-user',

  kcParkingSpace: 'KC-parkingSpace',

  oeeEvent: 'OEE-event',
  oeeUpdate: 'OEE-update',

  oeeReadingCr: 'OEE-reading',
  oeeDayReadingCr: 'OEE-dayReading',

  kcReadingMv: 'KC-readingMv',
  kcDayReadingMv: 'KC-dayReadingMv',

  oeeReadingBd: 'OEE-readingBd',
  oeeDayReadingBd: 'OEE-dayReadingBd',

  oeeReadingIj: 'OEE-readingIj',
  oeeDayReadingIj: 'OEE-dayReadingIj',
};

//
