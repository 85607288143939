import React from 'react';

import { IFrameData } from 'types/SCRUM-frame';

import './columns.scss';

interface IColumns {
  frame: IFrameData | null;
}

const Columns: React.FC<IColumns> = ({ frame }) => {
  return (
    <div className='dc-sc-fm-cl'>
      <div className='sc-fm-cl-container'>
        <div className='sc-fm-cl-column group'>-</div>
        {frame?.columns?.map((item, index) => {
          return (
            <div
              key={index}
              className='sc-fm-cl-column'
              style={{ borderBottom: `4px solid #${item.color}` }}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Columns;
