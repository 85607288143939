import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';

import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import { Events } from 'contexts/events.context';
import { Toaster } from 'contexts/toast.context';

import DropdownPrime from 'components/primereact/dropdown/dropdown';

import { editEventCR } from 'controllers/equipments';

import { DialogFooter, DialogHeader } from 'utils/dialog/dialog';

import { IReadingCR } from 'types/OEE-reading';

import { IValidationError } from '../../../../../types/ALL-yup';
import { IShowEditEvent } from '../details';
import { dataSchema } from '../validations/yup';

interface IEditEquipment {
  visible: IShowEditEvent;
  setVisible: React.Dispatch<React.SetStateAction<IShowEditEvent>>;
}

const EditEvent: React.FC<IEditEquipment> = ({ visible, setVisible }) => {
  const { getEventsType } = React.useContext(Events);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const eventsList = getEventsType('CR');

  const [formErr, setFormErr] = React.useState<Array<IValidationError>>([]);

  const [pending, setPending] = React.useState(false);

  const [reading, setReading] = React.useState({ name: '', code: '', number: '' });

  const result = Object.values([visible.item]);

  const newEventsList = eventsList.filter((item) => item.number !== '0' && item.number !== '1');

  const initialState = () => {
    setVisible({ ...visible, visible: false });
    setFormErr([]);
    setReading({ name: '', code: '', number: '' });
    setTimeout(() => {
      setPending(false);
    }, 400);
  };

  async function handleEdit() {
    setPending(true);

    const dataValidation: Partial<IReadingCR> = {
      event_id: reading.code,
      event_name: reading.name,
      event_number: reading.number,
      equipment_id: visible.item.equipment_id,
      equipment_name: visible.item.equipment_name,
      endDate: visible.item.endDate,
      startDate: visible.item.startDate,
      code3c_id: visible.item.code3c_id,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editEventCR(dataValidation, visible.item.id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Evento editado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o evento!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  const statusBodyTemplate = (data: IReadingCR, dataTable: ColumnBodyOptions) => {
    return <span>{dataTable.rowIndex + 1}</span>;
  };

  const startBodyTemplate = (data: IReadingCR) => {
    return <span>{format(data.startDate.toDate(), 'dd/MM - hh:mm:ss')}</span>;
  };

  const endBodyTemplate = (data: IReadingCR) => {
    return <span>{format(data.endDate.toDate(), 'dd/MM - hh:mm:ss')}</span>;
  };

  const totalBodyTemplate = (data: IReadingCR) => {
    return <span>{formatDistanceStrict(data.startDate.toDate(), data.endDate.toDate())}</span>;
  };

  const headerBodyTemplate = () => {
    return <div>Evento Selecionado</div>;
  };

  return (
    <div className='dialog-demo'>
      <div className='card'>
        <Dialog
          className='default-class-utils-dialog dialog-default-width'
          draggable={false}
          visible={visible.visible}
          onHide={() => initialState()}
          header={
            <DialogHeader
              type='edit'
              title='Editar Evento'
            />
          }
          footer={
            <DialogFooter
              type='edit'
              pending={pending}
              initialState={initialState}
              firstChange={true}
              handleFunction={handleEdit}
            />
          }
        >
          <div className='p-p-3'>
            <DataTable
              value={result}
              stripedRows
              showGridlines
              rowHover
              removableSort
              header={headerBodyTemplate}
            >
              <Column
                field='index'
                header='#'
                body={statusBodyTemplate}
              />
              <Column
                field='event_name'
                header='Status'
              />
              <Column
                field='event_number'
                header='Evento'
              />
              <Column
                field='start'
                header='Inicio'
                body={startBodyTemplate}
              />
              <Column
                field='end'
                header='Fim'
                body={endBodyTemplate}
              />
              <Column
                field='differenceInMilliseconds'
                header='Total'
                body={totalBodyTemplate}
                sortField='differenceInMilliseconds'
              />
            </DataTable>
          </div>
          <div className='p-p-3'>
            <DropdownPrime
              filter={false}
              filterBy=''
              disabled={false}
              value={reading}
              onChange={(e) => {
                setReading(e.value);
              }}
              options={newEventsList.map((events) => {
                return {
                  name: events.name,
                  code: events.id,
                  number: events.number,
                };
              })}
              optionLabel='name'
              formErr={formErr}
              fieldCode='event_name'
              placeholder='Selecione o Motivo da Parad'
            />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default EditEvent;
