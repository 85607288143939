import { useEffect, useState } from 'react';

import { Shift } from 'types-node-react/react/type';

import { findShift } from 'utils-node-react/react/utils';

export function useCurrentShift(workShift: Shift[] | undefined): { currentShift: string } {
  const [currentShift, setCurrentShift] = useState('');

  useEffect(() => {
    const updateCurrentShift = () => {
      setCurrentShift(findShift(workShift).shiftName);
    };

    updateCurrentShift();

    const interval = setInterval(updateCurrentShift, 60000);

    return () => clearInterval(interval);
  }, [workShift]);

  return { currentShift };
}
