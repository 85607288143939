import React from 'react';

import { endOfWeek, isDate, startOfMonth, startOfWeek, subDays } from 'date-fns';

import { CalendarChangeEvent } from 'primereact/calendar';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseCalendar from 'componentes_share_admin/primereact/calendar/calendar.component';
import BaseDropDown from 'componentes_share_admin/primereact/dropdown/dropdown.component';

import { IOptionsReports } from 'pages/machines/kanban/reports/reports.component';

import { Auth } from 'contexts/auth.context';

import s from './navbar.module.scss';

interface INavbarProps {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  typeReport: IOptionsReports;
  setTypeReport: React.Dispatch<React.SetStateAction<IOptionsReports>>;
  optionsReports: IOptionsReports[];
  daySummaryCalculation?: () => Promise<true | undefined>;

  children: React.ReactNode;
}

const Navbar: React.FC<INavbarProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  typeReport,
  setTypeReport,
  optionsReports,
  daySummaryCalculation,
  children,
}) => {
  const { user } = React.useContext(Auth);

  const [week, setWeek] = React.useState<Date[]>([
    subDays(startOfWeek(startDate), -1),
    subDays(endOfWeek(startDate), -1),
  ]);

  const getWeek = (day: Date | Date[]): Date[] => {
    const getStartAndEndOfWeek = (date: Date) => {
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });

      return [start, end];
    };

    if (Array.isArray(day) && day.length > 0) {
      return getStartAndEndOfWeek(day[0]);
    } else if (isDate(day)) {
      return getStartAndEndOfWeek(day as Date);
    }

    return [];
  };

  const handleWeekChange = (e: CalendarChangeEvent) => {
    if (Array.isArray(e.value)) {
      const value = getWeek(e.value as Date[]) || [];
      e.value[0] = value[0];
      e.value[1] = value[1];
      setWeek(value);
      setStartDate(subDays(e.value[1], 2) as Date);
    }
  };

  const handlePeriodStart = (e: Date) => {
    if (e >= endDate) {
      setEndDate(e);
    }

    setStartDate(e);
  };

  const handlePeriodEnd = (e: Date) => {
    if (e <= startDate) {
      setStartDate(e);
    }

    setEndDate(e);
  };

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.input}>
          <BaseDropDown
            placeholder='Período'
            optionLabel='name'
            options={optionsReports}
            value={typeReport}
            onChange={(e) => {
              setTypeReport(e.value);
              setEndDate(new Date());
              if (e.target.value.id === 'period') {
                setStartDate(startOfMonth(new Date()));
              } else {
                setStartDate(new Date());
              }
            }}
          />
        </div>
        {typeReport.id === 'period' && (
          <div className={s.period}>
            <div className={s.input}>
              <span className='p-float-label'>
                <BaseCalendar
                  showIcon
                  dateFormat='dd/mm/yy'
                  readOnlyInput
                  value={startDate}
                  onChange={(e) => handlePeriodStart(e.value as Date)}
                  maxDate={new Date()}
                />
                <label>Data Inicial</label>
              </span>
            </div>
            <div className={s.input}>
              <span className='p-float-label'>
                <BaseCalendar
                  showIcon
                  dateFormat='dd/mm/yy'
                  readOnlyInput
                  value={endDate}
                  onChange={(e) => handlePeriodEnd(e.value as Date)}
                  maxDate={new Date()}
                />
                <label>Data Final</label>
              </span>
            </div>
          </div>
        )}
        {typeReport.id === 'day' && (
          <React.Fragment>
            <div className={s.input}>
              <BaseCalendar
                showIcon
                dateFormat='dd/mm/yy'
                readOnlyInput
                value={startDate}
                onChange={(e) => setStartDate(e.value as Date)}
                maxDate={new Date()}
              />
            </div>
            {daySummaryCalculation && user?.userAccessLevel === 'master' && (
              <BaseButton
                label='Recalcular Sumario (A)'
                onClick={() => daySummaryCalculation()}
                icon='pi pi-refresh'
                severity='success'
              />
            )}
          </React.Fragment>
        )}
        {typeReport.id === 'week' && (
          <div className={s.input}>
            <BaseCalendar
              showIcon
              id='range'
              dateFormat='dd/mm/yy'
              selectionMode='range'
              readOnlyInput
              value={week}
              onChange={(e) => handleWeekChange(e)}
              maxDate={new Date()}
            />
          </div>
        )}
        {typeReport.id === 'month' && (
          <div className={s.input}>
            <BaseCalendar
              showIcon
              view='month'
              dateFormat='mm/yy'
              readOnlyInput
              value={startDate}
              onChange={(e) => setStartDate(e.value as Date)}
              maxDate={new Date()}
            />
          </div>
        )}
        {typeReport.id === 'year' && (
          <div className={s.input}>
            <BaseCalendar
              showIcon
              view='year'
              dateFormat='yy'
              readOnlyInput
              value={startDate}
              onChange={(e) => setStartDate(e.value as Date)}
              maxDate={new Date()}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default Navbar;
