import React from 'react';

import EChartDonut, { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import LayoutGraph from 'componentes_share_admin/layout/graph/graph.component';

import * as echarts from 'echarts/core';

import { PieChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TooltipComponent, GridComponent, PieChart, CanvasRenderer]);

interface IProps {
  enhancedReadings: IChartDonut[];
  title: string;
}

const GraphDonut: React.FC<IProps> = ({ enhancedReadings, title }) => {
  const series = React.useMemo(() => {
    if (!enhancedReadings || enhancedReadings.length === 0) return [];

    return enhancedReadings;
  }, [enhancedReadings]);

  return (
    <LayoutGraph
      title={title}
      height='16.5rem'
    >
      <EChartDonut
        series={series}
        height='13rem'
        width='20rem'
      />
    </LayoutGraph>
  );
};

export default GraphDonut;
