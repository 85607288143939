import React, { useEffect, useState } from 'react';

import * as echarts from 'echarts/core';

import { EChartOption } from 'echarts';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import ReactEChartsCore from 'echarts-for-react/lib/core';

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

export interface IChartBar {
  id: string;
  color: string;
  value: number;
  name: string;
  data: number[];
  itemStyle: { color: string };
}

interface IProps {
  series: IChartBar[];
  dateAxisLabels: string[];
  height: string;
}

const EChartBar: React.FC<IProps> = ({ series, dateAxisLabels, height }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (series) setCount((state) => state + 1);
  }, [series]);

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' },
      formatter: (params: EChartOption.Tooltip.Format[]) => {
        let tooltip = '';

        if (params.length > 0) {
          const filteredParams = params
            .filter((item) => item.value && Number(item.value) !== 0)
            .sort((a, b) => Number(b.value) - Number(a.value));

          if (filteredParams.length > 0) {
            tooltip += `${filteredParams[0].axisValue}<br/>`;

            filteredParams.forEach((item) => {
              tooltip += `<b style="color: ${item.color}">${item.seriesName}</b>: <b>${item.value}%</b><br/>`;
            });
          }
        }

        return tooltip;
      },
    },
    grid: {
      left: '1%',
      right: '1%',
      top: '10%',
      bottom: '1%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 100,
      axisLabel: { formatter: '{value}%' },
    },
    xAxis: {
      type: 'category',
      data: dateAxisLabels,
    },
    series: series.map((series) => ({
      ...series,
      type: 'bar',
      stack: 'total',
      label: {
        show: true,
        color: 'white',
        formatter: (params: { value: number }) => (Math.floor(params.value) > 2 ? `${Math.floor(params.value)}%` : ''),
      },
    })),
  } as EChartOption;

  return (
    <ReactEChartsCore
      style={{ width: '100%', height: height }}
      echarts={echarts}
      option={options}
      key={count}
    />
  );
};

export default EChartBar;
