import React, { FC, useCallback, useEffect, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseCheckbox from 'componentes_share_admin/primereact/checkbox/checkbox.module';

import s from './filter.module.scss';

interface IProps {
  equipmentsList?: IFilterArray[];
  eventsList?: IFilterArray[];
  shiftsList?: IFilterArray[];

  filters: IFilters;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;

  filterKey: string;
}

interface IFilterArray {
  id: string;
  name: string;
}

export interface IFilters {
  equipments: string[];
  events: string[];
  shifts: string[];
}

const Filter: FC<IProps> = ({ equipmentsList, eventsList, shiftsList, filters, setFilters, filterKey }) => {
  const op = useRef<OverlayPanel | null>(null);

  const handleEditFilter = useCallback(
    (checked: boolean, id: string, type: keyof IFilters) => {
      setFilters((currentFilters) => {
        const updatedItems = checked ? currentFilters[type].filter((i) => i !== id) : [...currentFilters[type], id];
        const newFilters = { ...currentFilters, [type]: updatedItems };

        localStorage.setItem(filterKey, JSON.stringify(newFilters));

        return newFilters;
      });
    },
    [filterKey, setFilters]
  );

  useEffect(() => {
    const localStorageItem = localStorage.getItem(filterKey);
    const storedFilters = localStorageItem ? JSON.parse(localStorageItem) : null;

    if (storedFilters && JSON.stringify(storedFilters) !== JSON.stringify(filters)) {
      setFilters(storedFilters);
    }
  }, [filters, filterKey, setFilters]);

  return (
    <>
      <BaseButton
        icon='pi pi-filter'
        size='small'
        onClick={(e) => op?.current?.toggle(e)}
      />
      <OverlayPanel ref={op}>
        <div className={s.filter_box}>
          {equipmentsList && (
            <React.Fragment>
              <div>Filtro de Equipamentos</div>
              <div className={s.content}>
                {equipmentsList.map((e, index) => {
                  const checked = !!filters.equipments.find((i) => i === e.id);

                  return (
                    <div key={index}>
                      <BaseCheckbox
                        checked={!checked}
                        onClick={() => handleEditFilter(checked, e.id, 'equipments')}
                      />
                      <span>{e.name}</span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
          {eventsList && (
            <React.Fragment>
              <div>Filtro de Eventos</div>
              <div className={s.content}>
                {eventsList.map((e, index) => {
                  const checked = !!filters.events.find((i) => i === e.id);

                  return (
                    <div key={index}>
                      <BaseCheckbox
                        checked={!checked}
                        onClick={() => handleEditFilter(checked, e.id, 'events')}
                      />
                      <span>{e.name}</span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
          {shiftsList && (
            <React.Fragment>
              <div>Filtro de Turnos</div>
              <div className={s.content}>
                {shiftsList.map((e, index) => {
                  const checked = !!filters.shifts.find((i) => i === e.id);

                  return (
                    <div key={index}>
                      <BaseCheckbox
                        checked={!checked}
                        onClick={() => handleEditFilter(checked, e.id, 'shifts')}
                      />
                      <span>{e.name}</span>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
      </OverlayPanel>
    </>
  );
};

export default Filter;
