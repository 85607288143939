import React from 'react';

import { Fieldset, FieldsetProps } from 'primereact/fieldset';

import s from './fieldset.module.scss';

interface IBaseFieldset extends FieldsetProps {
  children: React.ReactNode;
  visible?: boolean;
}

const BaseFieldset: React.FC<IBaseFieldset> = ({ children, visible, ...FieldsetProps }) => {
  if (visible) {
    return (
      <Fieldset
        className={s.fieldset}
        {...FieldsetProps}
      >
        {children}
      </Fieldset>
    );
  }

  return <React.Fragment />;
};

export default BaseFieldset;
