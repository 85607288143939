import { useContext, useEffect, useState } from 'react';

import { collectionGroup, getDocs, query, where } from 'firebase/firestore';

import { getSummaryEndDate, getSummaryStartDate } from 'componentes_share_admin/machines/reports/reports.functions';

import { IOeeMonthlySummaryData } from 'types-node-react/react/type';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useMonthlySummary(
  equipmentsIds: string[],
  startDate: Date,
  endDate: Date,
  report: string
): { monthlySummary: IOeeMonthlySummaryData[] | null; monthlySummaryLoading: boolean } {
  const { user } = useContext(Auth);

  const [monthlySummary, setMonthSummary] = useState<IOeeMonthlySummaryData[] | null>(null);
  const [monthlySummaryLoading, setMonthlySummaryLoading] = useState<boolean>(true);

  useEffect(() => {
    const start = getSummaryStartDate(report, startDate);
    const end = getSummaryEndDate(report, startDate, endDate);

    if (!user || !start || !end || !equipmentsIds || equipmentsIds.length === 0) return;

    const fetchEquipments = async () => {
      setMonthlySummaryLoading(true);

      const q = query(
        collectionGroup(firebaseFirestore, 'OEE-monthlySummary'),
        where('date', '>=', start),
        where('date', '<=', end),
        where('machine_id', 'in', equipmentsIds)
      );

      try {
        const documents = await getDocs(q);
        const data: IOeeMonthlySummaryData[] = [];

        documents.docs.forEach((item) => data.push({ ...item.data(), id: item.id } as IOeeMonthlySummaryData));

        setMonthSummary(data.length > 0 ? data : null);
        setMonthlySummaryLoading(false);
      } catch (error) {
        setMonthSummary(null);
        setMonthlySummaryLoading(false);

        console.error('Erro ao buscar dados de leituras:', error);
      }
    };

    fetchEquipments();
  }, [startDate, endDate, equipmentsIds, report, user]);

  return { monthlySummary, monthlySummaryLoading };
}
