import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';

import { IUser, IUserData } from 'types/ALL-user';

import { firebaseConnect, firebaseFirestore, getApi } from '../firebase/authentication';

export const searchHexaUser = async (id: string) => {
  const docRef = doc(firebaseFirestore, 'ALL-user', id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const hexaUser = { id: docSnap.id, ...docSnap.data() };
    return hexaUser as IUserData;
  } else {
    return null;
  }
};

export const signInUser = (email: string, password: string) => {
  const auth = getAuth(firebaseConnect);
  const userResponse = signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      if (user) {
        return user;
      }
      return null;
    })
    .catch(() => {
      return null;
    });

  return userResponse;
};

export const signOutUser = async () => {
  const auth = getAuth(firebaseConnect);
  await signOut(auth)
    .then(() => {
      window.location.reload();
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
  return false;
};

export async function addUser(data: Partial<IUser>, token: string) {
  try {
    const api = getApi(token);
    const response = await api.post('/user/create', data);
    if (response.data.id) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export const editCode3c = async (code3c: string, id: string) => {
  return updateDoc(doc(firebaseFirestore, 'ALL-user', id), {
    code3c_id: code3c,
    updated_at: Timestamp.now(),
  })
    .then(() => {
      window.location.reload();
      return true;
    })
    .catch((error) => {
      console.log('Erro ao estabelecer conexão com o banco de dados', error);
      return false;
    });
};
