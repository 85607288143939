import React, { useCallback } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { differenceInDays, format, subDays } from 'date-fns';

import { IDayReadingData } from 'types/KC-dayReadingMV';

import { millisToTime } from 'functions/millisToTime';

import s from './graphBars.module.scss';

interface IItem {
  name: string;
  data: number[];
  color: string;
}

interface IReadingGraph {
  [string: string]: IItem;
}

interface IProps {
  dayReadingList: IDayReadingData[];
  date: Date;
}

const StoppedGraph: React.FC<IProps> = ({ dayReadingList, date }) => {
  const today = date;

  const series = React.useMemo(() => {
    const days = dayReadingList.reduce((previous, item) => {
      const token = 6 + differenceInDays(item.date.toDate(), today);
      if (item.status === 'unavailable') {
        previous[item.status] = previous[item.status] || {
          color: '#4caf50',
          name: 'CHEIO',
          data: [0, 0, 0, 0, 0, 0, 0],
        };
        previous[item.status].data[token] += item.sumMillis;

        return previous;
      }
      if (item.status === 'available') {
        previous[item.status] = previous[item.status] || {
          color: '#fa4443',
          name: 'VAZIO',
          data: [0, 0, 0, 0, 0, 0, 0],
        };
        previous[item.status].data[token] += item.sumMillis;

        return previous;
      }

      return previous;
    }, {} as IReadingGraph);

    return Object.keys(days).map((i) => days[i]);
  }, [dayReadingList, today]);

  const graph = useCallback(() => {
    const optionsName = [
      format(subDays(today, 6), 'dd/MM/yy'),
      format(subDays(today, 5), 'dd/MM/yy'),
      format(subDays(today, 4), 'dd/MM/yy'),
      format(subDays(today, 3), 'dd/MM/yy'),
      format(subDays(today, 2), 'dd/MM/yy'),
      format(subDays(today, 1), 'dd/MM/yy'),
      format(subDays(today, 0), 'dd/MM/yy'),
    ];

    const options: ApexOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false,
          dataLabels: { position: 'center' },
        },
      },
      xaxis: { categories: optionsName },
      legend: {
        show: false,
        position: 'right',
        offsetY: 40,
        showForSingleSeries: true,
      },
      grid: { padding: { top: 20 } },
      fill: { opacity: 1 },
      tooltip: { y: { formatter: (val) => `${millisToTime(val)}` } },
      yaxis: {
        axisBorder: { show: true },
        axisTicks: { show: false },
        labels: {
          show: true,
          formatter: (val) => {
            if (val === 0) {
              return `${String(0)}%`;
            }
            return `${val}%`;
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (_val: number, e) => {
          const total = series.reduce((previous, item) => {
            return previous + item.data[e.dataPointIndex];
          }, 0);

          if (e.w.globals.series[e.seriesIndex]) {
            return `${(e.w.globals.series[e.seriesIndex][e.dataPointIndex] * (100 / total)).toFixed(0)}%`;
          }

          return `${0}%`;
        },
      },
    };

    return (
      <Chart
        options={options}
        series={series}
        type='bar'
        height={350}
        width='100%'
      />
    );
  }, [series, today]);

  return (
    <div className={s.container}>
      <div className={s.title}>Histórico dos Últimos 7 dias</div>
      <div className={s.graph}>{graph()}</div>
    </div>
  );
};

export default StoppedGraph;
