import React from 'react';

export interface IValidationError {
  path: string;
  errors: string[];
  inner: IValidationError[];
}

const catchErrors = (formErr: Array<IValidationError>, errorField: string) => {
  return (
    <small>
      {formErr.map((err, index) => {
        if (err.path === errorField) {
          return (
            <div
              className='p-my-2'
              style={{ color: 'red' }}
              key={index}
            >
              {err.errors}
            </div>
          );
        }
        return null;
      })}
    </small>
  );
};

const catchInvalid = (formErr: Array<IValidationError>, errorField: string) => {
  if (formErr.filter((err) => err.path === errorField).length >= 1) {
    return true;
  }
  return false;
};

export { catchErrors, catchInvalid };
