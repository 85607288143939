import * as React from 'react';

import { formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { IOptionsEventList } from 'types_new/ALL-options';

import s from './eventAndTime.module.scss';

interface IProps {
  eventMap: { [key: string]: IOptionsEventList };
  lastEventUpdatedAt: Date | undefined;
  lastEventId: string | undefined;
}

const EventAndTime: React.FC<IProps> = ({ lastEventUpdatedAt, lastEventId, eventMap }) => {
  const [time, setTime] = React.useState<string | undefined>(undefined);

  const updateEventTime = React.useCallback(() => {
    if (lastEventUpdatedAt) {
      const eventUpdate = lastEventUpdatedAt;

      const formatDate = formatDistanceStrict(eventUpdate, new Date(), { locale: ptBR, addSuffix: true });

      if (lastEventUpdatedAt > new Date()) {
        setTime('-');
      } else {
        setTime(formatDate);
      }
    } else {
      setTime('-');
    }
  }, [lastEventUpdatedAt]);

  React.useEffect(() => {
    updateEventTime();

    const intervalID = setInterval(updateEventTime, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [updateEventTime]);

  if (lastEventUpdatedAt && lastEventId) {
    const lastEquipmentEvent = eventMap[lastEventId];

    return (
      <div className={s.container}>
        <span style={{ color: lastEquipmentEvent?.color }}>
          <div>{time}</div>
          <div>{lastEquipmentEvent?.nickName ?? 'error'}</div>
        </span>
      </div>
    );
  }

  return null;
};

export default EventAndTime;
