import React from 'react';

import EChartTimeLine from 'componentes_share_admin/charts/timeLine/timeLine.component';
import LayoutGraph from 'componentes_share_admin/layout/graph/graph.component';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IReadingShift } from 'utils-node-react/react/utils';

import { IShiftDisplayList } from 'hooks/machines/useWorkShift.hook';

interface IProps {
  enhancedDayReadings: IReadingShift[];
  eventMap: { [key: string]: IOptionsEventList };
  shiftDisplayList: IShiftDisplayList[];
}

export const Timeline: React.FC<IProps> = ({ enhancedDayReadings, eventMap, shiftDisplayList }) => {
  const series = React.useMemo(() => {
    const categoriesName = shiftDisplayList.map((i) => i.name.toUpperCase()).reverse();
    const categoriesId = shiftDisplayList.map((i) => i.id).reverse();

    const data = enhancedDayReadings
      .map((reading) => {
        const start = reading.start * 1000;
        const end = reading.end * 1000;
        const event = eventMap[reading.id] || { name: 'error', color: 'error' };

        const shiftIndex = categoriesId.indexOf(reading.shift);

        if (shiftIndex === -1) {
          return null;
        }

        return {
          name: event.name,
          shift: reading.shift,
          value: [shiftIndex, start, end],
          itemStyle: { normal: { color: event.color } },
        };
      })
      .filter((item): item is NonNullable<typeof item> => item !== null);

    const startTime = data?.reduce((min, item) => Math.min(min, item.value[1]), Infinity);
    const endTime = data?.reduce((max, item) => Math.max(max, item.value[2]), -Infinity);

    return { series: data, startTime, endTime, categoriesName };
  }, [enhancedDayReadings, eventMap, shiftDisplayList]);

  return (
    <LayoutGraph
      title='TimeLine de Eventos'
      height='22rem'
    >
      <EChartTimeLine
        series={series.series}
        startTime={series.startTime}
        endTime={series.endTime}
        categories={series.categoriesName}
        height='22rem'
      />
    </LayoutGraph>
  );
};
