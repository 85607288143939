import React from 'react';

import { Button, ButtonProps } from 'primereact/button';

import { Auth } from 'contexts/auth.context';

import { useWindowResizeListener } from 'hooks/useWindowResizeListener.hook';

import s from './button.module.scss';

interface IButtonProps extends ButtonProps {
  label?: string | undefined;
  permission?: { moduleId: string; permissionId: string };
}

const BaseButton: React.FC<IButtonProps> = ({ label, permission, ...ButtonProps }) => {
  const { windowWidth } = useWindowResizeListener();
  const { user } = React.useContext(Auth);

  const isPermission = React.useMemo(() => {
    if (permission) {
      const check = user?.permissions?.[permission.moduleId]?.[permission.permissionId];

      return check?.includes('R');
    }

    return true;
  }, [permission, user]);

  if (isPermission) {
    return (
      <Button
        label={windowWidth > 1250 ? label : undefined}
        className={s.button}
        {...ButtonProps}
      />
    );
  }

  return <React.Fragment />;
};

export default BaseButton;
