import { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';

import { IDayReadingIjData } from 'types_new/ALL-dayReading';
import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

export function generateShiftReadingsOverview(
  dayReading: IDayReadingIjData[],
  equipments: IEquipmentGNdata[],
  filters: IFilters,
  eventsMap: { [key: string]: IOptionsEventList }
) {
  const separatedReadings: { [key: string]: IChartDonut[] } = {};
  const aggregatedEvents: { [key: string]: IChartDonut } = {};

  equipments
    .filter((i) => !filters.equipments.includes(i.id))
    .forEach((equip) => {
      const reading = dayReading.filter((summary) => summary.equipment_id === equip.id);

      if (reading) {
        reading.forEach((evt) => {
          evt.data
            .filter((i) => !filters.events.includes(i.event_id))
            .map((i) => {
              const detail: IChartDonut = {
                name: eventsMap[i.event_id]?.name ?? 'error',
                value: i.sumSeconds,
                itemStyle: { color: eventsMap[i.event_id]?.color ?? 'error' },
              };

              if (aggregatedEvents[detail.name]) {
                aggregatedEvents[detail.name].value += detail.value;
              } else {
                aggregatedEvents[detail.name] = { ...detail };
              }
            });
        });

        separatedReadings[equip.id] = reading.flatMap((evt) =>
          evt.data
            .filter((i) => !filters.events.includes(i.event_id))
            .map((i) => {
              return {
                name: eventsMap[i.event_id]?.name ?? 'error',
                value: i.sumSeconds,
                itemStyle: { color: eventsMap[i.event_id]?.color ?? 'error' },
              };
            })
        );

        separatedReadings[equip.id] = Object.values(
          separatedReadings[equip.id].reduce<{ [key: string]: IChartDonut }>((acc, item) => {
            if (acc[item.name]) {
              acc[item.name].value += item.value;
            } else {
              acc[item.name] = item;
            }
            return acc;
          }, {})
        );
      }
    });

  const groupedReadings = Object.values(aggregatedEvents);

  return { separatedReadings, groupedReadings };
}
