import * as React from 'react';

import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { Tag } from 'primereact/tag';

import { Auth } from 'contexts/auth.context';
import { SideMenu } from 'contexts/sideMenu.context';

import Icons from 'components/icons/icons';
import DropdownPrime from 'components/primereact/dropdown/dropdown';

import { editCode3c, signOutUser } from 'controllers/user';

import { useClientsList } from './hooks/clients';

import './header.scss';

const Header: React.FC = () => {
  const { user } = React.useContext(Auth);
  const { titlePath, extender, toggleMenuSize, fullscreen } = React.useContext(SideMenu);

  const [clientsList] = useClientsList();

  const [clientSelected, setClientSelected] = React.useState({ key: '', name: '' });

  const menu = React.useRef<Menu>(null);

  const showHeader = !fullscreen && user;

  const clients = React.useMemo(() => {
    const clients = clientsList.map((clients) => {
      return { key: clients.id, name: clients.name };
    });

    return clients;
  }, [clientsList]);

  React.useEffect(() => {
    const clients = clientsList.find((clients) => clients.id === user?.code3c_id);

    if (clients) {
      setClientSelected({ key: clients.id, name: clients.name });
    }
  }, [clientsList, user?.code3c_id]);

  const UserTemplete = () => {
    return (
      <div className='flex as-center'>
        {user?.profileImage ? (
          <img
            className='menu-image-user flex as-center'
            src={user?.profileImage}
            onClick={(e) => menu.current?.toggle(e)}
          />
        ) : (
          <Avatar
            className='menu-avatar-user flex as-center'
            label={user?.name[0] ? user?.name[0] : ''}
            size='large'
          />
        )}
        <div className='flex as-center direction-column'>
          <div className='menu-text-user-name'>{user?.name}</div>
          <div className='menu-tag-user-name'>
            {user?.userAccessLevel === 'junior' ? (
              <Tag
                value='Junior'
                style={{ backgroundColor: '#62C370' }}
              />
            ) : user?.userAccessLevel === 'pleno' ? (
              <Tag
                value='Pleno'
                style={{ backgroundColor: '#F2B728' }}
              />
            ) : user?.userAccessLevel === 'senior' ? (
              <Tag
                value='Senior'
                style={{ backgroundColor: '#7D52A5' }}
              />
            ) : (
              <Tag
                value='Master'
                style={{ backgroundColor: '#FF4D4D' }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const ClienteTemplete = () => {
    return (
      <div className='p-p-2'>
        <DropdownPrime
          placeholder='Cliente'
          value={clientSelected}
          onChange={(e) => {
            editCode3c(e.value.key, user?.id ? user.id : '');
          }}
          fieldCode=''
          formErr={[]}
          optionLabel='name'
          options={clients}
        />
      </div>
    );
  };

  const itemsJunior = [
    {
      template: () => {
        return <UserTemplete />;
      },
    },
    { separator: true },
    {
      command: () => {
        signOutUser();
      },
      label: 'Sair',
      icon: 'pi pi-fw pi-sign-out',
    },
  ];

  const itemsMaster = [
    {
      template: () => {
        return <UserTemplete />;
      },
    },
    { separator: true },
    {
      template: () => {
        return <ClienteTemplete />;
      },
    },
    { separator: true },
    {
      command: () => {
        signOutUser();
      },
      label: 'Sair',
      icon: 'pi pi-fw pi-sign-out',
    },
  ];

  return (
    <div className='default-class-navigation-header'>
      {titlePath !== 'ReadingSend' && showHeader && (
        <div className='header-background flex jc-between as-center'>
          <div className='flex as-center'>
            <Icons
              className={`header-icon-sidebar ${extender ? 'closed' : 'open'} flex as-center`}
              iconName='AiOutlineDoubleLeft'
              onClick={() => toggleMenuSize && toggleMenuSize()}
            />
            <div className='header-text-pathname'>{titlePath}</div>
          </div>
          <div className='header-user-info flex as-center'>
            {user.profileImage ? (
              <img
                className='header-image-user'
                src={user.profileImage}
                onClick={(e) => menu.current?.toggle(e)}
              />
            ) : (
              <Avatar
                className='header-avatar-user'
                label={user?.name[0] ? user?.name[0] : ''}
                size='large'
                onClick={(e) => menu.current?.toggle(e)}
              />
            )}
            {/* <button type='button' onClick={() => toggleDarkMode()}>
              DarkMode
            </button> */}
          </div>
          <Menu
            model={user.userAccessLevel === 'master' ? itemsMaster : itemsJunior}
            popup
            ref={menu}
            className='header-menu'
          />
        </div>
      )}
    </div>
  );
};

export default Header;
