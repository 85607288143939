import React, { useEffect, useMemo, useState } from 'react';

import { Auth } from 'contexts/auth.context';

import Legends from 'components/legends/legends';
import GraphFull from 'components/reports/graphFull/graphFull';
import GraphSimple from 'components/reports/graphSimple/graphSimple';
import Header from 'components/reports/header/header';
import Navbar from 'components/reports/navbar/navbar';
import TableData from 'components/reports/tableData/tableData';

import MachineHistory from './components/machinesHistory';
import MachinesHistoryArea from './components/machinesHistoryArea';

import { getDayReadingMonthBD } from 'controllers/dayReading';
import { getEquipments } from 'controllers/equipments';

import { IDayReadingBD } from 'types/OEE-dayReading';
import { IEquipmentBDdata } from 'types/OEE-equipment';

const MonthBD: React.FC = () => {
  const { user } = React.useContext(Auth);

  const [date, setDate] = useState<Date>(new Date());

  const [filterEquipment, setFilterEquipment] = useState([] as string[]);
  const [filterEvent, setFilterEvent] = useState([] as string[]);
  const [filterDays, setFilterDays] = useState([] as string[]);
  const [filterOthers, setFilterOthers] = useState([] as string[]);

  const [dayReadingList, setDayReadingList] = useState<IDayReadingBD[]>([]);
  const [equipments, setEquipments] = useState<IEquipmentBDdata[]>([]);

  const [pendingReadingList, setPendingReadingList] = useState(true);
  const [pendingEquipmentsList, setPendingEquipmentsList] = useState(true);

  const [defaultLoading, setDefaultLoading] = React.useState(true);

  const equipmentsFilter =
    filterEquipment.length !== 0 && equipments.length !== 0
      ? equipments.filter((item) => !filterEquipment.includes(item.name))
      : equipments;

  const dayReadingFiltered = React.useMemo(() => {
    const filterDayReadingList =
      filterEquipment.length !== 0 || filterEvent.length !== 0
        ? dayReadingList.filter(
            (item) => !filterEquipment.includes(item.equipment_name) && !filterEvent.includes(item.event_name)
          )
        : dayReadingList;

    return filterDayReadingList;
  }, [filterEquipment, filterEvent, dayReadingList]);

  const { availableTime, uptime } = useMemo(() => {
    const time = { availableTime: 0, uptime: 0 };

    time.availableTime = dayReadingFiltered.reduce((previous, item) => {
      if (item.event_number !== '1') {
        return previous + item.sumMillis;
      }
      return previous;
    }, 0);

    time.uptime = dayReadingFiltered.reduce((previous, item) => {
      if (item.event_number === '1') {
        return previous + item.sumMillis;
      }
      return previous;
    }, 0);

    return time;
  }, [dayReadingFiltered, filterEquipment, filterEvent, filterDays, filterOthers]);

  async function getDayReadingList(getDate: Date) {
    setPendingReadingList(true);
    setDefaultLoading(true);
    const response = await getDayReadingMonthBD(getDate, user ? user.code3c_id : '');
    if (response) {
      setDayReadingList(response);
      setPendingReadingList(false);
    } else {
      setDayReadingList([]);
      setPendingReadingList(false);
    }
  }

  async function getEquipmentsList() {
    const response = await getEquipments(user ? user.code3c_id : '', 'BD');
    if (response) {
      setEquipments(response);
      setPendingEquipmentsList(false);
    } else {
      setEquipments([]);
      setPendingEquipmentsList(false);
    }
  }

  useEffect(() => {
    getDayReadingList(date);
    getEquipmentsList();
  }, []);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(`REPORTS_MONTH_FILTER_EQUIPMENT_BD_V1_${user && user.code3c_id}`);
    if (localStorageItem !== JSON.stringify(filterEquipment) && localStorageItem !== null) {
      setFilterEquipment(JSON.parse(localStorageItem));
    }
  }, [filterEquipment]);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(`REPORTS_MONTH_FILTER_EVENT_BD_V1_${user && user.code3c_id}`);
    if (localStorageItem !== JSON.stringify(filterEvent) && localStorageItem !== null) {
      setFilterEvent(JSON.parse(localStorageItem));
    }
  }, [filterEvent]);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(`REPORTS_MONTH_FILTER_DAYS_BD_V1_${user && user.code3c_id}`);
    if (localStorageItem !== JSON.stringify(filterDays) && localStorageItem !== null) {
      setFilterDays(JSON.parse(localStorageItem));
    }
  }, [filterDays]);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(`REPORTS_MONTH_FILTER_OTHERS_BD_V1_${user && user.code3c_id}`);
    if (localStorageItem !== JSON.stringify(filterOthers) && localStorageItem !== null) {
      setFilterOthers(JSON.parse(localStorageItem));
    }
  }, [filterOthers]);

  React.useEffect(() => {
    if (!pendingReadingList && !pendingEquipmentsList) {
      setTimeout(() => {
        setDefaultLoading(false);
      }, 200);
    }
  }, [pendingReadingList, pendingEquipmentsList]);

  return (
    <div className='embroidery-reports-month'>
      <Navbar
        type='BD'
        date={date}
        setDate={setDate}
        typeCalendar='month'
        getDayReadingList={getDayReadingList}
        filterEvent={filterEvent}
        setFilterEvent={setFilterEvent}
        filterEquipment={filterEquipment}
        setFilterEquipment={setFilterEquipment}
        storageEquipmentID={`REPORTS_MONTH_FILTER_EQUIPMENT_BD_V1_${user && user.code3c_id}`}
        storageEventID={`REPORTS_MONTH_FILTER_EVENT_BD_V1_${user && user.code3c_id}`}
        defaultLoading={defaultLoading}
      />
      <div className='p-mt-3'>
        <Header
          uptime={uptime}
          availableTime={availableTime}
          defaultLoading={defaultLoading}
        />
      </div>
      <div className='p-grid flex p-mt-1'>
        <div className='p-col-12 p-md-12 p-lg-12'>
          <MachineHistory
            dayReadingList={dayReadingFiltered}
            date={date}
            defaultLoading={defaultLoading}
          />
        </div>
      </div>
      <div className='p-grid flex p-mt-1'>
        <div className='p-col-12 p-md-12 p-lg-12'>
          <MachinesHistoryArea
            dayReadingList={dayReadingFiltered}
            date={date}
            defaultLoading={defaultLoading}
          />
        </div>
      </div>
      <div className='p-grid p-d-flex p-mt-1'>
        <div className='p-col-12 p-md-6 p-lg-6 p-as-center'>
          <GraphSimple
            uptime={uptime}
            availableTime={availableTime}
            defaultLoading={defaultLoading}
          />
        </div>
        <div className='p-col-12 p-md-6 p-lg-6 p-as-center'>
          <GraphFull
            dayReadingList={dayReadingFiltered}
            type='BD'
            defaultLoading={defaultLoading}
          />
        </div>
      </div>
      <div className='p-grid flex'>
        <div className='p-col-12 p-md-12 p-lg-12'>
          <Legends
            type='BD'
            defaultLoading={defaultLoading}
            white={true}
          />
        </div>
      </div>
      <div className='p-grid flex p-mt-0'>
        <div className='p-col-12 p-md-12 p-lg-12'>
          <TableData
            equipments={equipmentsFilter as IEquipmentBDdata[]}
            dayReadingList={dayReadingFiltered}
            defaultLoading={defaultLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthBD;
