import React, { useCallback, useMemo } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { IReadingData } from 'types/KC-readingMV';

import s from './graphTimeline.module.scss';

interface IProps {
  reading: IReadingData[];
  date: Date;
}

interface IReadingGraph {
  name: string;
  color: string;
  data: IData[];
}

interface IReadingGraphData {
  [string: string]: IReadingGraph;
}

interface IData {
  x: string;
  y: number[];
}

const TimeLineGraph: React.FC<IProps> = ({ reading }) => {
  const series = useMemo(() => {
    const data = reading.reduce((previous, item) => {
      const token = item.status;

      if (item.status === 'available') {
        previous[token] = previous[token] || {
          color: '#fa4443',
          name: 'VAZIO',
          data: [],
        };
        previous[token].data.push({
          x: item.parkingSpace_name,
          y: [item.startDate.toDate().getTime(), item.endDate.toDate().getTime()],
        });
      }
      if (item.status === 'unavailable') {
        previous[token] = previous[token] || {
          color: '#4caf50',
          name: 'CHEIO',
          data: [],
        };

        previous[token].data.push({
          x: item.parkingSpace_name,
          y: [item.startDate.toDate().getTime(), item.endDate.toDate().getTime()],
        });
      }

      return previous;
    }, {} as IReadingGraphData);

    return Object.keys(data).map((item) => data[item]);
  }, [reading]);

  const graph = useCallback(() => {
    const options: ApexOptions = {
      chart: {
        height: 300,
        type: 'rangeBar',
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          rangeBarGroupRows: true,
        },
      },
      xaxis: { type: 'datetime', labels: { datetimeUTC: false } },
      tooltip: { x: { show: true, format: 'HH:mm:ss' } },
      legend: { show: false },
      grid: { padding: { top: 20 } },
    };

    return (
      <Chart
        options={options}
        series={series}
        type='rangeBar'
        height={350}
        width='100%'
      />
    );
  }, [series]);

  return (
    <div className={s.container}>
      <div className={s.title}>Linha do Tempo</div>
      <div className={s.graph}>{graph()}</div>
    </div>
  );
};

export default TimeLineGraph;
