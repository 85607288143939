import * as React from 'react';

import { doc, onSnapshot } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IFrameData } from 'types/SCRUM-frame';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useSearchFrame(sectorID: string | undefined): [IFrameData | null, boolean] {
  const { user } = React.useContext(Auth);

  const [frame, setFrame] = React.useState<IFrameData | null>(null);
  const [pendingFrame, setPendingFrame] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    setPendingFrame(true);

    if (sectorID) {
      const queryConfig = doc(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-frame', sectorID);

      const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
        if (snapshot.exists()) {
          const newUser = snapshot.data() as IFrameData;
          setFrame({ ...newUser, id: snapshot.id });
          setPendingFrame(false);
        }

        setPendingFrame(false);
      });

      return () => unsubscribe();
    }
  }, [sectorID, user]);

  return [frame, pendingFrame];
}
