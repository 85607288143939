import { Timestamp } from 'firebase/firestore';

import { IDefaultParams } from './ALL-DefaultParams';

export interface IFactory extends IDefaultParams {
  code3c_id: string;
  name: string;
  number: string;
  enabled: boolean;
  sector_id: string;
  notes?: string;
}

export interface IFactoryData extends IFactory {
  id: string;
}

export const initialFactory: IFactoryData = {
  id: '',
  code3c_id: '',
  name: '',
  number: '',
  sector_id: '',
  enabled: false,
  updated_at: Timestamp.now(),
  created_at: Timestamp.now(),
};
