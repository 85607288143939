import * as React from 'react';

import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';

import Factory from 'pages/administration/factory/factory';
import Group from 'pages/administration/group/group';
import Sector from 'pages/administration/sector/sector';
import AboutPage from 'pages/default/about/about.page';
import Error404 from 'pages/default/error404/error404';
import Login from 'pages/default/login/login';
import DetailsCR from 'pages/machines/carrossel/details/details';
import DiaryCR from 'pages/machines/carrossel/reports/diary/diary';
import MonthCR from 'pages/machines/carrossel/reports/month/month';
import WeeklyCR from 'pages/machines/carrossel/reports/weekly/weekly';
import YearlyCR from 'pages/machines/carrossel/reports/yearly/yearly';
import DetailsBD from 'pages/machines/embroidery/details/details';
import DiaryBD from 'pages/machines/embroidery/reports/diary/diary';
import MonthBD from 'pages/machines/embroidery/reports/month/month';
import WeeklyBD from 'pages/machines/embroidery/reports/weekly/weekly';
import YearlyBD from 'pages/machines/embroidery/reports/yearly/yearly';
import DetailsGENERIC from 'pages/machines/generic/details/details.page';
import ReportsGENERIC from 'pages/machines/generic/reports/reports.page';
import DetailsIJ from 'pages/machines/injector/details/details.page';
import ReportsINJECTOR from 'pages/machines/injector/reports/reports.page';
import DetailsKanban from 'pages/machines/kanban/details/details.component';
import ReportsKanban from 'pages/machines/kanban/reports/reports.component';
import Distribution from 'pages/machines/scrum/distribution/distribution.page';
import Frame from 'pages/machines/scrum/frame/frame.page';
import Sequence from 'pages/machines/scrum/sequence/sequence';

import Providers from 'contexts/providers.context';

import Header from 'components/navigation/header/header';
import SideBar from 'components/navigation/navbar/navbar';

import { PrivateRoutes } from 'routes/private.routes';

import { ListRoutes } from './listRoutes';

const Router = process.env.REACT_APP_ENVIRONMENT_WORK_TYPE === 'electron' ? HashRouter : BrowserRouter;

const RoutesMain: React.FC = () => {
  const RoutesComponente = () => {
    return (
      <React.Fragment>
        <SideBar />
        <Header />
        <div className='p-grid p-dir-col p-m-0 g-scroll'>
          <Routes>
            {ListRoutes.map((router, index) => (
              <Route
                key={index}
                path={router.path}
                element={
                  <PrivateRoutes
                    element={router.component}
                    style={router.style}
                    title={router.title}
                    path={router.path}
                  />
                }
              />
            ))}
            <Route
              path='/'
              element={<Login />}
            />
            <Route
              path='*'
              element={<Error404 />}
            />
            <Route
              path='/dashboard'
              element={
                <PrivateRoutes
                  element={<AboutPage />}
                  title='Sobre'
                  path='/dashboard'
                  type='sobre'
                />
              }
            />
            <Route
              path='/cartao_digital/:SectorID'
              element={
                <PrivateRoutes
                  element={<Distribution />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/cartao_digital/:SectorID/:FrameID'
              element={
                <PrivateRoutes
                  element={<Frame />}
                  title='Quadro'
                />
              }
            />
            <Route
              path='/cartao_digital/modelos'
              element={
                <PrivateRoutes
                  element={<Sequence />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/carrossel/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsCR />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/bordadeiras/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsBD />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/kanban/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsKanban />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/kanban/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsKanban />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/carrossel/relatorios/diario'
              element={
                <PrivateRoutes
                  element={<DiaryCR />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/carrossel/relatorios/semanal'
              element={
                <PrivateRoutes
                  element={<WeeklyCR />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/carrossel/relatorios/mensal'
              element={
                <PrivateRoutes
                  element={<MonthCR />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/carrossel/relatorios/anual'
              element={
                <PrivateRoutes
                  element={<YearlyCR />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/bordadeiras/relatorios/diario'
              element={
                <PrivateRoutes
                  element={<DiaryBD />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/bordadeiras/relatorios/semanal'
              element={
                <PrivateRoutes
                  element={<WeeklyBD />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/bordadeiras/relatorios/mensal'
              element={
                <PrivateRoutes
                  element={<MonthBD />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/bordadeiras/relatorios/anual'
              element={
                <PrivateRoutes
                  element={<YearlyBD />}
                  title='Relatorios'
                />
              }
            />
            <Route
              path='/admin/fabricas'
              element={
                <PrivateRoutes
                  element={<Factory />}
                  style='white'
                  title='Fábricas'
                />
              }
            />
            <Route
              path='/admin/grupos'
              element={
                <PrivateRoutes
                  element={<Group />}
                  style='white'
                  title='Grupos'
                />
              }
            />
            <Route
              path='/admin/setores'
              element={
                <PrivateRoutes
                  element={<Sector />}
                  style='white'
                  title='Setores'
                />
              }
            />
            {/* // ENFESTADEIRAS */}
            <Route
              path='/enfestadeiras/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsGENERIC settings={{ group: 'EF', type: 'GN', title: 'enfestadeiras' }} />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/enfestadeiras/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsGENERIC settings={{ group: 'EF', type: 'GN', title: 'enfestadeiras' }} />}
                  title='Relátorios'
                />
              }
            />
            {/* // REFILADEIRAS */}
            <Route
              path='/refiladeiras/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsGENERIC settings={{ group: 'RF', type: 'GN', title: 'refiladeiras' }} />}
                  title='Relátorios'
                />
              }
            />
            <Route
              path='/refiladeiras/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsGENERIC settings={{ group: 'RF', type: 'GN', title: 'refiladeiras' }} />}
                  title='Detalhes'
                />
              }
            />
            {/* // MOTORES */}
            <Route
              path='/motores/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsGENERIC settings={{ group: 'MT', type: 'GN', title: 'motores' }} />}
                  title='Relátorios'
                />
              }
            />
            <Route
              path='/motores/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsGENERIC settings={{ group: 'MT', type: 'GN', title: 'motores' }} />}
                  title='Detalhes'
                />
              }
            />
            {/* // CL 1400 */}
            <Route
              path='/cl1400/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsGENERIC settings={{ group: 'CL', type: 'GN', title: 'cl1400' }} />}
                  title='Relátorios'
                />
              }
            />
            <Route
              path='/cl1400/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsGENERIC settings={{ group: 'CL', type: 'GN', title: 'cl1400' }} />}
                  title='Detalhes'
                />
              }
            />
            {/* // MAQUINAS */}
            <Route
              path='/maquinas/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsGENERIC settings={{ group: 'GN', type: 'GN', title: 'maquinas' }} />}
                  title='Relátorios'
                />
              }
            />
            <Route
              path='/maquinas/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsGENERIC settings={{ group: 'GN', type: 'GN', title: 'maquinas' }} />}
                  title='Detalhes'
                />
              }
            />
            {/* // INJETORA */}
            <Route
              path='/injetoras/detalhes/:ID'
              element={
                <PrivateRoutes
                  element={<DetailsIJ settings={{ group: 'IJ', type: 'IJ', title: 'injetoras' }} />}
                  title='Detalhes'
                />
              }
            />
            <Route
              path='/injetoras/relatorios'
              element={
                <PrivateRoutes
                  element={<ReportsINJECTOR settings={{ group: 'IJ', type: 'IJ', title: 'injetoras' }} />}
                  title='Detalhes'
                />
              }
            />
          </Routes>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Providers>
      <Router>{RoutesComponente()}</Router>
    </Providers>
  );
};

export default RoutesMain;
