import React from 'react';

import { IOptionsEventList } from 'types_new/ALL-options';

import s from './legends.module.scss';

interface IProps {
  eventArray: IOptionsEventList[] | undefined;
}

const Legends: React.FC<IProps> = ({ eventArray }) => {
  if (eventArray) {
    return (
      <div className={s.container}>
        {eventArray.map(({ id, name, color }) => (
          <div
            key={id}
            className={s.content}
          >
            <div
              className={s.color}
              style={{ backgroundColor: color }}
            />
            <div className={s.name}>{name}</div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default Legends;
