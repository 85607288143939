import * as React from 'react';
import logoNotNameDark from 'assets/logoNotNameDark.png';
import logoNotNameWhite from 'assets/logoNotNameWhite.png';
import logoWithNameDark from 'assets/logoWithNameDark.png';
import logoWithNameWhite from 'assets/logoWithNameWhite.png';

import { ProgressSpinner } from 'primereact/progressspinner';

import { Themes } from 'contexts/theme.context';

import { useWindowResizeListener } from 'hooks/useWindowResizeListener.hook';

import s from './loading.module.scss';

interface IProps {
  height: string;
}

const Loading: React.FC<IProps> = ({ height }) => {
  const { windowWidth } = useWindowResizeListener();

  const { actualTheme } = React.useContext(Themes);

  return (
    <div
      className={s.container}
      style={{ height: height }}
    >
      {windowWidth && windowWidth < 1280 ? (
        <div className={s.image_small}>
          <img
            src={actualTheme?.type === 'dark' ? logoNotNameDark : logoNotNameWhite}
            alt='Logo do Sistema sem Nome'
            loading='lazy'
            className={s.small}
          />
          <ProgressSpinner className={s.spinner} />
        </div>
      ) : (
        <div className={s.image}>
          <img
            src={actualTheme?.type === 'dark' ? logoWithNameDark : logoWithNameWhite}
            alt='Logo do Sistema com Nome'
            loading='lazy'
            className={s.image}
          />

          <div className={s.title}>
            {process.env.REACT_APP_DESKTOP === 'STAGING' ? 'OEE - STAGING ' : 'OEE - PRODUÇÃO'}
          </div>
          <ProgressSpinner className={s.spinner} />
        </div>
      )}
    </div>
  );
};

export default Loading;
