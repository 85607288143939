import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import BaseCalendar from 'componentes_share_admin/primereact/calendar/calendar.component';
import BaseDropDown from 'componentes_share_admin/primereact/dropdown/dropdown.component';

import s from './navbar.module.scss';

interface IProps {
  id: string | undefined;
  equipments: { id: string; name: string }[];
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  title: string;

  children: React.ReactNode;
}

const Navbar: FC<IProps> = ({ id, equipments, date, setDate, title, children }) => {
  const history = useNavigate();

  const [equipment, setEquipment] = useState({ id: '', name: '' });

  useEffect(() => {
    const equip = equipments.find((equip) => equip.id === id);

    if (equip) setEquipment({ id: equip.id, name: equip.name });
  }, [id, equipments]);

  return (
    <div className={s.container}>
      <div className={s.left}>
        <div className={s.input}>
          <BaseDropDown
            placeholder='Equipamentos'
            optionLabel='name'
            value={equipment}
            options={equipments.map((i) => ({ id: i.id, name: i.name }))}
            onChange={(e) => history(`/${title}/detalhes/${e.value.id}`)}
          />
        </div>
        <div className={s.input}>
          <BaseCalendar
            showIcon
            value={date}
            dateFormat='dd/mm/yy - DD'
            onChange={(e) => setDate(e.value as Date)}
            maxDate={new Date()}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

export default Navbar;
