import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';

import InputText from 'pages/components_prime//inputText';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import ButtonPrime from 'components/primereact/button/button';

import { IValidationError } from 'types/ALL-yup';
import { ISequenceData } from 'types/SCRUM-sequence';

import { addDocumentById, genericFunctionToDelete, getSequenceList } from '../../monitor/controllers/firebase';
import { IShowHideSequence } from '../sequence';
import { sequenceSchema } from '../validations/sequence';

import './managerSequence.scss';

interface IManagerSequence {
  showHideManager: IShowHideSequence;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideSequence>>;
}

const ManagerSequence: React.FC<IManagerSequence> = ({ showHideManager, setShowHideManager }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [model, setModel] = React.useState<string>('');

  const [pending, setPending] = React.useState<boolean>(false);
  const [idAlreadyExists, setIdAlreadyExists] = React.useState<boolean>(false);
  const [showText, setShowText] = React.useState<boolean>(false);

  const [firstClick, setFirstClick] = React.useState<boolean>(false);
  const [firstChange, setFirstChange] = React.useState<boolean>(false);

  const [formErr, setFormErr] = React.useState<IValidationError[]>([]);

  const initialState = React.useCallback(() => {
    setShowHideManager({ type: '', visible: false });

    setModel('');

    setShowText(false);
    setIdAlreadyExists(false);
    setPending(false);
    setFirstClick(false);
    setFirstChange(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ISequenceData> = {
      model: model,
    };

    sequenceSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const response = await addDocumentById(dataValidation, code3c, 'SCRUM-sequence', model);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Nova Sequencia adicionada com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar a Sequencia!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleDelete() {
    setPending(true);

    const code3c = user ? user.code3c_id : '';
    const id = showHideManager.value ? showHideManager.value.id : '';

    const response = await genericFunctionToDelete(id, code3c, 'SCRUM-sequence');
    if (response) {
      initialState();
      setFormErr([]);
      toastSuccess('Sequencia deletada com sucesso!');
    } else {
      setPending(false);
      setFormErr([]);
      toastError('Ops! Algo deu errado ao deletar a Sequencia!');
    }
  }

  const getSequence = React.useCallback(async () => {
    const code3c = user ? user.code3c_id : '';

    const response = await getSequenceList(code3c, model);

    if (response) {
      if (response.length !== 0) {
        setIdAlreadyExists(true);
        setShowText(true);
      } else {
        setIdAlreadyExists(false);
        setShowText(false);
      }
    } else {
      setIdAlreadyExists(true);
    }
  }, [model, user]);

  React.useEffect(() => {
    setIdAlreadyExists(true);

    const delayDebounceFn = setTimeout(getSequence, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [getSequence, model]);

  return (
    <Dialog
      className='default-class-scrum-sequence-manager'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add'
          ? 'ADICIONAR NOVA SEQUENCIA'
          : showHideManager.type === 'delete'
          ? 'DELETAR SEQUENCIA'
          : ''
      }
      footer={
        showHideManager.type === 'add' ? (
          <ButtonPrime
            type='button'
            label='Adicionar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
            severity='success'
            disabled={pending || idAlreadyExists || !firstChange}
            onClick={() => handleAdd()}
          />
        ) : showHideManager.type === 'delete' ? (
          <ButtonPrime
            type='button'
            label='Deletar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-trash'}
            severity='danger'
            disabled={pending}
            onClick={() => handleDelete()}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      {showHideManager.type === 'delete' ? (
        <div className='container-delete'>
          Você está prestes a excluir a sequencia {showHideManager.value ? showHideManager.value.id : '-'}.
          <br /> <br />
          Tem certeza de que deseja continuar? Essa ação é irreversível e todas as informações associadas a esta
          sequencia serão permanentemente apagadas.
        </div>
      ) : showHideManager.type === 'add' || showHideManager.type === 'edit' ? (
        <div className='container'>
          <Fieldset legend='INFORMAÇÕES BÁSICAS'>
            <InputText
              placeholder='Modelo'
              id='model'
              fieldCode='model'
              formErr={formErr}
              firstClick={firstClick}
              value={model}
              onChange={(e) => {
                setModel(e.target.value);
                setFirstChange(true);
              }}
            />
            {showText && <div className='already-exists'>Esse Modelo já foi Utilizado</div>}
          </Fieldset>
        </div>
      ) : (
        <React.Fragment />
      )}
    </Dialog>
  );
};

export default ManagerSequence;
