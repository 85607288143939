import { useContext, useEffect, useState } from 'react';

import { doc, onSnapshot } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { firebaseFirestore } from '../firebase/authentication';

export function useSearchEquipment<T>(ID: string): [T | undefined, boolean] {
  const { user } = useContext(Auth);

  const [equipment, setEquipment] = useState<T>();
  const [pendingEquipment, setPendingEquipment] = useState<boolean>(true);

  useEffect(() => {
    if (!user) return;

    setPendingEquipment(true);

    const unsubscribe = onSnapshot(
      doc(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-machine', ID),
      (snapshot) => {
        if (snapshot.exists()) {
          const equipment = snapshot.data() as T;
          setEquipment({ ...equipment, id: snapshot.id });
        }

        setPendingEquipment(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [ID, user]);

  return [equipment, pendingEquipment];
}
