import * as React from 'react';

import { Checkbox } from 'primereact/checkbox';

import { FaFilter } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import { IEventsName } from 'contexts/events.context';

import ButtonPrime from 'components/primereact/button/button';

import Animation from 'utils/animation/animation';

import { IEventData } from 'types/OEE-event';

import './filter.scss';

interface IProps {
  events: { events: IEventData[]; colores: IEventsName[] };
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  filterEvent: string[];
  setFilterEvent: React.Dispatch<React.SetStateAction<string[]>>;
  cleanFilter: boolean;
  setCleanFilter: React.Dispatch<React.SetStateAction<boolean>>;
  storageEventId: string;
}

const Filter: React.FC<IProps> = ({
  events,
  setShowFilter,
  showFilter,
  filterEvent,
  setFilterEvent,
  cleanFilter,
  setCleanFilter,
  storageEventId,
}) => {
  const [internalFilterEvent, setInternalFilterEvent] = React.useState([] as string[]);

  function applyFilter() {
    setFilterEvent(internalFilterEvent);
    localStorage.setItem(storageEventId, JSON.stringify(internalFilterEvent));
    setShowFilter(false);
  }

  function onChangeFilterEvent(event: string) {
    if (internalFilterEvent.includes(event)) {
      setInternalFilterEvent(internalFilterEvent.filter((filter) => filter !== event));
    } else {
      setInternalFilterEvent([...internalFilterEvent, event]);
    }
  }

  React.useEffect(() => {
    if (cleanFilter === true) {
      setFilterEvent([]);
      setInternalFilterEvent([]);
      setCleanFilter(false);
      localStorage.setItem(storageEventId, JSON.stringify([]));
    }
  }, [cleanFilter]);

  React.useEffect(() => {
    setInternalFilterEvent(filterEvent);
  }, [filterEvent, showFilter]);

  return (
    <Animation
      show={showFilter}
      customClassName={`details-event-filter ${showFilter ? 'transition-fadeIn' : 'transition-fadeOut'}`}
    >
      <div className='p-p-3 container'>
        <div className='p-d-flex p-jc-between'>
          <div className='flex'>
            <div className='icon-bg flex-center-x'>
              <FaFilter className='icon flex-center-x-y' />
            </div>
            <div className='title flex-center-y'>Filtros</div>
          </div>
          <IoMdClose
            className='icon-closed flex-center-x-y'
            onClick={() => setShowFilter(false)}
          />
        </div>
        <div className='p-my-2 p-mx-5 flex-center-x-y p-flex-wrap'>
          <div
            className='flex-center-x-y p-flex-wrap'
            style={{ width: '55rem' }}
          >
            {events.events.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className='p-my-1'
                >
                  <Checkbox
                    value={item.name}
                    checked={internalFilterEvent.filter((filter) => filter === item.name).length !== 0 ? true : false}
                    onChange={(e) => onChangeFilterEvent(e.value)}
                  />
                  <label className='p-m-3 p-checkbox-label'>{item.name}</label>
                </div>
              );
            })}
          </div>
        </div>
        <div className='p-d-flex p-jc-end'>
          <div className='p-mr-3'>
            <ButtonPrime
              onClick={() => setInternalFilterEvent([])}
              severity='warning'
              label='Limpar'
              icon='pi pi-trash'
            />
          </div>
          <div>
            <ButtonPrime
              onClick={() => applyFilter()}
              severity='success'
              label='Aplicar'
              icon='pi pi-check'
            />
          </div>
        </div>
      </div>
    </Animation>
  );
};

export default Filter;
