import * as React from 'react';

import './alertMessages.scss';

interface IProps {
  passError: boolean;
  icon: string;
  message: string;
  style: string;
}

export const AlertMessages: React.FC<IProps> = ({ passError, icon, message, style }) => {
  return (
    <React.Fragment>
      {passError && (
        <div className={style}>
          <div className={`p-mx-2 flex-center-y ${icon}`} />
          <div className='flex-center-y'>{message}</div>
        </div>
      )}
    </React.Fragment>
  );
};
