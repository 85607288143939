import React from 'react';
import LogoClosed from 'assets/company/logoNotName.png';
import LogoExtended from 'assets/company/logoWithName.png';

import { Link } from 'react-router-dom';

import { Auth } from 'contexts/auth.context';
import { Routes } from 'contexts/routes.context';
import { SideMenu } from 'contexts/sideMenu.context';

import Icons from 'components/icons/icons';

import './navbar.scss';

const Navbar: React.FC = () => {
  const { user } = React.useContext(Auth);
  const { titlePath, extender, toggleMenuSize, fullscreen } = React.useContext(SideMenu);

  const { routes } = React.useContext(Routes);

  const validation = !fullscreen && user;

  const newListRoutes = React.useMemo(() => {
    if (user?.userAccessLevel === 'master' || user?.userAccessLevel === 'senior') {
      return routes;
    } else {
      return routes.filter((route) => route.title !== 'Administração');
    }
  }, [routes, user?.userAccessLevel]);

  return (
    <React.Fragment>
      {titlePath !== 'ReadingSend' && validation && (
        <div className='default-navbar-class'>
          <div className={`navbar-background ${!extender && 'closed'}`}>
            <div className={`navbar-img ${!extender && 'closed'}`}>
              {extender ? (
                <img
                  src={LogoExtended}
                  className='navbar-img-open'
                />
              ) : (
                <img
                  src={LogoClosed}
                  className='navbar-img-closed'
                />
              )}
            </div>
            {newListRoutes.map((item) => {
              const path = item.title.toUpperCase() === titlePath?.toUpperCase();
              return (
                <Link
                  key={item.title}
                  to={item.path}
                  className='navbar-no-decoration'
                >
                  <div className={`container-button ${path && 'active'}`}>
                    <div className='container-icon'>
                      {item.svg ? (
                        <item.svg className='svg' />
                      ) : (
                        <Icons
                          className='icon'
                          iconName={item.icon ? item.icon : ''}
                        />
                      )}
                    </div>
                    <div className={`text ${!extender && 'closed'}`}>{item.title}</div>
                  </div>
                </Link>
              );
            })}
          </div>
          {extender && (
            <div
              className='navbar-background-responsive'
              onClick={() => toggleMenuSize()}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Navbar;
