import * as React from 'react';

import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { endOfDay, startOfDay } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IDayReadingDataCR } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../../../../../../firebase/authentication';

export function useDayReadingList(date: Date): [IDayReadingDataCR[], boolean] {
  const { user } = React.useContext(Auth);

  const [dayReadingList, setDayReadingList] = React.useState<IDayReadingDataCR[]>([]);

  const [pendingDayReading, setPendingDayReading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingDayReading(true);

    const startDay = Timestamp.fromDate(startOfDay(date));
    const endDay = Timestamp.fromDate(endOfDay(date));

    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-dayReading'),
        where('date', '>=', startDay),
        where('date', '<=', endDay),
        orderBy('date')
      ),
      (snapshot) => {
        const list: IDayReadingDataCR[] = [];

        if (snapshot.empty) {
          setDayReadingList([]);
          setPendingDayReading(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IDayReadingDataCR);
        });

        setDayReadingList(list);
        setPendingDayReading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [date]);

  return [dayReadingList, pendingDayReading];
}
