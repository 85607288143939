import React from 'react';

import { Tooltip } from 'primereact/tooltip';

import s from './tooltip.module.scss';

interface IProps {
  value: string;
  index: number;
  children: React.ReactNode;
}

const BaseTooltip: React.FC<IProps> = ({ value, index, children }) => {
  return (
    <div className={s.container}>
      <Tooltip
        target={`.custom-target-icon${index}`}
        className={s.tooltip}
      />
      <i
        className={`custom-target-icon${index} p-overlay-badge`}
        data-pr-tooltip={value}
        data-pr-position='right'
        data-pr-at='right+5 top'
        data-pr-my='left center-2'
        onClick={() => navigator.clipboard.writeText(value)}
      >
        {children}
      </i>
    </div>
  );
};

export default BaseTooltip;
