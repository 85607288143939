import React from 'react';

import { InputNumber, InputNumberProps } from 'primereact/inputnumber';

import { catchErrors, catchInvalid, IValidationError } from 'functions/validations';

import s from './inputNumber.module.scss';

interface IInputNumber extends InputNumberProps {
  placeholder: string;

  firstClick?: boolean;

  formErr?: IValidationError[];
  fieldCode?: string;
}

const BaseInputNumber: React.FC<IInputNumber> = ({
  placeholder,
  firstClick,
  formErr,
  fieldCode,
  ...InputNumberProps
}) => {
  const invalid = catchInvalid(formErr ?? [], fieldCode ?? '');

  return (
    <div className={s.container}>
      <span className='p-float-label'>
        <InputNumber
          useGrouping={false}
          id={placeholder}
          className={firstClick ? (invalid ? s.error : s.success) : s.default}
          {...InputNumberProps}
        />
        <label htmlFor={placeholder}>{placeholder}</label>
      </span>
      {catchErrors(formErr ?? [], fieldCode ?? '')}
    </div>
  );
};

export default BaseInputNumber;
