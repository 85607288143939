import * as React from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { Events } from 'contexts/events.context';

import { IDayReadingDataCR } from 'types/OEE-dayReading';

import { millisToTime } from 'functions/millisToTime';

import './chartComplete.scss';

interface IProps {
  dayReadingFiltered: IDayReadingDataCR[];
}

interface IGraphData {
  color: string;
  sumMillis: number;
  name: string;
}

interface IGraphDataDiference {
  [string: string]: IGraphData;
}

const ChartComplete: React.FC<IProps> = ({ dayReadingFiltered }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('CR');

  const series = React.useMemo(() => {
    if (dayReadingFiltered.length === 0)
      return {
        name: ['Parado'],
        color: ['#FA4443'],
        sumMillis: [1],
      };
    const dateDiference = dayReadingFiltered.reduce((previous, item) => {
      const token = item.event_id;
      const color = colores.find((colores) => colores.number === item.event_number)?.color;
      previous[token] = previous[token] || {
        name: item.event_name,
        color: color ? color : '',
        sumMillis: 0,
      };
      previous[token].sumMillis += item.sumMillis;
      return previous;
    }, {} as IGraphDataDiference);

    const data = Object.keys(dateDiference).map((item) => dateDiference[item]);
    const name = data.map((item) => item.name);
    const color = data.map((item) => item.color);
    const sumMillis = data.map((item) => item.sumMillis);

    return { name, color, sumMillis };
  }, [dayReadingFiltered, colores]);

  const options: ApexOptions = {
    series: series.sumMillis.length === 1 ? [1] : series.sumMillis,
    colors: series.color.length === 1 ? ['#FA4443'] : series.color,
    labels: series.name.length === 1 ? ['Parado'] : series.name,
    chart: {
      type: 'donut',
      animations: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (e) => millisToTime(e),
      },
    },
    dataLabels: {
      formatter: (val: number) => {
        return `${val.toFixed(0)}%`;
      },
    },
  };

  const graph = React.useCallback(() => {
    return (
      <Chart
        options={options}
        series={options.series}
        type='donut'
        height={210}
        width='100%'
      />
    );
  }, [series]);

  return (
    <div className='carrossel-monitor-chart-complete'>
      <div className='background'>
        <div className='title'>Status Geral</div>
        {graph()}
      </div>
    </div>
  );
};

export default ChartComplete;
