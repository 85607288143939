import React from 'react';

import { Menu } from 'primereact/menu';

import Icons from 'componentes_share_admin/icons/icons';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { IGroupData } from 'types/ALL-group';
import { IParkingSpaceData } from 'types/KC-ParkingSpace';
import { ISectorData } from 'types/SCRUM-sector';

import { inverterCards } from '../functions/firebase';
import { IShowHideParking } from '../monitor.component';

import Parking from './parking';

import s from './group.module.scss';

interface IGroupProps {
  group: IGroupData;
  setShowHideParking: React.Dispatch<React.SetStateAction<IShowHideParking>>;
  sectorList: ISectorData[];
  parkingSpaceList: IParkingSpaceData[];
}

const Group: React.FC<IGroupProps> = ({ group, setShowHideParking, sectorList, parkingSpaceList }) => {
  const { toastError, toastSuccess } = React.useContext(Toaster);
  const { user } = React.useContext(Auth);

  const menu = React.useRef<Menu>(null);

  const validationParking = parkingSpaceList.filter((item) => item.enabled).length === 2;

  async function inverterCartões() {
    if (user?.code3c_id) {
      const response = await inverterCards(parkingSpaceList[0].id, parkingSpaceList[1].id, user.code3c_id);

      if (response.response) {
        toastSuccess(response.message);
      } else {
        toastError(response.message);
      }
    }
  }

  const items = [{ label: 'Inverter Cartões', icon: 'pi pi-sort-alt', command: () => inverterCartões() }];

  if (validationParking) {
    return (
      <div className={s.container}>
        <div className={s.content}>
          <div className={s.group}>
            <div className={s.title}>
              <span>{group.name}</span>
              <div onClick={(event) => menu.current?.toggle(event)}>
                <Icons iconName='BsThreeDotsVertical' />
              </div>
              <Menu
                model={items}
                popup
                ref={menu}
              />
            </div>
          </div>
          {parkingSpaceList
            .sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')))
            .filter((item) => item.enabled)
            .map((parking, index) => {
              return (
                <Parking
                  key={index}
                  parking={parking}
                  index={index}
                  sectorList={sectorList}
                  setShowHideParking={setShowHideParking}
                />
              );
            })}
        </div>
      </div>
    );
  }

  return <React.Fragment />;
};

export default Group;
