import React from 'react';

import EChartDonut, { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';
import EventAndTime from 'componentes_share_admin/machines/global/eventAndTime/eventAndTime.component';

import { IOptionsEventList } from 'types_new/ALL-options';

import { IEquipmentIJdata } from 'types/OEE-equipment';

import { ListOfHeads } from './listOfHeads.component';

import s from './graphDonut.module.scss';

interface IProps {
  eventMap: { [key: string]: IOptionsEventList };
  eventArray: IOptionsEventList[];

  equipment?: IEquipmentIJdata;
  enhancedReadings: IChartDonut[];
}

export const GraphDonut: React.FC<IProps> = ({ enhancedReadings, eventMap, eventArray, equipment }) => {
  const lastUpdated = equipment?.lastEvent_updated_at ? equipment.lastEvent_updated_at.toDate() : undefined;

  return (
    <div className={s.container}>
      <div className={s.title}>
        {equipment?.status === 'offline' && <div className={s.offline} />}
        {equipment ? equipment.name : 'Status Geral'}
      </div>
      <div className={s.graph}>
        <EventAndTime
          lastEventUpdatedAt={lastUpdated}
          lastEventId={equipment?.lastEvent_number}
          eventMap={eventMap}
        />
        <EChartDonut
          series={enhancedReadings}
          height='12rem'
          width='14.5rem'
        />
      </div>
      <ListOfHeads
        lastEventsStation={equipment?.lastEventsStation ?? null}
        eventArray={eventArray}
      />
    </div>
  );
};
