import React from 'react';

import { useParams } from 'react-router-dom';

import { ColorPicker } from 'primereact/colorpicker';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';

import CheckBox from 'pages/components_prime/checkBox';
import InputText from 'pages/components_prime/inputText';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { DialogFooter } from 'utils/dialog/dialog';

import { IValidationError } from 'types/ALL-yup';
import { IFrameData } from 'types/SCRUM-frame';

import {
  genericFunctionToAdd,
  genericFunctionToDelete,
  genericFunctionToEdit,
} from '../../../monitor/controllers/firebase';
import { IShowHideFrame } from '../../distribution.page';
import { frameSchema } from '../../validations/frame';

import './managerFrame.scss';

interface IManagerSector {
  showHideManager: IShowHideFrame;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideFrame>>;
}

const ManagerFrame: React.FC<IManagerSector> = ({ showHideManager, setShowHideManager }) => {
  const { SectorID } = useParams();

  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [name, setName] = React.useState<string>('');
  const [columns, setColumns] = React.useState([
    { id: Math.floor(Date.now() * Math.random()).toString(36), name: 'Coluna 1', color: '22C55E' },
    { id: Math.floor(Date.now() * Math.random()).toString(36), name: 'Coluna 2', color: '942222' },
  ]);

  const [pending, setPending] = React.useState<boolean>(false);

  const [firstClick, setFirstClick] = React.useState<boolean>(false);

  const [formErr, setFormErr] = React.useState<IValidationError[]>([]);

  const initialState = React.useCallback(() => {
    setShowHideManager({ type: '', visible: false });

    setName('');
    setColumns([
      { id: Math.floor(Date.now() * Math.random()).toString(36), name: 'Coluna 1', color: '22C55E' },
      { id: Math.floor(Date.now() * Math.random()).toString(36), name: 'Coluna 2', color: '942222' },
    ]);

    setPending(false);
    setFirstClick(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IFrameData> = {
      name: name,
      sector_id: SectorID,
      columns: columns,
    };

    frameSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const response = await genericFunctionToAdd(dataValidation, code3c, 'SCRUM-frame');
        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Novo Setor adicionado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar o Setor!');
        }
      })
      .catch((error: IValidationError) => {
        console.log(error);
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IFrameData> = {
      name: name,
      columns: columns,
    };

    frameSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const id = showHideManager.value ? showHideManager.value.id : '';
        const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-frame', id);
        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Quadro Editado com Sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o Quadro!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleDelete() {
    setPending(true);
    const code3c = user ? user.code3c_id : '';
    const id = showHideManager.value ? showHideManager.value.id : '';
    const response = await genericFunctionToDelete(id, code3c, 'SCRUM-frame');
    if (response) {
      initialState();
      setFormErr([]);
      toastSuccess('Quadro deletado com sucesso!');
    } else {
      setPending(false);
      setFormErr([]);
      toastError('Ops! Algo deu errado ao deletar o Quadro!');
    }
  }

  function handleEditName(name: string, propsIndex: number) {
    const data = columns.map((item, index: number) => {
      if (index === propsIndex) {
        return { ...item, name: name };
      }
      return item;
    });

    setColumns(data);
  }

  function handleEditColor(name: string, propsIndex: number) {
    const data = columns.map((item, index: number) => {
      if (index === propsIndex) {
        return { ...item, color: name };
      }
      return item;
    });

    setColumns(data);
  }

  React.useEffect(() => {
    if (showHideManager.value && showHideManager.type === 'edit') {
      setName(showHideManager.value.name);
      setColumns(showHideManager.value.columns);
    }
  }, [showHideManager]);

  return (
    <Dialog
      className='default-class-scrum-manager-sector'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add'
          ? 'ADICIONAR NOVO QUADRO'
          : showHideManager.type === 'edit'
          ? 'EDITAR QUADRO'
          : showHideManager.type === 'delete'
          ? 'DELETAR QUADRO'
          : ''
      }
      footer={
        showHideManager.type === 'add' ? (
          <DialogFooter
            type='add'
            pending={pending}
            initialState={initialState}
            firstChange={true}
            handleFunction={handleAdd}
          />
        ) : showHideManager.type === 'edit' ? (
          <DialogFooter
            type='edit'
            pending={pending}
            initialState={initialState}
            firstChange={true}
            handleFunction={handleEdit}
          />
        ) : showHideManager.type === 'delete' ? (
          <DialogFooter
            type='delete'
            pending={pending}
            initialState={initialState}
            firstChange={true}
            handleFunction={handleDelete}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      {showHideManager.type === 'delete' ? (
        <div className='container-delete'>
          Você está prestes a excluir o Quadro {showHideManager.value ? showHideManager.value.name : '-'}.
          <br /> <br />
          Tem certeza de que deseja continuar? Essa ação é irreversível e todas as informações associadas a este quadro
          serão permanentemente apagadas.
        </div>
      ) : showHideManager.type === 'add' || showHideManager.type === 'edit' ? (
        <div className='container'>
          <Fieldset legend='INFORMAÇÕES BÁSICAS'>
            <InputText
              placeholder='Digite o Nome do Quadro'
              id='name'
              fieldCode='name'
              formErr={formErr}
              firstClick={firstClick}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Fieldset>
          <Fieldset legend='CONFIGURAÇÕES DE COLUNAS'>
            <div className='container-column'>
              <div className='sortable'>
                {columns.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className='input'
                    >
                      <InputText
                        placeholder='Titulo'
                        id='color'
                        fieldCode='color'
                        formErr={formErr}
                        firstClick={firstClick}
                        value={item.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleEditName(event.target.value, index)
                        }
                      />
                      <InputText
                        placeholder='Cor Hexadecimal'
                        id='color'
                        fieldCode='color'
                        formErr={formErr}
                        firstClick={firstClick}
                        value={item.color}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleEditColor(event.target.value, index)
                        }
                      />
                      <ColorPicker
                        className='picker'
                        format='hex'
                        value={item.color}
                        onChange={(e) => handleEditColor(e.value as string, index)}
                      />
                    </div>
                  );
                })}
              </div>
              {/* <Button
                label='Nova Coluna'
                severity='success'
                disabled={true}
                onClick={() =>
                  setColumns(() => [
                    ...columns,
                    {
                      id: Math.floor(Date.now() * Math.random()).toString(36),
                      name: `Coluna ${columns.length + 1}`,
                      color: '000000',
                    },
                  ])
                }
              /> */}
            </div>
          </Fieldset>
          <Fieldset legend='CONFIGURAÇÕES DE LINHAS'>
            <div className='container-line'>
              <CheckBox
                multipleFrames={true}
                setMultipleFrames={setPending}
                description='Linhas Dinâmicas por Grupo'
              />
              <CheckBox
                multipleFrames={false}
                setMultipleFrames={setPending}
                description='Habilitar Linhas Fixas'
              />
            </div>
          </Fieldset>
        </div>
      ) : (
        <React.Fragment />
      )}
    </Dialog>
  );
};

export default ManagerFrame;
