import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';

import { ICardData } from 'types/SCRUM-card';
import { ISectorData } from 'types/SCRUM-sector';
import { ISequenceData } from 'types/SCRUM-sequence';

import { firebaseFirestore } from '../../../../../firebase/authentication';

type typeData = ISectorData | ICardData;

export const genericFunctionToAdd = async (data: Partial<typeData>, code3cId: string, collectionName: string) => {
  const queryConfig = collection(firebaseFirestore, 'ALL-code3c', code3cId, collectionName);

  try {
    await addDoc(queryConfig, {
      ...data,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const genericFunctionToEdit = async (
  data: Partial<typeData>,
  code3cId: string,
  collectionName: string,
  id: string
) => {
  try {
    await updateDoc(doc(firebaseFirestore, 'ALL-code3c', code3cId, collectionName, id), {
      ...data,
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const genericFunctionToDelete = async (id: string, code3cId: string, collectionName: string) => {
  try {
    await deleteDoc(doc(firebaseFirestore, 'ALL-code3c', code3cId, collectionName, id));

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export async function getSequenceList(code3c: string, model: string) {
  try {
    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'SCRUM-sequence'),
      where('model', '==', model)
    );

    const docsSnap = await getDocs(queryConfig);

    const list: ISequenceData[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as ISequenceData));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export const genericFunctionToEditNotCode3c = async (data: Partial<typeData>, collectionName: string, id: string) => {
  try {
    await updateDoc(doc(firebaseFirestore, collectionName, id), {
      ...data,
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const addDocumentById = async (
  data: Partial<typeData>,
  code3cId: string,
  collectionName: string,
  id: string
) => {
  const queryConfig = doc(firebaseFirestore, 'ALL-code3c', code3cId, collectionName, id);

  try {
    await setDoc(queryConfig, {
      ...data,
      created_at: Timestamp.now(),
      updated_at: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};
