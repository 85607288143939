import * as React from 'react';

import BaseButton from 'componentes_share_admin/primereact/button/button.component';
import BaseCalendar from 'componentes_share_admin/primereact/calendar/calendar.component';

import PrimeTabMenu from '../../tabMenu';

import s from './navbar.module.scss';

interface IProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  newDate: Date;
  setNewDate: React.Dispatch<React.SetStateAction<Date>>;
}

const Navbar: React.FC<IProps> = ({ date, setDate, newDate, setNewDate }) => {
  return (
    <div className={s.container}>
      <PrimeTabMenu active={1} />
      <div className={s.buttons}>
        <div>
          <BaseCalendar
            showIcon
            value={newDate}
            dateFormat='dd/mm/yy'
            onChange={(e) => setNewDate(e.value as Date)}
            maxDate={new Date()}
          />
        </div>
        <BaseButton
          label='Pesquisar'
          icon='pi pi-search'
          disabled={newDate === date}
          onClick={() => setDate(newDate)}
        />
      </div>
    </div>
  );
};

export default Navbar;
