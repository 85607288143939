import React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';

import { BsFillPlusCircleFill, BsTrash3 } from 'react-icons/bs';

import InputText from 'pages/components_prime/inputText';

import { Auth } from 'contexts/auth.context';

import { ISequence } from 'types/SCRUM-card';
import { ISectorData } from 'types/SCRUM-sector';

import { getSequenceList } from '../../controllers/firebase';

import './sequence.scss';

interface ISequenceProps {
  sequence: ISequence[] | undefined;
  setSequence: React.Dispatch<React.SetStateAction<ISequence[] | undefined>>;
  sector: ISectorData[];
  model: string;
  idAlreadyExists: boolean;
  setIdAlreadyExists: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sequence: React.FC<ISequenceProps> = ({
  sequence,
  sector,
  setSequence,
  model,
  idAlreadyExists,
  setIdAlreadyExists,
}) => {
  const { user } = React.useContext(Auth);

  const menu = React.useRef<Menu>(null);

  const formatSequence = React.useMemo(() => {
    const data = sequence ? sequence.map((item) => item.sector_id) : [];

    return data;
  }, [sequence]);

  const TempleteMenu = () => {
    const [search, setSearch] = React.useState('');

    return (
      <div className='content'>
        <InputText
          placeholder='Buscar por Setores'
          id='sector'
          fieldCode='sector'
          formErr={[]}
          firstClick={false}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='container-options'>
          {sector
            .filter((item) => !formatSequence.includes(item.id))
            .filter((item) => item.name.toUpperCase().indexOf(search.toUpperCase()) !== -1)
            .map((item, index) => {
              return (
                <div
                  className='item'
                  key={index}
                  onClick={() =>
                    setSequence(sequence ? [...sequence, { id: '', sector_id: item.id, status: 'pending' }] : undefined)
                  }
                >
                  {item.name}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const AddSector = () => {
    return (
      <React.Fragment>
        <div onClick={(e) => menu.current?.toggle(e)}>
          <BsFillPlusCircleFill className='icon' />
        </div>
        <Menu
          model={[{ template: () => <TempleteMenu /> }]}
          popup
          ref={menu}
          className='sector-menu'
        />
      </React.Fragment>
    );
  };

  const getSequence = React.useCallback(async () => {
    const code3c = user ? user.code3c_id : '';

    const response = await getSequenceList(code3c, model);

    if (response) {
      if (response.length !== 0) {
        const [objectResponse] = response;

        const newSequence =
          objectResponse?.sector_ids?.length !== 0 && objectResponse?.sector_ids?.length !== undefined
            ? objectResponse.sector_ids.map((item) => ({ id: '', sector_id: item, status: 'pending' } as ISequence))
            : [];

        setSequence(newSequence);
        setIdAlreadyExists(false);
      } else {
        setSequence([]);
        setIdAlreadyExists(false);
      }
    } else {
      setIdAlreadyExists(false);
    }
  }, [model, setIdAlreadyExists, setSequence, user]);

  React.useEffect(() => {
    setIdAlreadyExists(true);

    const delayDebounceFn = setTimeout(getSequence, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [getSequence, model, setIdAlreadyExists]);

  return (
    <div className='dc-monitor-sequence'>
      {idAlreadyExists === true ? (
        <div className='container-pending'>
          <div className='content'>
            Procurando Sequencia, Por favor, Aguarde...
            <ProgressSpinner className='spinner' />
          </div>
        </div>
      ) : (
        <React.Fragment>
          {sequence?.length === 0 ? (
            <div className='container-empty'>
              <div className='title'>Nenhuma Sequencia foi encontrada para o modelo indicado, crie um agora!</div>
              <div className='alert'>Adicione pelo menos um Setor ao Fluxo do Cartão</div>
              <AddSector />
            </div>
          ) : (
            <div className='container-card'>
              <ReactSortable
                filter='.addImageButtonContainer'
                dragClass='sortableDrag'
                list={sequence as ISequence[]}
                setList={setSequence as React.Dispatch<React.SetStateAction<ISequence[]>>}
                animation={200}
                easing='ease-out'
                className='card'
              >
                {sequence?.map((item, index) => {
                  const findSector = sector.find((sector) => sector.id === item.sector_id);

                  return (
                    <div
                      className='card-item'
                      key={index}
                      style={{ borderBottom: `4px solid #${findSector?.color}` }}
                    >
                      <div className='title'>{findSector?.name}</div>
                      <div className='container-icon'>
                        <BsTrash3
                          className='icon-trash'
                          onClick={() => setSequence(sequence.filter((s, i) => index !== i))}
                        />
                      </div>
                    </div>
                  );
                })}
              </ReactSortable>
              <AddSector />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Sequence;
