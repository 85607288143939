import React, { useContext, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Loading from 'componentes_share_admin/loading/loading.component';
import Filter, { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import Legends from 'componentes_share_admin/machines/global/legends/legends.component';
import Navbar from 'componentes_share_admin/machines/reports/navbar/navbar.component';
import {
  dateAxisLabels,
  optionsReports,
  setAxisValue,
} from 'componentes_share_admin/machines/reports/reports.functions';
import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { useEquipments } from 'pages/machines/generic/reports/hooks/useEquipments.hook';
import { IOptionsReports } from 'pages/machines/kanban/reports/reports.component';

import { Auth } from 'contexts/auth.context';

import GraphBar from './components/bar.component';
import GraphDonut from './components/graphDonut.component';
import GraphMixed from './components/graphMixed.component';

import { useTempletes } from 'hooks/machines/useTemplete.hook';

import { useMonthlySummary } from './hooks/useMonthlySummary';

import { IDayReadingDataIJNew } from 'types/OEE-dayReading';

import { ISettings } from 'routes/listRoutes';

import { navigation } from '../global';

import { generateShiftReadingsOverview } from './reports.function';

import s from './reports.module.scss';

interface IReportsInjectorController {
  settings: ISettings;
}

const ReportsInjectorController: React.FC<IReportsInjectorController> = ({ settings }) => {
  const history = useNavigate();

  const { user } = useContext(Auth);

  const configs = useMemo(() => {
    return { ...settings, code3c_id: user?.code3c_id };
  }, [settings, user?.code3c_id]);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [report, setReport] = useState<IOptionsReports>({ name: 'Por Mês', id: 'month' });

  const { equipments, equipmentsIds, equipmentsLoading } = useEquipments(configs);
  const { eventsMap, eventsList, eventsLoading } = useTempletes(configs);
  const { monthlySummary, monthlySummaryLoading } = useMonthlySummary(equipmentsIds, startDate, endDate, report.id);

  if (eventsLoading || equipmentsLoading || monthlySummaryLoading) {
    return (
      <BaseTabMenu
        index={2}
        menuItems={navigation(configs.title, history)}
      >
        <Loading height='calc(100vh - 8rem)' />
      </BaseTabMenu>
    );
  }

  return (
    <ReportsInjectorComponent
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      report={report}
      setReport={setReport}
      equipments={equipments}
      eventsMap={eventsMap}
      eventsList={eventsList}
      monthlySummary={monthlySummary}
      equipmentsIds={equipmentsIds}
      configs={configs}
    />
  );
};

interface IReportsGenericComponent {
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  endDate: Date;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  report: IOptionsReports;
  setReport: React.Dispatch<React.SetStateAction<IOptionsReports>>;
  equipments: IEquipmentGNdata[];
  eventsMap: { [key: string]: IOptionsEventList };
  eventsList: IOptionsEventList[];
  monthlySummary: IDayReadingDataIJNew[] | null;
  equipmentsIds: string[];
  configs: ISettings;
}

const ReportsInjectorComponent: React.FC<IReportsGenericComponent> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  report,
  setReport,
  equipments,
  eventsMap,
  eventsList,
  monthlySummary,
  equipmentsIds,
  configs,
}) => {
  const history = useNavigate();

  const { user } = React.useContext(Auth);

  const [filters, setFilters] = useState<IFilters>({ equipments: [], events: [], shifts: ['OFF'] });

  const getAxisLabels = useMemo(() => {
    const data = dateAxisLabels(report.id, startDate, endDate, equipmentsIds);

    return data;
  }, [startDate, endDate, equipmentsIds, report.id]);

  const memoizedEquipmentReadings = useMemo(() => {
    return generateShiftReadingsOverview(
      monthlySummary,
      filters,
      report,
      getAxisLabels,
      startDate,
      endDate,
      equipmentsIds,
      eventsMap
    );
  }, [endDate, equipmentsIds, eventsMap, filters, getAxisLabels, monthlySummary, report, startDate]);

  return (
    <BaseTabMenu
      index={2}
      menuItems={navigation(configs.title, history)}
    >
      <div className={s.container}>
        <Navbar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          typeReport={report}
          setTypeReport={setReport}
          optionsReports={optionsReports}
        >
          <Filter
            equipmentsList={equipments}
            eventsList={eventsList}
            filters={filters}
            setFilters={setFilters}
            filterKey={`v1_${user?.code3c_id}_reports_${configs.group}`}
          />
        </Navbar>
        <GraphBar
          eventEnhanced={memoizedEquipmentReadings.aggregatedEvents}
          dateAxisLabels={setAxisValue(getAxisLabels, report.id, startDate, endDate, equipments)}
        />
        <GraphMixed
          eventList={eventsList}
          eventEnhanced={memoizedEquipmentReadings.aggregatedEvents}
        />
        <div className={s.row_graph}>
          <div className={s.graph}>
            <GraphDonut
              eventEnhanced={memoizedEquipmentReadings.aggregatedEvents}
              title='Status Geral'
            />
          </div>
          {equipments
            .filter((i) => !filters.equipments.includes(i.id))
            .map((equip, index) => (
              <div
                key={index}
                className={s.graph}
              >
                <GraphDonut
                  eventEnhanced={memoizedEquipmentReadings.separatedReadings[equip.id]}
                  title={equip.name}
                />
              </div>
            ))}
        </div>
        <Legends eventArray={eventsList.filter((i) => !filters.events.includes(i.id))} />
      </div>
    </BaseTabMenu>
  );
};

export default ReportsInjectorController;
