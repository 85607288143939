import React from 'react';

import * as echarts from 'echarts/core';

import { EChartOption } from 'echarts';
import { PieChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import ReactEChartsCore from 'echarts-for-react/lib/core';

import { secondsToTime } from 'functions/machines/date.functions';

echarts.use([TooltipComponent, GridComponent, PieChart, CanvasRenderer]);

export interface IChartDonut {
  id?: string;
  name: string;
  value: number;
  itemStyle: { color: string };
}

interface IProps {
  series: IChartDonut[];
  height: string;
  width: string;
}

const EChartDonut: React.FC<IProps> = ({ series, height, width }) => {
  const options = {
    tooltip: {
      formatter(params: EChartOption.Tooltip.Format) {
        return `
        <b style="color: ${params.data.itemStyle.color}">${params.name}</b> <br>
        <span>Tempo:</span> <b>${secondsToTime(params.data.value)}</b> <br />
        <span>Porcentagem:</span>: <b>${params.percent}%</b>`;
      },
    },
    grid: {
      width: width,
    },
    series: [
      {
        type: 'pie',
        radius: ['67%', '95%'],
        data: series,
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: 'inner',
              color: 'white',
              fontHeight: 'bold',
              formatter: ({ percent }: { percent: number }) => (percent > 4 ? `${percent.toFixed(0)}%` : ''),
            },
          },
        },
      },
    ],
  } as EChartOption;

  return (
    <ReactEChartsCore
      style={{ width: width, height: height }}
      echarts={echarts}
      option={options}
    />
  );
};

export default EChartDonut;
