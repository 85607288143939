import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IFactoryData } from 'types/ALL-factory';

import { firebaseFirestore } from '../../../../firebase/authentication';

export function useFactory(): [IFactoryData[], boolean] {
  const { user } = React.useContext(Auth);

  const [factoryList, setFactoryList] = React.useState<IFactoryData[]>([]);
  const [pendingFactory, setPendingFactory] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingFactory(true);

    const unsubscribe = onSnapshot(
      query(collection(firebaseFirestore, 'ALL-factory'), where('code3c_id', '==', user.code3c_id), orderBy('name')),
      (snapshot) => {
        const list: IFactoryData[] = [];

        if (snapshot.empty) {
          setFactoryList([]);
          setPendingFactory(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IFactoryData);
        });

        setFactoryList(list);
        setPendingFactory(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user]);

  return [factoryList, pendingFactory];
}
