import * as React from 'react';

import { useResizeListener } from 'primereact/hooks';

interface ResizeListenerHook {
  windowWidth: number;
  windowHeight: number;
}

export const useWindowResizeListener = (): ResizeListenerHook => {
  const [windowWidth, setWindowWidth] = React.useState<number>(0);
  const [windowHeight, setWindowHeight] = React.useState<number>(0);

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      setWindowWidth((event.currentTarget as Window).innerWidth);
      setWindowHeight((event.currentTarget as Window).innerHeight);
    },
  });

  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    bindWindowResizeListener();
    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  return { windowWidth, windowHeight };
};
