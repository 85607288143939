import React, { useCallback, useMemo } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { format } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { IDayReadingCR } from 'types/OEE-dayReading';

import { millisToTime } from 'functions/millisToTime';

import './machinesHistoryArea.scss';

interface IProps {
  dayReadingList: IDayReadingCR[];
  date: Date;
  defaultLoading: boolean;
}

interface IReading {
  [string: string]: IItem;
}

interface IItem {
  date: Date;
  id: string;
  name: string;
  sumMillis: number;
  color: string;
}

interface IReadingGraph {
  [string: string]: IItemGraph;
}
interface IItemGraph {
  data: IGraphData[];
  color: string;
  id: string;
  name: string;
}
interface IGraphData {
  x: Date;
  y: number;
}

const MachinesHistoryArea: React.FC<IProps> = ({ dayReadingList, defaultLoading }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('CR');

  const series = useMemo(() => {
    const daysSum = dayReadingList.reduce((previous, item) => {
      const data =
        item.event_name === 'Funcionando'
          ? { ...item, event_id: '1', event_number: '1' }
          : { ...item, event_name: 'Parada', event_id: '0', event_number: '0' };
      const token = `${format(data.date.toDate(), 'dd/MM/yy')}-${data.event_name}`;
      const color = colores.find((colores) => colores.number === item.event_number)?.color;

      previous[token] =
        previous[token] ||
        ({
          date: data.date.toDate(),
          name: data.event_name,
          color: color ? color : '',
          id: data.event_id,
          sumMillis: 0,
        } as IItem);
      previous[token].sumMillis += data.sumMillis;
      return previous;
    }, {} as IReading);

    const days = Object.keys(daysSum).reduce((previous, item) => {
      const { color, date, id, name, sumMillis } = daysSum[item];

      const token = `${id}`;
      previous[token] =
        previous[token] ||
        ({
          color,
          data: [],
          id,
          name,
        } as IItemGraph);
      previous[token].data.push({ x: date, y: sumMillis });

      return previous;
    }, {} as IReadingGraph);

    return Object.keys(days).map((i) => days[i]);
  }, [dayReadingList, colores]);

  const options: ApexOptions = {
    chart: {
      type: 'area',
      animations: {
        enabled: false,
      },
    },

    yaxis: {
      labels: {
        formatter: (val: number) => {
          return `${Math.floor(val / 1000 / 3600)}H`;
        },
      },
    },

    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      y: {
        formatter: (val: number) => {
          return `${millisToTime(val)}`;
        },
      },
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
    },
    dataLabels: {
      formatter: (val: number) => {
        return `${Math.floor(val / 1000 / 3600)}H`;
      },
    },
  };

  const graph = useCallback(() => {
    return (
      <Chart
        options={options}
        series={series}
        type='area'
        height={300}
        width='100%'
      />
    );
  }, [series]);

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='28rem'
        />
      ) : (
        <div className='carrossel-reports-weekly-history'>
          <div className='title'>Histórico por Semana Completo em Horas</div>
          <div className='graph'>{graph()}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MachinesHistoryArea;
