import React, { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';

import { Timestamp } from 'firebase/firestore';

import { format } from 'date-fns';

import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabMenu } from 'primereact/tabmenu';

import BaseInputNumber from 'componentes_share_admin/primereact/inputNumber/inputNumber.component';

import Button from 'pages/components_prime/button';
import InputText from 'pages/components_prime/inputText';
import { genericFunctionToEdit } from 'pages/machines/scrum/monitor/controllers/firebase';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { ErrorResponse } from 'controllers/equipments';

import { useEquipments } from 'hooks/useEquipments';

import { IValidationError } from 'types/ALL-yup';
import { IEquipmentMVdata } from 'types/OEE-equipment';
import { ICardData, ISequence } from 'types/SCRUM-card';
import { ISectorData } from 'types/SCRUM-sector';

import { getApi } from '../../../../../firebase/authentication';
import ParkingFormat from '../../../scrum/components/parking/parking.component';
import Sequence from '../../../scrum/components/sequence/sequence';
import { useCard } from '../hooks/useCard.hook';
import { IShowHideParking } from '../monitor.component';
import { cardSchema } from '../validations/card.validation';

import HistoricCards from './historicCards';

import './managerParking.scss';

interface IManagerParking {
  showHideManager: IShowHideParking;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideParking>>;
  sectorList: ISectorData[];
}

const ManagerParking: React.FC<IManagerParking> = ({ showHideManager, setShowHideManager, sectorList }) => {
  const { user, accessToken } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const configCard = showHideManager.value ? showHideManager.value.id : '';

  const [equipmentsList, pendingEquipmentsList] = useEquipments<IEquipmentMVdata>('MV');
  const [cardList, pendingCardList] = useCard(configCard);

  const [objectCard] = cardList;

  const [menu, setMenu] = React.useState(0);

  const [model, setModel] = React.useState('');
  const [order, setOrder] = React.useState<number | undefined>(undefined);
  const [sequence, setSequence] = React.useState<ISequence[] | undefined>([]);
  const [clearSequence, setClearSequence] = React.useState(false);

  const [formErr, setFormErr] = React.useState<IValidationError[]>([]);

  const [firstChange, setFirstChange] = React.useState(false);
  const [firstClick, setFirstClick] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState(false);
  const [pendingSearchModel, setPendingSearchModel] = React.useState<boolean>(false);

  const infoCard =
    objectCard && objectCard.quantityReset
      ? `INFORMAÇÕES DO CARTÃO ( Quantidade de Resets: ${objectCard?.quantityReset} )`
      : 'INFORMAÇÕES DO CARTÃO';

  const tabMenuItems = [
    { label: 'Detalhes', command: () => setMenu(0) },
    { label: 'Histórico de Cartões', command: () => setMenu(1) },
  ];

  const initialState = React.useCallback(() => {
    setShowHideManager({ type: '', visible: false });
    setMenu(0);
    setModel('');
    setOrder(undefined);
    setSequence([]);
    setClearSequence(false);
    setFirstChange(false);
    setPending(false);
    setFormErr([]);
    setFirstClick(false);
    setPendingSearchModel(false);
  }, [setShowHideManager]);

  async function handleAddCard() {
    setPending(true);

    const dataValidation: Partial<ICardData> = {
      parkingSpace_id: showHideManager?.value?.id,
    };

    try {
      const api = getApi(accessToken);
      const response = await api.post('/card/create', dataValidation);

      if (response) {
        setPending(false);
        toastSuccess('Cartão Criado com Sucesso!');
      }

      return true;
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        console.log('--', error.response);
        const errorResponse: ErrorResponse = error.response.data;

        toastError(errorResponse.description);
        console.log({ response: false, message: errorResponse.description });
      } else {
        toastError('Não foi possível editar os dados.');
        console.log('Não foi possível editar os dados.');
      }
    }
  }

  async function handleChangeStatus() {
    setPending(true);

    const dataValidation = {
      parkingSpace_id: showHideManager?.value?.id,
      status: showHideManager.value?.status === 'available' ? 'unavailable' : 'available',
    };

    try {
      const api = getApi(accessToken);
      const response = await api.post('/reading/mv', dataValidation);

      if (response) {
        setPending(false);
        toastSuccess('Vaga Editada com Sucesso!');

        setShowHideManager({ type: '', visible: false });
      }

      return true;
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        const errorResponse: ErrorResponse = error.response.data;

        toastError(errorResponse.description);
        console.log({ response: false, message: errorResponse.description });
      } else {
        toastError('Não foi possível editar os dados.');
        console.log('Não foi possível editar os dados.');
      }
    }
  }

  async function handleEditCard() {
    setPending(true);
    setFirstClick(true);

    const dataValidationSimple: Partial<ICardData> = {
      model: model,
      order: order,
      sequence: sequence
        ? sequence?.map((item) => {
            if (item.status === 'notWithMe') {
              return { ...item, distributionEndDate: Timestamp.now(), distributionStartDate: Timestamp.now() };
            }
            if (item.status === 'withMe') {
              return {
                ...item,
                distributionEndDate: Timestamp.now(),
                distributionStartDate: Timestamp.now(),
                frameStartDate: Timestamp.now(),
              };
            }
            if (item.status === 'current') {
              return { ...item, distributionStartDate: Timestamp.now() };
            }
            return item;
          })
        : [],
    };

    const dataValidationAdvanced: Partial<ICardData> = {
      model: model,
      order: order,
      sequence: [],
      distribution: true,
      frameIds: [],
      sectorIds: [],
      quantityReset: objectCard?.quantityReset ? objectCard.quantityReset + 1 : 1,
    };

    const dataValidation = clearSequence ? dataValidationAdvanced : dataValidationSimple;

    cardSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const code3c = user ? user.code3c_id : '';
        const id = objectCard ? objectCard.id : '';
        const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-card', id);

        if (response) {
          setClearSequence(false);
          setPending(false);
          setFirstChange(false);
          setFormErr([]);
          setFirstClick(false);

          toastSuccess('Cartão Editado com Sucesso!');
        } else {
          setPending(false);

          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o Cartão!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleDeleteCard() {
    setPending(true);

    const dataValidation: Partial<ICardData> = {
      filed: true,
      excluded: true,
    };

    const code3c = user ? user.code3c_id : '';
    const id = objectCard ? objectCard.id : '';
    const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-card', id);

    if (response) {
      setPending(false);
      setFirstChange(false);

      toastSuccess('Cartão Deletado com Sucesso!');
    } else {
      setPending(false);

      toastError('Ops! Algo deu errado ao deletar o Cartão!');
    }
  }

  React.useEffect(() => {
    if (showHideManager.value && showHideManager.type === 'edit' && objectCard) {
      setOrder(objectCard.order);
      setModel(objectCard.model);
      setSequence(objectCard.sequence);
    }
  }, [objectCard, showHideManager.type, showHideManager.value]);

  const getModel = useCallback(async () => {
    if (!order) return;

    const api = getApi(accessToken);

    setPendingSearchModel(true);

    try {
      const response = await api.post('/productionScheduling/searchOrder', [String(order)]);

      setModel(response?.data[0]?.produto ? response.data[0].produto : '');
      setPendingSearchModel(false);
    } catch (error) {
      setModel('');
      setPendingSearchModel(false);
    }
  }, [accessToken, order]);

  useEffect(() => {
    if (firstChange) {
      const delayDebounceFn = setTimeout(getModel, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [firstChange, getModel]);

  const offline = React.useMemo(() => {
    const data = equipmentsList.find(
      (item: IEquipmentMVdata) =>
        Object.keys(item.parkingSpace_ids).filter((i) => item.parkingSpace_ids[i] === showHideManager?.value?.id)
          .length > 0
    );
    return data;
  }, [equipmentsList, showHideManager?.value?.id]);

  return (
    <Dialog
      className='dc-kb-mp'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header='INFORMAÇÕES DA VAGA'
    >
      <TabMenu
        model={tabMenuItems}
        className='dc-kb-mp-tabMenu'
      />
      {menu === 0 ? (
        <React.Fragment>
          {pendingCardList || pendingEquipmentsList ? (
            <div className='dc-kb-mp-loading'>
              <ProgressSpinner />
            </div>
          ) : (
            <div className='dc-kb-mp-container'>
              <Fieldset legend='INFORMAÇÕES BÁSICAS'>
                <div className='dc-kb-mp-title'>
                  <ParkingFormat
                    factory={showHideManager.value ? showHideManager.value.factory_id : 'error'}
                    group={showHideManager.value ? showHideManager.value.group_id : 'error'}
                    parking={showHideManager.value ? showHideManager.value.id : 'error'}
                  />
                  {offline?.status === 'offline' && (
                    <div>
                      <Button
                        label={
                          showHideManager.value?.status === 'available'
                            ? 'ATUALIZAR STATUS DA VAGA PARA CHEIO'
                            : 'ATUALIZAR STATUS DA VAGA PARA VAZIO'
                        }
                        severity={showHideManager.value?.status === 'available' ? 'success' : 'danger'}
                        moduleID='kanban-online'
                        permissionID='btn-save-card'
                        pendingProps={pending || pendingSearchModel}
                        onClick={() => handleChangeStatus()}
                      />
                    </div>
                  )}
                </div>
              </Fieldset>
              <div className='dc-kb-mp-container-info'>
                <Fieldset
                  legend={infoCard}
                  className='width100'
                >
                  {objectCard ? (
                    <React.Fragment>
                      <div className='dc-kb-mp-container-actions'>
                        <div className='dc-kb-mp-buttons'>
                          <Button
                            moduleID='kanban-online'
                            permissionID='btn-delete-card'
                            label='EXCLUIR'
                            severity='danger'
                            pendingProps={pending || pendingSearchModel}
                            onClick={() => handleDeleteCard()}
                          />
                          <Button
                            moduleID='kanban-online'
                            permissionID='btn-reset-sequence'
                            pendingProps={pending || pendingSearchModel}
                            label='LIMPAR SEQUENCIA'
                            severity='warning'
                            visible={objectCard.distribution === false}
                            onClick={() => {
                              setSequence([]);
                              setClearSequence(true);
                              setFirstChange(true);
                            }}
                          />
                          <Button
                            moduleID='kanban-online'
                            permissionID='btn-save-card'
                            label='SALVAR ALTERAÇÕES'
                            severity='success'
                            pendingProps={!firstChange || pending || pendingSearchModel}
                            onClick={() => handleEditCard()}
                          />
                        </div>
                      </div>
                      <div className='p-grid flex'>
                        <div className='p-col-12 p-md-6 p-lg-6'>
                          <BaseInputNumber
                            placeholder='Ordem (Números)'
                            id='order'
                            fieldCode='order'
                            formErr={formErr}
                            firstClick={firstClick}
                            value={order}
                            onChange={(e) => {
                              setOrder(e.value as number);
                              setFirstChange(true);
                            }}
                          />
                        </div>
                        <div className='p-col-12 p-md-6 p-lg-6'>
                          <InputText
                            placeholder='Data de Criação'
                            id='date'
                            fieldCode='date'
                            formErr={[]}
                            firstClick={false}
                            value={objectCard ? format(objectCard.created_at.toDate(), 'dd/MM HH:mm') : '-'}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='p-grid flex p-mt-2'>
                        <div className='p-col-12 p-md-12 p-lg-12'>
                          <InputText
                            disabled={pendingSearchModel}
                            placeholder='Modelo'
                            id='model'
                            fieldCode='model'
                            formErr={formErr}
                            firstClick={firstClick}
                            value={model}
                            onChange={(e) => {
                              setModel(e.target.value);
                              setFirstChange(true);
                            }}
                          />
                        </div>
                      </div>
                      {objectCard.distribution || clearSequence ? (
                        <div className='dc-kb-mp-container-empty'>
                          <div className='dc-kb-mp-content'>
                            {clearSequence
                              ? 'RESET REALIZADO COM SUCESSO. O CARTÃO VOLTARA AO ESTADO DE CONFIGURAÇÃO, MANTENDO A DATA DE CRIAÇÃO. NÃO ESQUEÇA DE SALVAR AS CONFIGURAÇÕES PARA CONTINUAR.'
                              : 'AGUARDANDO DEFINIÇÃO DA SEQUENCIA'}
                          </div>
                        </div>
                      ) : (
                        <div className='dc-kb-mp-container-sequence'>
                          <Sequence
                            sectorList={sectorList}
                            sequence={sequence}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <div className='dc-kb-mp-container-add'>
                      {showHideManager.value?.status === 'available' && (
                        <Button
                          moduleID='kanban-online'
                          permissionID='btn-add-card'
                          className='dc-kb-mp-button-add'
                          label='CRIAR CARTÃO'
                          severity='success'
                          onClick={() => handleAddCard()}
                          pendingProps={pending}
                        />
                      )}
                    </div>
                  )}
                </Fieldset>
              </div>
            </div>
          )}
        </React.Fragment>
      ) : (
        <HistoricCards card={showHideManager.value} />
      )}
    </Dialog>
  );
};

export default ManagerParking;
