import React from 'react';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';

import { endOfWeek, format, startOfWeek, subDays } from 'date-fns';

import { BreadCrumb } from 'primereact/breadcrumb';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';

import ButtonPrime from 'components/primereact/button/button';
import CalendarPrime from 'components/primereact/calendar/calendar';
import DropdownPrime from 'components/primereact/dropdown/dropdown';

import { IDayReadingDataCR } from 'types/OEE-dayReading';
import { ITypeOfEquipment } from 'types/OEE-equipment';

import Filter from '../filter/filter';

import './navbar.scss';

interface IProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  type: ITypeOfEquipment;
  typeCalendar: string;
  getDayReadingList?: (date: Date) => void;
  filterEvent: string[];
  setFilterEvent: React.Dispatch<React.SetStateAction<string[]>>;
  filterEquipment: string[];
  setFilterEquipment: React.Dispatch<React.SetStateAction<string[]>>;
  storageEquipmentID: string;
  storageEventID: string;
  defaultLoading: boolean;
  firstDate?: IDayReadingDataCR | undefined;
}

interface IOptionsReports {
  name: string;
  id: string;
}

const Navbar: React.FC<IProps> = ({
  date,
  setDate,
  type,
  typeCalendar,
  getDayReadingList,
  firstDate,
  filterEvent,
  setFilterEvent,
  filterEquipment,
  setFilterEquipment,
  storageEquipmentID,
  storageEventID,
  defaultLoading,
}) => {
  const history = useNavigate();

  const items = [
    {
      label:
        type === 'CR'
          ? 'Carrossel'
          : type === 'CT'
          ? 'Costuras'
          : type === 'BD'
          ? 'Bordadeiras'
          : type === 'IJ'
          ? 'Injetoras'
          : '',
      command: () => {
        history(
          type === 'CR'
            ? '/carrossel'
            : type === 'CT'
            ? '/costuras'
            : type === 'BD'
            ? '/bordadeiras'
            : type === 'IJ'
            ? '/injetoras'
            : ''
        );
      },
    },
    { label: 'Relatórios' },
    {
      label:
        typeCalendar === 'diary'
          ? 'Diário'
          : typeCalendar === 'weekly'
          ? 'Semanal'
          : typeCalendar === 'month'
          ? 'Mensal'
          : 'Anual',
    },
  ];

  const home = {
    icon: 'pi pi-home',
    command: () => {
      history('/dashboard');
    },
  };

  const optionsReports: IOptionsReports[] = [
    { name: 'Diário', id: 'diario' },
    { name: 'Semanal', id: 'semanal' },
    { name: 'Mensal', id: 'mensal' },
    { name: 'Anual', id: 'anual' },
  ];

  const yearList = [
    { name: '2022', code: '2022-01-01T00:00:00' },
    { name: '2023', code: '2023-01-01T00:00:00' },
  ];

  const [showFilter, setShowFilter] = React.useState(false);
  const [cleanFilter, setCleanFilter] = React.useState(false);

  const [newDate, setNewDate] = React.useState<Date>(date);

  const [dates, setDates] = React.useState<Date[] | undefined>([
    subDays(startOfWeek(newDate), -1),
    subDays(endOfWeek(newDate), -1),
  ]);

  const [yearSelected, setYearSelected] = React.useState({ name: format(date, 'yyyy'), code: '2023-01-01T00:00:00' });

  const getWeek = (day: Date | Date[] | undefined) => {
    if (Array.isArray(day)) {
      const days = new Array(15).fill(moment().format('LL'));
      const newDays = days.map((item, index) => {
        if (index < 7) {
          return moment(day[0]).subtract(7 - index, 'd');
        }
        return moment(day[0]).add(index - 7, 'd');
      });

      const week = newDays.filter((nD) => moment(nD).week() === moment(day[0]).week());

      return [subDays(week[0].toDate(), -1), subDays(week[6].toDate(), -1)] as Date[];
    }

    return dates;
  };

  function handleNewDate() {
    setDate(newDate);
  }

  function applySearch() {
    setDate(newDate);
    if (getDayReadingList) {
      getDayReadingList(newDate);
    }
  }

  function handleEditYear(value: DropdownChangeEvent) {
    setYearSelected(value.value);
    setNewDate(new Date(value.value.code));
  }
  return (
    <div className='reports-navbar'>
      {defaultLoading ? (
        <Skeleton className='skeleton' />
      ) : (
        <div className='background'>
          <div className='flex width100'>
            <BreadCrumb
              model={items}
              home={home}
            />
          </div>
          <div className='flex-center-y p-flex-wrap p-mt-3'>
            <div className='width100 flex-center-y p-flex-wrap p-jc-between'>
              <div className='flex-center-y p-flex-wrap g-rp-width100'>
                <div className='button back'>
                  <ButtonPrime
                    severity='success'
                    label='Voltar'
                    icon='pi pi-arrow-left'
                    onClick={() =>
                      history(
                        type === 'CR'
                          ? '/carrossel'
                          : type === 'CT'
                          ? '/costuras'
                          : type === 'BD'
                          ? '/bordadeiras'
                          : type === 'IJ'
                          ? '/injetoras'
                          : ''
                      )
                    }
                  />
                </div>
                <div className='flex-center-y button dropdown'>
                  <DropdownPrime
                    customClassName='width100 input-text-default'
                    placeholder='Selecionar Período'
                    fieldCode='select-carrossel'
                    formErr={[]}
                    value={
                      typeCalendar === 'diary'
                        ? { name: 'Diário', id: 'diario' }
                        : typeCalendar === 'weekly'
                        ? { name: 'Semanal', id: 'semanal' }
                        : typeCalendar === 'month'
                        ? { name: 'Mensal', id: 'mensal' }
                        : { name: 'Anual', id: 'anual' }
                    }
                    onChange={(e) => {
                      history(
                        `${
                          type === 'CR'
                            ? '/carrossel/relatorios/'
                            : type === 'CT'
                            ? '/costuras/relatorios/'
                            : type === 'BD'
                            ? '/bordadeiras/relatorios/'
                            : type === 'IJ'
                            ? '/injetoras/relatorios/'
                            : ''
                        }${e.value.id}`
                      );
                    }}
                    optionLabel='name'
                    options={optionsReports
                      .sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')))
                      .map((item) => {
                        return {
                          name: item.name,
                          id: item.id,
                        };
                      })}
                  />
                </div>
                <div className='flex-center-y button calendar'>
                  {typeCalendar === 'month' ? (
                    <div className='flex-center-y prime-calendar'>
                      <CalendarPrime
                        showIcon
                        value={newDate}
                        onChange={(e) => setNewDate(e.value as Date)}
                        view='month'
                        dateFormat='mm/yy'
                      />
                    </div>
                  ) : typeCalendar === 'weekly' ? (
                    <div className='flex-center-y prime-calendar'>
                      <CalendarPrime
                        showIcon
                        id='range'
                        dateFormat='dd/mm/yy'
                        value={dates}
                        onChange={(e) => {
                          if (Array.isArray(e.value)) {
                            const newValue = getWeek(e.value) || [];
                            e.value[0] = newValue[0];
                            e.value[1] = newValue[1];
                            setDates(newValue);
                            setNewDate(subDays(e.value[1], 2) as Date);
                          }
                        }}
                        selectionMode='range'
                        readOnlyInput
                      />
                    </div>
                  ) : typeCalendar === 'yearly' ? (
                    <div className='flex-center-y dropdown'>
                      <DropdownPrime
                        customClassName='width100 input-text-default'
                        placeholder='Selecione o Ano:'
                        fieldCode='select-carrossel'
                        formErr={[]}
                        optionLabel='name'
                        value={yearSelected}
                        options={yearList}
                        onChange={(e) => {
                          handleEditYear(e);
                        }}
                      />
                    </div>
                  ) : (
                    <div className='flex-center-y prime-calendar'>
                      <CalendarPrime
                        showIcon
                        value={newDate}
                        dateFormat='dd/mm/yy'
                        onChange={(e) => setNewDate(e.value as Date)}
                        maxDate={new Date()}
                        minDate={firstDate ? firstDate.date.toDate() : new Date()}
                      />
                    </div>
                  )}
                </div>
                <div className='flex-center-y button-search'>
                  <ButtonPrime
                    customClassName='system'
                    label='Pesquisar'
                    icon='pi pi-search'
                    disabled={newDate === date || moment(newDate).isValid() === false}
                    onClick={() => (typeCalendar === 'diary' ? handleNewDate() : applySearch())}
                  />
                </div>
              </div>
              <div className='button filter'>
                <ButtonPrime
                  customClassName='system'
                  label='Filtros'
                  icon='pi pi-filter'
                  onClick={() => setShowFilter(true)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Filter
        type={type}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        filterEvent={filterEvent}
        setFilterEvent={setFilterEvent}
        filterEquipment={filterEquipment}
        setFilterEquipment={setFilterEquipment}
        cleanFilter={cleanFilter}
        setCleanFilter={setCleanFilter}
        storageEquipmentID={storageEquipmentID}
        storageEventID={storageEventID}
      />
    </div>
  );
};

export default Navbar;
