import React from 'react';

import { useNavigate } from 'react-router-dom';

import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

interface IProps {
  active: number;
}

const PrimeTabMenu: React.FC<IProps> = ({ active }) => {
  const history = useNavigate();

  const tabMenuItems = [
    { label: 'Inicio', command: () => history('/kanban_online') },
    { label: 'Detalhes', disabled: true },
    { label: 'Relatórios', command: () => history('/kanban/relatorios') },
  ];

  return (
    <BaseTabMenu
      menuItems={tabMenuItems}
      index={active}
    />
  );
};

export default PrimeTabMenu;
