import { IChartDonut } from 'componentes_share_admin/charts/donut/donut.component';

import { IDayReadingIjData } from 'types_new/ALL-dayReading';
import { IOptionsEventList } from 'types_new/ALL-options';

import { IEventIj } from 'types/OEE-equipment';

export interface ICategories {
  categories: string[];
  count: number;
}

export interface IEditEvent {
  visible: boolean;
  item: Partial<IEventIj[]>;
  equipment_id: string;
  key: string;
}

export const initialEditEvent = {
  visible: false,
  item: [],
  equipment_id: '',
  key: '',
};

export function generateShiftReadingsOverview(
  categories: ICategories,
  dayReading: IDayReadingIjData[],
  eventsMap: { [key: string]: IOptionsEventList }
) {
  if (!dayReading) return { separatedReadings: {}, aggregatedEvents: [] };

  const separatedReadings: { [key: string]: IChartDonut[] } = {};

  categories.categories.forEach((category) => {
    const shiftSummary = dayReading.filter((summary) => `Estação ${summary.stationNumber + 1}` === category) || [];

    const aggregatedEventsMap: { [key: string]: IChartDonut } = {};

    shiftSummary.forEach((evt) => {
      evt.data.forEach((i) => {
        const eventName = eventsMap[i.event_id]?.name || 'error';
        const eventValue = i.sumSeconds;
        const eventColor = eventsMap[i.event_id]?.color || 'error';

        if (aggregatedEventsMap[eventName]) {
          aggregatedEventsMap[eventName].value += eventValue;
        } else {
          aggregatedEventsMap[eventName] = {
            name: eventName,
            value: eventValue,
            itemStyle: { color: eventColor },
          };
        }
      });
    });

    separatedReadings[category] = Object.values(aggregatedEventsMap);
  });

  const aggregatedEvents = Object.values(
    Object.values(separatedReadings).reduce<{ [key: string]: IChartDonut }>((acc, shift) => {
      shift.forEach((event) => {
        const { name, value } = event;
        acc[name] = acc[name] ? { ...acc[name], value: acc[name].value + value } : { ...event };
      });
      return acc;
    }, {})
  );

  return { separatedReadings, aggregatedEvents };
}
