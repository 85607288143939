import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Timestamp } from 'firebase/firestore';

import { differenceInSeconds, format } from 'date-fns';

import ParkingFormat from 'pages/machines/scrum/components/parking/parking.component';

import { ICardData } from 'types/SCRUM-card';

import { formatTime } from '../scrum.functions';

import s from './card.module.scss';

export interface IShowHideCard {
  type: string;
  visible: boolean;
  value?: ICardData;
}

interface ICardProps {
  card: ICardData;
  setShowHideCard: React.Dispatch<React.SetStateAction<IShowHideCard>>;
}

const Card: FC<ICardProps> = ({ card, setShowHideCard }) => {
  const [time, setTime] = useState<string>('-');

  useEffect(() => {
    const updateEventTime = () => {
      if (card?.created_at <= Timestamp.now()) {
        const newTime = differenceInSeconds(new Date(), card.created_at.toDate());

        const format = formatTime(newTime);

        setTime(format);
      } else {
        setTime('-');
      }
    };

    updateEventTime();

    const intervalID = setInterval(updateEventTime, 5000);

    return () => clearInterval(intervalID);
  }, [card]);

  const isOlderThanOneHour = useMemo(() => {
    if (!card) return false;

    const secondsDifference = differenceInSeconds(new Date(), card.created_at.toDate());

    return secondsDifference >= 3600;
  }, [card]);

  const container = classNames(s.container, { [s.flashing_red]: isOlderThanOneHour });

  return (
    <div className={container}>
      <span onClick={() => setShowHideCard({ type: 'edit', visible: true, value: card })}>
        <div>
          <ParkingFormat
            factory={card.factory_id}
            group={card.group_id}
            parking={card.parkingSpace_id}
          />
        </div>
        <div>
          <div>Ordem:</div>
          <b>{card.order}</b>
        </div>
        <div>
          <div>Modelo:</div>
          <b className={s.break_word}>{card.model}</b>
        </div>
        <div>
          <div>Criação:</div>
          <b>{format(card.created_at.toDate(), 'dd/MM HH:mm')}</b>
          <div>Tempo:</div>
          <b>{time}</b>
        </div>
      </span>
    </div>
  );
};

export default Card;
