import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import InputTextPrime from 'components/primereact/inputText/inputText';

import { addGeneric, editGeneric } from 'controllers/administration';

import { initialSector, ISectorData } from 'types/ALL-sector';
import { IValidationError } from 'types/ALL-yup';

import { dataSchema } from '../validations/yup';

interface IProps {
  showHideManager: IShowHideManager;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideManager>>;
}

interface IShowHideManager {
  type: string;
  visible: boolean;
  value: ISectorData;
}

const SectorManager: React.FC<IProps> = ({ showHideManager, setShowHideManager }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = useContext(Toaster);

  const [name, setName] = React.useState('');

  const [pending, setPending] = useState<boolean>(false);
  const [firstClick, setFirstClick] = useState<boolean>(false);

  const [formErr, setFormErr] = useState<IValidationError[]>([]);

  const initialState = useCallback(() => {
    setShowHideManager({ ...showHideManager, visible: false, value: initialSector });

    setName('');

    setPending(false);
    setFirstClick(false);
    setFormErr([]);
  }, [setShowHideManager, showHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ISectorData> = {
      code3c_id: user?.code3c_id,
      enabled: true,
      name: name,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addGeneric(dataValidation, 'ALL-sector');

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Novo setor adicionado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar o setor!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ISectorData> = {
      name: name,
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editGeneric(dataValidation, 'ALL-sector', showHideManager.value.id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Setor editado com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o setor!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  useEffect(() => {
    if (showHideManager.type === 'edit') {
      setName(showHideManager.value.name ? showHideManager.value.name : '');
    }
  }, [showHideManager]);

  return (
    <Dialog
      className='default-class-utils-dialog dialog-default-width'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add' ? (
          'ADICIONAR NOVO SETOR'
        ) : showHideManager.type === 'edit' ? (
          'EDITAR SETOR'
        ) : (
          <React.Fragment />
        )
      }
      footer={
        showHideManager.type === 'add' ? (
          <Button
            type='button'
            label='Adicionar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
            severity='success'
            disabled={pending}
            onClick={() => handleAdd()}
          />
        ) : showHideManager.type === 'edit' ? (
          <Button
            type='button'
            label='Editar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'}
            severity='warning'
            disabled={pending}
            onClick={() => handleEdit()}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      <div className='p-p-2'>
        <div className='p-grid flex'>
          <div className='p-col-12 p-md-12 p-lg-12 p-p-2'>
            <InputTextPrime
              disabled={pending}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Nome'
              fieldCode='name'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SectorManager;
