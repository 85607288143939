import * as React from 'react';

import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { startOfDay } from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IDayReadingDataBD } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useDayReadingList(): [Array<IDayReadingDataBD>, boolean] {
  const { user } = React.useContext(Auth);

  const [dayReadingList, setDayReadingList] = React.useState<Array<IDayReadingDataBD>>([]);

  const [pendingDayReading, setPendingDayReading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingDayReading(true);
    const lastDay = startOfDay(Timestamp.now().toDate());

    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-dayReadingBd'),
        where('date', '>=', lastDay),
        orderBy('date')
      ),
      (snapshot) => {
        const list: IDayReadingDataBD[] = [];

        if (snapshot.empty) {
          setDayReadingList([]);
          setPendingDayReading(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IDayReadingDataBD);
        });

        setDayReadingList(list);
        setPendingDayReading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return [dayReadingList, pendingDayReading];
}
