import React from 'react';

import { Auth } from 'contexts/auth.context';

import ListOfCards from './components/card/listOfCards.component';
import ManagerCard from './components/card/managerCard';
import ListOfSectors from './components/sector/listOfSectors.component';
import ManagerSector from './components/sector/managerSector';

import { useSector } from './hooks/useSector';

import { ICardData } from 'types/SCRUM-card';
import { ISectorData } from 'types/SCRUM-sector';

import PrimeTabMenu from '../tabMenu';

import './monitor.scss';

export interface IShowHideSector {
  type: string;
  visible: boolean;
  value?: ISectorData;
}

export interface IShowHideCard {
  type: string;
  visible: boolean;
  value?: ICardData;
}

export interface IShowHideImport {
  type: string;
  visible: boolean;
}

const Monitor: React.FC = () => {
  const { user } = React.useContext(Auth);

  const [sector, pendingSector] = useSector();

  const [showHideSector, setShowHideSector] = React.useState<IShowHideSector>({ type: '', visible: false });
  const [showHideCard, setShowHideCard] = React.useState<IShowHideCard>({ type: '', visible: false });

  const isPermission = React.useMemo(() => {
    const permission = user?.permissions?.['scrum-online']?.['btn-edit-card'];

    return permission?.includes('R');
  }, [user]);

  React.useEffect(() => {
    if (showHideSector.visible || showHideCard.visible) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'scroll';
    }
  }, [showHideCard, showHideSector]);

  return (
    <div className='default-class-scrum-monitor'>
      <PrimeTabMenu active={0} />
      <ManagerCard
        showHideManager={showHideCard}
        setShowHideManager={setShowHideCard}
        sector={sector}
      />
      <ManagerSector
        showHideManager={showHideSector}
        setShowHideManager={setShowHideSector}
      />
      {isPermission && <ListOfCards setShowHideCard={setShowHideCard} />}
      <ListOfSectors
        setShowHideSector={setShowHideSector}
        sector={sector}
        pendingSector={pendingSector}
      />
    </div>
  );
};

export default Monitor;
