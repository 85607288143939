import React, { useCallback } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { Skeleton } from 'primereact/skeleton';

import { millisToTime } from 'functions/millisToTime';

import './graphSimple.scss';

interface IProps {
  availableTime: number;
  uptime: number;
  defaultLoading: boolean;
}

const GraphSimple: React.FC<IProps> = ({ availableTime, uptime, defaultLoading }) => {
  const options: ApexOptions = {
    series: uptime && availableTime ? [uptime, availableTime] : [1],
    colors: uptime && availableTime ? ['#4CAF50', '#FA4443'] : ['#FA4443'],
    chart: {
      type: 'donut',
      animations: {
        enabled: false,
      },
    },
    labels: uptime && availableTime ? ['Funcionando', 'Parado'] : ['Parado'],

    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (e) => millisToTime(e),
      },
    },

    dataLabels: {
      formatter: (val: number) => {
        return `${val.toFixed(0)}%`;
      },
    },
  };

  const graph = useCallback(() => {
    return (
      <Chart
        options={options}
        series={options.series}
        type='donut'
        height={260}
        width='100%'
      />
    );
  }, [availableTime, uptime]);

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='22rem'
        />
      ) : (
        <div className='reports-graph-simple'>
          <div className='title'>Histórico Geral Simplificado</div>
          <div className='graph'>{graph()}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GraphSimple;
