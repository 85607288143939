import * as React from 'react';

import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { IFrameData } from 'types/SCRUM-frame';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useFrame(sectorId: string): [IFrameData[], boolean] {
  const { user } = React.useContext(Auth);

  const [frame, setFrame] = React.useState<IFrameData[]>([]);
  const [pendingFrame, setPendingFrame] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    setPendingFrame(true);

    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-frame'),
      where('sector_id', '==', sectorId),
      orderBy('created_at', 'asc')
    );

    const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
      const data: IFrameData[] = [];

      if (snapshot.empty) {
        setFrame([]);
        setPendingFrame(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        data.push({ ...item.data(), id: item.id } as IFrameData);
      });

      setFrame(data);
      setPendingFrame(false);
    });

    return () => unsubscribe();
  }, [sectorId, user]);

  return [frame, pendingFrame];
}
