import { NavigateFunction } from 'react-router-dom';

export const navigation = (title: string, history: NavigateFunction) => {
  return [
    { label: 'Inicio', icon: 'pi pi-fw pi-home', disabled: false, command: () => history(`/${title}`) },
    { label: 'Equipamento', icon: 'pi pi-fw pi-bookmark', disabled: true },
    {
      label: 'Relatorios',
      icon: 'pi pi-fw pi-calendar',
      disabled: false,
      command: () => history('/injetoras/relatorios'),
    },
  ];
};
