import React, { useCallback, useEffect } from 'react';

import { Timestamp } from 'firebase/firestore';

import { format } from 'date-fns';

import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';

import BaseInputNumber from 'componentes_share_admin/primereact/inputNumber/inputNumber.component';

import Button from 'pages/components_prime/button';
import InputText from 'pages/components_prime/inputText';
import ParkingFormat from 'pages/machines/scrum/components/parking/parking.component';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import { IValidationError } from 'types/ALL-yup';
import { ICardData, ISequence } from 'types/SCRUM-card';
import { ISectorData } from 'types/SCRUM-sector';
import { ISequenceData } from 'types/SCRUM-sequence';

import { getApi } from '../../../../../../firebase/authentication';
import { addDocumentById, genericFunctionToEdit } from '../../controllers/firebase';
import { IShowHideCard } from '../../monitor';
import { cardSchema } from '../../validations/card';

import Sequence from './sequence';

import './managerCard.scss';

interface IManagerCard {
  showHideManager: IShowHideCard;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideCard>>;
  sector: ISectorData[];
}

const ManagerCard: React.FC<IManagerCard> = ({ showHideManager, setShowHideManager, sector }) => {
  const { user, accessToken } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);

  const [order, setOrder] = React.useState<number | undefined>(undefined);
  const [model, setModel] = React.useState<string>('');
  const [sequence, setSequence] = React.useState<ISequence[] | undefined>([]);

  const [pending, setPending] = React.useState<boolean>(false);
  const [pendingSearchModel, setPendingSearchModel] = React.useState<boolean>(false);
  const [idAlreadyExists, setIdAlreadyExists] = React.useState<boolean>(false);

  const [firstClick, setFirstClick] = React.useState<boolean>(false);
  const [firstChange, setFirstChange] = React.useState<boolean>(false);

  const [formErr, setFormErr] = React.useState<IValidationError[]>([]);

  const initialState = React.useCallback(() => {
    setShowHideManager({ type: '', visible: false });

    setOrder(undefined);
    setModel('');
    setSequence([]);

    setPending(false);
    setPendingSearchModel(false);
    setFirstClick(false);
    setFirstChange(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<ICardData> = {
      order: order,
      model: model,
      sectorIds: sequence ? sequence.map((item) => item.sector_id) : [],
      distribution: sequence?.length === 0 ? true : false,
      sequence: sequence?.map((item, index) => {
        return {
          id: '',
          distributionStartDate: index === 0 ? Timestamp.now() : null,
          status: index === 0 ? 'current' : 'pending',
          sector_id: item.sector_id,
        };
      }),
    };

    const code3c = user ? user.code3c_id : '';

    cardSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const id = showHideManager.value ? showHideManager.value.id : '';
        const response = await genericFunctionToEdit(dataValidation, code3c, 'SCRUM-card', id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Card Editado com Sucesso!');

          const dataSequence: Partial<ISequenceData> = {
            model: model,
            sector_ids: sequence ? sequence.map((item) => item.sector_id) : [],
          };

          await addDocumentById(dataSequence, code3c, 'SCRUM-sequence', model);
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar o Card!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  React.useEffect(() => {
    if (showHideManager.value && showHideManager.type === 'edit') {
      setOrder(showHideManager.value.order);
      setModel(showHideManager.value.model);
    }
  }, [showHideManager.type, showHideManager.value]);

  const getModel = useCallback(async () => {
    if (!order) return;

    const api = getApi(accessToken);

    setPendingSearchModel(true);

    try {
      const response = await api.post('/productionScheduling/searchOrder', [String(order)]);

      setModel(response?.data[0]?.produto ? response.data[0].produto : '');
      setPendingSearchModel(false);
    } catch (error) {
      setModel('');
      setPendingSearchModel(false);
    }
  }, [accessToken, order]);

  useEffect(() => {
    if (firstChange) {
      const delayDebounceFn = setTimeout(getModel, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [firstChange, getModel]);

  return (
    <Dialog
      className='dc-monitor-manager-card'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header='CONFIGURAR CARTÃO'
      footer={
        <Button
          moduleID='scrum-online'
          permissionID='btn-edit-card'
          type='button'
          label='Salvar Configurações'
          icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'}
          severity='warning'
          pendingProps={pending || sequence?.length === 0 || pendingSearchModel}
          onClick={() => handleEdit()}
        />
      }
    >
      <div className='container'>
        <Fieldset legend='INFORMAÇÕES BÁSICAS'>
          <div className='title-parking'>
            <ParkingFormat
              factory={showHideManager.value ? showHideManager.value.factory_id : ''}
              group={showHideManager.value ? showHideManager.value.group_id : ''}
              parking={showHideManager.value ? showHideManager.value.parkingSpace_id : ''}
            />
          </div>
          <div className='p-grid flex'>
            <div className='p-col-12 p-md-6 p-lg-6'>
              <BaseInputNumber
                placeholder='Ordem (Números)'
                id='order'
                fieldCode='order'
                formErr={formErr}
                firstClick={firstClick}
                value={order}
                onChange={(e) => {
                  setOrder(e.value as number);
                  setFirstChange(true);
                }}
              />
            </div>
            <div className='p-col-12 p-md-6 p-lg-6'>
              <InputText
                placeholder='Data de Criação'
                id='date'
                fieldCode='date'
                formErr={formErr}
                firstClick={firstClick}
                value={showHideManager.value ? format(showHideManager.value?.created_at.toDate(), 'dd/MM HH:mm') : '-'}
                onChange={(e) => setModel(e.target.value)}
                disabled={true}
              />
            </div>
          </div>
          <div className='p-grid flex p-mt-2'>
            <div className='p-col-12 p-md-12 p-lg-12'>
              <InputText
                placeholder='Modelo'
                id='model'
                fieldCode='model'
                formErr={formErr}
                firstClick={firstClick}
                value={model}
                onChange={(e) => setModel(e.target.value)}
                disabled={pendingSearchModel}
              />
            </div>
          </div>
        </Fieldset>
        <Fieldset legend='CONFIGURAÇÃO DO FLUXO DO CARTÃO'>
          <Sequence
            sequence={sequence}
            setSequence={setSequence}
            sector={sector}
            model={model}
            idAlreadyExists={idAlreadyExists}
            setIdAlreadyExists={setIdAlreadyExists}
          />
        </Fieldset>
      </div>
    </Dialog>
  );
};

export default ManagerCard;
