/**
 * Interface para definir as opções de relatórios disponíveis.
 * Cada opção contém um nome legível para o usuário e um identificador único correspondente.
 */
export interface IReportOption {
  name: string; // Nome legível para o usuário, exibido como opção no UI.
  id: 'day' | 'week' | 'month' | 'year' | 'period'; // Identificador único para cada tipo de relatório.
}

/**
 * Objeto que mapeia números de meses, representados como strings, para suas abreviações em português.
 * Cada chave é uma string de dois caracteres representando o mês (com zero à esquerda para meses de um dígito)
 * e cada valor é a abreviatura de três letras correspondente do mês.
 *
 * @type {{ [key: string]: string }}
 */
export const months: { [key: string]: string } = {
  '01': 'Jan',
  '02': 'Fev',
  '03': 'Mar',
  '04': 'Abr',
  '05': 'Mai',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Ago',
  '09': 'Set',
  '10': 'Out',
  '11': 'Nov',
  '12': 'Dez',
};
