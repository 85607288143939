import React from 'react';

import { TbMoodEmpty } from 'react-icons/tb';

import s from './empty.module.scss';

interface IProps {
  text: string;
}

const Empty: React.FC<IProps> = ({ text }) => {
  return (
    <div className={s.container}>
      <TbMoodEmpty className={s.icon} />
      <div className={s.text}>{text}</div>
      <div className={s.text}>Para mais Informações, Contate um administrador!</div>
    </div>
  );
};

export default Empty;
