import { useContext, useEffect, useState } from 'react';

import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';

import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';

import { Auth } from 'contexts/auth.context';

import { IDayReadingDataIJNew } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useMonthlySummary(
  equipmentsIds: string[],
  startDate: Date,
  endDate: Date,
  report: string
): { monthlySummary: IDayReadingDataIJNew[] | null; monthlySummaryLoading: boolean } {
  const { user } = useContext(Auth);

  const [monthlySummary, setMonthSummary] = useState<IDayReadingDataIJNew[] | null>(null);
  const [monthlySummaryLoading, setMonthlySummaryLoading] = useState<boolean>(true);

  const getSummaryStartDate = (report: string, startDate: Date): Date => {
    switch (report) {
      case 'period':
        return startOfDay(startDate);
      case 'day':
        return startOfDay(startDate);
      case 'week':
        return subDays(startOfWeek(startDate), -1);
      case 'month':
        return startOfMonth(startDate);
      case 'year':
        return startOfYear(startDate);
      default:
        return new Date();
    }
  };

  const getSummaryEndDate = (report: string, startDate: Date, endDate: Date): Date => {
    switch (report) {
      case 'period':
        return endOfDay(endDate);
      case 'day':
        return endOfDay(startDate);
      case 'week':
        return subDays(endOfWeek(startDate), -1);
      case 'month':
        return endOfMonth(startDate);
      case 'year':
        return endOfYear(startDate);
      default:
        return new Date();
    }
  };

  useEffect(() => {
    const startDay = getSummaryStartDate(report, startDate);
    const endDay = getSummaryEndDate(report, startDate, endDate);

    if (!user || !equipmentsIds || equipmentsIds.length === 0) return;

    const fetchEquipments = async () => {
      setMonthlySummaryLoading(true);

      const q = query(
        collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'OEE-dayReadingIj'),
        where('date', '>=', startDay),
        where('date', '<=', endDay),
        where('equipment_id', 'in', equipmentsIds),
        orderBy('date')
      );

      try {
        const querySnapshot = await getDocs(q);
        const data: IDayReadingDataIJNew[] = [];

        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id } as IDayReadingDataIJNew);
        });

        setMonthSummary(data.length > 0 ? data : null);
        setMonthlySummaryLoading(false);
      } catch (error) {
        setMonthSummary(null);
        setMonthlySummaryLoading(false);

        console.error('Erro ao buscar dados de leituras:', error);
      }
    };

    fetchEquipments();
  }, [endDate, equipmentsIds, report, startDate, user]);

  return { monthlySummary, monthlySummaryLoading };
}
