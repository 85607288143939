import { Timestamp } from 'firebase/firestore';

import {
  addMonths,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';

import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { IReportOption, months } from './reports.types';

/**
 * Lista de opções de relatórios que podem ser gerados.
 * Essas opções incluem relatórios diários, semanais, mensais, anuais e por períodos específicos.
 */
export const optionsReports: IReportOption[] = [
  { name: 'Por Período', id: 'period' },
  { name: 'Por Dia', id: 'day' },
  { name: 'Por Semana', id: 'week' },
  { name: 'Por Mês', id: 'month' },
  { name: 'Por Ano', id: 'year' },
];

/**
 * Ajusta os valores dos rótulos do eixo com base no tipo de relatório e no intervalo de datas especificado.
 * Esta função transforma os índices numéricos dos rótulos em nomes de meses quando apropriado.
 *
 * @param labels - Array de índices de rótulos que podem representar meses.
 * @param report - Tipo de relatório que determina como os rótulos são processados.
 * @param start - Data de início do intervalo para determinar o formato de data necessário.
 * @param end - Data de fim do intervalo para determinar o formato de data necessário.
 * @returns - Array de strings com os rótulos ajustados. Se o tipo de relatório é 'period' e
 */
export const setAxisValue = (
  labels: string[],
  report: string,
  start: Date,
  end: Date,
  equipments: IEquipmentGNdata[]
): string[] => {
  switch (report) {
    case 'day':
      return labels.map((i) => {
        const equipment = equipments.find((e) => e.id === i);
        return equipment ? equipment.name : 'error';
      });
    case 'period':
      return getTypeOfDate(start, end) === 'MM' ? labels.map((i) => months[i]) : labels;
    case 'year':
      return labels.map((i) => months[i]);
    default:
      return labels;
  }
};

/**
 * Filtra leituras por datas, retornando verdadeiro se a data corresponde ao formato esperado.
 *
 * @param report - O tipo de relatório.
 * @param labels - Um array de etiquetas de data.
 * @param date - A data da leitura para verificar.
 * @param start - A data de início do intervalo.
 * @param end - A data de término do intervalo.
 * @returns boolean - True se a leitura estiver dentro do formato esperado, false caso contrário.
 */
export const filterReadingsByDate = (report: string, labels: string[], date: Date, start: Date, end: Date): boolean => {
  switch (report) {
    case 'period':
      return labels.includes(format(date, getTypeOfDate(start, end)));
    case 'day':
      return [format(start, 'dd/MM')].includes(format(date, 'dd/MM'));
    case 'week':
      return labels.includes(format(date, 'dd/MM'));
    case 'month':
      return labels.includes(format(date, 'dd/MM'));
    case 'year':
      return labels.includes(format(date, 'MM'));
    default:
      return false;
  }
};

/**
 * Determina o formato de data apropriado baseado no intervalo entre as datas de início e término.
 *
 * @param startDate - Data de início do intervalo.
 * @param endDate - Data de término do intervalo.
 * @returns O formato de data adequado com base na diferença de tempo entre as datas.
 */
function getTypeOfDate(startDate: Date, endDate: Date): string {
  const start = startDate;
  const end = endDate;

  const yearsDifference = end.getFullYear() - start.getFullYear();
  const isSameMonth = start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth();

  switch (true) {
    case isSameMonth:
      return 'dd/MM';
    case yearsDifference === 0:
      return 'MM';
    case yearsDifference >= 1:
      return 'yyyy';
    default:
      return 'error';
  }
}

/**
 * Encontra o índice de uma data ou máquina em uma lista baseada no tipo de relatório.
 *
 * @param labels - Lista de etiquetas para comparação.
 * @param report - Tipo de relatório que define o critério de comparação.
 * @param date - Data a ser avaliada.
 * @param start - Data de início para contextos de comparação.
 * @param end - Data de término para contextos de comparação.
 * @param equipIds - IDs de equipamentos, usados para comparação no contexto 'day'.
 * @param machines - Nome da máquina, usado para comparação no contexto 'day'.
 * @returns Índice na lista baseado no critério de relatório.
 */
export const getAxisIndex = (
  labels: string[],
  report: string,
  date: Timestamp,
  start: Date,
  end: Date,
  equipIds: string[],
  machines: string
) => {
  switch (report) {
    case 'period':
      return labels.indexOf(format(date.toDate(), getTypeOfDate(start, end)));
    case 'day':
      return equipIds.indexOf(machines);
    case 'week':
      return labels.indexOf(format(date.toDate(), 'dd/MM'));
    case 'month':
      return labels.indexOf(format(date.toDate(), 'dd/MM'));
    case 'year':
      return labels.indexOf(format(date.toDate(), 'MM'));
    default:
      return 0;
  }
};

/**
 * Determina o tipo de intervalo de tempo entre duas datas e retorna uma lista formatada com base no intervalo.
 * A função compara as datas de início e fim para determinar se elas estão no mesmo mês, no mesmo ano, ou se diferem por um ou mais anos, e retorna:
 * - Uma lista de todos os dias entre as datas, formatados como 'dd/MM', se as datas estão no mesmo mês.
 * - Uma lista de todos os meses entre as datas, formatados como 'MM', se as datas estão no mesmo ano, mas em meses diferentes.
 * - Uma lista de todos os anos entre as datas, formatados como 'yyyy', se a diferença entre as datas é de um ou mais anos.
 * Se nenhuma das condições acima for atendida, retorna um array vazio.
 *
 * @param startDate - A data de início do intervalo.
 * @param endDate - A data de fim do intervalo.
 * @returns Um array de strings contendo as datas formatadas conforme o intervalo identificado.
 */
function getDateRangeInfo(startDate: Date, endDate: Date): string[] {
  const start = startDate;
  const end = endDate;

  const yearsDifference = end.getFullYear() - start.getFullYear();
  const isSameMonth = start.getFullYear() === end.getFullYear() && start.getMonth() === end.getMonth();

  switch (true) {
    case isSameMonth:
      return eachDayOfInterval({ start, end }).map((day) => format(day, 'dd/MM'));
    case yearsDifference === 0:
      return eachMonthOfInterval({ start, end }).map((month) => format(month, 'MM'));
    case yearsDifference >= 1:
      return eachYearOfInterval({ start, end }).map((year) => format(year, 'yyyy'));
    default:
      return [];
  }
}

/**
 * Gera rótulos para um eixo de data baseado em um identificador específico que determina o tipo de intervalo de tempo.
 * - 'period': Retorna rótulos para cada dia, mês ou ano, dependendo da distância entre as datas fornecidas.
 * - 'day': Retorna uma lista de equipamentos, se fornecida; caso contrário, retorna um array vazio.
 * - 'week': Retorna rótulos para cada dia da semana, começando do domingo, com datas baseadas na data de início fornecida.
 * - 'month': Retorna rótulos para cada mês dentro do mês da data de início fornecida.
 * - 'year': Retorna rótulos para cada mês do ano da data de início fornecida.
 * Se o identificador não corresponder a nenhum caso conhecido, retorna um array vazio.
 *
 * @param id - Identificador do tipo de intervalo de tempo desejado ('period', 'day', 'week', 'month', 'year').
 * @param startDate - A data de início do intervalo para gerar rótulos.
 * @param endDate - A data de fim do intervalo para gerar rótulos.
 * @param equipmentsList - Uma lista opcional de equipamentos usada no caso 'day'.
 * @returns Um array de strings contendo os rótulos de data.
 */
export function dateAxisLabels(id: string, startDate: Date, endDate: Date, equipmentsList?: string[]): string[] {
  switch (id) {
    case 'period':
      return getDateRangeInfo(startDate, endDate);
    case 'day':
      return equipmentsList ?? [];
    case 'week':
      return [
        format(subDays(endOfWeek(startDate), 5), 'dd/MM'),
        format(subDays(endOfWeek(startDate), 4), 'dd/MM'),
        format(subDays(endOfWeek(startDate), 3), 'dd/MM'),
        format(subDays(endOfWeek(startDate), 2), 'dd/MM'),
        format(subDays(endOfWeek(startDate), 1), 'dd/MM'),
        format(subDays(endOfWeek(startDate), 0), 'dd/MM'),
        format(subDays(endOfWeek(startDate), -1), 'dd/MM'),
      ];
    case 'month':
      return getDateRangeInfo(startOfMonth(startDate), endOfMonth(startDate));
    case 'year':
      return getDateRangeInfo(startOfYear(startDate), endOfYear(startDate));
    default:
      return [];
  }
}

/**
 * Calcula a data de início para um resumo com base no tipo de relatório e na data de início fornecida.
 * - 'period': Retorna o primeiro dia do mês da data de início.
 * - 'week': Retorna o primeiro dia do mês, subtraindo um mês da data de início.
 * - 'month': Retorna o primeiro dia do mês da data de início.
 * - 'year': Retorna o primeiro dia do ano da data de início.
 * Se o tipo de relatório não for reconhecido, a função não tem efeito definido.
 *
 * @param report - O tipo de relatório ('period', 'week', 'month', 'year').
 * @param startDate - A data de início para calcular a data de início do resumo.
 * @returns A data de início do resumo calculada com base no tipo de relatório.
 */
export const getSummaryStartDate = (report: string, startDate: Date): Date => {
  switch (report) {
    case 'period':
      return startOfMonth(startDate);
    case 'day':
      return startOfMonth(startDate);
    case 'week':
      return startOfMonth(subMonths(startDate, 1));
    case 'month':
      return startOfMonth(startDate);
    case 'year':
      return startOfYear(startDate);
    default:
      return new Date();
  }
};

/**
 * Calcula a data de término para um resumo com base no tipo de relatório, na data de início e na data de término fornecidas.
 * - 'period': Retorna o último dia do mês da data de término.
 * - 'week': Retorna o último dia do mês, adicionando um mês à data de início.
 * - 'month': Retorna o último dia do mês da data de início.
 * - 'year': Retorna o último dia do ano da data de início.
 * Se o tipo de relatório não for reconhecido, a função não tem efeito definido.
 *
 * @param report - O tipo de relatório ('period', 'week', 'month', 'year').
 * @param startDate - A data de início usada para cálculos em relatórios 'week' e 'year'.
 * @param endDate - A data de término usada para cálculos em relatórios 'period'.
 * @returns A data de término do resumo calculada com base no tipo de relatório.
 */
export const getSummaryEndDate = (report: string, startDate: Date, endDate: Date): Date => {
  switch (report) {
    case 'period':
      return endOfMonth(endDate);
    case 'day':
      return endOfMonth(startDate);
    case 'week':
      return endOfMonth(addMonths(startDate, 1));
    case 'month':
      return endOfMonth(startDate);
    case 'year':
      return endOfYear(startDate);
    default:
      return new Date();
  }
};
