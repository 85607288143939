import { useContext, useEffect, useState } from 'react';

import { collectionGroup, onSnapshot, query, where } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ITypeOfEquipment } from 'types/OEE-equipment';

import { firebaseFirestore } from '../firebase/authentication';

export function useEquipments<T>(type: ITypeOfEquipment): [T[], boolean] {
  const { user } = useContext(Auth);

  const [equipments, setEquipments] = useState<T[]>([]);
  const [pendingEquipments, setPendingEquipments] = useState<boolean>(true);

  useEffect(() => {
    if (!user) return;

    setPendingEquipments(true);
    const unsubscribe = onSnapshot(
      query(
        collectionGroup(firebaseFirestore, 'OEE-machine'),
        where('code3c_id', '==', user.code3c_id),
        where('typeOf', '==', type),
        where('enabled', '==', true)
      ),
      (snapshot) => {
        const list: T[] = [];

        if (snapshot.empty) {
          setEquipments([]);
          setPendingEquipments(false);
          return;
        }
        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as T);
        });

        setEquipments(list);
        setPendingEquipments(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [type, user]);

  return [equipments, pendingEquipments];
}
