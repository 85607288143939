import React from 'react';

import { Checkbox } from 'primereact/checkbox';

import './checkBox.scss';

interface ICheckBox {
  multipleFrames: boolean;
  setMultipleFrames: React.Dispatch<React.SetStateAction<boolean>>;
  description: string;
}

const CheckBox: React.FC<ICheckBox> = ({ multipleFrames, setMultipleFrames, description }) => {
  return (
    <div className='default-class-check-box'>
      <Checkbox
        onChange={(e) => setMultipleFrames(e.checked as boolean)}
        checked={multipleFrames}
        disabled
      />
      <span>{description}</span>
    </div>
  );
};

export default CheckBox;
