import React, { useCallback, useMemo } from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { differenceInDays, endOfMonth, format, getDaysInMonth, subDays } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { IDayReadingCR } from 'types/OEE-dayReading';

import { millisToTime } from 'functions/millisToTime';
import { formatGraphItem } from 'functions/reports';

import './machinesHistoryArea.scss';

interface IProps {
  dayReadingList: IDayReadingCR[];
  defaultLoading: boolean;
  date: Date;
}

interface IReadingGraph {
  [string: string]: IItem;
}

interface IItem {
  name: string;
  data: number[];
  color: string;
}

const MachineHistory: React.FC<IProps> = ({ dayReadingList, date, defaultLoading }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('CR');

  const optionsName = useMemo(() => {
    const count = getDaysInMonth(date);

    const options: string[] = [];
    for (let i = 0; i < count; i++) {
      options.push(format(subDays(endOfMonth(date), count - 1 - i), 'dd/MM'));
    }
    return options;
  }, [date]);

  const series = useMemo(() => {
    const today = endOfMonth(date);

    const days = dayReadingList.reduce((previous, item) => {
      const count = getDaysInMonth(date) - 1;
      const token = count + differenceInDays(item.date.toDate(), today);
      const color = colores.find((colores) => colores.number === item.event_number)?.color;

      previous[item.event_id] = previous[item.event_id] || {
        name: item.event_name,
        color: color ? color : '',
        data: optionsName.map(() => 0),
      };

      previous[item.event_id].data[token] += item.sumMillis;

      return previous;
    }, {} as IReadingGraph);

    return Object.keys(days).map((i) => days[i]);
  }, [dayReadingList, colores]);

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        dataLabels: {
          position: 'center',
        },
      },
    },
    xaxis: {
      categories: optionsName,
      labels: {
        style: {
          fontSize: '10.5px',
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        top: 20,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return `${millisToTime(val)}`;
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: (val) => {
          if (val === 0) {
            return `${String(0)}%`;
          }
          return `${val}%`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 3,
      formatter: (val: number, e) => formatGraphItem(series, e),
    },
  } as ApexOptions;

  const graph = useCallback(() => {
    return (
      <Chart
        options={options}
        series={series}
        type='bar'
        height={300}
        width='100%'
      />
    );
  }, [series]);

  return (
    <React.Fragment>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='28rem'
        />
      ) : (
        <div className='carrossel-reports-month-history'>
          <div className='title'>Histórico por Mes Completo</div>
          <div className='graph'>{graph()}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MachineHistory;
