import React from 'react';

import * as echarts from 'echarts/core';

import { EChartOption } from 'echarts';
import { BarChart, LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import ReactEChartsCore from 'echarts-for-react/lib/core';

import { useWindowResizeListener } from 'hooks/useWindowResizeListener.hook';

import { secondsToTime } from 'functions/millisToTime';

echarts.use([TooltipComponent, GridComponent, BarChart, LineChart, CanvasRenderer]);

interface BarSeriesItemStyle {
  color: string;
  barBorderRadius: number;
}

interface BarSeries {
  value: number;
  percentage: number;
  itemStyle: BarSeriesItemStyle;
}

interface LineSeries {
  x: string;
  y: string;
}

export interface IChartMixed {
  barSeries: BarSeries[];
  lineSeries: LineSeries[];
}

interface IProps {
  series: IChartMixed;
  height: string;
}

const EChartMixed: React.FC<IProps> = ({ series, height }) => {
  const { windowWidth } = useWindowResizeListener();

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' },
      formatter(params: EChartOption.Tooltip.Format[]) {
        return `
        <b style="color: ${params[0].color}">${params[0].name}</b> <br>
        <span>Tempo: <b>${secondsToTime(params[0].value as number)}</b></span> <br>
        <span>Acumulado: <b>${params[1].value}%</b>`;
      },
    },
    grid: {
      left: '1%',
      right: '1%',
      top: '10%',
      bottom: '1%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: series.lineSeries.map((i) => i.x),
        axisLabel: { interval: 0, rotate: windowWidth < 1250 ? 30 : 0 },
      },
    ],
    yAxis: [
      { show: false },
      {
        type: 'value',
        position: 'left',
        alignTicks: true,
        axisLine: { show: true, lineStyle: { color: '#565656' } },
        axisLabel: { formatter: (val: number) => secondsToTime(val) },
        axisPointer: { label: { formatter: (params: { value: number }) => secondsToTime(params.value) } },
      },
      {
        type: 'value',
        position: 'right',
        alignTicks: true,
        axisLine: { show: true, lineStyle: { color: '#942222' } },
        axisLabel: { formatter: '{value}%' },
        axisPointer: { label: { formatter: '{value}%' } },
      },
    ],
    series: [
      {
        name: 'Tempo',
        type: 'bar',
        yAxisIndex: 1,
        data: series.barSeries,
        label: {
          show: true,
          position: 'top',
          backgroundColor: 'white',
          color: 'black',
          borderRadius: 4,
          padding: [5, 5],
          formatter: (params: { data: { percentage: number } }) => `${params.data.percentage.toFixed(2)}%`,
        },
      },
      {
        name: 'Acumulador',
        type: 'line',
        yAxisIndex: 2,
        data: series.lineSeries.map((i) => i.y),
        color: '#942222',
        label: {
          show: true,
          position: 'top',
          backgroundColor: '#942222',
          color: 'white',
          borderRadius: 4,
          padding: [5, 5],
          formatter: '{c}%',
        },
      },
    ],
  } as EChartOption;

  return (
    <ReactEChartsCore
      style={{ width: '100%', height: height }}
      echarts={echarts}
      option={options}
    />
  );
};

export default EChartMixed;
