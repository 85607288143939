import React from 'react';

import { AuthProvider } from './auth.context';
import { ConnectionProvider } from './connection.context';
import { DataProvider } from './data.context';
import { EventsProvider } from './events.context';
import { RoutesProvider } from './routes.context';
import { SideMenuProvider } from './sideMenu.context';
import { ThemesProvider } from './theme.context';
import { ToasterProvider } from './toast.context';

interface IProps {
  children: React.ReactNode;
}

const Providers: React.FC<IProps> = ({ children }) => {
  return (
    <AuthProvider>
      <ThemesProvider>
        <EventsProvider>
          <RoutesProvider>
            <SideMenuProvider>
              <ToasterProvider>
                <ConnectionProvider>
                  <DataProvider>{children}</DataProvider>
                </ConnectionProvider>
              </ToasterProvider>
            </SideMenuProvider>
          </RoutesProvider>
        </EventsProvider>
      </ThemesProvider>
    </AuthProvider>
  );
};

export default Providers;
