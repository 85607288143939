import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';

import { Button } from 'primereact/button';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

import { Auth } from 'contexts/auth.context';
import { Events } from 'contexts/events.context';

import { IReadingDataCR } from 'types/OEE-reading';

import { IShowEditEvent } from '../details';

interface IProps {
  equipmentName: string | undefined;
  readings: IReadingDataCR[];
  defaultLoading: boolean;
  setShowHideEdit?: React.Dispatch<React.SetStateAction<IShowEditEvent>>;
}

const TableEvents: React.FC<IProps> = ({ equipmentName, readings, defaultLoading, setShowHideEdit }) => {
  const { user } = React.useContext(Auth);
  const { getEventsType } = React.useContext(Events);

  const events = getEventsType();

  const StatusBody = (data: IReadingDataCR, dataTable: ColumnBodyOptions) => {
    return <span>{dataTable.rowIndex + 1}</span>;
  };

  const EventBody = (props: { id: string }) => {
    const searchEvent = events.find((item) => item.id === props.id);

    return <div style={{ color: searchEvent?.colour, fontWeight: 'bold' }}>{searchEvent?.name}</div>;
  };

  const StartBody = (data: IReadingDataCR) => {
    return <span>{format(data.startDate.toDate(), 'dd/MM - HH:mm:ss')}</span>;
  };

  const EndBody = (data: IReadingDataCR) => {
    return (
      <span>
        {data.endDate === data.startDate ? 'Em andamento' : format(data.endDate.toDate(), 'dd/MM - HH:mm:ss')}
      </span>
    );
  };

  const TotalBody = (data: IReadingDataCR) => {
    return (
      <span>
        {data.endDate === data.startDate ? '---' : formatDistanceStrict(data.startDate.toDate(), data.endDate.toDate())}
      </span>
    );
  };

  const ActionBody = (data: IReadingDataCR) => {
    return (
      <span>
        {/* {data.event_number !== '99' && data.event_number === '0' && data.endDate !== data.startDate ? (
          <Button
            icon='pi pi-pencil'
            className='p-button-rounded p-button-warning p-mr-2'
            onClick={() => {
              setShowHideEdit && setShowHideEdit({ item: data, visible: true });
            }}
            disabled={user?.userAccessLevel !== 'master'}
          />
        ) : (
          <React.Fragment>-</React.Fragment>
        )} */}
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-warning p-mr-2'
          onClick={() => {
            setShowHideEdit && setShowHideEdit({ item: data, visible: true });
          }}
          disabled={user?.userAccessLevel !== 'master'}
        />
      </span>
    );
  };

  const ResponsiveBody = (data: IReadingDataCR, dataTable: ColumnBodyOptions) => {
    const searchEvent = events.find((item) => item.id === data.event_id);

    return (
      <div>
        <div>
          Índice: <b>{dataTable.rowIndex + 1}</b>
        </div>
        <div>
          Evento: <span style={{ color: searchEvent?.colour, fontWeight: 'bold' }}>{searchEvent?.name}</span>
        </div>
        <div>
          Inicio: <b>{format(data.startDate.toDate(), 'dd/MM - HH:mm:ss')}</b>
        </div>
        <div>
          Fim:{' '}
          <b>{data.endDate === data.startDate ? 'Em andamento' : format(data.endDate.toDate(), 'dd/MM - HH:mm:ss')}</b>
        </div>
        <div>
          Total:{' '}
          <b>
            {data.endDate === data.startDate
              ? '---'
              : formatDistanceStrict(data.startDate.toDate(), data.endDate.toDate())}
          </b>
        </div>
      </div>
    );
  };

  return (
    <div className='default-class-table-events'>
      {defaultLoading ? (
        <Skeleton
          className='skeleton'
          height='35rem'
        />
      ) : (
        <div className='container'>
          <div className='title'>Eventos da Máquina: {equipmentName}</div>
          <div className='table-container'>
            <DataTable
              className='table'
              sortField='index'
              sortOrder={-1}
              stripedRows
              rowHover
              emptyMessage='Nenhum Evento Foi Encontrado.'
              scrollable
              scrollHeight='flex'
              virtualScrollerOptions={{ itemSize: 60, showLoader: true }}
              value={readings}
            >
              <Column
                className='mobile'
                field='index'
                header='Dados'
                body={ResponsiveBody}
              />
              <Column
                className='width-injector web'
                field='index'
                header='Índice'
                body={StatusBody}
              />
              <Column
                className='width-injector web'
                field='event_name'
                header='Status'
                sortable
                body={(rowData) => <EventBody id={rowData.event_id} />}
              />
              <Column
                className='width-injector web'
                field='start'
                header='Inicio'
                body={StartBody}
              />
              <Column
                className='width-injector web'
                field='end'
                header='Fim'
                body={EndBody}
              />
              <Column
                className='width-injector web'
                field='differenceInMilliseconds'
                header='Total'
                body={TotalBody}
                sortable
                sortField='differenceInMilliseconds'
              />
              <Column
                className='width-injector web'
                field='action'
                header='Ação'
                body={ActionBody}
              />
            </DataTable>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableEvents;
