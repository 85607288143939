import * as React from 'react';

import { Button as ButtonPrime, ButtonProps } from 'primereact/button';

import { Auth } from 'contexts/auth.context';

import './button.scss';

interface IProps extends ButtonProps {
  pendingProps: boolean;

  moduleID: string;
  permissionID: string;
}

const Button: React.FC<IProps> = ({ pendingProps, moduleID, permissionID, ...buttonProps }) => {
  const { user } = React.useContext(Auth);

  const isPermission = React.useMemo(() => {
    const permission = user?.permissions?.[moduleID]?.[permissionID];

    return permission?.includes('R');
  }, [moduleID, permissionID, user]);

  if (isPermission) {
    return (
      <ButtonPrime
        {...buttonProps}
        disabled={pendingProps}
      />
    );
  }

  return <React.Fragment />;
};

export default Button;
