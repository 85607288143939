import * as React from 'react';

import { collection, onSnapshot, query } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ICode3cData } from 'types/ALL-code3c';

import { firebaseFirestore } from '../../../../firebase/authentication';

export function useClientsList(): [ICode3cData[], boolean] {
  const { user } = React.useContext(Auth);

  const [clientsList, setClientsList] = React.useState<ICode3cData[]>([]);
  const [pendingClientsList, setPendingClientsList] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!user) return;

    setPendingClientsList(true);

    const unsubscribe = onSnapshot(query(collection(firebaseFirestore, 'ALL-code3c')), (snapshot) => {
      const list: ICode3cData[] = [];

      if (snapshot.empty) {
        setClientsList([]);
        setPendingClientsList(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        list.push({ ...item.data(), id: item.id } as ICode3cData);
      });

      setClientsList(list);
      setPendingClientsList(false);
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  return [clientsList, pendingClientsList];
}
