import React from 'react';

import { Shift } from 'types-node-react/react/type';

import CurrentTime from '../currentTime/currentTime.component';

import { useCurrentShift } from './useCurrentShift.hook';

import s from './currentShift.module.scss';

interface IProps {
  children: React.ReactNode;

  workShift: Shift[] | undefined;
}

const CurrentShift: React.FC<IProps> = ({ children, workShift }) => {
  const { currentShift } = useCurrentShift(workShift);

  return (
    <div className={s.currentTime}>
      <div className={s.left}>
        <div>
          <CurrentTime />
        </div>
        <div>TURNO ATUAL: {currentShift}</div>
      </div>
      {children}
    </div>
  );
};

export default CurrentShift;
