import React from 'react';

import BaseFieldset from 'componentes_share_admin/primereact/fieldset/fieldset.component';

import { Data } from 'contexts/data.context';

import { IFactoryData } from 'types/ALL-factory';
import { ISectorData } from 'types/SCRUM-sector';

import { IShowHideParking } from '../monitor.component';

import Group from './group.component';

import s from './factory.module.scss';

interface IFactoryProps {
  factory: IFactoryData;
  setShowHideParking: React.Dispatch<React.SetStateAction<IShowHideParking>>;
  sectorList: ISectorData[];
}

const Factory: React.FC<IFactoryProps> = ({ factory, setShowHideParking, sectorList }) => {
  const { groupList, parkingSpaceList } = React.useContext(Data);

  const percentage = React.useMemo(() => {
    const filteredParking = parkingSpaceList.filter((item) => item.enabled && item.factory_id === factory.id);
    const total = filteredParking.length;

    const calculateUsage = () => {
      const usage = filteredParking.filter((item) => item.status === 'unavailable').length;

      return (100 * usage) / total;
    };

    return isNaN(total) ? 0 : calculateUsage().toFixed();
  }, [factory.id, parkingSpaceList]);

  return (
    <div className={s.container}>
      <BaseFieldset
        legend={`${factory.name} - ${percentage}%`}
        visible={true}
      >
        <div className={s.group}>
          {groupList
            .sort((a, b) => Number(a.name.replace(/[^\d]+/g, '')) - Number(b.name.replace(/[^\d]+/g, '')))
            .map((group, index) => {
              const parking = parkingSpaceList.filter((p) => p.group_id === group.id && p.factory_id === factory.id);

              return (
                <Group
                  key={String(index)}
                  group={group}
                  setShowHideParking={setShowHideParking}
                  sectorList={sectorList}
                  parkingSpaceList={parking}
                />
              );
            })}
        </div>
      </BaseFieldset>
    </div>
  );
};

export default Factory;
