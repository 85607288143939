import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { Auth } from 'contexts/auth.context';
import { Toaster } from 'contexts/toast.context';

import DropdownPrime from 'components/primereact/dropdown/dropdown';
import InputTextPrime from 'components/primereact/inputText/inputText';
import InputTextareaPrime from 'components/primereact/inputTextarea/inputTextarea';

import { addGeneric, editGeneric } from 'controllers/administration';

import { IFactoryData, initialFactory } from 'types/ALL-factory';
import { ISectorData } from 'types/ALL-sector';
import { IValidationError } from 'types/ALL-yup';

import { dataSchema } from '../validations/yup';

interface IProps {
  showHideManager: IShowHideManager;
  setShowHideManager: React.Dispatch<React.SetStateAction<IShowHideManager>>;
  sectorList: ISectorData[];
}

interface IShowHideManager {
  type: string;
  visible: boolean;
  value: IFactoryData;
}

interface IDropDownType {
  key: string;
  name: string;
}

const FactoryManager: React.FC<IProps> = ({ showHideManager, setShowHideManager, sectorList }) => {
  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = useContext(Toaster);

  const [name, setName] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [notes, setNotes] = React.useState('');

  const [sector, setSector] = React.useState<IDropDownType | undefined>(undefined);

  const [pending, setPending] = useState<boolean>(false);
  const [firstClick, setFirstClick] = useState<boolean>(false);

  const [formErr, setFormErr] = useState<IValidationError[]>([]);

  const initialState = useCallback(() => {
    setShowHideManager({ type: '', visible: false, value: initialFactory });
    setName('');
    setNumber('');
    setPending(false);

    setSector(undefined);

    setFirstClick(false);
    setFormErr([]);
  }, [setShowHideManager]);

  async function handleAdd() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IFactoryData> = {
      code3c_id: user?.code3c_id,
      enabled: true,
      name: name,
      number: number,
      notes: notes,
      sector_id: sector !== undefined ? sector.key : '',
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await addGeneric(dataValidation, 'ALL-factory');

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Nova fábrica adicionada com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao adicionar a fábrica!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  async function handleEdit() {
    setPending(true);
    setFirstClick(true);

    const dataValidation: Partial<IFactoryData> = {
      name: name,
      number: number,
      notes: notes,
      sector_id: sector !== undefined ? sector.key : '',
    };

    dataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        const response = await editGeneric(dataValidation, 'ALL-factory', showHideManager.value.id);

        if (response) {
          initialState();
          setFormErr([]);
          toastSuccess('Fábrica editada com sucesso!');
        } else {
          setPending(false);
          setFormErr([]);
          toastError('Ops! Algo deu errado ao editar a fábrica!');
        }
      })
      .catch((error: IValidationError) => {
        setFormErr(error.inner);
        setPending(false);
      });
  }

  useEffect(() => {
    if (showHideManager.type === 'edit') {
      const getSector = sectorList.find((factory) => factory.id === showHideManager.value.sector_id);
      setSector({ key: getSector?.id ? getSector?.id : '', name: getSector?.name ? getSector?.name : '' });

      setName(showHideManager.value.name ? showHideManager.value.name : '');
      setNumber(showHideManager.value.number ? showHideManager.value.number : '');
      setNotes(showHideManager.value.notes ? showHideManager.value.notes : '');
    }
  }, [sectorList, showHideManager]);

  return (
    <Dialog
      className='default-class-utils-dialog dialog-default-width'
      visible={showHideManager.visible}
      onHide={() => initialState()}
      draggable={false}
      header={
        showHideManager.type === 'add' ? (
          'ADICIONAR NOVA FÁBRICA'
        ) : showHideManager.type === 'edit' ? (
          'EDITAR FÁBRICA'
        ) : (
          <React.Fragment />
        )
      }
      footer={
        showHideManager.type === 'add' ? (
          <Button
            type='button'
            label='Adicionar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
            severity='success'
            disabled={pending}
            onClick={() => handleAdd()}
          />
        ) : showHideManager.type === 'edit' ? (
          <Button
            type='button'
            label='Editar'
            icon={pending ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'}
            severity='warning'
            disabled={pending}
            onClick={() => handleEdit()}
          />
        ) : (
          <React.Fragment />
        )
      }
    >
      <div className='p-p-2'>
        <div className='p-grid flex'>
          <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
            <InputTextPrime
              disabled={pending}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Nome'
              fieldCode='name'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
          <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
            <InputTextPrime
              disabled={pending}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder='Número'
              fieldCode='number'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
          <div className='p-col-12 p-md-12 p-lg-4 p-p-2'>
            <DropdownPrime
              disabled={pending}
              value={sector}
              onChange={(e) => setSector(e.value)}
              optionLabel='name'
              options={sectorList.map((sector) => {
                return { name: sector.name, key: sector.id };
              })}
              placeholder='Setor'
              fieldCode='sector_id'
              formErr={formErr}
              firstClick={firstClick}
            />
          </div>
        </div>
        <div className='p-grid flex p-mt-2'>
          <div className='p-col-12 p-md-12 p-lg-12 p-p-2'>
            <InputTextareaPrime
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder='Observações'
              fieldCode='notes'
              formErr={[]}
              rows={4}
              autoResize={false}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default FactoryManager;
