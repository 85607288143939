import * as React from 'react';

import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';

import { Auth } from 'contexts/auth.context';

import { ISectorData } from 'types/SCRUM-sector';

import { firebaseFirestore } from '../../../../../firebase/authentication';

export function useSector(): [ISectorData[], boolean] {
  const { user } = React.useContext(Auth);

  const [sector, setSector] = React.useState<ISectorData[]>([]);
  const [pendingSector, setPendingSector] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (user === null) return;

    setPendingSector(true);

    const queryConfig = query(
      collection(firebaseFirestore, 'ALL-code3c', user.code3c_id, 'SCRUM-sector'),
      orderBy('created_at', 'asc')
    );

    const unsubscribe = onSnapshot(queryConfig, (snapshot) => {
      const data: ISectorData[] = [];

      if (snapshot.empty) {
        setSector([]);
        setPendingSector(false);
        return;
      }

      snapshot.docs.forEach((item) => {
        data.push({ ...item.data(), id: item.id } as ISectorData);
      });

      setSector(data);
      setPendingSector(false);
    });

    return () => unsubscribe();
  }, [user]);

  return [sector, pendingSector];
}
