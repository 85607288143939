import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  code: yup.string().label('Code').required(),
  factory_id: yup.string().label('Fábrica').required(),
  group_id: yup.string().label('Grupo').required(),
  name: yup.string().label('Nome').required(),
  number: yup.string().label('Numero').required(),
  cell: yup.string().label('Cell').required(),
});
