import React from 'react';

import { addLocale } from 'primereact/api';
import { Calendar, CalendarProps } from 'primereact/calendar';

import s from './calendar.module.scss';

type ICalendarProps = CalendarProps;

const BaseCalendar: React.FC<ICalendarProps> = ({ ...CalendarProps }) => {
  addLocale('ptBr', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar',
  });

  return (
    <Calendar
      className={s.calendar}
      locale='ptBr'
      showIcon
      {...CalendarProps}
    />
  );
};

export default BaseCalendar;
