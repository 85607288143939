import React from 'react';

import { useNavigate } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';

interface IProps {
  active: number;
}

const PrimeTabMenu: React.FC<IProps> = ({ active }) => {
  const history = useNavigate();

  const tabMenuItems = [
    { label: 'Inicio', command: () => history('/cartao_digital') },
    { label: 'Quadros', disabled: true },
    { label: 'Gerenciar Sequencias', command: () => history('/cartao_digital/modelos') },
    { label: 'Relatórios', disabled: true },
  ];

  return (
    <div className='card'>
      <TabMenu
        model={tabMenuItems}
        activeIndex={active}
      />
    </div>
  );
};

export default PrimeTabMenu;
