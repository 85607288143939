import React from 'react';

import { format, formatDistanceStrict } from 'date-fns';

import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { IReadingData } from 'types/KC-readingMV';

import s from './events.module.scss';

interface IProps {
  readings: IReadingData[];
}

const MachineEvents: React.FC<IProps> = ({ readings }) => {
  const indexBodyTemplate = (data: IReadingData, dataTable: ColumnBodyOptions) => {
    return <span>{dataTable.rowIndex + 1}</span>;
  };

  const startBodyTemplate = (data: IReadingData) => {
    return <span>{format(data.startDate.toDate(), 'dd/MM - HH:mm:ss')}</span>;
  };

  const endBodyTemplate = (data: IReadingData) => {
    return (
      <span>
        {data.endDate === data.startDate ? 'Em andamento' : format(data.endDate.toDate(), 'dd/MM - HH:mm:ss')}
      </span>
    );
  };

  const totalBodyTemplate = (data: IReadingData) => {
    return (
      <span>
        {data.endDate === data.startDate ? '---' : formatDistanceStrict(data.startDate.toDate(), data.endDate.toDate())}
      </span>
    );
  };

  const statusBodyTemplate = (data: IReadingData) => {
    return <span>{data.status === 'unavailable' ? 'CHEIO' : 'VAZIO'}</span>;
  };

  return (
    <div className={s.container}>
      <div className={s.title}>Eventos detalhados:</div>
      <div className={s.table}>
        <DataTable
          value={readings}
          emptyMessage='Nenhum Evento Foi Encontrado.'
          sortField='index'
          sortOrder={-1}
        >
          <Column
            field='#'
            header='#'
            body={indexBodyTemplate}
          />
          <Column
            field='parkingSpace_name'
            header='Vaga'
            sortable
          />
          <Column
            field='status'
            header='Status'
            sortable
            body={statusBodyTemplate}
          />
          <Column
            field='start'
            header='Inicio'
            body={startBodyTemplate}
          />
          <Column
            field='end'
            header='Fim'
            body={endBodyTemplate}
          />
          <Column
            field='differenceInMilliseconds'
            header='Total'
            body={totalBodyTemplate}
            sortable
            sortField='differenceInMilliseconds'
          />
        </DataTable>
      </div>
    </div>
  );
};

export default MachineEvents;
