import { Timestamp } from 'firebase/firestore';

import { IDefaultParams } from './ALL-DefaultParams';

export interface IReadingCR extends IDefaultParams {
  equipment_id: string;
  equipment_name: string;
  code3c_id: string;
  event_id: string;
  event_name: string;
  event_number: string;
  startDate: Timestamp;
  endDate: Timestamp;
  scheduled: boolean;
}

export interface IReadingIJ extends IDefaultParams {
  code3c_id: string;
  equipment_id: string;
  equipment_name: string;
  event_id: string;
  event_name: string;
  event_number: string;
  startDate: Timestamp;
  endDate: Timestamp;
  compartmentNumber: number;
  stationNumber: number;
}

export interface IReadingDataCR extends IReadingCR {
  id: string;
}

export interface IReadingDataIJ extends IReadingIJ {
  id: string;
}

export const initialReadingCR: IReadingDataCR = {
  id: '',
  equipment_id: '',
  equipment_name: '',
  code3c_id: '',
  event_id: '',
  event_name: '',
  event_number: '',
  scheduled: false,
  startDate: Timestamp.now(),
  endDate: Timestamp.now(),
  updated_at: Timestamp.now(),
  created_at: Timestamp.now(),
};

export const initialReadingIJ: IReadingDataIJ = {
  id: '',
  equipment_id: '',
  equipment_name: '',
  code3c_id: '',
  event_id: '',
  event_name: '',
  event_number: '',
  startDate: Timestamp.now(),
  endDate: Timestamp.now(),
  compartmentNumber: 0,
  stationNumber: 0,
  updated_at: Timestamp.now(),
  created_at: Timestamp.now(),
};
