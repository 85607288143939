import { collection, getDocs, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear, subDays } from 'date-fns';

import { IDayReadingDataBD, IDayReadingDataCR, IDayReadingDataCT, IDayReadingDataIJNew } from 'types/OEE-dayReading';

import { firebaseFirestore } from '../firebase/authentication';

export async function getDayReadingMonthCR(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfMonth(date));
  const endDay = Timestamp.fromDate(endOfMonth(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReading'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );
    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataCR[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataCR));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingWeekCR(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(subDays(startOfWeek(date), -1));
  const endDay = Timestamp.fromDate(subDays(endOfWeek(date), -1));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReading'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataCR[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataCR));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingYearlyCR(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfYear(date));
  const endDay = Timestamp.fromDate(endOfYear(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReading'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataCR[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataCR));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingMonthCT(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfMonth(date));
  const endDay = Timestamp.fromDate(endOfMonth(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingCt'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );
    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataCT[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataCT));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingWeekCT(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(subDays(startOfWeek(date), -1));
  const endDay = Timestamp.fromDate(subDays(endOfWeek(date), -1));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingCt'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataCT[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataCT));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingYearlyCT(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfYear(date));
  const endDay = Timestamp.fromDate(endOfYear(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingCt'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataCT[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataCT));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingMonthBD(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfMonth(date));
  const endDay = Timestamp.fromDate(endOfMonth(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingBd'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );
    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataBD[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataBD));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingWeekBD(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(subDays(startOfWeek(date), -1));
  const endDay = Timestamp.fromDate(subDays(endOfWeek(date), -1));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingBd'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataBD[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataBD));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingYearlyBD(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfYear(date));
  const endDay = Timestamp.fromDate(endOfYear(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingBd'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataBD[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataBD));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingMonthIJ(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfMonth(date));
  const endDay = Timestamp.fromDate(endOfMonth(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingIj'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );
    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataIJNew[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataIJNew));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingWeekIJ(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(subDays(startOfWeek(date), -1));
  const endDay = Timestamp.fromDate(subDays(endOfWeek(date), -1));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingIj'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataIJNew[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataIJNew));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}

export async function getDayReadingYearlyIJ(date: Date, code3c: string) {
  const startDay = Timestamp.fromDate(startOfYear(date));
  const endDay = Timestamp.fromDate(endOfYear(date));

  try {
    const colRef = query(
      collection(firebaseFirestore, 'ALL-code3c', code3c, 'OEE-dayReadingIj'),
      where('date', '>=', startDay),
      where('date', '<=', endDay),
      orderBy('date')
    );

    const docsSnap = await getDocs(colRef);

    const list: IDayReadingDataIJNew[] = [];

    docsSnap.forEach((doc) => list.push({ ...doc.data(), id: doc.id } as IDayReadingDataIJNew));

    return list;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
}
