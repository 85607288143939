import React from 'react';

import Chart from 'react-apexcharts';

import { ApexOptions } from 'apexcharts';

import { Timestamp } from 'firebase/firestore';

import { differenceInDays, format, subDays } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { Events } from 'contexts/events.context';

import { IDayReadingDataCR } from 'types/OEE-dayReading';

import { millisToTime } from 'functions/millisToTime';

interface IProps {
  dayReadingFiltered: IDayReadingDataCR[];
  date?: Date;
  defaultLoading: boolean;
}

interface IReadingGraph {
  [string: string]: IReading;
}

interface IReading {
  name: string;
  data: number[];
  color: string;
}

const LastSevenDays: React.FC<IProps> = ({ dayReadingFiltered, date, defaultLoading }) => {
  const { getEventsName } = React.useContext(Events);

  const colores = getEventsName('CR');

  const today = Timestamp.now();

  const optionsName = [
    format(subDays(date ? date : today.toDate(), 6), 'dd/MM/yy'),
    format(subDays(date ? date : today.toDate(), 5), 'dd/MM/yy'),
    format(subDays(date ? date : today.toDate(), 4), 'dd/MM/yy'),
    format(subDays(date ? date : today.toDate(), 3), 'dd/MM/yy'),
    format(subDays(date ? date : today.toDate(), 2), 'dd/MM/yy'),
    format(subDays(date ? date : today.toDate(), 1), 'dd/MM/yy'),
    format(subDays(date ? date : today.toDate(), 0), 'dd/MM/yy'),
  ];

  const series = React.useMemo(() => {
    const days = dayReadingFiltered.reduce((previous, item) => {
      const token = 6 + differenceInDays(item.date.toDate(), date ? date : today.toDate());
      const color = colores.find((colores) => colores.number === item.event_number)?.color;

      previous[item.event_id] = previous[item.event_id] || {
        name: item.event_name,
        color: color ? color : '',
        data: [0, 0, 0, 0, 0, 0, 0],
      };

      previous[item.event_id].data[token] += item.sumMillis;

      return previous;
    }, {} as IReadingGraph);

    return Object.keys(days).map((i) => days[i]);
  }, [dayReadingFiltered, date, colores]);

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      animations: {
        enabled: false,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        dataLabels: {
          position: 'center',
        },
      },
    },
    xaxis: {
      categories: optionsName,
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        top: 20,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return `${millisToTime(val)}`;
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: (val) => {
          if (val === 0) {
            return `${String(0)}%`;
          }
          return `${val}%`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: 3,

      formatter: (val: number, e) => {
        const total = series.reduce((previous, item) => {
          return previous + item.data[e.dataPointIndex];
        }, 0);

        if (e.w.globals.series[e.seriesIndex]) {
          return `${(e.w.globals.series[e.seriesIndex][e.dataPointIndex] * (100 / total)).toFixed(0)}%`;
        }

        return `${0}%`;
      },
    },
  };

  const graph = React.useCallback(() => {
    if (series && series.length !== 0) {
      return (
        <Chart
          options={options}
          series={series}
          type='bar'
          width='100%'
          height={350}
        />
      );
    }
  }, [series]);

  return (
    <div className='default-class-last-seven-days'>
      {defaultLoading ? (
        <Skeleton height='28rem' />
      ) : (
        <div className='container'>
          <div className='title'>Histórico dos Últimos 7 dias</div>
          <div className='graph'>{graph()}</div>
        </div>
      )}
    </div>
  );
};

export default LastSevenDays;
