import React, { useContext, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Loading from 'componentes_share_admin/loading/loading.component';
import CurrentShift from 'componentes_share_admin/machines/global/currentShift/currentShift.component';
import { useCurrentShift } from 'componentes_share_admin/machines/global/currentShift/useCurrentShift.hook';
import Filter, { IFilters } from 'componentes_share_admin/machines/global/filter/filter.component';
import Legends from 'componentes_share_admin/machines/global/legends/legends.component';
import BaseTabMenu from 'componentes_share_admin/primereact/tabMenu/tabMenu.component';

import { IOptionsEventList } from 'types_new/ALL-options';
import { IEquipmentGNdata } from 'types_new/OEE-equipments';

import { IAllWorkShiftData, IOeeDayReadingData } from 'types-node-react/react/type';

import { Auth } from 'contexts/auth.context';

import { GraphDonut } from './components/graphDonut.component';

import { useEquipments } from 'hooks/machines/useEquipments.hook';
import { useTempletes } from 'hooks/machines/useTemplete.hook';
import { useWorkShift } from 'hooks/machines/useWorkShift.hook';

import { useDayReading } from './hooks/useDayReading.hook';

import { ISettings } from 'routes/listRoutes';

import { navigation } from '../global';

import { generateShiftReadingsOverview } from './monitor.functions';

import s from './monitor.module.scss';

interface IMonitorGenericController {
  settings: ISettings;
}

const MonitorGenericController: React.FC<IMonitorGenericController> = ({ settings }) => {
  const { user } = useContext(Auth);

  const history = useNavigate();

  const configs = useMemo(() => {
    return { ...settings, code3c_id: user?.code3c_id };
  }, [settings, user?.code3c_id]);

  const { templete, eventsMap, eventsList, eventsLoading } = useTempletes(configs);
  const { equipments, equipmentsId, equipmentsLoading } = useEquipments(configs);
  const { dayReading, loadingDayReading } = useDayReading(equipmentsId);
  const { workShift, loadingWorkShift } = useWorkShift(user?.code3c_id, templete?.workShift_id);

  if (equipmentsLoading || eventsLoading || loadingDayReading || loadingWorkShift) {
    return (
      <BaseTabMenu
        index={0}
        menuItems={navigation(configs.title, history)}
      >
        <Loading height='calc(100vh - 8rem)' />
      </BaseTabMenu>
    );
  }

  return (
    <MonitorGenericComponent
      dayReading={dayReading}
      workShift={workShift}
      equipments={equipments}
      eventsMap={eventsMap}
      configs={configs}
      eventsList={eventsList}
    />
  );
};

interface IMonitorGenericComponent {
  dayReading: IOeeDayReadingData[] | null;
  workShift: IAllWorkShiftData | null;
  equipments: IEquipmentGNdata[];
  eventsMap: { [key: string]: IOptionsEventList };
  configs: ISettings;
  eventsList: IOptionsEventList[];
}

const MonitorGenericComponent: React.FC<IMonitorGenericComponent> = ({
  dayReading,
  workShift,
  equipments,
  eventsMap,
  configs,
  eventsList,
}) => {
  const history = useNavigate();

  const { user } = useContext(Auth);

  const { currentShift } = useCurrentShift(workShift?.shifts);

  const [filters, setFilters] = useState<IFilters>({ equipments: [], events: [], shifts: [] });

  const memoizedEquipmentReadings = useMemo(() => {
    return generateShiftReadingsOverview(dayReading, workShift, equipments, filters, currentShift, eventsMap);
  }, [dayReading, workShift, equipments, filters, currentShift, eventsMap]);

  return (
    <BaseTabMenu
      index={0}
      menuItems={navigation(configs.title, history)}
    >
      <div className={s.container}>
        <CurrentShift workShift={workShift?.shifts}>
          <Filter
            equipmentsList={equipments}
            eventsList={eventsList}
            filters={filters}
            setFilters={setFilters}
            filterKey={`${user?.code3c_id}_monitor_${configs.group}`}
          />
        </CurrentShift>
        <div className={s.content}>
          <GraphDonut
            eventMap={eventsMap}
            enhancedReadings={memoizedEquipmentReadings.groupedReadings}
          />
          {equipments
            .filter((equip) => !filters.equipments.includes(equip.id))
            .map((equip) => (
              <span
                key={equip.id}
                onClick={() => history(`/${configs.title}/detalhes/${equip.id}`)}
              >
                <GraphDonut
                  eventMap={eventsMap}
                  equipment={equip}
                  enhancedReadings={memoizedEquipmentReadings.separatedReadings[equip.id]}
                  lastEvent={memoizedEquipmentReadings.separatedLastEvent[equip.id]}
                />
              </span>
            ))}
        </div>
        <Legends eventArray={eventsList.filter((i) => !filters.events.includes(i.id))} />
      </div>
    </BaseTabMenu>
  );
};

export default MonitorGenericController;
